import React from 'react'
import "./top3form.css"
const Top3Form = ({activeStep, setActiveStep,setUserData}) => {
  const [fname,setFname]=React.useState("");
  const [lastName,setLastName]=React.useState("");
  const handleChangeB = (e) =>{
    setLastName(e.target.value);
    setUserData((prev)=>[prev[0],prev[1],prev[2],e.target.value,prev[4],prev[5],prev[6],prev[7],prev[8],prev[9]]);
    setActiveStep(4);
  }
  const handleChangeA = (e) =>{
    setFname(e.target.value);
    setUserData((prev)=>[prev[0],prev[1],e.target.value,prev[3],prev[4],prev[5],prev[6],prev[7],prev[8],prev[9]]);
  }
  return (
    <>
    <div className="top3form-container">
        <div className="top3form-wrapper">
    <h2 className="top3-head">First name</h2>
    <input type="text" value={fname} placeholder='Choose an option' className='input-top3' onChange={(e)=>handleChangeA(e)} required />
        </div>

        <div className="top3form-wrapper1">
    <h2 className="top3-head">Last name</h2>
    <input type="text" value={lastName} placeholder='Choose an option' className='input-top3' onChange={(e)=>handleChangeB(e)} required />
        </div>
    </div>
    </>
  )
}

export default Top3Form