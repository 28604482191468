import { useNavigate } from "react-router-dom";
// import Header from "../../components/Header/Header";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import DynamicProfilePhoto from "../DynamicProfilePhoto";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
import styles from "./datamangement.module.css";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Sidebar from "../../components/sidebar/Sidebar";
import { setUserInformation, selectUsers } from "../../redux/dataSlices";
import { useDispatch, useSelector } from "react-redux";
export default function DataManagement() {
  const showDatamanagement = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data1 = localStorage.getItem("datapic");
  const parsedData1 = JSON.parse(data1);
  const data = localStorage.getItem("datasso");
  const parsedData = JSON.parse(data);
  const { enqueueSnackbar } = useSnackbar();

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  const [addNewUser, setAddNewUser] = useState(false);
  const handleAddNewUser = () => {
    setAddNewUser(true);
  };

  // add users to be implemented here onSubmit
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      primaryEmail: "",
      secondaryEmail: "",
      phoneNumber: "",
    },
    onSubmit: (values) => {
      dispatch(setUserInformation(values));
      enqueueSnackbar(`User added`, {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
      setAddNewUser(false);
    },
  });
  const users = useSelector(selectUsers);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedUsers(selectAll ? [] : users.map((user, index) => index));
  };

  const handleUserCheckboxChange = (index) => {
    const updatedSelectedUsers = selectedUsers.includes(index)
      ? selectedUsers.filter((selectedIndex) => selectedIndex !== index)
      : [...selectedUsers, index];
    setSelectedUsers(updatedSelectedUsers);
  };
  useEffect(() => {
    enqueueSnackbar("Still Under Development", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
  }, []);
  if (showDatamanagement) {
    if (addNewUser)
      return (
        <div className="flex flex-col">
          <div className="flex flex-row ml-40 mt-12 items-center">
            <svg
              className="mr-4 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                formik.resetForm();
                setAddNewUser(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M11 1L1 11M1 1L11 11"
                stroke="#979B99"
                stroke-linecap="round"
              />
            </svg>
            <p
              className="font-['Lato'] text-sm not-italic font-semibold"
              style={{ color: "#979B99", lineHeight: "16px" }}
            >
              Add new user
            </p>
          </div>
          <div
            className="w-full mt-12"
            style={{
              borderBottom: "1px solid #f2f2f2",
            }}
          ></div>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-row justify-center mt-28">
              <div
                className="flex flex-col items-start p-10 w-[55%]"
                style={{
                  borderRadius: "8px",
                  boxShadow: "0px 2px 13px 1px rgba(7, 24, 20, 0.08)",
                }}
              >
                <div className="flex flex-row">
                  <h1 className="font-['Lato'] text-2xl not-italic font-semibold">
                    User Information
                  </h1>
                </div>
                <div className="flex flex-row w-full justify-between mt-10">
                  <div className="flex flex-col w-[45%]">
                    <label
                      htmlFor="firstName"
                      className="bg-white font-[Lato] font-semibold text-xs"
                      style={{ lineHeight: "16px", color: "#979b99" }}
                    >
                      First name
                    </label>
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      className="py-2 px-3"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      style={{ borderRadius: "8px", border: "1px solid #ccc" }}
                      placeholder="First name"
                      required
                    />
                  </div>
                  <div className="flex flex-col w-[45%]">
                    <label
                      htmlFor="lastName"
                      className="bg-white font-[Lato] font-semibold text-xs"
                      style={{ lineHeight: "16px", color: "#979b99" }}
                    >
                      Last name
                    </label>
                    <input
                      id="lastName"
                      name="lastName"
                      className="py-2 px-3"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      style={{ borderRadius: "8px", border: "1px solid #ccc" }}
                      placeholder="Last name"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-row w-full justify-between mt-10">
                  <div className="flex flex-col w-[45%]">
                    <label
                      htmlFor="primaryEmail"
                      className="bg-white font-[Lato] font-semibold text-xs"
                      style={{ lineHeight: "16px", color: "#979b99" }}
                    >
                      Primary email
                    </label>
                    <input
                      id="primaryEmail"
                      name="primaryEmail"
                      type="email"
                      className="py-2 px-3"
                      onChange={formik.handleChange}
                      value={formik.values.primaryEmail}
                      style={{ borderRadius: "8px", border: "1px solid #ccc" }}
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="flex items-end w-[45%]">
                    <p className="font-[Lato] text-base not-italic font-semibold">
                      @company.tech
                    </p>
                  </div>
                </div>
                <div className="flex flex-row w-full justify-between mt-10">
                  <div className="flex flex-col w-[45%]">
                    <label
                      htmlFor="secondaryEmail"
                      className="bg-white font-[Lato] font-semibold text-xs"
                      style={{ lineHeight: "16px", color: "#979b99" }}
                    >
                      Secondary email
                    </label>
                    <input
                      id="secondaryEmail"
                      name="secondaryEmail"
                      type="email"
                      className="py-2 px-3"
                      onChange={formik.handleChange}
                      value={formik.values.secondaryEmail}
                      style={{ borderRadius: "8px", border: "1px solid #ccc" }}
                      placeholder="First name"
                    />
                  </div>
                  <div className="flex flex-col w-[45%]">
                    <label
                      htmlFor="phoneNumber"
                      className="bg-white font-[Lato] font-semibold text-xs"
                      style={{ lineHeight: "16px", color: "#979b99" }}
                    >
                      Phone number
                    </label>
                    <input
                      id="phoneNumber"
                      name="phoneNumber"
                      className="py-2 px-3"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      style={{ borderRadius: "8px", border: "1px solid #ccc" }}
                      placeholder="Number"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-end w-full mt-10 gap-4">
                  <button
                    className="btn btn-outline-dark"
                    onClick={(e) => {
                      e.stopPropagation();
                      formik.resetForm();
                      setAddNewUser(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-dark"
                    style={{
                      background: "#071814",
                      color: "white",
                    }}
                  >
                    Add new user
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    else
      return (
        <>
          {/* Header1 */}
          {/* <Header onToggleNavbar={toggleNavbar} /> */}
          <Sidebar onToggleNavbar={toggleNavbar} />

          {/* <div>
        <div className="row">
          <div
            className="row"
            style={{
              height: "85px",
              width: "981px",
              backgroundColor: "#fff",
              borderBottom: "1px solid #F2F2F2",
              marginLeft: "200px",
            }}
          >
            <div className="col-6">
              <form
                className="container-fluid"
                style={{ alignItems: "center", Padding: "10px" }}
              >
                <div
                  className="input-group"
                  style={{
                    marginTop: "27px",
                    width: "110%",
                    marginLeft: "40px",
                  }}
                >
                  <span
                    className="input-group-text"
                    style={{ background: "#EDEDED" }}
                    id="basic-addon1"
                  >
                    <img
                      className=" img-fluid "
                      src="/images2/search.svg"
                      alt=""
                    />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    style={{ outline: "none" }}
                    placeholder=""
                    aria-label="Username"
                  />
                </div>
              </form>
            </div>
            <div
              className="col-6"
              style={{
                position: "absolute",
                top: "30px",
                right: "-10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "30px",
              }}
            >
              <img className="img-fluid " src="/images2/bell.svg" alt="" />
              <div
                className="two"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/settings");
                }}
              >
                {parsedData1 ? (
                  <img src={parsedData1.pic} alt="" className="pro-image" />
                ) : (
                  <>
                    {
                      <div className="hr-parent">
                        <div className="hr-child">
                          <h5 className="hr-child-h5" id="hr-title">
                            {parsedData ? (
                              <DynamicProfilePhoto
                                name={parsedData.name}
                                size={100}
                              />
                            ) : (
                              "HR"
                            )}
                          </h5>
                        </div>
                      </div>
                    }
                  </>
                )}
                <div className="HR">
                  <h5>{parsedData ? parsedData.name : "HR"}</h5>
                </div>
              </div>

              <img
                className=" img-fluid"
                style={{ width: "24px", height: "24px" }}
                src="/images2/chevron-down.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div> */}
          <div
            className=""
            style={{
              marginLeft: isNavbarOpen ? "445px" : "250px",
              transition: "1s ease-in-out",
            }}
          >
            <div
              className="flex justify-between align-middle"
              style={{
                height: "85px",
                width: "80%",
                backgroundColor: "#fff",
                borderBottom: "1px solid #F2F2F2",
                // marginLeft: "2%",
              }}
            >
              <div style={{ width: "35vw" }}>
                <form
                  className="container-fluid"
                  style={{ alignItems: "center", Padding: "10px" }}
                >
                  <div
                    className="input-group"
                    style={{
                      marginTop: "27px",
                      width: "110%",
                      // marginLeft: "20px",
                    }}
                  >
                    <span
                      className="input-group-text"
                      style={{ background: "#EDEDED" }}
                      id="basic-addon1"
                    >
                      <SearchIcon></SearchIcon>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      style={{ outline: "none" }}
                      placeholder=""
                      aria-label="Username"
                    />
                  </div>
                </form>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "30px",
                }}
              >
                <div>
                  <NotificationsOutlinedIcon
                    style={{ cursor: "pointer" }}
                  ></NotificationsOutlinedIcon>
                </div>
                <div
                  className="two"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/settings");
                  }}
                >
                  {parsedData1 ? (
                    <img src={parsedData1.pic} alt="" className="pro-image" />
                  ) : (
                    <>
                      {
                        <div className="hr-parent">
                          <div className="hr-child">
                            <h5 className="hr-child-h5" id="hr-title">
                              {parsedData ? (
                                <DynamicProfilePhoto
                                  name={parsedData.name}
                                  size={100}
                                />
                              ) : (
                                "HR"
                              )}
                            </h5>
                          </div>
                        </div>
                      }
                    </>
                  )}
                  <div className="HR">
                    <h5>{parsedData ? parsedData.name : "HR"}</h5>
                  </div>
                </div>

                <div>
                  <ExpandMoreOutlinedIcon
                    style={{ cursor: "pointer" }}
                  ></ExpandMoreOutlinedIcon>
                </div>
              </div>
            </div>
            <div
              className={`${styles.rectangle}`}
              style={{ transition: "1s ease-in-out" }}
            >
              <h3>Data Mangement</h3>
              <div className="flex ">
                <img
                  className={`${styles.command1} img-fluid`}
                  src="/command-1.png"
                  alt=""
                />
                <img
                  className={`${styles.command2} img-fluid`}
                  src="/command-2.png"
                  alt=""
                />
                <img
                  className={`${styles.command3} img-fluid`}
                  src="/command-3.png"
                  alt=""
                />
                <img
                  className={`${styles.command4} img-fluid`}
                  src="/command-4.png"
                  alt=""
                />
              </div>
            </div>
            <div className={styles.teamAccess}>
              <p className={styles.heading}>Team access</p>
              <div className={styles.card}>
                <p className={styles.subHeading}>Chatbot file transfer</p>
                <div
                  style={{ marginBottom: "40px" }}
                  className=" w-100  d-flex justify-content-between"
                >
                  <p className={styles.text}>No team has access</p>
                  <button className="btn">Add Team</button>
                </div>
                <p className={styles.subHeading}>Chatbot voice hurdle</p>
                <div
                  style={{ marginBottom: "40px" }}
                  className=" w-100  d-flex justify-content-between"
                >
                  <p className={styles.text}>
                    you can modify teams by adding new members
                  </p>
                  <button className="btn">Add Team</button>
                </div>
                <div className="w-100 d-flex border-bottom  border-black  justify-content-between ">
                  <p className={styles.subHeading}>1. Chat and Call</p>
                  <div style={{ rotate: "90deg" }}>&hellip;</div>
                </div>
                <div
                  style={{ marginBottom: "40px" }}
                  className="w-100 d-flex border-bottom  border-black  justify-content-between "
                >
                  <p className={styles.subHeading}>2. Chat only team</p>
                  <div style={{ rotate: "90deg" }}>&hellip;</div>
                </div>
                <p className={styles.subHeading}>Stats and Analytics</p>
                <div
                  style={{ marginBottom: "40px" }}
                  className=" w-100  d-flex justify-content-between"
                >
                  <p className={styles.text}>
                    You can modify teams by adding new members
                  </p>
                  <button className="btn">Add Team</button>
                </div>
                <div className="w-100 d-flex border-bottom  border-black  justify-content-between ">
                  <p className={styles.subHeading}>1. Analytics team</p>
                  <div style={{ rotate: "90deg" }}>&hellip;</div>
                </div>
                <div className="w-100 d-flex border-bottom  border-black  justify-content-between ">
                  <p className={styles.subHeading}>2. Developers team</p>
                  <div style={{ rotate: "90deg" }}>&hellip;</div>
                </div>
                <div className="w-100 d-flex border-bottom  border-black  justify-content-between ">
                  <p className={styles.subHeading}>3. Tech team</p>
                  <div style={{ rotate: "90deg" }}>&hellip;</div>
                </div>
              </div>
            </div>
            <p
              style={{ marginTop: "60px", marginBottom: "40px" }}
              className={styles.heading}
            >
              Add -Modify-Delete
            </p>
            <div className={styles.allTeams}>
              <div className="flex justify-between">
                <button
                  className={`${styles.allTeamsBtn} p-2 ml-3 flex justify-center items-center`}
                >
                  All teams
                  <span className="ml-3">
                    <img src="/images2/chevron-down.svg" alt="" />
                  </span>
                </button>
                <div
                  className="mr-3 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddNewUser();
                  }}
                >
                  Add new user
                </div>
              </div>
              <div className="inline-flex flex-col items-start w-full mt-[30px]">
                <table class="table-auto">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                          className="cursor-pointer"
                        />
                      </th>
                      <th className="bg-[#EDEDED] py-1">Name</th>
                      <th className="bg-[#EDEDED]">Email</th>
                      <th className="bg-[#EDEDED]">Status</th>
                      <th className="bg-[#EDEDED]">Last sign-in</th>
                      <th className="bg-[#EDEDED]">Email usage</th>
                      <th className="bg-[#EDEDED]">Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              value={index}
                              checked={selectedUsers.includes(index)}
                              onChange={() => handleUserCheckboxChange(index)}
                              className="cursor-pointer"
                            />
                          </td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>{user.primaryEmail}</td>
                          <td>Active</td>
                          <td>2 days ago</td>
                          <td>0 GB</td>
                          <td>
                            <select>
                              <option>UI designer</option>
                              <option>Developer</option>
                            </select>
                          </td>
                        </tr>
                        <tr
                          className="w-full"
                          style={{ borderBottom: "1px solid #EDEDED" }}
                        ></tr>
                      </React.Fragment>
                    ))}
                    {/* <tr>
                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                    <td>Malcolm Lockyer</td>
                    <td>1961</td>
                  </tr>
                  <tr>
                    <td>Witchy Woman</td>
                    <td>The Eagles</td>
                    <td>1972</td>
                  </tr>
                  <tr>
                    <td>Shining Star</td>
                    <td>Earth, Wind, and Fire</td>
                    <td>1975</td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      );
  }
}
