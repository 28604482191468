import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCountriesForLanguageSelection,
  setSelectedCountries,
} from "../../redux/dataSlices";
import countries, { individualSection } from "../../data/countries";
import sortIfSelected from "../../utils/sort";

function PartB({ indSelectedCountries }) {
  const dispatch = useDispatch();
  const countrySearch = useSelector((state) => state.data.countrySearch);

  const [individualSectionSub1, indexesThatAreSelectedinSub1] = useMemo(
    () => sortIfSelected(individualSection["sub1"], indSelectedCountries),
    [indSelectedCountries]
  );
  const [individualSectionSub2, indexesThatAreSelectedinSub2] = useMemo(
    () => sortIfSelected(individualSection["sub2"], indSelectedCountries),
    [indSelectedCountries]
  );
  return (
    <div
      style={{
        flex: 8,
        display: "flex",
        marginTop: "10px",
      }}
    >
      <div
        className="CountryScroller"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 20%",
          justifyContent: "start",
        }}
      >
        {individualSectionSub1.map((e, idx) => {
          const selected = idx < indexesThatAreSelectedinSub1;
          const country = countries[e];
          const show =
            countrySearch === ""
              ? true
              : countries[e]
                  .toLowerCase()
                  .includes(countrySearch.toLowerCase());
          if (show)
            return (
              <button
                key={e}
                style={{
                  flex: 1,
                  fontWeight: "550",
                  direction: "ltr",
                  fontSize: "15px",
                  border: "none",
                  borderRadius: "8px",
                  padding: "5px",
                  color: "#979B99",
                  outlineWidth: "1px",
                  outlineStyle: "solid",
                  margin: "2% -10px",
                  whiteSpace: "break-spaces",
                  textAlign: "center",
                  minWidth: "90px",
                  backgroundColor: selected ? "#D7F3ED" : "white",
                  outline: selected ? "none" : "1px solid #979B99",
                }}
                onClick={() => {
                  dispatch(setSelectedCountries(e));
                  dispatch(setCountriesForLanguageSelection());
                }}
              >
                {country}
              </button>
            );
          return <></>;
        })}
      </div>
      <div
        className="CountryScroller"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        {individualSectionSub2.map((e, idx) => {
          const selected = idx < indexesThatAreSelectedinSub2;
          const country = countries[e];
          const show =
            countrySearch === ""
              ? true
              : countries[e]
                  .toLowerCase()
                  .includes(countrySearch.toLowerCase());
          if (show)
            return (
              <button
                style={{
                  flex: 1,
                  fontWeight: "550",
                  direction: "ltr",
                  fontSize: "15px",
                  border: "none",
                  borderRadius: "8px",
                  padding: "5px",
                  color: "#979B99",
                  outlineWidth: "1px",
                  outlineStyle: "solid",
                  margin: "2% -10px",
                  whiteSpace: "break-spaces",
                  textAlign: "center",
                  minWidth: "90px",
                  maxWidth: "130px",
                  backgroundColor: selected ? "#D7F3ED" : "white",
                  outline: selected ? "none" : "1px solid #979B99",
                }}
                onClick={() => {
                  dispatch(setSelectedCountries(e));
                  dispatch(setCountriesForLanguageSelection());
                }}
                key={e}
              >
                {country}
              </button>
            );
          else return <></>;
        })}
      </div>
      {/* {[1, 2].map((data, key) => {
        return (
          <div
            key={key}
            className="Hostspot Regions"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "none",
                flexDirection: "row",
                fontSize: "11px",
                maxHeight: "20px",

                color: "#646866",
                // marginTop: "12px",
                marginLeft: "20px",
                // border: "1px solid black",
              }}
            >

            </div>
            <div
              id="region2"
              className="scrollArea"
              style={{
                flex: 1,
                marginLeft: "2px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                direction: "rtl",
                overflowY: "auto",
                maxHeight: "340px",
              }}
            >
              <div style={{ direction: "ltr" }}>
                {masterData[key === 1 ? 4 : 3][key === 1 ? 2 : 4].map(
                  (data3, key2) => {
                    return (
                      <div
                        className="CountryScroller"
                        style={{ display: "flex", margin: "0% 20%" }}
                        key={key2}
                      >
                        <button
                          id={"country" + key2 + "_" + data}
                          style={{
                            flex: 1,
                            fontWeight: "550",
                            direction: "ltr",
                            backgroundColor:
                              key === "1"
                                ? left || right
                                  ? "#D7F3ED"
                                  : "white"
                                : "white",
                            fontSize: "15px",
                            border: "none",
                            borderRadius: "8px",
                            padding: "5px",
                            color: "#979B99",
                            outlineWidth: "1px",
                            outlineStyle: "solid",
                            // lineHeight: "1px",
                            margin: "2% -10px",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                            minWidth: "90px",
                            maxWidth: "130px",
                          }}
                          onClick={(e) => {
                            if (
                              e.target.style.backgroundColor !==
                              "rgb(215, 243, 237)"
                            ) {
                              e.target.style.backgroundColor = "#D7F3ED";
                              e.target.style.outline = "none";
                              const se = selectedCountry + ",~" + data3;
                              dispatch(setSelectedCountries(se));
                            } else {
                              e.target.style.backgroundColor = "white";
                              e.target.style.outlineColor = "#979B99";
                              e.target.style.outlineWidth = "1px";
                              e.target.style.outlineStyle = "solid";
                              if (props.otherData.includes(",~" + data3)) {
                                const se =
                                  selectedCountry.substring(
                                    0,
                                    selectedCountry.indexOf(",~" + data3)
                                  ) +
                                  selectedCountry.substring(
                                    selectedCountry.indexOf(data3) +
                                      data3.length
                                  );
                                dispatch(setSelectedCountries(se));
                                // props.otherController(
                                //   (prev) =>
                                //     prev.substring(
                                //       0,
                                //       prev.indexOf(",~" + data3)
                                //     ) +
                                //     prev.substring(
                                //       prev.indexOf(data3) + data3.length
                                //       )
                                //       );
                              }
                            }
                          }}
                        >
                          {data3}
                        </button>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        );
      })} */}
    </div>
  );
}

export default PartB;
