import "./AccuracyPage.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateAll,
  haveUnsavedThings,
  setCountriesForLanguageSelection,
  togglesaveRegionsToDB,
} from "../../redux/dataSlices";
import { enqueueSnackbar } from "notistack";
import { cn } from "../../lib/utils";
import { addRegionsAndLangs } from "../../redux/apiCalls";
import { Zoom } from "@mui/material";
function AccuracyPage({ isNavbarOpen }) {
  const info = useSelector((state) =>
    state.data.activateAll ? "Deactivate" : "Activate"
  );
  const dispatch = useDispatch();
  const countriesDataWithLangs = useSelector((state) => state.data.countries);
  const saveRegionsToDB = useSelector((state) => state.data.saveRegionsToDB);
  useEffect(() => {
    if (info === "Deactivate" && saveRegionsToDB) {
      addRegionsAndLangs({
        regionsAndLangs: countriesDataWithLangs,
        activated: info === "Deactivate",
      })
        .then((e) => {
          dispatch(haveUnsavedThings({ what: "language", toSet: false }));

          enqueueSnackbar(`All Changes saved`, {
            variant: "default",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            TransitionComponent: Zoom,
          });
          dispatch(togglesaveRegionsToDB());
        })
        .catch((e) => {
          enqueueSnackbar(`Error saving`, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            TransitionComponent: Zoom,
          });
        });
    }
  }, [saveRegionsToDB, info]);

  return (
    <div
      className="AccuracyPage"
      style={
        isNavbarOpen
          ? { transition: "1s ease-in-out" }
          : { right: "200px", transition: "1s ease-in-out" }
      }
    >
      <div className="main-wrap">
        <p className="AccuracyPage-text">
          Automatically select all the languages and choose the highest language
          accuracy available
        </p>

        <button
          className={cn(
            "px-4 py-3 rounded-xl transition-colors border border-black font-semibold hover:bg-primaryColor hover:text-white"
          )}
          onClick={() => {
            dispatch(activateAll());
            //   Object.keys(zones).map((e) => {
            //     dispatch(toggleHotSpotZones(e));
            //     Object.keys(regions).map((e) => {
            //       dispatch(toggleHotSpotRegions({ regionId: e }));
            //       subRegions[e].map((ee) => {
            //         dispatch(toggleSubRegions([e, ee].join("-")));
            //       });
            //   });
            // });
            // Object.keys(countries).map((e) => {
            //   dispatch(setSelectedCountries(e));
            // });
            dispatch(setCountriesForLanguageSelection());

            enqueueSnackbar(`${info}d`, {
              anchorOrigin: {
                horizontal: "center",
                vertical: "top",
              },
            });
          }}
        >
          {info}
          <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
        </button>
      </div>

      <Link to="/language" className="customize-btn">
        customize
      </Link>
    </div>
  );
}
export default AccuracyPage;
