import React from 'react'

function Dashboard() {
  return (
    <div className='d-flex align-items-center justify-content-center text-light'>
      <h1>
        Dashboard page
      </h1>
    </div>
  )
}

export default Dashboard
