import React, { Component } from "react";
import removeBG from "./assets/images/SMM-removebg-preview.png";

class DigitalMarketing extends Component {

  render() {
    return (
      <React.Fragment>
          <div className="center">
            <div className="what-bsns-row center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-6">
                    <div className="bsns-left-sec">
                    <p className="small-heading">Website</p>
                      <br></br>
                      <p className="large-heading">Translation</p>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6">
                    <div className="bsns-right">
                      <div className="srvc-item-sec">
                        <div className="center">
                            <img alt="img" src={removeBG} />
                        </div>
                        <h3 style={{fontSize:"1.75rem"}}>Website Translations</h3>
                        <p className="serviceP">
                          We do translations on the fly, Stop speculating if the
                          potential foreign customers are aware of your
                          business! Localize your global website.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

export default DigitalMarketing;
