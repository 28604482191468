import React from "react";

function Crumb({ name, onClick, isLast,sliderInterface,languageInterface }) {
  return (
    <>
      <span
        className={`${
                        sliderInterface && name === "Accuracy"
                          ? "bg-white/80"
                          : ""
                      } ${
                        languageInterface && name === "Countries"
                          ? "bg-white/80"
                          : ""
                      } hover:bg-white/80 p-1 transition-colors  rounded-md text-sm line-clamp-1 text-ellipsis`}
        onClick={onClick}
      >
        {name}
      </span>
      {!isLast && <span>{">"}</span>}
    </>
  );
}

export default Crumb;
