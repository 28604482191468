import React, { Component } from 'react'
import DetailPopUp2 from './DetailPopUp2';

class FeatureBlog extends Component {
    state = {
        BlogDetail: {}
    }


    openBlogPopUp = (data) => {
        // console.log('data', data);
        this.setState({
            BlogDetail: data
        }, () => {
            window.$('#modelPopUp1').modal('show')
        })

    }
    render() {
        return (
            <React.Fragment>
                <DetailPopUp2 blogDetail={this.state.BlogDetail} />
                <div className="featureBlog center">
                    <div className="container">
                        <div className="featureBlogRow bgColor">
                            <h1 style={{color:"#4c5c69",fontSize:"50px"}}>Featured Blogs</h1>
                        </div>
                        <div className="row bgColor">
                            <div className="col-md-2">
                                <div className="blog-card" style={{ cursor: "pointer" }} onClick={() => { this.openBlogPopUp(this.props.data[0]) }}>
                                    <img src={this.props.data[0].img2} />
                                    <div className="blog-card-text">{this.props.data[0].title1}</div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-2">
                                <div className="blog-card" style={{ cursor: "pointer" }} onClick={() => { this.openBlogPopUp(this.props.data[1]) }}>
                                    <img src={this.props.data[1].img2} />
                                    <div className="blog-card-text">{this.props.data[1].title1}</div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-2">
                                <div className="blog-card" style={{ cursor: "pointer" }} onClick={() => { this.openBlogPopUp(this.props.data[2]) }}>
                                    <img src={this.props.data[2].img2} />
                                    <div className="blog-card-text">{this.props.data[2].title1}</div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                        <div className="row bgColor">
                            <div className="col-md-2"></div>
                            <div className="col-md-2">
                                <div className="blog-card" style={{ cursor: "pointer" }} onClick={() => { this.openBlogPopUp(this.props.data[3]) }}>
                                    <img src={this.props.data[3].img2} />
                                    <div className="blog-card-text">{this.props.data[3].title1}</div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-2">
                                <div className="blog-card" style={{ cursor: "pointer" }} onClick={() => { this.openBlogPopUp(this.props.data[4]) }}>
                                    <img src={this.props.data[4].img2} />
                                    <div className="blog-card-text">{this.props.data[4].title1}</div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-2">
                                <div className="blog-card" style={{ cursor: "pointer" }} onClick={() => { this.openBlogPopUp(this.props.data[5]) }}>
                                    <img src={this.props.data[5].img2} />
                                    <div className="blog-card-text">{this.props.data[5].title1}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default FeatureBlog


