import React, { useState } from "react";
import Header from "../../components/Header/Header";
import DynamicProfilePhoto from "./DynamicProfilePhoto";

const options = [
  "Unread",
  "On hold",
  "Finished",
  "Online",
  "UI team",
  "Dev team",
  "Front end team",
];
const data1 = localStorage.getItem("datapic");
const parsedData1 = JSON.parse(data1);
const data = localStorage.getItem("datasso");
const parsedData = JSON.parse(data);

function Chatpage() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggling = () => {
    setIsOpen(!isOpen);
  };
  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  return (
    <div>
      <Header />
      <div className="left-[426px] top-[3px] absolute flex-col justify-center items-start inline-flex">
        <div className="self-stretch pl-[49px] pr-[120px] py-[30px] justify-start items-center gap-[76px] inline-flex">
          <div className=" pl-4 pr-[470px] py-2  justify-start items-center gap-2.5 flex bg-zinc-100  contenteditable rounded-lg hover:outline ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="search">
                <path
                  id="Vector"
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#646866"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M21.0004 21L16.6504 16.65"
                  stroke="#646866"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </div>
          <div className="justify-start items-center gap-[30px] flex ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="bell">
                <path
                  id="Vector"
                  d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                  stroke="#4C4C4C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                  stroke="#4C4C4C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>

            <div className="justify-start items-center gap-[30px] flex">
              <div className="justify-start items-center gap-4 flex">
                <div className="w-10 h-10 relative">
                  <div className="w-10 h-10 left-0 top-0 absolute bg-violet-100 rounded-lg" />
                  <div className="w-[34px] h-[34px] left-[3px] top-[3px] absolute bg-indigo-300 rounded-lg" />
                  <div className="left-[5px] top-[8px] absolute text-white text-lg font-semibold font-['Lato'] leading-normal">
                    {parsedData1 ? (
                      <img src={parsedData1.pic} alt="" />
                    ) : parsedData ? (
                      <DynamicProfilePhoto name={parsedData.name} size={100} />
                    ) : (
                      "HR"
                    )}
                  </div>
                </div>
                <div className="text-zinc-700 text-lg font-bold font-['Lato'] truncate">
                  {parsedData ? parsedData.name : "HR"}
                </div>
              </div>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="chevron-up">
                  <path
                    id="Vector"
                    d="M18 9L12 15L6 9"
                    stroke="#4C4C4C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-48 pr-48 pt-[30px] pb-7 left-[426px] top-[103px] absolute bg-neutral-50 flex-col justify-start items-start gap-5 inline-flex">
        <div className="justify-start items-start gap-3.5 inline-flex">
          <div className="px-[30px] py-2.5 rounded border border-stone-300 justify-start items-start gap-2.5 flex hover:bg-green-200">
            <button className="text-neutral-400 text-lg font-semibold font-['Lato'] leading-normal">
              Clients
            </button>
          </div>
          <div className="px-[30px] py-2.5 rounded border border-stone-300 justify-start items-start gap-2.5 flex hover:bg-green-200">
            <button className="text-neutral-400 text-lg font-semibold font-['Lato'] leading-normal">
              Agents
            </button>
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-[21px] flex">
          <div className="pl-4 pr-[470px] py-1.5 bg-zinc-100 rounded-lg justify-start items-center gap-2.5 inline-flex hover:outline">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="search">
                <path
                  id="Vector"
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#646866"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M20.9999 20.9999L16.6499 16.6499"
                  stroke="#646866"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </div>
          <div className="w-[124px] h-[29px] relative">
            <div className="w-5 h-5 left-[104px] top-[29px] absolute origin-top-left -rotate-90" />
            <div className="inline-flex">
              <div className="relative inline-flex main-w-[500px] rounded-md bg-white ">
                <a
                  className="text-zinc-900 font-bold font-['Lato']  rounded -1-md px-2 py-2"
                  onClick={toggling}
                >
                  {selectedOption || "Latest"}
                </a>
                <div className="relative">
                  <button
                    type="button"
                    className="border-1 border-gray-10 inline-flex h-full 
                               items-center justify-center rounded-r-md px-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700"
                  >
                    <img src="../images/other/chevron-left.jpg" alt="" />
                  </button>
                </div>
                {isOpen && (
                  <div className="absolute top-6 right-0 z-10 mt-4 min-w-[200px] origin-top-right rounded-md-border border-gray-100 bg-white shadow-lg">
                    {options.map((option) => (
                      <button
                        key={option}
                        type="button"
                        onClick={onOptionClicked(option)}
                        className="block rounded-lg px-4 py-2 text-sm text-gray-500 font-semibold no-underline hover:bg-gray-100"
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex-col justify-start items-start flex">
            <div className="pt-3.5 flex-col justify-start items-start gap-3.5 flex">
              <div className="justify-start items-center gap-[261px] inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <div className="w-[50px] h-[50px] relative">
                    <div
                      className={`w-[50px] h-[50px] left-0 top-0 absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-violet-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div
                      className={`w-[42px] h-[42px] left-[4px] top-[4px] absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-indigo-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div className="left-[15px] top-[19px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      DV
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-800 text-lg font-semibold font-['Lato'] leading-normal">
                      User 327
                    </div>
                    <div className="text-neutral-400 text-sm font-semibold font-['Lato'] leading-none">
                      Hello can you help me?
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-2 inline-flex">
                  <div className="text-neutral-400 text-[11px] font-semibold font-['Lato'] leading-none">
                    3h ago
                  </div>
                  <img src="../images/other/Check.png" alt="" />
                </div>
              </div>
            </div>
            <div className="pt-3.5 flex-col justify-start items-start gap-3.5 flex">
              <div className="justify-start items-center gap-[261px] inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <div className="w-[50px] h-[50px] relative">
                    <div
                      className={`w-[50px] h-[50px] left-0 top-0 absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-violet-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div
                      className={`w-[42px] h-[42px] left-[4px] top-[4px] absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-indigo-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div className="left-[15px] top-[19px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      UI
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-800 text-lg font-semibold font-['Lato'] leading-normal">
                      User 327
                    </div>
                    <div className="text-neutral-400 text-sm font-semibold font-['Lato'] leading-none">
                      Hello can you help me?
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-2 inline-flex">
                  <div className="text-neutral-400 text-[11px] font-semibold font-['Lato'] leading-none">
                    3h ago
                  </div>
                  <div className="w-6 h-6 relative">
                    <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-400 rounded-full" />
                    <div className="left-[8px] top-[4px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      2
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-3.5 flex-col justify-start items-start gap-3.5 flex">
              <div className="justify-start items-center gap-[261px] inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <div className="w-[50px] h-[50px] relative">
                    <div
                      className={`w-[50px] h-[50px] left-0 top-0 absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-violet-300"
                          : "bg-green-400"
                      }`}
                    />
                    <div
                      className={`w-[42px] h-[42px] left-[4px] top-[4px] absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-indigo-300"
                          : "bg-emerald-400"
                      }`}
                    />
                    <div className="left-[17px] top-[17px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      FE
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-800 text-lg font-semibold font-['Lato'] leading-normal">
                      User 327
                    </div>
                    <div className="w-[143px] text-neutral-400 text-sm font-semibold font-['Lato'] leading-none">
                      Thank you
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-2 inline-flex">
                  <div className="text-neutral-400 text-[11px] font-semibold font-['Lato'] leading-none">
                    3h ago
                  </div>
                  <img src="../images/other/Check.png" alt="" />
                </div>
              </div>
            </div>
            <div className="pt-3.5 flex-col justify-start items-start gap-3.5 flex">
              <div className="justify-start items-center gap-[261px] inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <div className="w-[50px] h-[50px] relative">
                    <div
                      className={`w-[50px] h-[50px] left-0 top-0 absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-violet-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div
                      className={`w-[42px] h-[42px] left-[4px] top-[4px] absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-indigo-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div className="left-[15px] top-[19px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      UI
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-800 text-lg font-semibold font-['Lato'] leading-normal">
                      User 327
                    </div>
                    <div className="text-neutral-400 text-sm font-semibold font-['Lato'] leading-none">
                      Hello can you help me?
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-2 inline-flex">
                  <div className="text-neutral-400 text-[11px] font-semibold font-['Lato'] leading-none">
                    3h ago
                  </div>
                  <div className="w-6 h-6 relative">
                    <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-400 rounded-full" />
                    <div className="left-[8px] top-[4px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      2
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-3.5 flex-col justify-start items-start gap-3.5 flex">
              <div className="justify-start items-center gap-[261px] inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <div className="w-[50px] h-[50px] relative">
                    <div
                      className={`w-[50px] h-[50px] left-0 top-0 absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-violet-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div
                      className={`w-[42px] h-[42px] left-[4px] top-[4px] absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-indigo-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div className="left-[15px] top-[19px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      UI
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-800 text-lg font-semibold font-['Lato'] leading-normal">
                      User 327
                    </div>
                    <div className="text-neutral-400 text-sm font-semibold font-['Lato'] leading-none">
                      Hello can you help me?
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-2 inline-flex">
                  <div className="text-neutral-400 text-[11px] font-semibold font-['Lato'] leading-none">
                    3h ago
                  </div>
                  <div className="w-6 h-6 relative">
                    <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-400 rounded-full" />
                    <div className="left-[8px] top-[4px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      2
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-3.5 flex-col justify-start items-start gap-3.5 flex">
              <div className="justify-start items-center gap-[261px] inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <div className="w-[50px] h-[50px] relative">
                    <div
                      className={`w-[50px] h-[50px] left-0 top-0 absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-violet-300"
                          : "bg-green-400"
                      }`}
                    />
                    <div
                      className={`w-[42px] h-[42px] left-[4px] top-[4px] absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-indigo-300"
                          : "bg-emerald-400"
                      }`}
                    />
                    <div className="left-[17px] top-[17px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      FE
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-800 text-lg font-semibold font-['Lato'] leading-normal">
                      User 327
                    </div>
                    <div className="text-neutral-400 text-sm font-semibold font-['Lato'] leading-none">
                      Hello can you help me?
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-2 inline-flex">
                  <div className="text-neutral-400 text-[11px] font-semibold font-['Lato'] leading-none">
                    3h ago
                  </div>
                  <img src="../images/other/Check.png" alt="" />
                </div>
              </div>
            </div>
            <div className="pt-3.5 flex-col justify-start items-start gap-3.5 flex">
              <div className="justify-start items-center gap-[261px] inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <div className="w-[50px] h-[50px] relative">
                    <div
                      className={`w-[50px] h-[50px] left-0 top-0 absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-violet-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div
                      className={`w-[42px] h-[42px] left-[4px] top-[4px] absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-indigo-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div className="left-[15px] top-[19px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      DV
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-800 text-lg font-semibold font-['Lato'] leading-normal">
                      User 327
                    </div>
                    <div className="text-neutral-400 text-sm font-semibold font-['Lato'] leading-none">
                      Hello can you help me?
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-2 inline-flex">
                  <div className="text-neutral-400 text-[11px] font-semibold font-['Lato'] leading-none">
                    3h ago
                  </div>
                  <img src="../images/other/Check.png" alt="" />
                </div>
              </div>
            </div>
            <div className="pt-3.5 flex-col justify-start items-start gap-3.5 flex">
              <div className="justify-start items-center gap-[261px] inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <div className="w-[50px] h-[50px] relative">
                    <div
                      className={`w-[50px] h-[50px] left-0 top-0 absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-violet-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div
                      className={`w-[42px] h-[42px] left-[4px] top-[4px] absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-indigo-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div className="left-[15px] top-[19px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      DV
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-800 text-lg font-semibold font-['Lato'] leading-normal">
                      User 327
                    </div>
                    <div className="text-neutral-400 text-sm font-semibold font-['Lato'] leading-none">
                      Hello can you help me?
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-2 inline-flex">
                  <div className="text-neutral-400 text-[11px] font-semibold font-['Lato'] leading-none">
                    3h ago
                  </div>
                  <img src="../images/other/Check.png" alt="" />
                </div>
              </div>
            </div>
            <div className="pt-3.5 flex-col justify-start items-start gap-3.5 flex">
              <div className="justify-start items-center gap-[261px] inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <div className="w-[50px] h-[50px] relative">
                    <div
                      className={`w-[50px] h-[50px] left-0 top-0 absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-violet-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div
                      className={`w-[42px] h-[42px] left-[4px] top-[4px] absolute rounded-full ${
                        selectedOption == "Unread"
                          ? "bg-indigo-300"
                          : "bg-orange-400"
                      }`}
                    />
                    <div className="left-[15px] top-[19px] absolute text-white text-sm font-semibold font-['Lato'] leading-none">
                      DV
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-800 text-lg font-semibold font-['Lato'] leading-normal">
                      User 327
                    </div>
                    <div className="text-neutral-400 text-sm font-semibold font-['Lato'] leading-none">
                      Hello can you help me?
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-2 inline-flex">
                  <div className="text-neutral-400 text-[11px] font-semibold font-['Lato'] leading-none">
                    3h ago
                  </div>
                  <img src="../images/other/Check.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      //{" "}
    </div>
  );
}

export default Chatpage;
