import { useState } from "react";
import React from "react";
import "./footer.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
const Footer = () => {
  const [data, setData] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const handleInput = (e) => {
    // setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    setData(e.target.value);
  };
  const subscribe = async () => {
    const res = await axios.post(
      // "process.env.REACT_APP_SERVER_URL/nodemailer",
      `${process.env.REACT_APP_SERVER_URL}/api/welcomeemail/email`,
      { data }
    );

    if (res.status === 200) {
      setData("");
      enqueueSnackbar("Thankyou for subscribe us", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    }
  };

  return (
    <>
      <div className="footer">
        <div className="footer-inside">
          <div className="footer-left">
            <div className="footer-left-menu">
              <div className="footer-menu-brand">
                <img
                  src="/images/logo/Vector 10.png"
                  alt=""
                  className="brand-logo-img"
                />
              </div>
              <p className="description">Business starts with localization</p>
              <img
                src="/images/other/Frame 2929.png"
                alt="rating"
                className="rating-img"
              />
            </div>
            <div className="footer-left-menu">
              <h2 className="footer-header">Company</h2>
              <div className="footer-para-container">
                <a
                  href="https://glocalise.notion.site/Team-6ad0dc0aedf6455ab48972b1bac7f027"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Who we are</p>
                </a>
                <a
                  href="https://plea-sure.notion.site/Job-Board-85511c94c4d842568731c65e5b1b5e6a?pvs=4"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Careers</p>
                </a>
                <a
                  href="https://glocalise.notion.site/Partner-with-us-fe71f241436747bbb06568ff50126c6d"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Partner with Us</p>
                </a>
              </div>
            </div>
            <div className="footer-left-menu">
              <h2 className="footer-header">Legal</h2>
              <div className="footer-para-container">
                <a
                  href="https://glocalise.notion.site/Term-Condition-9742e6fa98c54fff90eaf913c272b018"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Terms & Condition</p>
                </a>
                <a
                  href="https://glocalise.notion.site/User-data-b40897d815fb488a9d298e82eedc052c"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">User Data</p>
                </a>
                <a
                  href="https://glocalise.notion.site/Website-Privacy-Policy-d8a83cd77c8b4791b4acf6babe33a8d5"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Privacy Policy</p>
                </a>
              </div>
            </div>
            <div className="footer-left-menu">
              <h2 className="footer-header">Resources</h2>
              <div className="footer-para-container">
                <a
                  href="/services"
                  target="_self"
                  // rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Services</p>
                </a>
                <a
                  href="https://glocalise.notion.site/About-44d8f0d65d084f98b3551179b857c630"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">About</p>
                </a>
                <a
                  href="/blogs"
                  target="_self"
                  // rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Blogs</p>
                </a>
                <a
                  href="https://glocalise.notion.site/Book-a-Demo-Calendly-1eb14b0731c145519561ca8ca7d266e2"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Book a Demo</p>
                </a>
                <a
                  href="https://glocalise.notion.site/Help-Center-a07061de083e48d0aaf5b7b1383f4fed"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Help Center</p>
                </a>
              </div>
            </div>
            <div className="footer-left-menu">
              <h2 className="footer-header">Solutions by goal</h2>
              <div className="footer-para-container">
                <a
                  href="https://glocalise.notion.site/Translate-website-c2485baee2db443687dda34255571c8e"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Translate website</p>
                </a>
                <a
                  href="https://glocalise.notion.site/Multilingual-Chatbot-fa93e3a420774cf2a4d19f025874a98c"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Multilingual Chatbot</p>
                </a>
                <a
                  href="https://glocalise.notion.site/Stats-and-Analytics-c2ff25062d814d4ea89ee12a746a9c5e"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Stats and Analytics</p>
                </a>
                <a
                  href="https://glocalise.notion.site/Link-Building-ec6fa7251f3042bdaf64b72178c7b2a2"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Link Building</p>
                </a>
                <a
                  href="https://glocalise.notion.site/Expand-via-domain-5fc5937ac66d4133a0c15b7e3b429bc7"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footer-para">Expand via Domains</p>
                </a>
              </div>
            </div>
          </div>
          <div className="footerRight">
            <h2 className="footer-header">Subscribe</h2>
            <p className="footer-para">
              Enter your email to get notified about our new solutions
            </p>
            <div className="footer-form">
              <input
                type="text"
                placeholder="Enter your email"
                className="footer-inputbar"
                value={data}
                onChange={handleInput}
              />
              <button className="footer-input-button" onClick={subscribe}>
                Send &gt;
              </button>
            </div>
            <div className="d-flex flex-row align-items-center mt-4">
              <a href="tel:+1 (445) 212-5275">
                <i
                  className="bi bi-telephone"
                  style={{ color: "#646866", fontSize: "13px" }}
                ></i>
              </a>
              <a
                href="tel:+1 (445) 212-5275"
                style={{ textDecoration: "none" }}
              >
                <div className="mx-2 tel-number">+1 (445) 212-5275</div>
              </a>
            </div>
            <div className="footer-social-icon">
              <a
                href="https://wa.me/14452125275"
                style={{
                  backgroundColor: "#f2f2f2",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50px",
                  marginTop: "4px",
                }}
              >
                <img
                  src="/images/other/WhatsApp.png"
                  alt="whatsapp"
                  className="social-icon"
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "0px",
                    marginLeft: "3px",
                    marginBottom: "6px",
                  }}
                />
              </a>
              <a href="https://www.facebook.com/people/Glocalise/100092326386460/">
                <img
                  src="/images/other/Group 452.png"
                  alt="instagram"
                  className="social-icon"
                />
              </a>
              <a href="https://twitter.com/glocali_se">
                <img
                  src="/images/other/Group 453.png"
                  alt="twitter"
                  className="social-icon"
                />
              </a>

              <a href="https://www.instagram.com/glocali_se/">
                <img
                  src="/images/other/Group 454.png"
                  alt="facebook"
                  className="social-icon"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-inside2">
          <div
            className="d-flex flex-column justify-content-start w-100 footer-content"
            style={{ padding: "0rem 3rem" }}
          >
            <img
              src="/images/other/Group 12.png"
              alt=""
              style={{ width: "100%", marginLeft: "-10px" }}
            />
            <div
              style={{ fontSize: "12px", marginTop: "15px", color: "#646866" }}
            >
              We are proud to partner with AWS and leverage their cutting-edge
              technologies to deliver exceptional products and services to our
              customers. AWS has been instrumental in enabling us to innovate
              and scale rapidly.
            </div>
          </div>
          <div
            className="d-flex flex-column justify-content-start w-100 footer-content"
            style={{ padding: "0rem 3rem" }}
          >
            <img
              src="/images/other/googleFooter.jpg"
              alt=""
              style={{ width: "100%", marginLeft: "-10px" }}
            />
            <div
              style={{ fontSize: "12px", marginTop: "15px", color: "#646866" }}
            >
              Our partnership with Google for Startups is a great opportunity to
              leverage their expertise, resources, and global reach to
              accelerate our growth and develop innovative solutions that
              deliver exceptional value to our customers.
            </div>
          </div>

          <div
            className="d-flex flex-column justify-content-start w-100 footer-content"
            style={{ padding: "0rem 3rem" }}
          >
            <img
              src="/images/other/ms.png"
              alt=""
              style={{ width: "100%", marginLeft: "-11%" }}
            />
            <div
              style={{ fontSize: "12px", marginTop: "15px", color: "#646866" }}
            >
              We are delighted to have the support of Microsoft as our partner e
              look forward to working closely with Microsoft and leveraging
              their expertise to take our business to new heights. We believe
              that this partnership will be instrumental in achieving our goals
              and delivering value to our customers.
            </div>
          </div>
          <div
            className="d-flex flex-column justify-content-start w-100 footer-content"
            style={{ padding: "0rem 3rem" }}
          >
            <img src="/images/other/nvi.png" alt="" style={{ width: "100%" }} />
            <div
              style={{ fontSize: "12px", marginTop: "15px", color: "#646866" }}
            >
              We're thrilled to have Nvidia as our partners. Their support and
              expertise will help us deliver exceptional products to our
              customers and accelerate our growth. We look forward to working
              closely with them to achieve our goals.
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <h2 className="footer-bottom-head">
            {" "}
            &copy; 2024 Glocali.se All rights reserved
          </h2>
        </div>
      </div>
    </>
  );
};

export default Footer;
