import React, { Component } from "react";
import user from "./assets/images/user.png";
import DetailPopUp from "./DetailPopUp";

class BlogSection extends Component {
  state = {
    BlogDetail: {},
    isModalOpen: false,
  };

  openBlogPopUp = (data) => {
    this.setState({
      BlogDetail: data,
      isModalOpen: true,
    });
  };

  closeBlogPopUp = () => {
    this.setState({
      BlogDetail: {},
      isModalOpen: false,
    });
  };

  render() {
    return (
      <div onClick={(e)=>{
        e.stopPropagation();
        this.closeBlogPopUp();
      }
      }>
        <DetailPopUp
          blogDetail={this.state.BlogDetail}
          isOpen={this.state.isModalOpen}
          onClose={this.closeBlogPopUp}
        />
        <div className="blogSection center">
          <div className="container">
            <div className="blog row center">
              {this.props.data.map((blog, index) => (
                <div className="col-auto" key={index}>
                  <div
                    className="card mx-2"
                    style={{ height: "80%", width: "19rem" }}
                  >
                    <img
                      src={blog.blogDetailImage}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title">{blog.title}</h5>
                      <div className="inner-body-blog">
                        <img alt="img" src={user}></img>
                        <p>Harshit Saini</p>
                        <button
                          data-bs-toggle="modal"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.openBlogPopUp(blog);
                          }}
                        >
                          view more
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogSection;
