import React, { useState, useEffect } from "react";
import './passbook.css';
import {PDFDocument} from 'pdf-lib';



const TransactionItem = ({ transaction }) => {
  const { id,type, download, amount, status, time } = transaction;
  const statusColorClass = status === "failed" ? "bg-red-200" : "bg-yellow-100";
  const statusColorClass2 = status === "failed" ? "bg-red-400" : "bg-yellow-300";
  
  const generatePdf = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const { width, height } = page.getSize();

    page.drawText(`Transaction ID: ${id}`, { x: 50, y: height - 70});
    page.drawText(`Transaction Type: ${type}`, { x: 50, y: height - 90 });
    page.drawText(`Amount: ${amount}`, { x: 50, y: height - 110 });
    page.drawText(`Status: ${status}`, { x: 50, y: height - 130 });
    page.drawText(`Timestamp: ${time}`, { x: 50, y: height - 150 });

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  };
  const handleDownload = async () => {
    const pdfBytes = await generatePdf();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `transaction_${id}.pdf`;
    a.click();
    URL.revokeObjectURL(url);
  };







  return (
    <>
   
      <tr className={statusColorClass}>
      
        <td className="py-2 px-3 border border-dark"><span className="px-2">{id}</span>
                   {type}</td>
                   <td className="py-2 px-3 border border-dark">
          <button style={{color:'blue'}} onClick={handleDownload}>{download}</button>
        </td>
        <td className="py-2 px-3 border border-dark">{amount}</td>
        <td className="py-2 px-3 border border-dark">{status}</td>
        <td className="py-2 px-3 border border-dark w-[100%]">{time}</td>
      </tr>
    </>
  );
};

const Passbook = () => {
  const [transactions, setTransactions] = useState([]);
  const [selectedType, setSelectedType] = useState("all");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);


  useEffect(() => {
    const getTransactions = async () => {
      try {
        const result = await fetch(
          "https://jsonplaceholder.typicode.com/posts"
        );
        if (result.ok) {
          const data = await result.json();

          
          const types = [
            "invoice",
            "transaction_failed",
            "transaction_success",
            "money_added",
            "money_withdrawn",
          ];
          const state = [
            "success",
            "failed"
          ]
          const mappedData = data.map((item) => {
            let type = types[Math.floor(Math.random() * types.length)];
            let Optstate = state[Math.floor(Math.random() * state.length)];
             let Id = item.id;
            if (type === "transaction_failed") {
              Optstate = "failed";
            } else if (type === "transaction_success") {
              Optstate = "success";
            }
          
            return {
              type: type,
              id:Id,
              download: "CHAJO19281",
              amount: Math.floor(Math.random() * 100),
              status: Optstate,
              time: new Date().toLocaleString(),
            };
          });

          const filter = selectedType ==="all"
          ? mappedData
          :mappedData.filter((transaction) => transaction.type === selectedType);

          setTransactions(filter);
        } else {
          console.log("error fake api");
        }
      } catch (error) {
        console.log("error getting data", error);
      }
    };
    getTransactions();
  }, [selectedType]); 

  return (
    <>
      <div style={{ borderRadius: '25px', background: 'var(--Tertiary-Container, #E8E8FD)'}}
       className="main-div d-flex w-[880px] h-[500px]  border border-black p-16 flex flex-col gap-[4]  self-stretch">
        <h1 style={{fontsize: '24px'}}
        className="className= font-medium font-lato text-[24px]">Passbook</h1>
        <div className="table-div mt-4 d-flex h-[450px] overflow-y-auto  gap-[16px]">
        
        <table
         className="w-full items-start rounded-[25px]border border-dark self-stretch ">
          <thead className="static">
            <tr style={{backgroundColor:'#A1A3F7'}}>
             <th className=" h-[8px] py-1 px-3 border border-dark"
             onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
                 
                {/* {isDropdownVisible && ( */}
                  <select style={{backgroundColor:'#A1A3F7', borderStyle:'none', outline:'none'}}
                   className=""
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    <option style={{backgroundColor:'#ffffff'}} value="all">Types</option>
                    <option style={{backgroundColor:'#ffffff'}} value="invoice">Invoice</option>
                    <option style={{backgroundColor:'#ffffff'}} value="transaction_failed">
                      Transaction Failed
                    </option>
                    <option style={{backgroundColor:'#ffffff'}} value="transaction_success">
                      Transaction Success
                    </option>
                    <option style={{backgroundColor:'#ffffff'}} value="money_added">Money Added</option>
                    <option style={{backgroundColor:'#ffffff'}} value="money_withdrawn">Money Withdrawn</option>
                  </select>
                {/* )} */}
              </th> 
              <th className="py-1 px-3 border border-dark">Transaction</th>
              <th className="py-1 px-3 border border-dark">Amount</th>
              <th className="py-1 px-3 border border-dark">Status</th>
              <th className="py-1 px-3 border border-dark">Timestamp</th>
            </tr>
          </thead>
          <tbody  className="max-h-[450px] overflow-y-auto ">
            {transactions.map((transaction, index) => (
              <TransactionItem key={index} transaction={transaction} />
            ))}
          </tbody>
        </table>
        </div>
      
      </div>
    </>
  );
};

export default Passbook;
