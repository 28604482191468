import { Chart as ChartJs } from 'chart.js/auto';
import React from 'react'
import { Bar } from "react-chartjs-2"
const Chart = ({ chartdata }) => {
    return (
        <Bar data={chartdata} options={

            {
                scales: {

                    x: {
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                            drawBorder: false,
                            lineWidth: 5
                        },
                        title: {
                            display: true,

                            color: "grey",
                            font: {
                                size: 14,

                            }
                        }
                    },
                    y: {
                        border: {
                            display: false
                        },
                        ticks: {
                            stepSize: 10,
                            callback: (value, index, values) => {
                                return `${value}%`
                            }
                        },
                        title: {
                            display: true,
                            color: "grey",
                            font: {
                                size: 14,

                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    }
                }


            }

        } />
    )
}

export default Chart


