import { useState } from "react";
import { Search, CheckSquare2, Square } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";

import { Button } from "../../ui/button";
import { cn } from "../../lib/utils";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUnsavedLangSelection } from "../../redux/dataSlices";
import countries from "../../data/countries";
export function LanguageSelection({ langs, countryId }) {
  const dispatch = useDispatch();
  const languagesId = useSelector((state) => state.data.languages);
  const [open, setOpen] = useState(false);
  const langKeys = useMemo(() => Object.keys(languagesId), []);
  const selectedlangs = useMemo(() => langs.map((lang) => lang.id), [langs]);
  const defaultAccuracyVal = useSelector((state) => state.data.defaultAccuracy);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className="rounded-2xl px-2">
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between font-normal text-base"
          style={{ fontFamily: "Lato", color: "#646866" }}
        >
          Search/Add
          <Search className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full max-h-96 overflow-y-auto p-0">
        <Command>
          <CommandInput placeholder="Search Language" />
          <CommandEmpty>No Languages found</CommandEmpty>
          <CommandGroup heading="Suggestions">
            {langKeys.map((langId) => {
              const language = languagesId[langId];
              const selected = selectedlangs.includes(langId);
              return (
                <CommandItem
                  key={langId}
                  value={languagesId[langId]}
                  onSelect={() => {
                    dispatch(
                      setUnsavedLangSelection({
                        remove: selected,
                        countryId: countryId,
                        langData: {
                          countryId,
                          language,
                          id: langId,
                          accuracy: defaultAccuracyVal,
                        },
                      })
                    );
                  }}
                >
                  {selected ? (
                    <CheckSquare2 className={cn("mr-2 h-4 w-4")} />
                  ) : (
                    <Square className={cn("mr-2 h-4 w-4")} />
                  )}

                  {language}
                </CommandItem>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
