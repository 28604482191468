import PropTypes from "prop-types";
import React from "react";
import "./Email_Settings.css";
import Chatbot from "./Chatbot";
export const Email_settings = (props) => {
  // const =

  // const companyparsedData1 = JSON.parse(companydata1);
  const {
    fontFamilyHTS,
    fontWeightHTS,
    fontSizeHTS,
    textHTS,
    descTBS,
    fontFamilyTBS,
    fontWeightTBS,
    fontSizeTBS,
    fontFamilyBTS,
    fontWeightBTS,
    fontSizeBTS,
    changepass,
     
  } = props.emailSettings;

  // const companiedata1 = localStorage.getItem("companiedatapic");
  // const companieparsedData1 = JSON.parse(companiedata1);

  // console.log(companieparsedData1.companiedatapic);

  return (
    // <div className={`CTA-button ${className}`}>
    //   <div className={`group ${groupClassName}`}>
    //     <div className={`overlap-group ${overlapGroupClassName}`}>
    //       <p className={`payments ${paymentsClassName}`}>{text}</p>
    //     </div>
    //   </div>
    // </div>
    <div>
      <div className="email-settings-card">
        <div className="doted-line">
          <hr />
        </div>
        <div className="main-content-div d-flex ">
          <div className="areyousure">
            {/* <h1>{props.text.desc}</h1> */}
            <h1
              style={{
                fontSize: fontSizeHTS,
                fontWeight: fontWeightHTS,
                fontFamily: fontFamilyHTS,
                color:props.emailHeaderColor,
              }}
              
            >
              {textHTS}
            </h1>
          </div>
          
          <div className="descriptionofareyousure">
            <p
              style={{
                fontSize: fontSizeTBS,
                fontWeight: fontWeightTBS,
                fontFamily: fontFamilyTBS,
                color:props.emailTextBoxColor
              }}
            >
              {/* {props.description.data} */}
              {descTBS}
            </p>
          </div>
          <div
            className="changepassBtn"
          >
            <p style={{fontSize: fontSizeBTS,
              fontWeight: fontWeightBTS,
              fontFamily: fontFamilyBTS,
              backgroundColor: props.emailBottomColor,
              color: props.emailBottomTextColor
            }}>{changepass}</p>
          </div>
        </div>
        {/* <div className="pro-image" style={{width:"100px",height:"100px",background:"black",position:"absolute",display:"flex",left: "231px",
    top: "100px"}}></div> */}
    
        <div className="doted-line">
          <hr />
        </div>
        <div className="unsubscribesentence">
          <p>
            If you no longer wish to recive this emails please click Unsubscribe
            at any moment.
          </p>
        </div>
        {/* <div className="img">kyyjdfff<img src={companydata1.companydatapic} alt="" /></div> */}
{/* <div>{props.photu}</div> */}

{/* <img src={companieparsedData1.companiepic} alt="" style={{marginTop:"89px",
    width: "90px",
    height: "79px",
    position: "absolute",
    marginLeft: "218px"}}/> */}
      </div>
    
    </div>
  );
};
