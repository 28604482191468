import React from 'react'
import "./secondaryEmail.css";

function SecondaryEmail({setSecEmail,setEmailConfirmVisible,setForgotten}) {
  return (
    <div>
      <div className='d-flex flex-row justify-content-between align-items-center upper-email'>
        <div className='heading-email'>
        Secondary Email

        </div>
        <i class="bi bi-x-lg cur" onClick={() => setSecEmail(false)}></i>
      </div>
      <div className='d-flex flex-column inside-email'>
        <div className='text-email'>
            New email
        </div>
        <input type="text" className='input-email'/>

      </div>
      <div className='d-flex flex-column inside-email'>
        <div className='text-email'>
            Current password
        </div>
        <input type="text" className='input-email'/>

      </div>
      <div className='forgot' onClick={()=>{setEmailConfirmVisible(true);setSecEmail(false);setForgotten(true)}}>
        Forgotten password?
      </div>
      <div className='d-flex flex-row end-email'>
        <button className='cancel-email'>
            Cancel
        </button>
        <button className='submit-email' onClick={()=>{
          
          setEmailConfirmVisible(true);
          setSecEmail(false)}
          }>
            Submit
        </button>
      </div>
    </div>
  )
}

export default SecondaryEmail
