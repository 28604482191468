import React from "react";
import ZoneButton from "./ZoneButton";
import RegionButton from "./RegionButton";
import ChildOne from "./ChildOne";
import WhiteLoad from "./WhiteLoad";
import "./test.css";
import SearchBar from "./SearchBar";
import { useSelector } from "react-redux";
import zones from "../../data/zones";
import { regionNames } from "../../data/regions";
import { allSubRegions } from "../../data/subRegions";
import { selectedRegions } from "../../redux/dataSlices";
function UpperDiv(props) {
  const hotspotRegionKeys = useSelector(selectedRegions);
  const hotspotZones = useSelector((state) => state.data.hotspotZones);
  return (
    <div style={{ flex: 1, display: "flex", border: "0px solid green" }}>
      <div
        style={{
          flex: 1,
          margin: "2%",
          borderRadius: "8px",
          padding: "10px",
          display: "flex",
          width: "100px",
          flexDirection: "column",
          boxShadow: "0px 0px 5px rgb(180, 180, 180)",
        }}
      >
        <SearchBar placeholder={true} />
        <div
          style={{
            flex: 1,
            fontSize: "11px",
            marginTop: "10px",
            maxHeight: "20px",
            color: "#CCCCCC",
          }}
        >
          Hotspot Zones
        </div>
        <div
          id="zone"
          className="scrollArea 	"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            padding: "10px",
            overflowY: "hidden",
            overflowX: "auto",
            paddingBottom: "20px",
          }}
        >
          {Object.keys(zones).map((zoneKey) => {
            return (
              <ZoneButton key={zoneKey} id={zoneKey} text={zones[zoneKey]} />
            );
          })}
        </div>
        <div
          style={{
            flex: 1,
            fontSize: "11px",
            maxHeight: "20px",
            color: "#CCCCCC",
            // border: "1px solid black",
          }}
        >
          Regions
        </div>
        <div
          style={{
            flex: 6,
            display: "flex",
            overflowX: "auto",
          }}
        >
          <div
            className="Hostspot Regions"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              width: "30vw",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                fontSize: "11px",
                maxHeight: "20px",
                color: "#646866",
                width: "auto",
              }}
            >
              Hotspot Regions
            </div>
            <div
              id="region"
              className="scrollArea"
              style={{
                flex: 1,
                marginLeft: "0px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                // direction: "rtl",
                overflowY: "auto",
                minHeight: "240px",
                padding: "0px 30px 10px 0px",
                maxHeight: "250px",
                width: "80%",
              }}
            >
              {Object.keys(regionNames).map((region) => {
                return <RegionButton key={region} regionId={region} />;
              })}
            </div>
          </div>
          <div
            className="ChildElements scrollArea"
            style={{
              flex: 2,
              display: "flex",
              padding: "10px",
              overflowX: "auto",
              marginBottom: "0px",
              marginLeft: "-9%", //editted
              borderLeft: "0.1px solid grey", //editted
            }}
          >
            {hotspotRegionKeys.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ flex: 1 }}>
                  <WhiteLoad />
                </div>
                <div style={{ flex: 1 }}>
                  <WhiteLoad />
                </div>
              </div>
            ) : (
              <div>
                {/* Whenever The hotspotRegionKeys STATE Changes they are Iterated and displayed in the right area of the UpperDiv Section */}
                {hotspotRegionKeys.map((region) => {
                  return (
                    <ChildOne
                      key={region}
                      region={region}
                      regions={allSubRegions[region]}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpperDiv;
