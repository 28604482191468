import React, { useEffect, useMemo, useState } from "react";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
import "./Drop.css";
import { RefreshCcw, Check, Unplug } from "lucide-react";
import countries from "../data/countries";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";

import {
  checkStausForHostName,
  deleteHostname,
  registerHostname,
} from "../redux/apiCalls";
import { pushToKv2 } from "../redux/apiCalls";
import DomainElement from "./DomainElement";
import { useDispatch, useSelector } from "react-redux";
import {
  setCountriesForLanguageSelection,
  setDomainForCountry,
  setDontShow,
  setSelectedCountries,
  setStatusForHostNames,
} from "../redux/dataSlices";
import { Button } from "../ui/button";
import { cn } from "../lib/utils";
import { Trash2 } from "lucide-react";
import axios from "axios";
import { publicRequest } from "../requestMethods";
import { removeCountriesFromSaved } from "../redux/userRedux";

const classes = [
  "status-active",
  "status-unavailable",
  "status-notregistered",
  "status-pending",
  "status-pending_validation",
];
function Drop({
  keyz,
  countryId,
  wantPast,
  automatically,
  bolleandata: configType,
  alreadyConfigured,
}) {
  const [alertDialogShow, setAlertDialogShow] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const status = useSelector(
    (state) => state.data.countries[countryId]?.status ?? "unavailable"
  );
  const bolleandata = useMemo(
    () => (wantPast ? false : configType),
    [configType]
  );
  const dontShow = useSelector((state) =>
    wantPast ? false : state.data.countries[countryId]?.dontShow ?? false
  );
  const inputText = useSelector((state) => state.data.inputText);
  const { enqueueSnackbar } = useSnackbar();
  const tld = countryId.split("-").at(-1);
  const domainnames = inputText.slice(4);
  const suffix = domainnames + "/" + tld;
  const prefix = tld + "." + domainnames;
  const [premiumvalue, setPremiumvalue] = useState("");
  const [premium, setPremium] = useState("");
  const [domainType, setDomainType] = useState(automatically ? 2 : 1);
  const [disable, setDisable] = useState(true);
  const [newbollean, setNewbollean] = useState(bolleandata);
  const [texthighlight, setTexthighlight] = useState(false);
  const [domainpermanent, setDomainpermanent] = useState(false);
  const [hostName, setHostName] = useState(suffix);
  // console.log("e ", domainnames);
  // React.useEffect(() => {
  // 	alert("Domain typed changed");
  // }, [domainType]);
  useEffect(() => {
    setHostName(suffix);
  }, [suffix]);

  useEffect(() => {
    setPremiumvalue("www." + domainnames.split(".")[0] + "." + tld);
    setPremium(premiumvalue);
  }, [inputText, domainnames, countryId]);

  const handleSwitchView = () => {
    if (alreadyConfigured) {
      enqueueSnackbar("Already Configured", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
      return;
    }
    if (bolleandata === true) {
      enqueueSnackbar(
        "Your domain setting is selected automatically if you want to change DNS setting select manually ",
        {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        }
      );
      return;
    }
    setView((prev) => !prev);
  };
  function commonClick(prop) {
    if (domainType !== 3 && !domainpermanent) {
      setDomainType(Number(prop.target.dataset["domaintype"]));
    }
  }
  const dispatch = useDispatch();
  const topLevelHostNameId = useSelector(
    (state) => state.data.topLevelHostNameId
  );
  const clickHandler = (e) => {
    if (alreadyConfigured) return;
    if (e.target.value === "1") {
      setDomainType(1);
      setNewbollean(true);
      setTexthighlight(false);
    } else if (e.target.value === "2") {
      setDomainType(2);
      setNewbollean(false);
      setTexthighlight(false);
    } else if (domainpermanent === true) {
      setDomainType(4);
      setNewbollean(false);
      setTexthighlight(true);
    } else {
      setDomainType(3);
      setNewbollean(false);
      setTexthighlight(true);
    }
    checkStatus();
  };
  useEffect(() => {
    if (automatically) {
      checkStatus();
    }
    if (wantPast) return;
    setDomainType(automatically ? 2 : 1);
  }, [automatically]);

  async function kvhandler({ url, id, type }) {
    if (alreadyConfigured) return;
    // alert(CountryCode[dummyData[0]]);
    try {
      await pushToKv2({
        key: url,
        value: id,
      });
      dispatch(
        setDomainForCountry({
          countryKey: tld,
          domain: type === "suffix" ? `https://www.${url}` : url,
        })
      );
      successPopUp("Data Saved successfully");
      checkStatus();
    } catch (error) {
      enqueueSnackbar("Unable to save", {
        variant: "error",
        anchorOrigin: {
          vertical: "toautomaticallyp",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    }
    // const DataToPush = {};
    // DataToPush.regions = {};
    // DataToPush.type = "CSR";
    // let TOKEN = user._id;
    // [0].map(async () => {
    //   var obj = {};
    //   var obj2 = {};
    //   let countrycode = tld;
    //   let value = url;
    //   obj2["domain"] = value;
    //   var temp_obj = {};
    //   temp_obj[countrycode] = 1;
    //   temp_obj["default"] = countrycode;
    //   obj2.langs = temp_obj;
    //   obj[tld] = obj2;

    //   DataToPush.regions[countrycode] = obj2;
    //   await pushToKv2({
    //     key: value,
    //     value: TOKEN,
    //   });
    //   return null;
    // });
    // let value = url;
    // DataToPush.regions["default"] = {
    //   domain: value,
    //   en: 1,
    //   default: "en",
    // };
    // DataToPush.parent = "https://" + url;
    // console.log(DataToPush);
    // console.log("Keyname: '" + TOKEN + "'");
    // await pushToKv({
    //   key: TOKEN,
    //   value: JSON.stringify({ data: DataToPush }),
    // }).then((data) => {
    // if (data.status === 200) {
    //   enqueueSnackbar("Data Saved successfully", {
    //     variant: "default",
    //     anchorOrigin: {
    //       vertical: "top",
    //       horizontal: "center",
    //     },
    //     TransitionComponent: Zoom,
    //   });
    // } else {
    //   enqueueSnackbar("Data Could not be saved.Try again later", {
    //     variant: "error",
    //     anchorOrigin: {
    //       vertical: "top",
    //       horizontal: "center",
    //     },
    //     TransitionComponent: Zoom,
    //   });
    // }
    // });
  }
  const [connected, setConnected] = useState(false);

  async function handleDelete() {
    if (alreadyConfigured) return;
    try {
      const res = await deleteHostname({ url: hostName, country: countryId });
      if (res.data.success) {
        successPopUp("Hostname deleted successfully");
        if (wantPast) {
          dispatch(removeCountriesFromSaved(countryId));
        }
        dispatch(setSelectedCountries(countryId));
      } else {
        enqueueSnackbar("Hostname not deleted successfully", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error deleting", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    }
  }
  const [refreshing, setRefreshing] = useState(false);

  async function checkStatus() {
    if (hostName.length === 0) return;
    setRefreshing(true);
    try {
      const res = await checkStausForHostName({
        url: hostName,
        country: countryId,
        tld_id: topLevelHostNameId,
      });
      if (res.data.success) {
        if (!view) {
          const type =
            ["prefix", "suffix", "premiumValue", "premium"].indexOf(
              res.data.data.domain.type ?? "suffix"
            ) + 1;
          setDomainType(type);
          switch (type) {
            case 1:
              setNewbollean(true);
              setTexthighlight(false);
              break;
            case 2:
              setNewbollean(false);
              setTexthighlight(false);
              break;
            case 4:
              setNewbollean(false);
              setTexthighlight(true);
              break;
            case 3:
              setNewbollean(false);
              setTexthighlight(true);
              break;
          }
        }
        dispatch(
          setStatusForHostNames({
            countryKey: countryId,
            status: res.data.data.cf.ssl.status,
          })
        );
      } else {
        dispatch(
          setStatusForHostNames({
            countryKey: countryId,
            status: "unavailable",
          })
        );
      }
    } catch (error) {
      dispatch(
        setStatusForHostNames({
          countryKey: countryId,
          status: "error",
        })
      );
      enqueueSnackbar("Error getting status", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    } finally {
      setRefreshing(false);
    }
  }
  useEffect(() => {
    if (domainType === 1) {
      setHostName(prefix);
    } else if (domainType === 2) {
      setHostName(suffix);
    } else if (domainType === 3) {
      setHostName(premiumvalue);
    } else if (domainType === 4) {
      setHostName(premium);
    }
  }, [domainType]);
  const handleChange = (withCheck = true) => {
    if (!topLevelHostNameId || topLevelHostNameId.length === 0) {
      enqueueSnackbar("You need to register tld to continue", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
      return;
    }
    if (withCheck && !connected) {
      enqueueSnackbar("Add CNAME record to continue", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
      return;
    }
    let type;
    switch (domainType) {
      case 1:
        type = "prefix";
        break;
      case 2:
        type = "suffix";
        break;
      case 3:
        type = "premiumValue";
        break;
      case 4:
        type = "premium";
        break;

      default:
        break;
    }
    registerHostname({
      url: hostName,
      type,
      country: countryId,
      tld_id: topLevelHostNameId,
    })
      .then(() => kvhandler({ url: hostName, id: topLevelHostNameId, type }))
      .catch(() => console.log("Some error occured while registering hostname"))
      .finally(() => {
        setView((prev) => !prev);
      });
  };
  const [view, setView] = useState(false);
  const successPopUp = (text) => {
    enqueueSnackbar(text, {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
  };

  const [textToCopyCvalue, setTextToCopyCvalue] = useState(
    "saas.glocalise.site"
  );
  const [isCopiedCvalue, setIsCopiedCvalue] = useState(false);
  const handleCopyClickCvalue = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopyCvalue)
      .then(() => {
        setIsCopiedCvalue(true);
        setTimeout(() => setIsCopiedCvalue(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  if (dontShow) return <></>;
  return (
    <div
      className="mainDropDiv"
      style={{
        display: "flex",
        flexDirection: "column",
        // margin: "1px",
        padding: "10px",
        borderBottom: "1px solid #ededed	",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // border: "1px solid red",
          // maxHeight: "10px",
        }}
      >
        <div style={{ flex: 1 }}>
          <input type="checkbox" />
        </div>
        {hostName === premiumvalue && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{
              marginLeft: "-29px",
              marginRight: "5px",
            }}
          >
            <path
              fill="currentColor"
              d="m21.838 11.126l-.229 2.436c-.378 4.012-.567 6.019-1.75 7.228C18.678 22 16.906 22 13.36 22h-2.72c-3.545 0-5.317 0-6.5-1.21c-1.183-1.21-1.371-3.216-1.749-7.228l-.23-2.436c-.18-1.912-.27-2.869.058-3.264a.992.992 0 0 1 .675-.367c.476-.042 1.073.638 2.268 1.998c.618.704.927 1.055 1.271 1.11a.923.923 0 0 0 .562-.09c.319-.16.53-.595.955-1.464l2.237-4.584C10.989 2.822 11.39 2 12 2c.61 0 1.011.822 1.813 2.465l2.237 4.584c.424.87.636 1.304.955 1.464c.176.089.37.12.562.09c.344-.055.653-.406 1.271-1.11c1.195-1.36 1.792-2.04 2.268-1.998a.992.992 0 0 1 .675.367c.327.395.237 1.352.057 3.264Z"
              opacity=".5"
            />
            <path
              fill="currentColor"
              d="M8.25 18a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
            />
          </svg>
        )}
        <div style={{ flex: 2 }}>
          {domainType === 1
            ? prefix
            : domainType === 3
            ? premium
            : `www.${suffix}`}
        </div>
        <div style={{ flex: 2 }} className="flex gap-2 items-center">
          {alreadyConfigured ? (
            <span className="bg-yellow-500 px-2 py-1 rounded-md">
              Already Configured
            </span>
          ) : (
            <span
              className={`uppercase rounded-md px-2 py-1 text-white status-${status}`}
            >
              {status}
            </span>
          )}
          {!alreadyConfigured && (
            <RefreshCcw
              className={`cursor-pointer ${refreshing ? "animate-spin" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
                checkStatus();
              }}
            />
          )}
        </div>
        <div style={{ flex: 2 }}>{countries[countryId]}</div>
        <div style={{ flex: 1 }}>
          {alreadyConfigured ? (
            <Trash2
              onClick={() => {
                dispatch(
                  setDontShow({ countryKey: countryId, dontShow: true })
                );
                // dispatch(setCountriesForLanguageSelection());
              }}
            />
          ) : view ? (
            <i class="bi bi-chevron-up point" onClick={handleSwitchView}></i>
          ) : (
            <i class="bi bi-chevron-down point" onClick={handleSwitchView}></i>
          )}
        </div>
      </div>

      {bolleandata ? null : (
        <>
          {view ? (
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 1px rgb(180, 180, 180)",
                backgroundColor: "#FAFAFA",
                margin: "20px 20px 20px 10px",
                padding: "20px",
              }}
            >
              <h5>DNS records for ccTLDs</h5>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                }}
              >
                <div
                  className="DnsOptions"
                  style={{
                    display: "flex",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                    justifyContent: "space-between",
                  }}
                  onClick={(prop) => commonClick(prop)}
                >
                  <input
                    data-domainType="1"
                    // checked={newbollean}
                    name={"HelloWorld" + keyz}
                    value={1}
                    type="radio"
                    id={"btnradio2" + keyz}
                    onClick={clickHandler}
                    hidden
                  />
                  <label
                    style={{
                      borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.1) ",
                      marginBottom: "0px",
                      height: "48px",
                      borderTopLeftRadius: "7px",
                      borderBottomLeftRadius: "7px",
                    }}
                    className="inputtext"
                    htmlFor={"btnradio2" + keyz}
                    data-selected={domainType === 1}
                  >
                    {prefix}
                  </label>
                  <input
                    data-domainType="2"
                    name={"HelloWorld" + keyz}
                    value={2}
                    type="radio"
                    hidden
                    // checked={!newbollean && !texthighlight}
                    id={"btnradio1" + keyz}
                    onClick={clickHandler}
                  />
                  <label
                    className="inputtext"
                    data-selected={domainType === 2}
                    htmlFor={"btnradio1" + keyz}
                    style={{
                      height: "48px",
                      marginBottom: "0px",
                    }}
                  >
                    {suffix}
                  </label>

                  <input
                    data-domainType="3"
                    name={"HelloWorld" + keyz}
                    value={premiumvalue}
                    type="text"
                    onClick={clickHandler}
                    onChange={(e) => {
                      let inp = e.target.value;
                      let val;
                      if (inp.startsWith("www.") && inp !== "www.") {
                        val = inp;
                      } else if (inp === "www.") {
                        val = "";
                      } else {
                        val = "www." + inp;
                      }
                      setPremiumvalue(val);
                      setPremium(inp.includes("www") ? inp : e + "." + inp);
                    }}
                    data-selected={domainType === 3}
                    className="inputtext"
                    id={"btnradio3" + keyz}
                    readOnly={disable}
                    style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.1) " }}
                  />
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{
                    position: "relative",
                    marginTop: "-4.5%",
                    marginBottom: "1.5%",
                    // top: "50%",
                    left: "66%",
                  }}
                >
                  <path
                    fill="currentColor"
                    d="m21.838 11.126l-.229 2.436c-.378 4.012-.567 6.019-1.75 7.228C18.678 22 16.906 22 13.36 22h-2.72c-3.545 0-5.317 0-6.5-1.21c-1.183-1.21-1.371-3.216-1.749-7.228l-.23-2.436c-.18-1.912-.27-2.869.058-3.264a.992.992 0 0 1 .675-.367c.476-.042 1.073.638 2.268 1.998c.618.704.927 1.055 1.271 1.11a.923.923 0 0 0 .562-.09c.319-.16.53-.595.955-1.464l2.237-4.584C10.989 2.822 11.39 2 12 2c.61 0 1.011.822 1.813 2.465l2.237 4.584c.424.87.636 1.304.955 1.464c.176.089.37.12.562.09c.344-.055.653-.406 1.271-1.11c1.195-1.36 1.792-2.04 2.268-1.998a.992.992 0 0 1 .675.367c.327.395.237 1.352.057 3.264Z"
                    opacity=".5"
                  />
                  <path
                    fill="currentColor"
                    d="M8.25 18a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
                  />
                </svg>
                <p
                  onClick={() => {
                    setDisable(false);
                    setDomainType(4);
                    setDomainpermanent(!domainpermanent);
                    // setDisable(false);
                  }}
                  className="customdomain"
                >
                  Do you have another Domain? Click here
                </p>
              </div>
              {
                <DomainElement
                  inputText={inputText}
                  domainType={domainType}
                  CountryCode={countryId}
                  premium={premiumvalue}
                  premium2={premium}
                />
              }
              <AlertDialog
                open={alertDialogShow}
                onOpenChange={(v) => {
                  if (domainType !== 2) {
                    setAlertDialogShow((e) => !e);
                  } else {
                    setConnected(true);
                    handleChange(false);
                  }
                }}
              >
                <AlertDialogTrigger>
                  <button className="SaveButton">Save</button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      Make sure you add{" "}
                      <span className="text-primaryColor">CNAME</span> records
                      before registering
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      <CheckCNAMEAddedOrNot
                        connected={connected}
                        setConnected={setConnected}
                        hostName={
                          domainType === 2
                            ? `www.${hostName.split("/").at(0)}`
                            : hostName
                        }
                      />
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={handleChange}>
                      Continue
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>

              <button onClick={handleDelete} className="DeleteButton">
                {/* add event handler here */}
                Delete
              </button>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default Drop;

/**
 * 
 * <ToggleButtonGroup
						color="primary"
						value={alignment}
						exclusive
						onChange={handleChange}
						aria-label="urlSelector"
					>
						<ToggleButton value="prefix">
							{inputText}/
							{CountryCode[e.charAt(0) === "~" ? e.substring(1) : e]}/
						</ToggleButton>
						<ToggleButton value="suffix">
							{CountryCode[e.charAt(0) === "~" ? e.substring(1) : e]}.
							{inputText}
						</ToggleButton>
						<ToggleButton value="premium">{e}</ToggleButton>
					</ToggleButtonGroup>
 */

export function CheckCNAMEAddedOrNot({
  showOnlyIcon,
  hostName,
  connected,
  setConnected,
}) {
  const [checkingCNAME, setCheckingCNAME] = useState(true);

  async function checkName() {
    setCheckingCNAME(true);

    try {
      const res = await publicRequest.get(`utils/cname?url=${hostName}`, {
        baseURL: `${process.env.REACT_APP_SERVER_URL}/api/`,
      });
      if (res.status === 200) {
        if (res.data.success) {
          setConnected(res.data.data.includes("saas.glocalise.site"));
        } else {
          setConnected(false);
        }
      }
    } catch (error) {
      enqueueSnackbar("Error getting status", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    } finally {
      setCheckingCNAME(false);
    }
  }
  useEffect(() => {
    if (hostName.length === 0) {
      setCheckingCNAME(false);
      return;
    }
    checkName();
  }, [hostName]);
  if (showOnlyIcon) {
    return (
      <Button
        disabled={checkingCNAME}
        onClick={checkName}
        className={cn("flex items-center gap-2 bg-black disabled:bg-black/90", {
          "animate-pulse": checkingCNAME,
        })}
      >
        {checkingCNAME
          ? "Checking..."
          : connected
          ? "Connected"
          : "Not Connected"}
        {checkingCNAME ? (
          <RefreshCcw className="animate-spin" />
        ) : connected ? (
          <Check />
        ) : (
          <Unplug />
        )}
      </Button>
    );
  }
  return (
    <section>
      <section className="flex items-center my-2 gap-2">
        <span className="font-bold underline">{hostName}</span>
        {checkingCNAME ? (
          <RefreshCcw className="animate-spin" />
        ) : connected ? (
          <Check />
        ) : (
          <Unplug />
        )}
      </section>
      <Button
        disabled={checkingCNAME}
        onClick={checkName}
        className={cn("bg-black disabled:bg-black/90", {
          "animate-pulse": checkingCNAME,
        })}
      >
        {checkingCNAME ? "Checking..." : "Check"}
      </Button>
    </section>
  );
}
