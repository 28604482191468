import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sentOtpForLogin, sentOtpForRegister } from "../../redux/apiCalls";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import "./signup.css";
import { Zoom } from "@mui/material";
import OtpVerify from "./otpverify/OtpVerify";
import axios from "axios";

import {
  auth,
  googleprovider,
  facebookprovider,
  microsoftprovider,
} from "../../Firebase";
import localStorage from "redux-persist/es/storage";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/userRedux";
const schema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
});

const Signup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [verify, setVerify] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };

  const checkboxdata = () => {
    setCheckbox(!checkbox);
  };
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: schema,
      validateOnChange: true,
      validateOnBlur: true,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values) => {
        console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
        //// to get rid of all the values after submitting the form
      },
    });

  const ssodata = localStorage.getItem("datasso");

  const login = async () => {
    const jwtToken = await localStorage.getItem("jwtToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/ssologin/getssodata`,

        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (response.status === 200) {
        const responseData = await response.json();
        let name = responseData.data.name;
        if (name) {
          localStorage.setItem(
            "datasso",
            JSON.stringify({
              name: responseData.data.name,
              email: responseData.data.email,
            })
          );
        } else {
          localStorage.setItem(
            "datasso",
            JSON.stringify({
              name: "User",
              email: responseData.data.email,
            })
          );
        }

        enqueueSnackbar("successfully login", {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        navigate("/Language-&-Region");
        // window.location.href = "https://app.glocalise.net/chatbot";
      } else {
        enqueueSnackbar("Create your Account ", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        navigate("/signup");

        // window.location.href = "https://www.glocalise.net/signup";
      }
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();
  const handleRequestOtp = async () => {
    if (checkbox) {
      const res = await sentOtpForRegister(values);
      if (res.status === 200) {
        if (res.data.success) {
          enqueueSnackbar("Otp send successfully to your email", {
            variant: "default",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            TransitionComponent: Zoom,
          });
          setVerify(true);
          setCheckbox(false);
        } else {
          enqueueSnackbar(res.data.message, {
            variant: "default",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            TransitionComponent: Zoom,
          });
        }
      }
    } else {
      enqueueSnackbar("please click on checkbox", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    }
  };

  const handleAuth = () => {
    try {
      if (!userName) {
        auth
          .signInWithPopup(googleprovider)
          .then(async (result) => {
            axios

              .post(
                `${process.env.REACT_APP_SERVER_URL}/api/ssologin/setssodata`,
                {
                  name: result.user._delegate.displayName,
                  email: result.user._delegate.email,
                  token: await result.user.getIdToken(),
                }
              )

              .then((response) => {
                if (response.status === 200) {
                  if (response.data.data !== null) {
                    localStorage.setItem(
                      "datasso",
                      JSON.stringify({
                        name: response.data.data.name,
                        email: response.data.data.email,
                      })
                    );
                    localStorage.setItem("jwtToken", response.data.data.token);
                    enqueueSnackbar("successfully register", {
                      variant: "default",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      TransitionComponent: Zoom,
                    });
                    dispatch(loginSuccess(response.data.data));
                    navigate("/question");
                  } else {
                    enqueueSnackbar(response.data.message, {
                      variant: "default",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      TransitionComponent: Zoom,
                    });
                  }
                }
              })
              .catch((error) => {
                if (error.response.status === 302) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: error.response.data.data.name,
                      email: error.response.data.data.email,
                    })
                  );
                  enqueueSnackbar("successfuflly login", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/Language-&-Region");

                  // window.location.href = "https://app.glocalise.net/chatbot";
                }
              });
          })
          .catch((error) => {
            alert(error.message);
          });
      } else if (userName) {
        auth
          .signOut()
          .then(() => {
            // setUser("");
          })
          .catch((err) => alert(err.message));
      }
    } catch (error) {
      if (error.response.status === 302) {
        enqueueSnackbar("user is already register", {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        navigate("/Language-&-Region");

        // window.location.href = "https://app.glocalise.net/chatbot";
      }
    }
  };

  const facebook = () => {
    try {
      if (!userName) {
        auth
          .signInWithPopup(facebookprovider)
          .then((result) => {
            axios

              .post(
                `${process.env.REACT_APP_SERVER_URL}/api/ssologin/setssodata`,
                {
                  name: result.user._delegate.displayName,
                  email: result.user._delegate.email,
                }
              )

              .then((data) => {
                if (data.status === 200) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: data.data.data.name,
                      email: data.data.data.email,
                    })
                  );
                  localStorage.setItem("jwtToken", data.data.token);
                  enqueueSnackbar("successfully register", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/question");
                  window.location.reload();
                }
              })
              .catch((error) => {
                if (error.response.status === 302) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: error.response.data.data.name,
                      email: error.response.data.data.email,
                    })
                  );
                  enqueueSnackbar("successfuflly login", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/Language-&-Region");

                  // window.location.href = "https://app.glocalise.net/chatbot";
                }
              });
          })
          .catch((error) => {
            alert(error.message);
          });
      } else if (userName) {
        auth
          .signOut()
          .then(() => {})
          .catch((err) => alert(err.message));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const microsoft = () => {
    try {
      if (!userName) {
        auth
          .signInWithPopup(microsoftprovider)
          .then((result) => {
            axios

              .post(
                `${process.env.REACT_APP_SERVER_URL}/api/ssologin/setssodata`,
                {
                  name: result.user._delegate.displayName,
                  email: result.user._delegate.email,
                }
              )

              .then((data) => {
                if (data.status === 200) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: data.data.data.name,
                      email: data.data.data.email,
                    })
                  );
                  localStorage.setItem("jwtToken", data.data.token);
                  enqueueSnackbar("successfully register", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/question");
                  window.location.reload();
                }
              })
              .catch((error) => {
                if (error.response.status === 302) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: error.response.data.data.name,
                      email: error.response.data.data.email,
                    })
                  );
                  enqueueSnackbar("successfuflly login", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/Language-&-Region");

                  // window.location.href = "https://app.glocalise.net/chatbot";
                }
              });
          })
          .catch((error) => {
            alert(error.message);
          });
      } else if (userName) {
        auth
          .signOut()
          .then(() => {})
          .catch((err) => alert(err.message));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const linkedin = () => {
    // window.open("process.env.REACT_APP_SERVER_URL/api/auth/linkedin", "_self");
    // window.location.href = "http://localhost:8800/api/auth/linkedin";
  };

  return (
    <>
      {!verify ? (
        <div>
          <div className="signup-wrapper">
            <div className="brand">
              <Link to="/">
                {" "}
                <img
                  src="/images/logo/Vector 10.png"
                  alt=""
                  className="signup-brand-img"
                />
              </Link>
            </div>
            <div className="signup-right">
              <p className="signin">Have an Account? </p>

              <p className="link-sign">
                <Link
                  to={"/login"}
                  style={{ color: "#EE9644", textDecoration: "none" }}
                >
                  Sign in
                </Link>
              </p>
            </div>
          </div>
          <div className="signup-container">
            <div className="signup-box">
              <h2 className="signup-box-head">Let's get you set up</h2>
              <p className="signup-box-para">
                Join Glocali.se by entering your email
              </p>

              <div className="form-signup">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="label-form" className="label-form">
                    Email
                  </label>
                  <input
                    type="email"
                    className="input-signup"
                    placeholder="test1@gmail.com"
                    required
                    name="email"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      required
                      onBlur={handleBlur}
                      checked={checkbox}
                      onClick={checkboxdata}
                    />
                    <label
                      htmlFor="check-label"
                      className="form-check-label check-label"
                      for="flexCheckDefault"
                    >
                      Creating an account means you’re okay with our Terms of
                      Service, <br /> Privacy Policy, and default Notification
                      Settings
                    </label>
                  </div>
                  {values.email !== "" ? (
                    <button
                      className="continue-signup"
                      type="submit"
                      onClick={handleRequestOtp}
                    >
                      Continue with email &gt;
                    </button>
                  ) : (
                    <button className="continue-signup" type="submit" disabled>
                      Continue with email &gt;
                    </button>
                  )}
                </form>
              </div>
              <div className="form-divider">
                <div className="left-line"></div>

                <div className="middle-content">or sign up with</div>
                <div className="right-line"></div>
              </div>
              <div className="login-brand">
                <img
                  src="/images/other/Group 2022.png"
                  alt=""
                  className="login-brand-logo"
                  onClick={
                    checkbox
                      ? handleAuth
                      : () => {
                          enqueueSnackbar("please click on checkbox", {
                            variant: "error",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            TransitionComponent: Zoom,
                          });
                          setCheckbox(false);
                        }
                  }
                />
                <img
                  src="/images/other/Group 2021.png"
                  alt=""
                  className="login-brand-logo"
                />
                <img
                  src="/images/other/Group 2020.png"
                  alt=""
                  className="login-brand-logo"
                  onClick={
                    checkbox
                      ? microsoft
                      : () => {
                          enqueueSnackbar("please click on checkbox", {
                            variant: "error",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            TransitionComponent: Zoom,
                          });

                          setCheckbox(false);
                        }
                  }
                />
                <img
                  src="/images/other/Group 2019.png"
                  alt=""
                  className="login-brand-logo"
                  onClick={
                    checkbox
                      ? linkedin
                      : () => {
                          enqueueSnackbar("please click on checkbox", {
                            variant: "error",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            TransitionComponent: Zoom,
                          });
                          setCheckbox(false);
                        }
                  }
                />
                <img
                  src="/images/other/Group 2018.png"
                  alt=""
                  className="login-brand-logo"
                  onClick={
                    checkbox
                      ? facebook
                      : () => {
                          enqueueSnackbar("please click on checkbox", {
                            variant: "error",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            TransitionComponent: Zoom,
                          });
                          setCheckbox(false);
                        }
                  }
                />
              </div>
            </div>
          </div>
          <div className="signup-sidebar">
            <img src="/images/other/Group 465.png" alt="contactus" />
            <div className="signup-sidebar-content">Contact us</div>
          </div>
        </div>
      ) : (
        <OtpVerify
          setVerify={setVerify}
          values={values}
          initialValues={initialValues}
        />
      )}
    </>
  );
};

export default Signup;
