import React from 'react'
import "./top7form.css"
const Top7Form = ({activeStep, setActiveStep,setUserData}) => {
  const [businessName,setBusName]=React.useState("");
  const handleChange = (e) =>{
    setUserData((prev)=>[prev[0],prev[1],prev[2],prev[3],prev[4],prev[5],prev[6],prev[7],e.target.value,prev[9]]);
    setBusName(e.target.value);
    setActiveStep(8)
  }
  return (
    <div className="top7form-container">
    <div className="top7form-wrapper">
<h2 className="top7-head">Company/Business Name</h2>
<input type="text" placeholder='Enter Your Company/Business Name' value={businessName} className='input-top7' onChange={(e)=>handleChange(e)} required />
    </div>
</div>
  )
}

export default Top7Form