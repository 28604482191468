// import "./App.css";
import React from "react";
import MainPage from "./components/MainPage";
import "./components/test.css";
// import TestPage from "./components/TestPage";

function App(props) {
  return (
    <div className="App scrollArea" style={{ flex: 1, display: "flex" }}>
      <MainPage
        dummyData={props.dummyData}
        setDummyData={props.setDummyData}
        selectedCountry={props.selectedCountry}
        setSelectedCountry={props.setSelectedCountry}
      />
    </div>
  );
}

export default App;
