import React from 'react'
import "./localize.css"
const Localize = () => {
  return (
    <div className="localize-container">
        <div className="localize-wrapper">
        <div className="localize-right">
            <div className="localize-right-div">
                {/* <div className="left-img">
                  <img src="/images/other/Group 292.png" alt="" className='left-image' style={{width:"243px",height:"275px"}}/>
                </div>
                <div className="middle-img">
                  <img src="/images/other/Group 289.png" alt="" className="mid-img" style={{width:"270px",height:"341px"}} />
                </div> */}
                <img src="/images/other/ELEMENT.svg" alt="" style={{width:"100%"}}/>
                
            </div>
        </div>


        <div className="localize-left">
            <div className="header21">Localization</div>
            <div className="header3">Stop speculating if the potential foreign customers are <br /> aware of your business! Localize your global website.
</div>
            
        </div>
        </div>
    </div>
  )
}

export default Localize