import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCountriesForLanguageSelection,
  toggleHotSpotZones,
} from "../../redux/dataSlices";

function ZoneButton(props) {
  const dispatch = useDispatch();
  const selected = useSelector((state) =>
    state.data.hotspotZones.includes(props.id)
  );
  return (
    <button
      style={{
        fontWeight: "550",
        backgroundColor: "white",
        fontSize: "14px",
        border: "none",
        borderRadius: "8px",
        padding: "15px 15px 15px 15px",
        color: "#979B99",
        outlineWidth: "1px",
        outlineStyle: "solid",
        lineHeight: "1px",
        maxHeight: "32px",
        minWidth: "70px",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
        marginRight: "12px",
        textAlign: "center",
        ...{
          backgroundColor: selected ? "#D7F3ED" : "white",
          outline: selected ? "none" : "1px solid #979B99",
        },
      }}
      onClick={(e) => {
        dispatch(toggleHotSpotZones(props.id));
        dispatch(setCountriesForLanguageSelection());
      }}
    >
      {props.text}
    </button>
  );
}

export default ZoneButton;
