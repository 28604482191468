import React, { Component } from "react";
import whatweOffer from "./assets/images/what we offer.png";

class WhatWeOffer extends Component {
  state = {
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
  };
  render() {
    return (
      <React.Fragment>
        <div className="Container">
          <div className="what-we-offer-row center">
            <div className="container">
              <p style={{ fontSize: "30px" }}>What we offer</p>
              <p
                style={{
                  fontSize: "60px",
                  color: "rgb(66 140 250)",
                  marginTop: "40px",
                }}
              >
                At a Glance
              </p>
              <img className="offer-img" alt="img" src={whatweOffer}></img>
              <br></br>
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <div className="service-icon">
                    <p
                      onMouseEnter={() => this.setState({ one: true })}
                      onMouseLeave={() => this.setState({ one: false })}
                    >
                      Website Translation
                    </p>
                    {this.state.one === true && (
                      <div className="offer-div">
                        <ul>
                          <li style={{textAlign:"start"}}>
                            Stop speculating if the potential foreign customers
                            are aware of your business! Localize your global
                            website.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="service-icon">
                    <p
                      onMouseEnter={() => this.setState({ two: true })}
                      onMouseLeave={() => this.setState({ two: false })}
                    >
                      ML Based Analytics
                    </p>
                    {this.state.two === true && (
                      <div className="offer-div">
                        <span style={{fontSize:"16px",fontWeight:"400px"}}>
                          Everything you ever wanted to know about your
                          website.... 
                        </span><br/>
                        <span style={{fontSize:"16px"}}>...but your analytics never told you.</span> 
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="service-icon">
                    <p
                      onMouseEnter={() => this.setState({ three: true })}
                      onMouseLeave={() => this.setState({ three: false })}
                    >
                      Aotu SEO Enhancement
                    </p>
                    {this.state.three === true && (
                      <div className="offer-div">
                        <span style={{fontSize:"15px"}}> SEO Enhancement that brings the traffic that you always
                            needed, by going beyond the borders of search engine
                            algorithms.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="service-icon">
                    <p
                      onMouseEnter={() => this.setState({ four: true })}
                      onMouseLeave={() => this.setState({ four: false })}
                    >
                        AI Based Translations
                    </p>
                    {this.state.four === true && (
                      <div className="offer-div">
                        <span style={{fontSize:"15px"}}>
                            Break the ice! Communicate with your customers even if
                            you don't know their language. We translate your chats
                            on fly.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WhatWeOffer;
