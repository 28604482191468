import React, { Component } from 'react'
import Footer from '../../components/footer/Footer';
import Banner from './Banner';
import "./Services.css";
import WebsiteTranslation from './WebsiteTranslations';
import Analytics from './Analytics';
import Chatbot from './Chatbot';
import SEOEnhancement from './SEOEnhancement';
import WhatWeOffer from './WhatWeOffer';
import GetInTouch from './GetInTouch';

class Index extends Component {
    render() {
        return (
            <React.Fragment>
                <Banner />
                <WebsiteTranslation />
                <Analytics />
                <Chatbot />
                <SEOEnhancement />
                <WhatWeOffer />
                <GetInTouch />
                <Footer />
            </React.Fragment>

        )
    }
}

export default Index
