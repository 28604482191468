import React from "react";
import "./PopOuts.css";
import {
  setCountriesForLanguageSelection,
  setSelectedCountries,
} from "../../../redux/dataSlices";
import { useDispatch } from "react-redux";
import countries from "../../../data/countries";
function PopOuts({ id }) {
  const dispatch = useDispatch();
  const countryId = id.split("-").at(-1);

  return (
    <div
      onClick={() => {
        dispatch(setSelectedCountries(id));
        dispatch(setCountriesForLanguageSelection());
      }}
      className="d-flex align-items-center  justify-content-between "
    >
      <p
        className="selectedbutton"
        style={{
          direction: "ltr",
          flex: "1",
          padding: "5px",
          border: "none",
          outline: "none",
          margin: "1px",
          marginLeft: "-5vw",
          fontSize: "15px",
          textAlign: "start",
          backgroundColor: "white",
          minWidth: "70px",
        }}
      >
        {countries[countryId]}
        {/* <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M9 1L1 9M1 1L9 9" stroke="#4C4C4C" strokeLinecap="round" />
            </svg> /}
      {/ <CloseIcon style={{ color: "red", marginLeft: "0.2rem" }} /> */}
      </p>
      <div
        style={{
          color: "red",
          marginTop: "2px",
        }}
      >
        <i className="fas fa-trash-can fa-pull-right fa-1.9x"></i>
      </div>
    </div>
  );
}

export default PopOuts;
