import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { useEffect } from "react";
// import "./colorpicker.css";

function Colorpicker({ handleColorChange }) {
  const [hexValue, setHexValue] = useState("");

  const [color, setColor] = useState({
    r: 241,
    g: 112,
    b: 19,
    a: 1,
  });

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = (e) => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setHexValue(color.hex);
  };
  useEffect(() => {
    handleColorChange(hexValue);
  }, [hexValue]);

  const styles = reactCSS({
    default: {
      color: {
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        background: hexValue,
      },
      swatch: {
        width: "25px",
        height: "25px",
        background: `url('/images/other/Group 5359.png')`,
        backgroundSize: "cover", 
        // background: "#fff",
        borderRadius: "50%",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "4",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "5px",
      },
    },
  });

  const handleInputChange = (e) => {
    const newHexValue = e.target.value;
    setHexValue(newHexValue);

    // Extract RGB values from the new   hex value
    const rgbValues = hexToRgb(newHexValue);

    if (rgbValues) {
      const { r, g, b, a } = rgbValues;
      setColor({
        r: r || 0,
        g: g || 0,
        b: b || 0,
        a: a || 1,
      });
    }
  };

  const hexToRgb = (hex) => {
    const result =
      /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: result[4] ? parseInt(result[4], 16) / 255 : 1,
        }
      : null;
  };

  return (
    <>
      <div>
        <div className="colopikercontainer">
          <div
            className="border-side-cp"
            style={styles.swatch}
            onClick={handleClick}
          >
            <div style={styles.color} />
          </div>
          {displayColorPicker ? (
            <div className="popover" style={styles.popover}>
              <div
                className="cover"
                style={styles.cover}
                onClick={handleClose}
              />
              <SketchPicker color={hexValue} onChange={handleChange} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="rc-editableinput">
        <input
          id="rc-editable-input-42"
          spellCheck="false"
          value={hexValue}
          onChange={handleInputChange}
          style={{
            width: "85px",
            padding: "4px 10% 3px",
            border: "none",
            boxShadow: "rgb(204, 204, 204) 0px 0px 0px 1px inset",
            fontSize: "11px",
            borderRadius: "4px",
            height: "36px",
            fontSize: "15px",
          }}
        />
      </div>
    </>
  );
}

export default Colorpicker;
