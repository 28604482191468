import React, { useState, useEffect } from "react";
import "./confirmEmail.css";
import Modal from "react-awesome-modal";
import PasswordChange from "../passwordChange/PasswordChange";

function ConfirmEmail({
  setEmailConfirmVisible,
  setNewPassVisible,
  forgotten,
  setForgotten,
}) {
  // console.log("forgot is",forgotten);
  // const [visible2, setVisible2] = useState(false)
  const [second, setSecond] = useState(30);
  var timer;
  useEffect(() => {
    timer = setInterval(() => {
      setSecond(second - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center upper-confirm">
        <div className="heading-confirm">Confirm Email</div>
        <i
          class="bi bi-x-lg icon-confirm cur"
          onClick={() => setEmailConfirmVisible(false)}
        ></i>
      </div>
      <div className="d-flex justify-content-center align-items-center code-confirm">
        We have sent four digit code on harshitacha@cloud.com
      </div>
      <div className="d-flex flex-row justify-content-center input-confirm">
        <input type="text" />
        <input type="text" />
        <input type="text" />
        <input type="text" />
      </div>
      <div className="resend-confirm">
        {second > 0 ? (
          <>Auto detecting OTP,Resend in {second} sec</>
        ) : (
          <>Resend OTP? </>
        )}
      </div>
      <div className="d-flex flex-row-reverse justify-content-right outer-submit-confirm">
        {forgotten ? (
          <button
            className="submit-confirm"
            onClick={() => {
              setNewPassVisible(true);
              setEmailConfirmVisible(false);
              setForgotten(false);
            }}
          >
            Submit
          </button>
        ) : (
          <button
            className="submit-confirm"
            onClick={() => {
              setEmailConfirmVisible(false);
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default ConfirmEmail;
