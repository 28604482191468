/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from "react";
import "./chatbot.css";
import Helping from "../../components/helping/Helping";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Accordion, Zoom } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Link, useNavigate } from "react-router-dom";
// import ColorPiker from "../../ColorPiker";
// import Colorpicker from "../components/colorpicker/Colorpicker";
import Colorpicker from "../components/colorpicker/Colorpicker";
import { Email_settings } from "./Email_settings";
//devang
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CssIcon from "@mui/icons-material/Css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
// import "./newfile.css";
// import Header from "../../components/Header";
// import Header from "../../components/Header/Header";

// import "../chatbot/CustomAccordion.css";
import Button from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import StyledTextarea from "./EmptyTextarea";
import StyledTextarea1 from "./EmptyTextarea1";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import { Margin } from "@mui/icons-material";
import CustomizedSlider from "./CustomizedSlider";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Checkboxy from "./Checkboxy";
import DynamicProfilePhoto from "./DynamicProfilePhoto";
import help from "../../components/helping/help.png";
import { setChatPersonalization } from "../../redux/dataSlices";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import { useSnackbar } from "notistack";
{
  /* <link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css"
  integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
  crossorigin="anonymous"
></link>; */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Chatbot(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [showChatbot, setShowChatbot] = useState(false);
  const navigate = useNavigate();
  // const [personalize, setPersonalize] = useState(false);
  // const toggle = () => {
  //   setPersonalize(!personalize);
  // };
  const [emailSettings, setEmailSettings] = useState({
    fontFamilyHTS: "",
    fontWeightHTS: "bold",
    fontSizeHTS: "20",
    textHTS: "Are you really sure?",

    descTBS:
      "If you are then please do so. But if you have not requested this password request there is a possiblity someone else has done so. We recommend you change your password every now and then",
    fontFamilyTBS: "",
    fontWeightTBS: "bold",
    fontSizeTBS: "20",

    fontFamilyBTS: "",
    fontWeightBTS: "bold",
    fontSizeBTS: "18",
    changepass: "change password",
  });
  const [dark, setDark] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  // const [age, setAge] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [bg, setBg] = useState("white");
  const [bgl, setBgl] = useState("black");
  const [panell, setPanell] = useState(false);
  const [helpshow, setHelpshow] = useState(false);
  const [toggleimage, setToggleimage] = useState(true);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setPanell(panel);
  };
  const chatRef = useRef();
  const bgRef = useRef();
  const textRef = useRef();

  const [passcolr, setPasscolor] = useState("");
  const [displayTextColor, setDisplayTextColor] = useState("#fff");
  const [displayMainColor, setDisplayMainColor] = useState("#fff");
  const [displayBg, setDisplayBg] = useState("#fff");
  const [displayChatIconColor, setDisplayChatIconColor] = useState("#fff");
  const [emailHeaderColor, setEmailHeaderColor] = useState("#fff");
  const [emailTextBoxColor, setEmailTextBoxColor] = useState("#fff");
  const [emailBottomTextColor, setEmailBottomTextColor] = useState("#fff");
  const [emailBottomColor, setEmailBottomColor] = useState("#fff");
  const openhelp = () => {
    setHelpshow(true);
  };
  const [color, setColor] = useState({
    fontFamily: "",
    fontWeight: "",
    fontSize: "0",
  });
  const { fontFamily, fontWeight, fontSize } = color;
  // useEffect(() => {
  //   const login = async () => {
  //     try {
  //       const response = await fetch(
  //         "process.env.REACT_APP_SERVER_URL/api/ssologin/getssodata",

  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${jwtToken}`,
  //           },
  //         }
  //       );
  //       if (response.status === 200) {
  //         const responseData = await response.json();
  //         let name = responseData.data.name;
  //         if (name) {
  //           localStorage.setItem(
  //             "datasso",
  //             JSON.stringify({
  //               name: responseData.data.name,
  //               email: responseData.data.email,
  //             })
  //           );
  //         } else {
  //           localStorage.setItem(
  //             "datasso",
  //             JSON.stringify({
  //               name: "User",
  //               email: responseData.data.email,
  //             })
  //           );
  //         }

  //         enqueueSnackbar("successfully login", {
  //           variant: "default",
  //           anchorOrigin: {
  //             vertical: "top",
  //             horizontal: "center",
  //           },
  //           TransitionComponent: Zoom,
  //         });

  //         navigate("/chatbot");
  //         window.location.reload();
  //       } else {
  //         enqueueSnackbar("Create your Account ", {
  //           variant: "error",
  //           anchorOrigin: {
  //             vertical: "top",
  //             horizontal: "center",
  //           },
  //           TransitionComponent: Zoom,
  //         });
  //         navigate("/signup");
  //         window.location.reload();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   login();
  // }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setColor({ ...color, [name]: value });
  };

  const onSubmit = (e) => {
    // e.preventDefault();
    console.log(color);
  };

  const [companyphoto, companysetphoto] = useState("");
  const [companyphoto1, companysetphoto1] = useState("");
  const [photo, setphoto] = useState("");
  const [photo1, setphoto1] = useState("");
  const [companiephoto, companiesetphoto] = useState("");
  const [companiephoto1, companiesetphoto1] = useState("");
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    companysetphoto1(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      companysetphoto(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    console.log(5);
    const d = new FormData();
    d.append("file", companyphoto1);
    d.append("upload_preset", "harshit");
    d.append("cloud_name", "dd8w9zrqr");
    const uploadRes = await axios.post(
      "https://api.cloudinary.com/v1_1/dd8w9zrqr/image/upload",
      d
    );
    console.log(uploadRes.data);
    const { url } = uploadRes.data;
    localStorage.setItem(
      "companydatapic",
      JSON.stringify({
        companypic: url,
      })
    );
  };
  const companydata1 = localStorage.getItem("companydatapic");
  const companyparsedData1 = JSON.parse(companydata1);
  ///////////////////////////////////////////////////////////
  const handleImageChange1 = (event) => {
    const file = event.target.files[0];
    companiesetphoto1(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      companiesetphoto(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handlesubmit1 = async (e) => {
    e.preventDefault();
    console.log(5);
    const d = new FormData();
    d.append("file", companiephoto1);
    d.append("upload_preset", "harshit");
    d.append("cloud_name", "dd8w9zrqr");
    const uploadRes = await axios.post(
      "https://api.cloudinary.com/v1_1/dd8w9zrqr/image/upload",
      d
    );
    console.log(uploadRes.data);
    const { url } = uploadRes.data;
    localStorage.setItem(
      "companiedatapic",
      JSON.stringify({
        companiepic: url,
      })
    );
  };
  const onChangeEmail = (e) => {
    const { name, value } = e.target;
    setEmailSettings({ ...emailSettings, [name]: value });
  };
  const companiedata1 = localStorage.getItem("companiedatapic");
  const companieparsedData1 = JSON.parse(companiedata1);
  ///////////////////////////////////////////////////
  const data1 = localStorage.getItem("datapic");
  const parsedData1 = JSON.parse(data1);
  const data = localStorage.getItem("datasso");
  const parsedData = JSON.parse(data);
  //   console.log(parsedData.name);

  const changeColor = () => {
    if (dark === true) {
      console.log("dark");
      return "dark-chat-box";
    } else if (dark === false) {
      return "main-chat-box";
    }
  };

  const toggle = () => {
    setBg("black");
  };
  const togglelight = () => {
    setBg("white");
  };
  const dispatch = useDispatch();
  const chatPersonalization = useSelector(
    (state) => state.data.chatPersonalization
  );
  useEffect(() => {
    if (bgRef.current) {
      bgRef.current.setAttribute(
        "style",
        chatPersonalization["css"]["background"]
      );
    }
    if (textRef.current) {
      textRef.current.setAttribute("style", chatPersonalization["css"]["text"]);
    }
    if (chatRef.current) {
      chatRef.current.setAttribute(
        "style",
        chatPersonalization["css"]["chat"] + chatPersonalization["css"]["text"]
      );
    }
  }, [chatPersonalization["css"]]);
  const handleC = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    enqueueSnackbar("Still Under Development", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
  });

  const {
    fontFamilyHTS,
    fontWeightHTS,
    fontSizeHTS,
    textHTS,
    descTBS,
    fontSizeTBS,
    fontWeightTBS,
    fontFamilyTBS,
    fontSizeBTS,
    fontWeightBTS,
    fontFamilyBTS,
    changepass,
  } = emailSettings;

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        "Lato",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  if (showChatbot) {
    return (
      <div className="chatbot__container flex" style={{ float: "right" }}>
        <Sidebar onToggleNavbar={toggleNavbar} />
        {/* <div className="chatbot__navbar">
        <Header onToggleNavbar={toggleNavbar} />
      </div> */}

        {/*.............................. <Navbar></Navbar>......................................... */}
        <div
          className={`chatbot__home flex justify-end ${
            !isNavbarOpen ? "ml-16" : "ml-28"
          }`}
          style={{ transition: "1s ease-in-out", float: "right" }}
        >
          <div>
            <div
              className="flex justify-between align-middle"
              style={{
                height: "85px",
                width: "80%",
                backgroundColor: "#fff",
                borderBottom: "1px solid #F2F2F2",
                // marginLeft: "200px",
              }}
            >
              {/* <div className="col-6"> */}
              <div style={{ width: "35vw" }}>
                <form
                  className="container-fluid"
                  style={{ alignItems: "center", Padding: "10px" }}
                >
                  <div
                    className="input-group"
                    style={{
                      marginTop: "27px",
                      width: "110%",
                      // marginLeft: "20px",
                    }}
                  >
                    <span
                      className="input-group-text"
                      style={{ background: "#EDEDED" }}
                      id="basic-addon1"
                    >
                      <SearchIcon></SearchIcon>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      style={{ outline: "none" }}
                      placeholder=""
                      aria-label="Username"
                    />
                  </div>
                </form>
              </div>
              <div
                style={{
                  // width: "20%",
                  // position: "absolute",
                  // top: "28px",
                  // right: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "30px",
                }}
              >
                <div>
                  <NotificationsOutlinedIcon
                    style={{ cursor: "pointer" }}
                  ></NotificationsOutlinedIcon>
                </div>
                {/* <div
                                    className="two"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "16px",
                                    }}
                                >
                                    <div className="hr-parent">
                                        <div className="hr-child">
                                            <h5 className="hr-child-h5" id="hr-title">
                                                {props.user ? <img style={{ width: "30px", height: "30px", borderRadius: "10px" }} src={`${props.user.photos[0].value}`} alt="img.jpeg" /> : "HR"}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="HR">
                                        <h5>{props.user ? props.user.displayName : "Harshit Raja"}</h5>
                                    </div>
                                </div> */}

                <div
                  className="two"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/settings");
                  }}
                >
                  {parsedData1 ? (
                    <img src={parsedData1.pic} alt="" className="pro-image" />
                  ) : (
                    <>
                      {
                        <div className="hr-parent">
                          <div className="hr-child">
                            <h5 className="hr-child-h5" id="hr-title">
                              {parsedData ? (
                                <DynamicProfilePhoto
                                  name={parsedData.name}
                                  size={100}
                                />
                              ) : (
                                "HR"
                              )}
                            </h5>
                          </div>
                        </div>
                      }
                    </>
                  )}
                  <div className="HR">
                    <h5>{parsedData ? parsedData.name : "HR"}</h5>
                  </div>
                </div>

                <div>
                  <ExpandMoreOutlinedIcon
                    style={{ cursor: "pointer" }}
                  ></ExpandMoreOutlinedIcon>
                </div>
              </div>
            </div>
            {/* <div className="col-2 bg-white"></div> */}
            <div
              className={`col-7 ${
                isNavbarOpen ? "chatbot__navbar-open" : "chatbot__navbar-closed"
              }`}
            >
              <div className="Reactangle d-flex justify-content-between mt-50px">
                <h3 id="chatbot-title" style={{ fontSize: "1.5rem" }}>
                  Chatbot
                  <div className="message_icons_div">
                    <img
                      style={{ marginRight: "6.9px", paddingTop: "6px" }}
                      src="./header_images/message-square.png"
                      alt="img.jpeg"
                    />
                    <img
                      style={{ paddingTop: "38.7px", paddingRight: "7.89px" }}
                      src="./header_images/message-square3.png"
                      alt="img.jpeg"
                    />
                    <img
                      style={{ paddingTop: "10px" }}
                      src="./header_images/message-square2.png"
                      alt="img.jpeg"
                    />
                  </div>
                </h3>
              </div>

              <div className="horizontalbox d-flex">
                <div
                  className="vertical1 d-flex "
                  style={{ paddingTop: "60px", marginLeft: "0px" }}
                >
                  {/* ..............................<CustomAccordion ></CustomAccordion>.............................. */}
                  {/* setBg={setBg} mode={mode} */}

                  <div
                    className="main-accordion"
                    style={{ marginLeft: "-40px" }}
                  >
                    <div className="accordion-1">
                      <Accordion
                        className=""
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          style={{ borderBottom: "2px solid #edeaea" }}
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            Display Personalization
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ margin: "30px 16px 30px 16px" }}>
                            <Typography>
                              <h6
                                style={{
                                  padding: "10px 0 0 0px",
                                  marginTop: "-30px",
                                  marginBottom: "0px",
                                }}
                              >
                                <b>Theme</b>
                              </h6>

                              {/* .........................tab componant.............................. */}
                              {/* <Tab
                mode={props.mode} modee={props.modee}></Tab> */}
                              <Box sx={{ width: "100%" }}>
                                <Box
                                  sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                  }}
                                >
                                  <Tabs
                                    value={value}
                                    onChange={handleC}
                                    aria-label="icon label tabs example"
                                    style={{
                                      // border: "2px solid #edeaea",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tab
                                      icon={<LightModeOutlinedIcon />}
                                      label="Light"
                                      onClick={togglelight}
                                      style={{
                                        border: "2px solid #edeaea",
                                        color: "black",
                                      }}
                                    />

                                    <Tab
                                      icon={<DarkModeOutlinedIcon />}
                                      label="Dark"
                                      onClick={toggle}
                                      style={{
                                        border: "2px solid #edeaea",
                                        color: "black",
                                        marginLeft: "-10px",
                                      }}
                                    />
                                    <Tab
                                      icon={<TuneIcon />}
                                      label="Custom"
                                      style={{
                                        border: "2px solid #edeaea",
                                        color: "black",
                                        marginLeft: "-10px",
                                        marginRight: "-10px",
                                      }}
                                    />
                                    <Tab
                                      icon={<CssIcon />}
                                      label="Css"
                                      style={{
                                        border: "2px solid #edeaea",
                                        color: "black",
                                        marginRight: "70px",
                                      }}
                                    />
                                  </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}></TabPanel>
                                <TabPanel value={value} index={1}></TabPanel>
                                <TabPanel value={value} index={2}>
                                  <div
                                    className="text_style_container "
                                    style={{
                                      width: "100%",
                                      height: "130px",
                                      borderBottom: "1px solid #EDEDED",
                                    }}
                                  >
                                    <p>Text style</p>
                                    <div className="text_align_cnt d-flex">
                                      <div className="dropdown_btn">
                                        <FormControl
                                          sx={{ m: 1 }}
                                          variant="standard"
                                          style={{ fontFamily: "Lato" }}
                                        >
                                          <InputLabel id="demo-customized-select-label">
                                            {/* Age */}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={fontFamily}
                                            name="fontFamily"
                                            onChange={onChange}
                                            input={<BootstrapInput />}
                                            style={{ fontFamily: "" }}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={"cursive"}>
                                              Cursive
                                            </MenuItem>
                                            <MenuItem value={"fantasy"}>
                                              Fantasy
                                            </MenuItem>
                                            <MenuItem value={"monospace"}>
                                              Monospace
                                            </MenuItem>
                                            <MenuItem value={"sans-serif"}>
                                              Sans-Serif
                                            </MenuItem>
                                          </Select>
                                        </FormControl>

                                        <FormControl
                                          sx={{ m: 1 }}
                                          variant="standard"
                                        >
                                          <InputLabel id="demo-customized-select-label">
                                            {/* Age */}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={fontWeight}
                                            name="fontWeight"
                                            onChange={onChange}
                                            input={<BootstrapInput />}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={"lighter"}>
                                              lighter
                                            </MenuItem>
                                            <MenuItem value={"normal"}>
                                              normal
                                            </MenuItem>
                                            <MenuItem value={"bold"}>
                                              bold
                                            </MenuItem>
                                          </Select>
                                        </FormControl>

                                        <FormControl
                                          sx={{ m: 1 }}
                                          variant="standard"
                                        >
                                          <InputLabel id="demo-customized-select-label">
                                            {/* Age */}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={fontSize}
                                            name="fontSize"
                                            onChange={onChange}
                                            input={<BootstrapInput />}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={11}>
                                              Eleven
                                            </MenuItem>
                                            <MenuItem value={12}>
                                              Twelve
                                            </MenuItem>
                                            <MenuItem value={14}>
                                              Fourteen
                                            </MenuItem>
                                            <MenuItem value={16}>
                                              Sixteen
                                            </MenuItem>
                                            <MenuItem value={18}>
                                              Eighteen
                                            </MenuItem>
                                            <MenuItem value={20}>
                                              Twenty
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </div>
                                      <div className="color-grediant d-flex">
                                        <Colorpicker
                                          handleColorChange={
                                            setDisplayTextColor
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="main-color_and_background-container d-flex padding-bottom-100px">
                                    <div className="main-color_cnt">
                                      <p>Main color</p>
                                      <div className="color-grediant d-flex">
                                        <Colorpicker
                                          handleColorChange={
                                            setDisplayMainColor
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="background-color">
                                      <p>Background color</p>
                                      <div className="color-grediant d-flex">
                                        <Colorpicker
                                          handleColorChange={setDisplayBg}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="main-chat-icon-color">
                                    <div className="main-color_cnt">
                                      <p>Chat icon color</p>
                                      <div className="color-grediant d-flex">
                                        <Colorpicker
                                          handleColorChange={
                                            setDisplayChatIconColor
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="add-logo-image-main-container h-98px  w-262px  ">
                                    <p>Add logo image</p>
                                    <div className="sub-add-logo-image-container d-flex w-188px align-item-center gap-5px">
                                      {/* <div className="c-logo ">
                                                                            <div className="l d-flex align-item-center justify-content-center">
                                                                            </div>
                                                                        </div> */}
                                      <div className="d-flex flex-column mt-4">
                                        {companyphoto ? (
                                          <img
                                            src={companyphoto}
                                            alt=""
                                            className="pro-image"
                                            style={{
                                              marginTop: "-10%",
                                              marginBottom: "2%",
                                            }}
                                          />
                                        ) : (
                                          <>
                                            {companyparsedData1 ? (
                                              <img
                                                src={
                                                  companyparsedData1.companypic
                                                }
                                                alt=""
                                                className="pro-image"
                                                style={{
                                                  marginTop: "-10%",
                                                  marginBottom: "2%",
                                                }}
                                              />
                                            ) : (
                                              <>
                                                {companyphoto ? (
                                                  <img
                                                    src={companyphoto}
                                                    alt=""
                                                    className="pro-image"
                                                    style={{
                                                      marginTop: "-10%",
                                                      marginBottom: "2%",
                                                    }}
                                                  />
                                                ) : (
                                                  <div className="hr-parent">
                                                    <div className="hr-child">
                                                      <h5
                                                        className="hr-child-h5"
                                                        id="hr-title1"
                                                      ></h5>
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                        <div className="d-flex flex-column">
                                          <input
                                            type="file"
                                            onChange={handleImageChange}
                                            accept="image/*"
                                            className=" d-flex align-items-center"
                                            style={{}}
                                          />
                                          {/* <label htmlFor="" style={{}}>Upload</label> */}
                                        </div>
                                      </div>
                                      <div
                                        className="c-name"
                                        style={{
                                          marginLeft: "-50%",
                                          marginTop: "-7%",
                                        }}
                                      >
                                        <h6>Company / Chatbot name</h6>
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                  <div>
                                    <label for="exampleFormControlTextarea1">
                                      Input CSS Code
                                    </label>
                                    <div
                                      className="border rounded-md p-2 bg-white"
                                      id="exampleFormControlTextarea1"
                                    >
                                      <pre>.background{"{"}</pre>
                                      <textarea
                                        onChange={(e) => {
                                          dispatch(
                                            setChatPersonalization({
                                              type: "CSS",
                                              data: {
                                                background:
                                                  e.currentTarget.value,
                                              },
                                            })
                                          );
                                        }}
                                        placeholder="Code"
                                        className="codebox"
                                      ></textarea>
                                      <pre>{"}"}</pre>
                                      {/* <pre>.main{"{"}</pre>
                                    <textarea
                                      onChange={(e) => {
                                        dispatch(
                                          setChatPersonalization({
                                            type: "CSS",
                                            data: {
                                              main: e.currentTarget.value,
                                            },
                                          })
                                        );
                                      }}
                                      placeholder="Code"
                                      className="codebox"
                                    ></textarea>
                                    <pre>{"}"}</pre> */}
                                      <pre>.chat{"{"}</pre>
                                      <textarea
                                        onChange={(e) => {
                                          dispatch(
                                            setChatPersonalization({
                                              type: "CSS",
                                              data: {
                                                chat: e.currentTarget.value,
                                              },
                                            })
                                          );
                                        }}
                                        placeholder="Code"
                                        className="codebox"
                                      ></textarea>
                                      <pre>{"}"}</pre>
                                      <pre>.text{"{"}</pre>
                                      <textarea
                                        onChange={(e) => {
                                          dispatch(
                                            setChatPersonalization({
                                              type: "CSS",
                                              data: {
                                                text: e.currentTarget.value,
                                              },
                                            })
                                          );
                                        }}
                                        placeholder="Code"
                                        className="codebox"
                                      ></textarea>
                                      <pre>{"}"}</pre>
                                    </div>
                                  </div>
                                </TabPanel>
                              </Box>
                              {/* .................................................buttons............................................. */}
                              <div className="container devbuttons d-flex">
                                <input
                                  className="btn btn-outline-dark resetmsgbtn "
                                  type="submit"
                                  value="Reset"
                                />
                                <input
                                  className="btn btn-dark msgbtn"
                                  type="submit"
                                  value="Save"
                                  onClick={handlesubmit}
                                />
                              </div>
                              {/* </div> */}
                            </Typography>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    {/* 
                  <div className="accordion-2">
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>
                          Language & Chat
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="massege"></div>
                          <div className="laguage"></div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div> */}

                    <div className="accordion-2">
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            Language & Chat
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* <Typography> */}

                          <h6 style={{ fontWeight: "600", marginLeft: "1rem" }}>
                            Message
                          </h6>

                          <div className="container justify d-flex firstdiv">
                            <div
                              className="container bot"
                              style={{ width: "90%", marginLeft: "1rem" }}
                            >
                              <h6
                                style={{ fontWeight: "600", fontSize: "90%" }}
                              >
                                Bot
                              </h6>
                              <StyledTextarea
                                style={{ marginLeft: "1rem", width: "92%" }}
                              ></StyledTextarea>
                            </div>
                            <div
                              className="container agent"
                              style={{ width: "90%", marginRight: "1rem" }}
                            >
                              <h6
                                className="devheader"
                                style={{ fontWeight: "600", fontSize: "90%" }}
                              >
                                Agent
                              </h6>
                              <StyledTextarea1
                                style={{ marginRight: "1rem", width: "92%" }}
                              ></StyledTextarea1>
                            </div>
                          </div>
                          <div className="container devbuttons d-flex">
                            <input
                              className="btn btn-outline-dark resetmsgbtn "
                              type="submit"
                              value="Reset"
                            />
                            <input
                              className="btn btn-dark msgbtn"
                              type="submit"
                              value="Save"
                            />
                          </div>

                          <hr
                            style={{
                              background: "none",
                              marginLeft: "1rem",
                              marginRight: "1rem",
                            }}
                          />

                          <h6
                            className="devheader_lang"
                            style={{ marginLeft: "1rem" }}
                          >
                            Language
                          </h6>
                          {/* <div className="container justify d-flex" >
                <p className="devpara">You can switch language in chat whenever you need</p>
                <input className="btn btn-outline-dark langbtn" type="submit" value="Add Language" onClick={handleOpen} />
              </div>

              <div className="container bottom_lang">
                <div className="checky" style={{ margin: 0, padding: 0, display: displayValue, marginBottom: '1rem' }}> */}
                          <Checkboxy />
                          {/* </div>

                <div className="container justify d-flex " >
                  <div className="devpara1" style={{ fontSize: "1rem" }}>1. English ( United States )</div>
                  <MoreVertSharpIcon style={{ color: "grey", fontSize: "1.5rem" }} className="MoreVertSharpIcon" />
                </div>
                <hr />
                <div className="container justify d-flex " >
                  <div className="devpara1" style={{ fontSize: "1rem" }}>2. Hindi</div>
                  <MoreVertSharpIcon style={{ color: "grey", fontSize: "1.5rem" }} className="MoreVertSharpIcon" />
                </div>
                <hr />
              </div> */}

                          <CustomizedSlider />

                          <div className="container last_chat_bot_line">
                            Language will be applied to voice hurdle as well
                          </div>

                          <hr
                            style={{
                              background: "none",
                              marginLeft: "1rem",
                              marginRight: "1rem",
                            }}
                          />
                          <h6
                            className="devheader_lang1"
                            style={{ marginLeft: "1rem" }}
                          >
                            Disable regions
                          </h6>
                          <div className="container hurdle">
                            <h7
                              style={{
                                fontWeight: "600",
                                fontSize: "90%",
                                marginLeft: "1rem",
                              }}
                            >
                              Chatbot
                            </h7>
                            <div className="container d-flex lastcontainer">
                              <h7
                                style={{
                                  color: "rgba(151, 155, 153, 1)",
                                  marginLeft: "1rem",
                                }}
                              >
                                All regions enabled
                              </h7>
                              <input
                                className="btn btn-outline-dark magicbtn "
                                type="submit"
                                value="Add regions"
                              />
                            </div>
                            <h7
                              style={{
                                fontWeight: "600",
                                fontSize: "90%",
                                marginLeft: "1rem",
                              }}
                            >
                              Voice hurdle
                            </h7>
                            <div className="container d-flex lastcontainer">
                              <h7
                                style={{
                                  color: "rgba(151, 155, 153, 1)",
                                  marginLeft: "1rem",
                                }}
                              >
                                All regions enabled
                              </h7>
                              <input
                                className="btn btn-outline-dark magicbtn "
                                type="submit"
                                value="Add regions"
                              />
                            </div>
                          </div>
                          <hr
                            style={{
                              background: "none",
                              marginLeft: "1rem",
                              marginRight: "1rem",
                            }}
                          />
                          <div className="container devbuttons d-flex margin_top">
                            <input
                              className="btn btn-outline-dark resetmsgbtn "
                              type="submit"
                              value="Reset"
                              style={{
                                marginBottom: "6%",
                                backgroundColor: "#ffffff",
                              }}
                            />
                            <input
                              className="btn btn-dark msgbtn"
                              type="submit"
                              value="Save"
                              style={{ marginBottom: "6%" }}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="accordion-3">
                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3bh-content"
                          id="panel3bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            Email settings
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {/* content */}

                            <div style={{ margin: "30px 16px 30px 16px" }}>
                              <Typography>
                                <h6
                                  style={{
                                    padding: "10px 0 0 0px",
                                    marginTop: "-30px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  <b>Theme</b>
                                </h6>

                                {/* .........................tab componant.............................. */}
                                {/* <Tab
                mode={props.mode} modee={props.modee}></Tab> */}
                                <Box sx={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      borderBottom: 1,
                                      borderColor: "divider",
                                    }}
                                  >
                                    <Tabs
                                      value={value}
                                      onChange={handleC}
                                      aria-label="icon label tabs example"
                                      style={{
                                        // border: "2px solid #edeaea",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Tab
                                        icon={<TuneIcon />}
                                        label="Custom"
                                        style={{
                                          border: "2px solid #edeaea",
                                          color: "black",
                                          marginLeft: "6px",
                                          marginRight: "-10px",
                                        }}
                                      />
                                      <Tab
                                        icon={<CssIcon />}
                                        label="Css"
                                        style={{
                                          border: "2px solid #edeaea",
                                          color: "black",
                                          marginRight: "286px",
                                        }}
                                      />
                                    </Tabs>
                                  </Box>
                                  <TabPanel value={value} index={0}>
                                    <div
                                      className="text_style_container "
                                      style={{
                                        width: "100%",
                                        height: "82px",
                                        position: "relative",
                                        top: "20px",
                                      }}
                                    >
                                      <p>Header text style</p>
                                      <div
                                        className="text_align_cnt d-flex"
                                        style={{
                                          marginTop: "-20px",
                                          height: "78px",
                                          top: "9px",
                                          position: "relative",
                                        }}
                                      >
                                        <div className="dropdown_btn">
                                          <FormControl
                                            sx={{ m: 1 }}
                                            variant="standard"
                                            style={{ fontFamily: "Lato" }}
                                          >
                                            <InputLabel id="demo-customized-select-label">
                                              {/* Age */}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={fontFamilyHTS}
                                              name="fontFamilyHTS"
                                              onChange={onChangeEmail}
                                              input={<BootstrapInput />}
                                              style={{ fontFamily: "" }}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value={"cursive"}>
                                                Cursive
                                              </MenuItem>
                                              <MenuItem value={"fantasy"}>
                                                Fantasy
                                              </MenuItem>
                                              <MenuItem value={"monospace"}>
                                                Monospace
                                              </MenuItem>
                                              <MenuItem value={"sans-serif"}>
                                                Sans-Serif
                                              </MenuItem>
                                            </Select>
                                          </FormControl>

                                          <FormControl
                                            sx={{ m: 1 }}
                                            variant="standard"
                                          >
                                            <InputLabel id="demo-customized-select-label">
                                              {/* Age */}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={fontWeightHTS}
                                              name="fontWeightHTS"
                                              onChange={onChangeEmail}
                                              input={<BootstrapInput />}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value={"lighter"}>
                                                lighter
                                              </MenuItem>
                                              <MenuItem value={"normal"}>
                                                normal
                                              </MenuItem>
                                              <MenuItem value={"bold"}>
                                                bold
                                              </MenuItem>
                                            </Select>
                                          </FormControl>

                                          <FormControl
                                            sx={{ m: 1 }}
                                            variant="standard"
                                          >
                                            <InputLabel id="demo-customized-select-label">
                                              {/* Age */}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={fontSizeHTS}
                                              name="fontSizeHTS"
                                              onChange={onChangeEmail}
                                              input={<BootstrapInput />}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value={10}>
                                                Ten
                                              </MenuItem>
                                              <MenuItem value={11}>
                                                Eleven
                                              </MenuItem>
                                              <MenuItem value={12}>
                                                Twelve
                                              </MenuItem>
                                              <MenuItem value={14}>
                                                Fourteen
                                              </MenuItem>
                                              <MenuItem value={16}>
                                                Sixteen
                                              </MenuItem>
                                              <MenuItem value={18}>
                                                Eighteen
                                              </MenuItem>
                                              <MenuItem value={20}>
                                                Twenty
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                        <div className="color-grediant d-flex">
                                          <Colorpicker
                                            handleColorChange={
                                              setEmailHeaderColor
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div
                                    className="main-color_and_background-container d-flex padding-bottom-100px"
                                    style={{ border: "1px solid #ededed" }}
                                  >
                                    <p
                                      style={{
                                        color: "#333",

                                        fontFamily: "Lato",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "16px",
                                        marginTop: "-5px",
                                      }}
                                    >
                                      Are you really sure?
                                    </p>
                                  </div> */}
                                    {/* <Input></Input> */}
                                    <input
                                      className="main-color_and_background-container d-flex padding-bottom-100px"
                                      style={{
                                        border: "1px solid #ededed",
                                        position: "relative",
                                        top: "38px",
                                      }}
                                      value={emailSettings.textHTS}
                                      name="textHTS"
                                      onChange={(e) => {
                                        onChangeEmail(e);
                                      }}
                                    />
                                    <div
                                      className="text_style_container "
                                      style={{
                                        width: "100%",
                                        height: "82px",
                                        borderTop: "1px solid #EDEDED",
                                        top: "60px",
                                        position: "relative",
                                      }}
                                    >
                                      <p
                                        style={{
                                          paddingTop: "20px",
                                          marginTop: "4px",
                                        }}
                                      >
                                        Text box style
                                      </p>
                                      <div
                                        className="text_align_cnt d-flex"
                                        style={{
                                          marginTop: "-20px",
                                          height: "78px",
                                        }}
                                      >
                                        <div className="dropdown_btn">
                                          <FormControl
                                            sx={{ m: 1 }}
                                            variant="standard"
                                            style={{ fontFamily: "Lato" }}
                                          >
                                            <InputLabel id="demo-customized-select-label">
                                              {/* Age */}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={fontFamilyTBS}
                                              name="fontFamilyTBS"
                                              onChange={onChangeEmail}
                                              input={<BootstrapInput />}
                                              style={{ fontFamily: "" }}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value={"cursive"}>
                                                Cursive
                                              </MenuItem>
                                              <MenuItem value={"fantasy"}>
                                                Fantasy
                                              </MenuItem>
                                              <MenuItem value={"monospace"}>
                                                Monospace
                                              </MenuItem>
                                              <MenuItem value={"sans-serif"}>
                                                Sans-Serif
                                              </MenuItem>
                                            </Select>
                                          </FormControl>

                                          <FormControl
                                            sx={{ m: 1 }}
                                            variant="standard"
                                          >
                                            <InputLabel id="demo-customized-select-label">
                                              {/* Age */}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={fontWeightTBS}
                                              name="fontWeightTBS"
                                              onChange={onChangeEmail}
                                              input={<BootstrapInput />}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value={"lighter"}>
                                                lighter
                                              </MenuItem>
                                              <MenuItem value={"normal"}>
                                                normal
                                              </MenuItem>
                                              <MenuItem value={"bold"}>
                                                bold
                                              </MenuItem>
                                            </Select>
                                          </FormControl>

                                          <FormControl
                                            sx={{ m: 1 }}
                                            variant="standard"
                                          >
                                            <InputLabel id="demo-customized-select-label">
                                              {/* Age */}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={fontSizeTBS}
                                              name="fontSizeTBS"
                                              onChange={onChangeEmail}
                                              input={<BootstrapInput />}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value={10}>
                                                Ten
                                              </MenuItem>
                                              <MenuItem value={11}>
                                                Eleven
                                              </MenuItem>
                                              <MenuItem value={12}>
                                                Twelve
                                              </MenuItem>
                                              <MenuItem value={14}>
                                                Fourteen
                                              </MenuItem>
                                              <MenuItem value={16}>
                                                Sixteen
                                              </MenuItem>
                                              <MenuItem value={18}>
                                                Eighteen
                                              </MenuItem>
                                              <MenuItem value={20}>
                                                Twenty
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                        <div className="color-grediant d-flex">
                                          <Colorpicker
                                            handleColorChange={
                                              setEmailTextBoxColor
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div
                                    className="main-color_and_background-container d-flex padding-bottom-100px"
                                    style={{
                                      border: "1px solid #ededed",
                                      marginTop: " 26px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#333",

                                        fontFamily: "Lato",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "16px",
                                      }}
                                    >
                                      If you are then please do so. But if you
                                      have not requested this password request
                                      there is a possiblity someone else has
                                      done so. We recommend you change your
                                      password every now and then.{" "}
                                    </p>
                                  </div> */}
                                    <input
                                      className="main-color_and_background-container d-flex padding-bottom-100px"
                                      style={{
                                        border: "1px solid #ededed",
                                        marginTop: " 26px",
                                        top: "66px",
                                        position: "relative",
                                        wordBreak: "break-word !important",
                                      }}
                                      value={emailSettings.descTBS}
                                      name="descTBS"
                                      onChange={(e) => {
                                        onChangeEmail(e);
                                      }}
                                    />
                                    <div
                                      className="text_style_container "
                                      style={{
                                        width: "100%",
                                        height: "82px",
                                        borderTop: "1px solid #EDEDED",
                                        position: "relative",
                                        top: "84px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          paddingTop: "20px",
                                          marginTop: "4px",
                                        }}
                                      >
                                        Button text style
                                      </p>
                                      <div
                                        className="text_align_cnt d-flex"
                                        style={{
                                          marginTop: "-20px",
                                          height: "78px",
                                        }}
                                      >
                                        <div className="dropdown_btn">
                                          <FormControl
                                            sx={{ m: 1 }}
                                            variant="standard"
                                            style={{ fontFamily: "Lato" }}
                                          >
                                            <InputLabel id="demo-customized-select-label">
                                              {/* Age */}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={fontFamilyBTS}
                                              name="fontFamilyBTS"
                                              onChange={onChangeEmail}
                                              input={<BootstrapInput />}
                                              style={{ fontFamily: "" }}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value={"cursive"}>
                                                Cursive
                                              </MenuItem>
                                              <MenuItem value={"fantasy"}>
                                                Fantasy
                                              </MenuItem>
                                              <MenuItem value={"monospace"}>
                                                Monospace
                                              </MenuItem>
                                              <MenuItem value={"sans-serif"}>
                                                Sans-Serif
                                              </MenuItem>
                                            </Select>
                                          </FormControl>

                                          <FormControl
                                            sx={{ m: 1 }}
                                            variant="standard"
                                          >
                                            <InputLabel id="demo-customized-select-label">
                                              {/* Age */}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={fontWeightBTS}
                                              name="fontWeightBTS"
                                              onChange={onChangeEmail}
                                              input={<BootstrapInput />}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value={"lighter"}>
                                                lighter
                                              </MenuItem>
                                              <MenuItem value={"normal"}>
                                                normal
                                              </MenuItem>
                                              <MenuItem value={"bold"}>
                                                bold
                                              </MenuItem>
                                            </Select>
                                          </FormControl>

                                          <FormControl
                                            sx={{ m: 1 }}
                                            variant="standard"
                                          >
                                            <InputLabel id="demo-customized-select-label">
                                              {/* Age */}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={fontSizeBTS}
                                              name="fontSizeBTS"
                                              onChange={onChangeEmail}
                                              input={<BootstrapInput />}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value={10}>
                                                Ten
                                              </MenuItem>
                                              <MenuItem value={11}>
                                                Eleven
                                              </MenuItem>
                                              <MenuItem value={12}>
                                                Twelve
                                              </MenuItem>
                                              <MenuItem value={14}>
                                                Fourteen
                                              </MenuItem>
                                              <MenuItem value={16}>
                                                Sixteen
                                              </MenuItem>
                                              <MenuItem value={18}>
                                                Eighteen
                                              </MenuItem>
                                              <MenuItem value={20}>
                                                Twenty
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                        <div className="color-grediant d-flex">
                                          <Colorpicker
                                            handleColorChange={
                                              setEmailBottomTextColor
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="changepassword d-flex"
                                      style={{
                                        width: "201px",
                                        height: "40px",
                                        border: "1px solid #EDEDED",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginTop: "120px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          alignItems: "center",
                                          justifyContent: "center",
                                          display: "flex",
                                          marginTop: "14px",
                                        }}
                                        value={changepass}
                                        name="changepass"
                                        onChange={emailSettings}
                                      >
                                        change password
                                      </p>
                                    </div>
                                    <div
                                      className="text_style_container "
                                      style={{
                                        display: "inline-flex",
                                        width: "100%",
                                        height: "100px",
                                        borderTop: "1px solid #EDEDED",
                                        position: "relative",
                                        alignItems: "center",
                                        paddingBottom: "15px",
                                        borderBottom: "1px solid #EDEDED",
                                        // top: "84px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          paddingTop: "20px",
                                          marginTop: "4px",
                                          marginRight: "49%",
                                        }}
                                      >
                                        Button color
                                      </p>
                                      <div
                                        className="text_align_cnt d-flex"
                                        style={{
                                          marginTop: "20px",
                                          height: "78px",
                                        }}
                                      >
                                        <div className="color-grediant d-flex">
                                          <Colorpicker
                                            handleColorChange={
                                              setEmailBottomColor
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    {/* <div className="main-chat-icon-color">
                                    <div
                                      className="main-color_cnt"
                                      style={{ borderTop: "1px solid #EDEDED" }}
                                    >
                                      <p
                                        style={{
                                          paddingTop: "20px",
                                          marginTop: "4px",
                                        }}
                                      >
                                        Button color
                                      </p>
                                      <div className="color-grediant d-flex pos">
                                        <Colorpicker
                                          handleColorChange={
                                            setEmailBottomColor
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                                    <div
                                      className="add-logo-image-main-container h-98px  w-262px  "
                                      style={{
                                        marginTop: "10%",
                                      }}
                                    >
                                      <p>Add logo image</p>
                                      <div className="sub-add-logo-image-container d-flex w-188px align-item-center gap-5px">
                                        <div className="d-flex flex-column mt-4">
                                          {companiephoto ? (
                                            <img
                                              src={companiephoto}
                                              alt=""
                                              className="pro-image"
                                              style={{
                                                marginTop: "-10%",
                                                marginBottom: "2%",
                                              }}
                                            />
                                          ) : (
                                            <>
                                              {companieparsedData1 ? (
                                                <img
                                                  src={
                                                    companieparsedData1.companiepic
                                                  }
                                                  alt=""
                                                  className="pro-image"
                                                  style={{
                                                    marginTop: "-10%",
                                                    marginBottom: "2%",
                                                  }}
                                                />
                                              ) : (
                                                <>
                                                  {companiephoto ? (
                                                    <img
                                                      src={companiephoto}
                                                      alt=""
                                                      className="pro-image"
                                                      style={{
                                                        marginTop: "-10%",
                                                        marginBottom: "2%",
                                                      }}
                                                    />
                                                  ) : (
                                                    <div className="hr-parent">
                                                      <div className="hr-child"></div>
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                          <div className="d-flex flex-column">
                                            <input
                                              type="file"
                                              onChange={handleImageChange1}
                                              accept="image/*"
                                              className=" d-flex align-items-center"
                                              style={{}}
                                            />
                                            {/* <label htmlFor="" style={{}}>Upload</label> */}
                                          </div>
                                        </div>
                                        <div
                                          className="c-name"
                                          style={{
                                            marginLeft: "-50%",
                                            marginTop: "-7%",
                                          }}
                                        >
                                          <h6>Company / Chatbot name</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </TabPanel>
                                  <TabPanel value={value} index={1}>
                                    <div
                                      className="form-group"
                                      style={{
                                        // background: "white",
                                        // color: "black",
                                        paddingBottom: "25px",
                                      }}
                                    >
                                      <label
                                        for="exampleFormControlTextarea1"
                                        style={
                                          {
                                            // background: "",
                                            // color: "black",
                                          }
                                        }
                                      >
                                        Input CSS Code
                                      </label>
                                      <textarea
                                        placeholder="Code"
                                        className="form-control bg-white"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                      ></textarea>
                                    </div>
                                  </TabPanel>
                                </Box>
                                {/* .................................................buttons............................................. */}
                                <div className="container devbuttons d-flex">
                                  <input
                                    className="btn btn-outline-dark resetmsgbtn "
                                    type="submit"
                                    value="Reset"
                                  />
                                  <input
                                    className="btn btn-dark msgbtn"
                                    type="submit"
                                    value="Submit"
                                    onClick={handlesubmit1}
                                  />
                                </div>
                                {/* </div> */}
                              </Typography>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div
                      className="accordion-4"
                      onClick={() => {
                        navigate("/admin-setting");
                      }}
                    >
                      <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handleChange("panel4")}
                        style={{ marginBottom: "100px" }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowRightAltIcon
                              style={{
                                color: "#39c6a4",
                                fontWeight: 1000,
                                fontSize: "1.8 rem",
                              }}
                            />
                          }
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            Admin setting
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{/* content */}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
                {panell === "panel1" ? (
                  <div
                    className="vertical2"
                    style={{ paddingTop: "60px", marginLeft: "30px" }}
                  >
                    {/* <Phone bg={bg}  ></Phone> */}

                    <div
                      ref={bgRef}
                      className={`${changeColor()} min-h-[500px] min-w-[200px]`}
                      style={{
                        background: bg,
                        fontSize: fontSize,
                        fontWeight: fontWeight,
                        fontFamily: fontFamily,
                        backgroundColor: displayBg,
                      }}
                    >
                      <div className="flex items-center w-full p-2 bg-primaryColor justify-between">
                        <i className="bi bi-chevron-left text-xl text-white"></i>
                        <img src="/images/other/online.png" alt="" />
                      </div>
                      <div className="grow flex flex-col px-2 gap-2 w-full">
                        {/* display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12.5373px 7.83582px 12.5373px 23.5075px;
  margin-top: auto;
  gap: 7.84px;

  position: absolute;
  min-width: 198.34px;
  min-height: 51.07px;
  left: 15.67px;
  top: 86.11px;


  background: #f2f2f2;
  border-radius: 0px 6.26866px 6.26866px 6.26866px;
  font-size: 10.9701px; */}
                        <div
                          ref={textRef}
                          className="bg-[#f2f2f2] rounded-md p-1 m-1 max-w-[40%] min-w-fit"
                        >
                          Hello, our Technical Team is online. How can we help?
                        </div>
                        <div
                          ref={chatRef}
                          className="bg-primaryColor rounded-md self-end m-1 p-1 max-w-[75%] min-w-fit"
                        >
                          Why I can't translate my website
                        </div>
                      </div>
                      <div className="flex items-center mb-2 gap-1">
                        <i className="bi bi-link-45deg text-xl"></i>
                        <div className="flex grow rounded-md items-center p-2 bg-[#f2f2f2] justify-between">
                          <textarea
                            placeholder="Type here.."
                            rows={1}
                            className="bg-transparent focus:outline-none grow resize-none"
                          />
                          <i className="bi bi-emoji-smile"></i>
                        </div>
                        <i className="bi bi-globe2"></i>
                        <img src="/images/other/send.png" alt="" />
                      </div>
                    </div>
                    <div id="rectangleIcon_div">
                      <img
                        id="rectangle_icon"
                        src="./header_images/Rectangle.png"
                        alt="rectangle.jpeg"
                      />

                      <div className="chatbot-lang-parent">
                        <div className="chatbot-lang-div">
                          <div className="country-lang">
                            <div className="d-flex flex-row align-items-center justify-content-center">
                              <img
                                src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
                                alt="Flag of France"
                              />
                              Ag
                            </div>
                          </div>
                          <i
                            className="bi bi-lightning-charge-fill"
                            style={{ color: "#ffb301", marginRight: "-10px" }}
                          ></i>

                          <div>by Glocali.se</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Email_settings
                    emailHeaderColor={emailHeaderColor}
                    emailTextBoxColor={emailTextBoxColor}
                    emailBottomTextColor={emailBottomTextColor}
                    emailBottomColor={emailBottomColor}
                    emailSettings={emailSettings}
                    colorr={passcolr}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {toggleimage ? (
          <img src={help} alt="help" className="helpimage" onClick={openhelp} />
        ) : null}
        {helpshow ? (
          <Helping
            onClick={() => {
              setHelpshow(false);
            }}
            settoggle={setToggleimage}
          />
        ) : null}
      </div>
    );
  }
  return <></>;
}

export default Chatbot;
