import React from "react";
import "./stats.css";
import { useNavigate } from "react-router-dom";
const Stats = () => {
  const Navigate = useNavigate();
  const getstarted = () => {
    Navigate("/signup");
  };
  return (
    <div className="stats-container">
      <div className="stats-wrapper">
        <div className="stats-right">
          <div className="stats-right-div">
            <img
              src="/images/other/Group 447.png"
              alt="stats"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="stats-left">
          <div className="header21">Stats</div>
          <div className="header3">
            Everything you ever wanted to know about your <br /> website....{" "}
            <br />
            ...but your analytics never told you.
          </div>
        </div>
      </div>

      <div className="btn1 mt-5" onClick={getstarted}>
        Get Started &gt;
      </div>
    </div>
  );
};

export default Stats;
