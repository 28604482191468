import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
    getSSO: localStorage.getItem("jwtToken") ?? false,
  },
  reducers: {
    setGetSSO: (state, action) => {
      state.getSSO = action.payload;
      if (!action.payload) {
        state.currentUser = null;
      }
    },
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    removeCountriesFromSaved: (state, action) => {
      const val = action.payload;
      console.log(val);
      const idx = state.currentUser.regions.selectedCountries
        .map((e) => e.split("-").at(-1))
        .indexOf(val);
      if (idx === -1) {
        state.currentUser.regions.selectedCountries.push(val);
      } else {
        state.currentUser.regions.selectedCountries.splice(idx, 1);
      }
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  removeCountriesFromSaved,
  setGetSSO,
} = userSlice.actions;
export default userSlice.reducer;
