export const UserData = [
    {
        id: 1,
        year: "January",
        userGain: 50,
        userLost: 823
    },
    {
        id: 2,
        year: "Feburary",
        userGain: 10,
        userLost: 83
    }, {
        id: 3,
        year: "March",
        userGain: 20,
        userLost: 823
    }, {
        id: 4,
        year: "April",
        userGain: 30,
        userLost: 823
    }, {
        id: 5,
        year: "May",
        userGain: 40,
        userLost: 823
    }, {
        id: 6,
        year: "June",
        userGain: 50,
        userLost: 823
    },
    {
        id: 7,
        year: "July",
        userGain: 60,
        userLost: 823
    },
    {
        id: 8,
        year: "August",
        userGain: 70,
        userLost: 823
    },
    {
        id: 9,
        year: "September",
        userGain: 80,
        userLost: 823
    },
    {
        id: 10,
        year: "October",
        userGain: 90,
        userLost: 823
    },
    {
        id: 11,
        year: "November",
        userGain: 100,
        userLost: 823
    }
] 