import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Content from "./Content";
import "./test.css";

const drawerWidth = 0;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export default function MainPage(props) {
  const open = false;

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        marginLeft: "0px",
        backgroundColor: "white",
      }}
    >
      <Main
        className="scrollArea"
        open={open}
        sx={{
          padding: 0,
          paddingLeft: "0px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 19,
            display: "flex",
            margin: "2px 5px 5px 2px",
          }}
        >
          <Content
            dummyData={props.dummyData}
            setDummyData={props.setDummyData}
            selectedCountry={props.selectedCountry}
            setSelectedCountry={props.setSelectedCountry}
          />
        </div>
      </Main>
    </Box>
  );
}
