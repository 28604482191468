import {
  Box,
  Button,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { Bars } from "react-loader-spinner";
import React from "react";
import "./question.css";
import PropTypes from "prop-types";
import Top3Form from "../../components/formstep/top3form/Top3Form";
import Top4Form from "../../components/formstep/top4form/Top4Form";
import Top5Form from "../../components/formstep/top5form/Top5Form";
import Top6Form from "../../components/formstep/top6form/Top6Form";
import Top7Form from "../../components/formstep/top7form/Top7Form";
import Top1Form from "../../components/formstep/top1form/Top1Form";
import Top2Form from "../../components/formstep/top2form/Top2Form";
import Top8Form from "../../components/formstep/top8form/Top8Form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { userRequest } from "../../requestMethods";
import { updateQuestion } from "../../redux/apiCalls";
import axios from "axios";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 5px)",
    right: "calc(50% + 5px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#61D1B6",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#61D1B6",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#61D1B6",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#61D1B6",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {/* {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
          ) : (
            <div className="QontoStepIcon-circle" />
          )} */}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};
const Question = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const updateFormData = async () => {
    setLoading(true);
    const Formdata = {};
    [
      "domainname",
      "roleCompany",
      "firstname",
      "lastname",
      "worktype",
      "companySize",
      "businessType",
      "technologyUsed",
      "companyName",
      "hearAbout",
    ].forEach((data2, key) => {
      Formdata[data2] = userData[key];
    });
    const token = localStorage.getItem("jwtToken");
    try {
      const res = await updateQuestion(Formdata, user._id, user.token);
      console.log(res);
      if (res.data && res.status === 200) {
        // If the update is successful
        // navigate(`/chatbot/?token=${token}`);
        navigate(`/Language-&-Region`);
      } else {
        alert("Please try again later.");
      }
    } catch (error) {
      console.error("Error updating form data:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false whether the request succeeds or fails
    }
    console.log(Formdata);
  };

  const [userData, setUserData] = React.useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const steps = ["", "", "", "", "", "", "", "", ""];

  return (
    <div>
      {/* loading spinner */}
      <div
        style={{
          display: loading ? "flex" : "none",
          backdropFilter: "blur(10px)",
        }}
        className="flex fixed w-[100%] justify-center items-center h-[100%] z-10"
      >
        <Bars
          height="80"
          width="80"
          color="#39c6a4"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
      <div className="question-wrapper">
        <div className="brand-question">
          <img
            src="/images/other/add.png"
            alt=""
            style={{ display: "flex", alignItems: "center" }}
          />
          <h2 className="brand-name">Create an account</h2>
        </div>
        <div className="question-right">
          <p className="signin">{user.email}</p>
        </div>
      </div>

      <div className="question-container">
        <div className="question-box">
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<QontoConnector />}
            >
              {steps.map((index, label) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    onClick={handleStep(index)}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box sx={{ mt: 2, mb: 1, py: 1 }}>
                    <Top1Form
                      userData={userData}
                      setUserData={setUserData}
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                    />
                    {(activeStep === 2 || activeStep > 1) && (
                      <Top6Form
                        userData={userData}
                        setUserData={setUserData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                      />
                    )}
                    {(activeStep === 3 || activeStep > 2) && (
                      <Top3Form
                        userData={userData}
                        setUserData={setUserData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                      />
                    )}
                    {(activeStep === 4 || activeStep > 4) && (
                      <Top4Form
                        userData={userData}
                        setUserData={setUserData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                      />
                    )}
                    {(activeStep === 5 || activeStep > 4) && (
                      <Top5Form
                        userData={userData}
                        setUserData={setUserData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                      />
                    )}
                    {(activeStep === 6 || activeStep > 5) && (
                      <Top2Form
                        activeStep={activeStep}
                        userData={userData}
                        setUserData={setUserData}
                        setActiveStep={setActiveStep}
                      />
                    )}
                    {(activeStep === 7 || activeStep > 6) && (
                      <Top7Form
                        userData={userData}
                        setUserData={setUserData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                      />
                    )}
                    {(activeStep === 8 || activeStep > 7) && (
                      <Top8Form
                        userData={userData}
                        setUserData={setUserData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                      />
                    )}
                    <div className="flex flex-col gap-2">
                      {userData[2] !== "" && (
                        <button
                          onClick={updateFormData}
                          className="question-complete"
                        >
                          Skip
                        </button>
                      )}
                      <button
                        onClick={updateFormData}
                        className="question-complete"
                      >
                        Complete application &gt;
                      </button>
                    </div>
                  </Box>
                  {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 1}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button> */}
                  {/* <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button> */}
                  {/* {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))} */}
                  {/* </Box> */}
                </React.Fragment>
              )}
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Question;
