// AppContext.js
import React, { createContext, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  // You can add any other data or functions you want to share within the context

  return (
    <AppContext.Provider value={{ isOpen, toggle }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
