import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { addAdminBalance } from "../../redux/apiCalls";
import { useSelector } from "react-redux";

const AddMoney = ({ onBalanceUpdate }) => {
  const user = useSelector((state) => state.user.currentUser);

  const [input, setInput] = useState("");
  const buttonText1 = "$100";
  const buttonText2 = "$200";
  const buttonText3 = "$500";
  const buttonText4 = "$1000";
  // const [show, setShow] = useState(false);

  const [handleSuccessTrigger, setHandleSuccessTrigger] = useState(false);
  const [handleFailureTrigger, setHandleFailureTrigger] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setHandleSuccessTrigger(false);
      setHandleFailureTrigger(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, [handleSuccessTrigger, handleFailureTrigger]);

  const createOrder = ({ data, actions }) => {
    // Order is created on the server and the order id is returned
    return fetch(`${process.env.REACT_APP_SERVER_URL}/api/paypal/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // use the "body" param to optionally pass additional order information
      // like product skus and quantities
      body: JSON.stringify({
        purchase: {
          description: "payment",
          cost: input.replace("$", ""),
        },
      }),
    })
      .then((response) => response.json())
      .then((order) => order.id);
  };

  const onApprove = (data, actions) => {
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/paypal/orders/${data.orderID}/capture`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: data.orderID,
        }),
      }
    ).then((response) => {
      response.json();
      setHandleSuccessTrigger(true);
      addAdminBalance(parseInt(input.replace("$", ""))).then((res) => {
        onBalanceUpdate();
      });
    });
  };
  const onCancel = (data, actions) => {
    // console.log("transaction cancelled", data);
    setHandleFailureTrigger(true);
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setHandleSuccessTrigger(false);
        setHandleFailureTrigger(false);
      }}
    >
      <div className="px-[100px] py-4 bg-gradient-to-b from-lime-500 to-lime-100 rounded-[50px] justify-center items-center gap-2.5 flex flex-col">
        <Popup
          trigger={
            <button className="text-black text-lg font-medium font-['Lato']">
              Add Money +
            </button>
          }
          position="left"
        >
          <div className="w-[544px] h-[360px] p-2 bg-white rounded-[25px] flex-col justify-center items-center gap-4 inline-flex">
            <div className="self-stretch" />
            <div className="flex-col justify-start items-start gap-1 flex">
              <div className="px-4 mr-16 justify-start items-start gap-2.5 inline-flex">
                <div className="text-neutral-500 text-sm font-bold font-['Lato']">
                  Enter Amount
                </div>
              </div>

              <input
                className="text-black text-2xl font-bold font-['Lato'] w-[440px] px-4 py-2 bg-violet-100 rounded-[50px]"
                placeholder="$100"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>
            <div className="justify-center items-center gap-4 inline-flex">
              <div className="px-2 py-1 rounded-[50px] border border-black justify-center items-center gap-2.5 flex">
                <button
                  className="text-black text-2xl font-bold font-['Lato']"
                  onClick={() => setInput(buttonText1)}
                >
                  {buttonText1}
                </button>
              </div>
              <div className="px-2 py-1 rounded-[50px] border border-black justify-center items-center gap-2.5 flex">
                <button
                  className="text-black text-2xl font-bold font-['Lato']"
                  onClick={() => setInput(buttonText2)}
                >
                  {buttonText2}
                </button>
              </div>
              <div className="px-2 py-1 rounded-[50px] border border-black justify-center items-center gap-2.5 flex">
                <button
                  className="text-black text-2xl font-bold font-['Lato']"
                  onClick={() => setInput(buttonText3)}
                >
                  {buttonText3}
                </button>
              </div>
              <div className="px-2 py-1 rounded-[50px] border border-black justify-center items-center gap-2.5 flex">
                <button
                  className="text-black text-2xl font-bold font-['Lato']"
                  onClick={() => setInput(buttonText4)}
                >
                  {buttonText4}
                </button>
              </div>
            </div>
            <div className="mt-4">
              <PayPalButtons
                forceReRender={[input]}
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
                onCancel={(data, actions) => onCancel(data, actions)}
                onError={(data, actions) => onCancel(data, actions)}
              />
            </div>
            {/* <PayPal cost={input} /> */}
            <div>
              {/* {show ? <PayPal /> : null} */}
              <Popup open={handleSuccessTrigger} position="center">
                <div className="h-[800px] w-[full] bg-white rounded-[19.98px] flex-col justify-center items-center flex p-5">
                  <img
                    src="/images2/success.gif"
                    alt=""
                    width="94px"
                    height="65px"
                    className="-mt-4 mb-4"
                  />

                  <div className="flex-col justify-center items-center gap-2.5 flex">
                    <div className=" ml-7 w-[521px] h-[70px] mr-6 bg-lime-300 rounded-[65px] justify-center items-center inline-flex">
                      <div className="w-[521.27px] text-center text-black text-[33.31px] font-semibold font-['Poppins'] ">
                        Payment Successful!
                      </div>
                    </div>
                    <div className="mt-1 text-center text-black text-opacity-70 text-2xl font-normal font-['Poppins'] ">
                      Hurray!!! Your payment for the Subscription has been
                      successfully renewed.
                    </div>
                  </div>
                  <div className="mt-4 w-[494px] h-[0px] border-[1px] border-[#00000029] "></div>
                  <div className="flex-col justify-start items-start gap-2.5 flex">
                    <div className="w-[494px] text-center text-black text-opacity-70 text-2xl font-normal font-['Poppins'] mt-3">
                      Total Payment
                    </div>
                    <div className="-mt-1">
                      <div className="w-[494px] text-center text-black text-[39.97px] font-semibold font-['Poppins'] ">
                        {input}
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-5 flex mt-3">
                    <div className=" justify-start items-start gap-5 inline-flex">
                      <div className=" p-5 rounded-[9.99px] border-2 border-indigo-400 flex-col justify-start items-start gap-[6.66px] inline-flex w-[250px]">
                        <div className=" text-black text-opacity-70 text-xl font-normal font-['Poppins'] leading-relaxed">
                          Ref Number
                        </div>
                        <div className=" text-black text-[21px] font-medium font-['Poppins'] leading-[29.98px]">
                          000085752257
                        </div>
                      </div>
                      <div className=" px-4 py-[1.85rem] rounded-[9.99px] border-2 border-indigo-400 flex-col justify-start items-start gap-[6.66px] inline-flex">
                        <div className=" text-black text-opacity-70 text-xl font-normal font-['Poppins'] leading-relaxed">
                          Payment Time
                        </div>
                        <div className=" text-black text-[21px] font-medium font-['Poppins'] ">
                          {Date()}
                        </div>
                      </div>
                    </div>
                    <div className=" justify-start items-start gap-5 inline-flex">
                      <div className=" p-5 rounded-[9.99px] border-2 border-indigo-400 flex-col justify-start items-start gap-[6.66px] inline-flex">
                        <div className=" text-black text-opacity-70 text-xl font-normal font-['Poppins'] leading-relaxed">
                          Payment Method
                        </div>
                        <div className=" text-black text-[21px] font-medium font-['Poppins'] leading-[29.98px]">
                          Paypal
                        </div>
                      </div>
                      <div className=" p-5 rounded-[9.99px] border-2 border-indigo-400 flex-col justify-start items-start gap-[6.66px] inline-flex">
                        <div className=" text-black text-opacity-70 text-xl font-normal font-['Poppins'] leading-relaxed">
                          Sender Name
                        </div>
                        <div className=" text-black text-[21px] font-medium font-['Poppins'] leading-[29.98px]">
                          {user.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
            </div>
            <div className="">
              <Popup open={handleFailureTrigger} position="center">
                <div className="h-[80vh] bg-white rounded-[19.98px] flex-col justify-start items-center flex p-5">
                  <img
                    src="/images2/failure.gif"
                    alt=""
                    width="94px"
                    height="65px"
                    className="-mt-4 mb-4"
                  />

                  <div className="flex-col justify-center items-center gap-2.5 flex">
                    <div className=" ml-7 w-[521px] h-[70px] mr-6 bg-[#FF8989] rounded-[65px] justify-center items-center inline-flex">
                      <div className="w-[521.27px] text-center text-black text-[33.31px] font-semibold font-['Poppins'] ">
                        Payment Failed!
                      </div>
                    </div>
                    <div className="mt-1 text-center text-black text-opacity-70 text-2xl font-normal font-['Poppins'] ">
                      Ohh no!
                      <br />
                      Your payment has failed, please try again.
                    </div>
                  </div>
                  <div className="mt-7 w-[494px] h-[0px] border-[1px] border-[#00000029] "></div>
                  <div className="mt-4 flex-col justify-start items-start gap-2.5 flex">
                    <div className="w-[494px] text-center text-black text-opacity-70 text-2xl font-normal font-['Poppins'] mt-3">
                      Total Payment
                    </div>
                    <div className="">
                      <div className="w-[494px] text-center text-black text-[39.97px] font-semibold font-['Poppins'] ">
                        {input}
                      </div>
                    </div>
                  </div>
                  <div className="font-[Poppins] text-center text-[23.315px] font-normal leading-[36.639px] mt-2">
                    We are sorry, {user.name}, but there’s a problem processing
                    your payment{" "}
                  </div>
                  <div
                    className="mt-3 px-[196px] py-[30px] rounded-[65px] font-[Poppins] text-[40px] cursor-pointer"
                    style={{
                      background:
                        "linear-gradient(180deg, #B3EF67 0%, rgba(179, 239, 103, 0.37) 50.95%, #B3EF67 100%)",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log("retry clicked");
                      setHandleFailureTrigger(false);
                    }}
                  >
                    Retry
                  </div>
                  {/* <div className="flex-col justify-start items-start gap-5 flex mt-3">
                    <div className=" justify-start items-start gap-5 inline-flex">
                      <div className=" p-5 rounded-[9.99px] border-2 border-indigo-400 flex-col justify-start items-start gap-[6.66px] inline-flex w-[250px]">
                        <div className=" text-black text-opacity-70 text-xl font-normal font-['Poppins'] leading-relaxed">
                          Ref Number
                        </div>
                        <div className=" text-black text-[21px] font-medium font-['Poppins'] leading-[29.98px]">
                          000085752257
                        </div>
                      </div>
                      <div className=" px-4 py-[1.85rem] rounded-[9.99px] border-2 border-indigo-400 flex-col justify-start items-start gap-[6.66px] inline-flex">
                        <div className=" text-black text-opacity-70 text-xl font-normal font-['Poppins'] leading-relaxed">
                          Payment Time
                        </div>
                        <div className=" text-black text-[21px] font-medium font-['Poppins'] ">
                          {Date()}
                        </div>
                      </div>
                    </div>
                    <div className=" justify-start items-start gap-5 inline-flex">
                      <div className=" p-5 rounded-[9.99px] border-2 border-indigo-400 flex-col justify-start items-start gap-[6.66px] inline-flex">
                        <div className=" text-black text-opacity-70 text-xl font-normal font-['Poppins'] leading-relaxed">
                          Payment Method
                        </div>
                        <div className=" text-black text-[21px] font-medium font-['Poppins'] leading-[29.98px]">
                          Paypal
                        </div>
                      </div>
                      <div className=" p-5 rounded-[9.99px] border-2 border-indigo-400 flex-col justify-start items-start gap-[6.66px] inline-flex">
                        <div className=" text-black text-opacity-70 text-xl font-normal font-['Poppins'] leading-relaxed">
                          Sender Name
                        </div>
                        <div className=" text-black text-[21px] font-medium font-['Poppins'] leading-[29.98px]">
                          {user.name}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </Popup>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default AddMoney;
