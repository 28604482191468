import * as React from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import "./datatable.css";
const DataTable1 = () => {
  const [countries, setCountries] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const customStyles = {
    headCells: {
      style: {
        fontSize: ".9rem",
        width: "15px",
        text: "center",
        fontWeight: "600",
        background: "rgba(237, 237, 237, 1)",
      },
    },
    cells: {
      style: {
        fontWeight: "600",
        fontSize: "1.1rem",
      },
    },
  };
  const getcountries = async () => {
    try {
      const response = await axios.get("https://restcountries.com/v2/all");
      setCountries(response.data);
      setFilteredCountries(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      name: "Country Name",
      selector: (row) => row.name,
      style: "font-family:'Lato', sans-serif",
    },
    {
      name: "Country Native Name",
      selector: (row) => row.nativeName,
      style: "color:rgba(100, 104, 102, 1);font-family:'Lato', sans-serif",
    },
    {
      name: "Country Capital",
      selector: (row) => row.capital,
      style: "color:rgba(100, 104, 102, 1);font-family:'Lato', sans-serif",
    },
  ];
  useEffect(() => {
    getcountries();
  }, []);

  useEffect(() => {
    if (search === "") {
      setFilteredCountries(countries);
    }
    const result = countries.filter((country) => {
      return country.name.toLowerCase().match(search.toLowerCase());
    });
    setFilteredCountries(result);
  }, [search]);

  return (
    <div style={{ width: "90%" }}>
      {loading ? (
        <h2 style={{ color: "black", textAlign: "center" }}>
          Loading you stats
        </h2>
      ) : (
        <DataTable
          columns={columns}
          data={filteredCountries}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="300px"
          // selectableRows
          selectableRowsHilight
          hilightOnHover
          subHeader
          paginationPerPage={5}
          subHeaderComponent={
            <div
              style={{
                width: "100%",
                position: "relative",
                marginTop: "-1rem",
              }}
            >
              {/* <input type="text" placeholder='' value={search} onChange={(e) => setSearch(e.target.value)} style={{ border: 'none', borderLeft: 'none', width: '100%', background: 'rgba(237, 237, 237, 1)', marginBottom: '3%', borderRadius: "4px 4px 4px 4px", paddingLeft: '2rem', fontSize: "1.5rem", color: "black" }} /> */}
              <select
                name=""
                id=""
                className="month-select"
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: "2rem", marginLeft: "-1.5rem" }}
              >
                <option value="Africa">One month</option>
                <option value="Bulgaria">One year</option>
              </select>
            </div>
          }
          customStyles={customStyles}
        />
      )}
    </div>
  );
};

export default DataTable1;
