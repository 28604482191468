// import React, { Component } from "react";
// // import ReactHtmlParser from "react-html-parser";

// class DetailPopUp extends Component {
//   render() {
//     console.log("detail blog data", this.props.blogDetail.title);
//     const markup = { __html: `${this.props.blogDetail.blogDetails}` };

//     return (
//       <div
//         className="modal fade bd-example-modal-lg"
//         id="modelPopUp"
//         tabindex="-1"
//         role="dialog"
//         aria-labelledby="myLargeModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-lg">
//           <div className="modal-content">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-4">
//                   <p className="byName">By: Harshit Saini</p>
//                   <p className="DateBlog">Date: 21/07/2021</p>
//                 </div>
//                 <div className="col-md-8">
//                   <img
//                     alt="Blog-img"
//                     src={this?.props?.blogDetail?.blogDetailImage}
//                   />
//                 </div>
//                 <h1>{this.props.blogDetail.title}</h1>
//                 {/* {ReactHtmlParser(this.props.blogDetail.blogDetails)} */}

//                 <div dangerouslySetInnerHTML={markup}></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default DetailPopUp;

import React, { Component } from "react";

class DetailPopUp extends Component {
  render() {
    const { blogDetail, isOpen, onClose } = this.props;
    const markup = { __html: `${blogDetail.blogDetails}` };

    return (
      <div
        className={`modal fade ${isOpen ? "show" : ""}`}
        style={{ display: isOpen ? "block" : "none"}}
        id="modelPopUp"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden={!isOpen}
      >
        <div className="modal-dialog modal-lg"
        onClick={(e)=>{
          e.stopPropagation();
        }}>
          <div className="modal-content">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <p className="byName">By: Harshit Saini</p>
                  <p className="DateBlog">Date: 21/07/2021</p>
                </div>
                <div className="col-md-8">
                  <img alt="Blog-img" src={blogDetail.blogDetailImage} />
                </div>
                <div className="col-md-12">
                  <h1>{blogDetail.title}</h1>
                  <div dangerouslySetInnerHTML={markup}></div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="relative btn btn-secondary cursor-pointer top-[2rem]"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailPopUp;
