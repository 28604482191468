export const downPointer = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path
      d="M0.390601 1.73225L4.34034 5.68199C4.73086 6.07252 5.36403 6.07252 5.75455 5.68199L9.70429 1.73225C10.3343 1.10229 9.88809 0.0251465 8.99719 0.0251465H1.09771C0.206803 0.0251465 -0.239364 1.10229 0.390601 1.73225Z"
      fill="black"
    />
  </svg>
);

export const dollar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="36"
    viewBox="0 0 22 36"
    fill="none"
  >
    <g clip-path="url(#clip0_103_991)">
      <path
        d="M14.2373 15.6857C9.33347 14.1686 6.9642 13.2171 6.9642 10.8C6.9642 8.17714 10.0222 7.22571 11.9507 7.22571C15.5597 7.22571 16.8821 9.77143 17.1851 10.6714L21.538 8.94857C21.1247 7.81714 19.2789 4.03714 14.2098 3.21429V0H9.38857V3.24C2.22565 4.68 2.17055 10.5686 2.17055 10.8514C2.17055 16.6886 8.36923 18.3343 11.3997 19.3629C15.7525 20.8029 17.681 22.1143 17.681 24.5829C17.681 27.4886 14.7883 28.7229 12.2262 28.7229C7.21214 28.7229 5.77956 23.9143 5.61426 23.3486L1.04102 25.0714C2.77665 30.7029 7.32234 32.22 9.36102 32.6829V36H14.1822V32.8114C15.6148 32.58 22.5022 31.2943 22.5022 24.5314C22.5298 20.9571 20.8492 17.82 14.2373 15.6857Z"
        fill="#71C921"
      />
    </g>
    <defs>
      <clipPath id="clip0_103_991">
        <rect
          width="21"
          height="36"
          fill="white"
          transform="translate(0.932617)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const circle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
  >
    <path
      d="M14 0.5C21.4395 0.5 27.5 6.75133 27.5 14.5C27.5 22.2487 21.4395 28.5 14 28.5C6.56055 28.5 0.5 22.2487 0.5 14.5C0.5 6.75133 6.56055 0.5 14 0.5Z"
      fill="#9090FF"
      stroke="black"
    />
  </svg>
);

export const rightArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.0505 11.47L13.0505 4.47C12.8575 4.28243 12.5792 4.21204 12.3203 4.28533C12.0614 4.35863 11.8613 4.56447 11.7953 4.82533C11.7293 5.08619 11.8075 5.36243 12.0005 5.55L17.7105 11.25H4.48047C4.06626 11.25 3.73047 11.5858 3.73047 12C3.73047 12.4142 4.06626 12.75 4.48047 12.75H17.7005L12.0005 18.45C11.8575 18.5893 11.7769 18.7804 11.7769 18.98C11.7769 19.1796 11.8575 19.3707 12.0005 19.51C12.1383 19.6546 12.3307 19.7345 12.5305 19.73C12.7295 19.7309 12.9206 19.6516 13.0605 19.51L20.0605 12.51C20.3529 12.2172 20.3529 11.7428 20.0605 11.45L20.0505 11.47Z"
      fill="#0075FF"
    />
  </svg>
);
