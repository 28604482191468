import React from 'react'
import "./seo.css"
const Seo = () => {
  return (
    <div className="seo-container">
        <div className="seo-wrapper">


        <div className="seo-left">
            <div className="header21">Seo Enhancement</div>
            <div className="header3">SEO Enhancement that brings the traffic that you always <br /> needed, by going beyond the borders of search engine <br /> algorithms.
</div>
            
        </div>
        <div className="seo-right">
            <div className="seo-right-div">
              {/* <div className="seo-top-left">
                <img src="/images/other/Group 444.png" alt="" className="seo-top" />
              </div>
              <div className="seo-bottom-left">
                <img src="/images/other/Message file.png" alt="" className="seo-top" />
              </div>
              <div className="seo-top-right">
                <img src="/images/other/Group 378.png" alt="" className="seo-top" />
              </div>
              <div className="seo-bottom-right">
                <img src="/images/other/Group 387.png" alt="" className="seo-top" />
              </div>
              <div className="seo-middle">
                <div className="middle-left">
                  <img src="/images/other/seting3.png" alt="" className="left-img" />
                </div>
                <div className="middle-middle">
                  <img src="/images/other/settings.png" alt="" className="middle-img" />
                </div>
                <div className="middle-right">
                  <img src="/images/other/setting4.png" alt="" className="middle-img" />
                </div>

              </div> */}
              <img src="/images/other/Group 446.svg" alt="" style={{width:"100%"}}/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Seo