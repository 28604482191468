import React, { useState } from "react";
import "./DomainElement.css";
import "./Drop.css";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";

function DomainElement({
  inputText,
  CountryCode,
  domainType,
  premium,
  premium2,
}) {
  const { enqueueSnackbar } = useSnackbar();
  let primium2;
  if (premium2 === undefined) {
    primium2 = "";
  } else {
    primium2 = premium2.split(".")[0];
  }
  const primium = premium.split(".")[0];
  // console.log(primium, primium2);
  // const [firstroutes, setFirstroutes] = useState("");
  // const [secondroutes, setSecondroutes] = useState("");
  // const [thirdroutes, setThirdroutes] = useState("");
  // const firstroute = (e) => {
  //   setFirstroutes(e.target.value);
  // };
  // const secondroute = (e) => {
  //   setSecondroutes(e.target.value);
  // };
  // const thirdroute = (e) => {
  //   setThirdroutes(e.target.value);
  // };
  const [isCountryCodeCopied, setIsCountryCodeCopied] = useState(false);
  const [countryCode, setCountryCode] = useState(CountryCode);
  const handleCopyCountryCode = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(countryCode)
      .then(() => {
        setIsCountryCodeCopied(true);
        setTimeout(() => setIsCountryCodeCopied(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  const [textToCopyCvalue, setTextToCopyCvalue] = useState(
    "saas.glocalise.site"
  );
  const [isCopiedCvalue, setIsCopiedCvalue] = useState(false);
  const handleCopyClickCvalue = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopyCvalue)
      .then(() => {
        setIsCopiedCvalue(true);
        setTimeout(() => setIsCopiedCvalue(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  const [textToCopy, setTextToCopy] = useState("www");
  const [isCopiedText, setIsCopiedText] = useState(false);
  const handleCopyTextClick = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopiedText(true);
        setTimeout(() => setIsCopiedText(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  const [isCopied2, setIsCopied2] = useState(false);
  const [textToCopy2, setTextToCopy2] = useState("Martix");
  const handleCopyClick2 = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopy2)
      .then(() => {
        setIsCopied2(true);
        setTimeout(() => setIsCopied2(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  const [isCopied3, setIsCopied3] = useState(false);
  const [textToCopy3, setTextToCopy3] = useState(
    "CName Value from hosting provider"
  );

  const handleCopyClick3 = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopy3)
      .then(() => {
        setIsCopied3(true);
        setTimeout(() => setIsCopied3(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <div className="DomainFrame">
      {/* <select className="dropdownMain" name="cars" id="cars">
				<option className="option1" value="blog">
					Blog
				</option>
				<option value="blog">blog</option>
				<option value="blog">Blog</option>
				<option value="blog">Blog</option>
			</select>
			<select className="dropdownMain" name="cars" id="cars">
				<option value="blog">www</option>
				<option value="blog">www</option>
				<option value="blog">www</option>
				<option value="blog">www</option>
			</select>
			<input className="domainInputField" value={domainName} type="text" />
			<select className="dropdownMain" name="cars" id="cars">
				<option value="blog">www</option>
				<option value="blog">www</option>
				<option value="blog">www</option>
				<option value="blog">www</option>
			</select> */}
      {domainType === 2 ? null : null}
      {domainType === 1 ? (
        <div className="data-div">
          <div
            className="d-flex flex-row justify-content-between title-div"
            style={{ width: "100%", padding: "40px 12px" }}
          >
            <div className="bold" style={{ marginRight: "75px" }}>
              CNAME
            </div>
            <div className="bold">CNAME Value</div>
          </div>
          {/* inputs tags feting data from API  */}
          <div className="input-div" style={{ padding: "12px 12px" }}>
            {/*  */}
            {/* <input type="text" className="flex justify-center items-center relative -top-2" style={{ outline: "none", border: "none", borderRadius: "20%", textAlign: "center", backgroundColor: "white", padding: "20px 10px", width: "40px" }} inputMode="none" value={CountryCode}/> */}
            <div className="flex" style={{ cursor: "pointer" }}>
              <div
                className="flex flex-row relative"
                style={{
                  outline: "none",
                  border: "none",
                  borderRadius: "20%",
                  textAlign: "center",
                  backgroundColor: "white",
                  padding: "5px 10px",
                  width: "40px",
                  height: "35px",
                  top: "-5px",
                }}
              >
                {CountryCode}
              </div>
              <img
                src="../images/other/copyIcon.png"
                alt="icon"
                style={{
                  width: "20px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCountryCode(CountryCode);
                  handleCopyCountryCode();
                }}
              />
            </div>
            <div className="flex flex-row-reverse">
              {/* <input
                type="text"
                style={{ outline: "none", border: "none", cursor: "pointer" }}
                value={"saas.glocalise.site"}
              /> */}
              {textToCopyCvalue}
              <img
                src="../images/other/copyIcon.png"
                alt="icon"
                style={{
                  width: "20px",
                  marginRight: "5px",
                  // position: "relative",
                  // left: "-18vw",
                  // marginTop: "5px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyClickCvalue();
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      {domainType === 4 ? (
        <>
          <div className="data-div">
            <div
              className="d-flex flex-row justify-content-between title-div"
              style={{ width: "100%", padding: "40px 12px" }}
            >
              <div className="bold" style={{ marginRight: "75px" }}>
                CNAME
              </div>
              <div className="bold">CNAME Value</div>
            </div>
            {/* inputs tags feting data from API  */}
            <div className="input-div" style={{ padding: "12px 12px" }}>
              <div className="flex flex-row">
                <input
                  type="text"
                  style={{ outline: "none", border: "none" }}
                  inputMode="none"
                  value={primium}
                />
                <img
                  src="../images/other/copyIcon.png"
                  alt="icon"
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    position: "relative",
                    left: "-65%",
                    // marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyTextClick();
                  }}
                />
              </div>
              <div className="flex flex-row-reverse">
                {/* <input
                  type="text"
                  style={{ outline: "none", border: "none" }}
                  value={"saas.glocalise.site"}
                /> */}
                {textToCopyCvalue}
                <img
                  src="../images/other/copyIcon.png"
                  alt="icon"
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    cursor: "pointer",
                    // position: "relative",
                    // left: "-18vw",
                    // marginTop: "5px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyClickCvalue();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="data-div">
            <div
              className="d-flex flex-row title-div"
              style={{ width: "100%", padding: "40px 12px" }}
            >
              <div className="bold">CName</div>
              <div className="bold">CName Value</div>
            </div>
            {/* inputs tags feting data from API  */}
            <div className="input-div flex">
              <div
                className="flex"
                title={isCopied2 ? "Text Copied" : "Click to copy"}
                style={{ cursor: "pointer" }}
              >
                {textToCopy2}
                <img
                  src="../images/other/copyIcon.png"
                  alt="icon"
                  style={{
                    width: "20px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyClick2();
                  }}
                />
              </div>
              <div
                className="flex flex-row-reverse"
                title={isCopied3 ? "Text Copied" : "Click to copy"}
                style={{ marginRight: "0rem", cursor: "pointer" }}
              >
                {textToCopy3}
                <img
                  src="../images/other/copyIcon.png"
                  alt="icon"
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyClick3();
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {domainType === 3 ? (
        <>
          {" "}
          <div className="data-div">
            <div
              className="d-flex flex-row justify-content-between title-div"
              style={{ width: "100%", padding: "40px 12px" }}
            >
              <div className="bold" style={{ marginRight: "75px" }}>
                CNAME
              </div>
              <div className="bold">CNAME Value</div>
            </div>
            {/* inputs tags feting data from API  */}
            <div className="input-div" style={{ padding: "12px 12px" }}>
              <div className="flex flex-row">
                <input
                  type="text"
                  style={{ outline: "none", border: "none" }}
                  inputMode="none"
                  value={primium}
                />
                <img
                  src="../images/other/copyIcon.png"
                  alt="icon"
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    position: "relative",
                    left: "-65%",
                    // marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyTextClick();
                  }}
                />
              </div>
              <div className="flex flex-row-reverse">
                {/* <input
                  type="text"
                  style={{ outline: "none", border: "none" }}
                  value={"saas.glocalise.site"}
                /> */}
                {textToCopyCvalue}
                <img
                  src="../images/other/copyIcon.png"
                  alt="icon"
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    // position: "relative",
                    // left: "-18vw",
                    // marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyClickCvalue();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="data-div">
            <div
              className="d-flex flex-row title-div"
              style={{ width: "100%", padding: "40px 12px" }}
            >
              <div className="bold">CName</div>
              <div className="bold">CName Value</div>
            </div>
            {/* inputs tags feting data from API  */}
            <div className="input-div flex">
              <div
                className="flex"
                title={isCopied2 ? "Text Copied" : "Click to copy"}
                style={{ cursor: "pointer" }}
              >
                {textToCopy2}
                <img
                  src="../images/other/copyIcon.png"
                  alt="icon"
                  style={{
                    width: "20px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyClick2();
                  }}
                />
              </div>
              <div
                className="flex flex-row-reverse"
                title={isCopied3 ? "Text Copied" : "Click to copy"}
                style={{ marginRight: "0rem", cursor: "pointer" }}
              >
                {textToCopy3}
                <img
                  src="../images/other/copyIcon.png"
                  alt="icon"
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyClick3();
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DomainElement;
