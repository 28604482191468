import { KeyboardArrowRight } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, redirect } from "react-router-dom";
import { Language } from "../LanguageCode";
import "./navbar.css";
import zIndex from "@mui/material/styles/zIndex";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
const Navbar = ({
  scrollToServices,

  scrollToBlogs,
}) => {
  const { innerWidth: width, innerHeight: height } = window;
  const [menu, setMenu] = useState(true);
  const [countryToggle1, setCountryToggle1] = useState(false);
  const [value1, setValue1] = useState(false);
  const [languageflag1, setLanguageflag1] = useState("");
  const [languagecode1, setLanguagecode1] = useState("");
  const countryDrop1 = () => {
    setCountryToggle1(!countryToggle1);
  };
  const languagevalue1 = (index) => {
    setLanguageflag1(Language[index].image);
    setLanguagecode1(Language[index].code);
    setValue1(true);
  };
  const Navigate = useNavigate();
  const toggleMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    if (countryToggle1) {
      if (document.body.getAttribute("listenersAttached") === "true") {
        return;
      }
      setTimeout(() => {
        document.body.setAttribute("listenersAttached", true);
        document.body.addEventListener("click", (e) => {
          countryDrop1();
        });
      }, 100);
    }
    return () =>
      window.removeEventListener("click", (e) => {
        document.body.removeAttribute("listenersAttached");
      });
  }, [countryToggle1]);
  const trynowbutton = () => {
    Navigate("/signup");
  };
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="container">
      <div className="wrapper">
        <div className="navleft">
          <Link to="/">
            {" "}
            <img
              src="/images/logo/Vector 10.png"
              alt=""
              className="navimg"
            />{" "}
          </Link>
        </div>
        {width > 1000 ? (
          <div
            className="d-flex flex-row justify-content-between"
            style={{ width: "70%" }}
          >
            <div className="navcenter">
              <li className="navcenter-list">Home</li>
              <li className="navcenter-list" onClick={scrollToServices}>
                Services
              </li>
              <li className="navcenter-list" onClick={scrollToBlogs}>
                Blogs
              </li>
              <li className="navcenter-list">
                <a href="https://wa.me/14452125275" className="contactus">
                  Contact us
                </a>
              </li>
            </div>
            <div className="navright">
              <div
                className="country-lang"
                onClick={(e) => {
                  document.body.click();
                  e.stopPropagation();
                  countryDrop1();
                }}
              >
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <img
                    src={
                      !value1
                        ? "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
                        : languageflag1
                    }
                    alt="Flag of France"
                  />
                  {!value1 ? "CA" : languagecode1}
                </div>
              </div>{" "}
              <div
                className="lang-popup1"
                style={{ display: countryToggle1 ? "flex" : "none" }}
              >
                {Language.map((e, index) => (
                  <div
                    className="d-flex flex-row align-items-center mb-2 justify-content-between"
                    onClick={(e) => {
                      e.stopPropagation();
                      languagevalue1(index);
                      countryDrop1();
                    }}
                    key={index}
                  >
                    <img
                      src={e.image}
                      alt="Flag of France"
                      style={{
                        width: "20px",
                        height: "11px",
                        marginRight: "3px",
                      }}
                    />
                    <div>{e.code}</div>
                  </div>
                ))}
              </div>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <div className="navright-list login-btn ">Login</div>
              </Link>
              <div className="navright-list free-trial-btn">
                <button className="navright-button" onClick={trynowbutton}>
                  Try Now <KeyboardArrowRight className="arrow" />{" "}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            <div className="hamburger2">
              <input type="checkbox" onClick={toggleMenu} />
              <div
                className="hamburgerlines"
                style={{ position: menu ? "absolute" : "fixed" }}
              >
                <span className="lines line1"></span>
                <span className="lines line2"></span>
                <span className="lines line3"></span>
              </div>
              <ul className="menu-items">
                <li
                  className="navcenter-list"
                  style={{ color: "white", fontSize: "30px" }}
                >
                  Home
                </li>
                <li
                  className="navcenter-list"
                  style={{ color: "white", fontSize: "30px" }}
                >
                  Services
                </li>
                <li
                  className="navcenter-list"
                  style={{ color: "white", fontSize: "30px" }}
                >
                  Blogs
                </li>
                <li
                  className="navcenter-list"
                  style={{ color: "white", fontSize: "30px" }}
                >
                  <Link></Link> us
                </li>
                <li>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <div
                      className="navright-list login-btn "
                      style={{ color: "white", fontSize: "30px" }}
                    >
                      Login
                    </div>
                  </Link>
                </li>
                <li>
                  <div className="navright-list free-trial-btn">
                    <button className="navright-button">
                      Try Now <KeyboardArrowRight className="arrow" />{" "}
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
