import React, { Component } from "react";
import logo from "./assets/images/logo1.png";

export class Graphics extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="center">
          <div className="what-bsns-row">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <div className="bsns-left-sec">
                    <p className="small-heading serviceP">AI Based</p>
                    <br></br>
                    <p className="large-heading">Chatbot</p>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 center  ">
                  <div className="bsns-right">
                    <div className="srvc-item-sec">
                      <div className="center">
                        <img src={logo}></img>
                      </div>
                      <h3 style={{ fontSize: "1.75rem" }}>AI based Chatbot</h3>
                      <p className="serviceP">
                        Break the ice! Communicate with your customers even if
                        you don't know their language. We translate your chats
                        on fly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Graphics;
