export default function sortIfSelected(arr, allElements) {
  const ss = new Set();
  return [
    arr.sort((a, b) => {
      const isSelectedA = allElements.includes(a);
      const isSelectedB = allElements.includes(b);
      if (isSelectedA && isSelectedB) {
        ss.add(a);
        ss.add(b);

        return 0;
      } else if (isSelectedA) {
        ss.add(a);
        return -1;
      } else if (isSelectedB) {
        ss.add(b);
        return 1;
      } else {
        return 0;
      }
    }),
    ss.size,
  ];
}
