import React, { useEffect, useState } from "react";
import Navbar from "./navbar/Navbar";
import Newsletter from "./newsletter/NewsLetter";
import { Radio } from "react-loader-spinner";
import Footer from "./footer/Footer";
import "./Blogspage.css";
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import axios from "axios";
function Blogspage() {
  const [blockMap, setBlockMap] = useState(null);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const fetchNotionPage = async () => {
      try {
        const response = await fetch(
          "https://atiuttam.notion.site/Let-s-test-702208d6fb884244b18102030af28216?pvs=4"
        );
        console.log(response, "response");
        if (!response) {
          return setLoader(true);
        } else {
          console.log(response.data);
          setLoader(false);
          setBlockMap(response.data);
        }
      } catch (error) {
        console.error("Error fetching Notion page:", error);
      }
    };
    fetchNotionPage();
  }, []);
  return (
    <main>
      <section className="blogspage-navbar">
        <Navbar />
      </section>
      <section className="blogspage-icon">
        <div className="Reactangle ">
          <h3 id="blog__chatbot-title">
            Blogs
            <div className="message_icons_div">
              <img
                style={{ marginRight: "6.9px", paddingTop: "6px" }}
                src="./header_images/message-square.png"
                alt="img.jpeg"
              />
              <img
                style={{ paddingTop: "38.7px", paddingRight: "7.89px" }}
                src="./header_images/message-square3.png"
                alt="img.jpeg"
              />
              <img
                style={{ paddingTop: "10px" }}
                src="./header_images/message-square2.png"
                alt="img.jpeg"
              />
            </div>
          </h3>
        </div>
      </section>
      <section className="blogspage-notion">
        {loader ? (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Radio colors={["#51E5FF", "#7DE2D1", "#FF7E6B"]} />
          </span>
        ) : (
          blockMap !== null && <NotionRenderer blockMap={blockMap} />
        )}
      </section>
      <section>
        <Newsletter />
      </section>
      <section>
        <Footer />
      </section>
    </main>
  );
}

export default Blogspage;
