import React from 'react'

function University() {
  return (
    <div className='d-flex align-items-center justify-content-center text-light'>
      UNIVERSITY PAGE
    </div>
  )
}

export default University
