/**
 * * Mapping unique-ids => zones names
 */
const zones = {
  ME: "MENA",
  EM: "EMEA",
  AP: "APAC",
  NA: "NAC",
  EE: "EEMEA",
  SE: "SEMEA",
  CE: "CEMEA",
  CEE: "CEE",
  EU: "EUMEN",
  EME: "EMEIA",
  EMEA: "EMEAC",
  NAC: "NACE",
  CEM: "CEMA",
};
export default zones;
