import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, NavLink } from "react-router-dom";
import DynamicProfilePhoto from "../chatbot/DynamicProfilePhoto";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import "./TimeSpent.css";
import SetLimit from "./SetLimit";
import TranslationEstimator from "./TranslationEstimator";
import Wallet from "./Wallet";
import Passbook from "./Passbook";
import Subscriptions from "./Subscriptions";
import Sidebar from "../../components/sidebar/Sidebar";
import Makepayment from "../Makepayment";

function TimeSpent2({ balance, handleBalanceUpdate }) {
  const navigate = useNavigate();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("wallet");
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const data1 = localStorage.getItem("datapic");
  const parsedData1 = JSON.parse(data1);
  const data = localStorage.getItem("datasso");
  const parsedData = JSON.parse(data);
  let content;
  const [blur, setBlur] = useState(false);
  useEffect(() => {
    if (balance < 100 && currentPage != "wallet") {
      setBlur(true);
    } else {
      setBlur(false);
    }
  }, [balance, currentPage]);

  switch (currentPage) {
    case "limit":
      content = <SetLimit balance={balance} />;
      // content = <></>;
      break;
    case "passbook":
      content = <Passbook />;
      break;
    case "estimator":
      content = <TranslationEstimator />;
      break;
    case "subscriptions":
      content = <Subscriptions />;
      break;
    default:
      content = (
        <Wallet balance={balance} handleBalanceUpdate={handleBalanceUpdate} />
      );
  }
  return (
    <div className="flex w-full">
      {/* Sidebar */}
      <div className="flex">
        {/* Old sidebar */}
        {/* <Header onToggleNavbar={toggleNavbar} /> */}
        <Sidebar onToggleNavbar={toggleNavbar} />
      </div>

      {/* Blocking popup for paypal */}
      {/* <div
        className="flex w-full h-full fixed z-[1000] justify-center items-center"
        style={{
          background: "rgba(0, 0, 0, 0.08)",
          backdropFilter: "blur(10px)",
          display: blur ? "flex" : "none",
        }}
      >
        <Makepayment setCurrentPage={setCurrentPage} />
      </div> */}
      <div
        className={`flex flex-col w-full ml-40 items-center ${
          isNavbarOpen ? "timespent__navbar-open" : "timespent__navbar-closed"
        }`}
      >
        <div
          style={{
            width: isNavbarOpen ? "75%" : "60%",
            transition: "1s ease-in-out",
          }}
          className="flex items-center justify-between my-4 "
        >
          <form className="flex" style={{ width: " 70%" }}>
            <div className="input-group">
              <span
                className="input-group-text"
                style={{ background: "#EDEDED" }}
                id="basic-addon1"
              >
                <SearchIcon></SearchIcon>
              </span>
              <input
                type="text"
                className="form-control"
                style={{ outline: "none" }}
                placeholder=""
                aria-label="Username"
              />
            </div>
          </form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "30px",
            }}
          >
            <div>
              <NotificationsOutlinedIcon
                style={{ cursor: "pointer" }}
              ></NotificationsOutlinedIcon>
            </div>
            <div
              className="two"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/settings");
              }}
            >
              {parsedData1 ? (
                <img src={parsedData1.pic} alt="" className="pro-image" />
              ) : (
                <>
                  {
                    <div className="hr-parent">
                      <div className="hr-child">
                        <h5 className="hr-child-h5" id="hr-title">
                          {parsedData ? (
                            <DynamicProfilePhoto
                              name={parsedData.name}
                              size={100}
                            />
                          ) : (
                            "HR"
                          )}
                        </h5>
                      </div>
                    </div>
                  }
                </>
              )}
              <div className="HR">
                <h5>{parsedData ? parsedData.name : "HR"}</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex justify-between items-center text-l"
          style={{
            background: "#f2f2f2",
            padding: "10px 20px",
            flexShrink: 0,
            height: "54px",
            width: "100%",
          }}
        >
          <div
            className={`timespent__navlink-section ${
              currentPage === "wallet" ? "active" : ""
            }`}
            onClick={() => setCurrentPage("wallet")}
          >
            Wallet
          </div>
          <div
            className={`timespent__navlink-section ${
              currentPage === "limit" ? "active" : ""
            }`}
            onClick={() => setCurrentPage("limit")}
          >
            Set Limit
          </div>
          <div
            className={`timespent__navlink-section ${
              currentPage === "passbook" ? "active" : ""
            }`}
            onClick={() => setCurrentPage("passbook")}
          >
            Passbook
          </div>
          <div
            className={`timespent__navlink-section ${
              currentPage === "estimator" ? "active" : ""
            }`}
            onClick={() => setCurrentPage("estimator")}
          >
            Cost Estimator
          </div>
          <div
            className={`timespent__navlink-section ${
              currentPage === "subscriptions" ? "active" : ""
            }`}
            onClick={() => setCurrentPage("subscriptions")}
          >
            Active Subscriptions
          </div>
        </div>
        <div
          className="flex justify-center mt-10"
          style={{
            width: isNavbarOpen ? "90%" : "95%",
            transition: "1s ease-in-out",
          }}
        >
          {content}
        </div>
      </div>
    </div>
  );
}

export default TimeSpent2;
