import React, { useState, useEffect } from "react";
import "./SetLimit.css";
import { downPointer } from "./svgs";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedCountries } from "../../redux/dataSlices";
import countries from "../../data/countries";
import { getAdminBalance } from "../../redux/apiCalls";

const SetLimit = ({ balance }) => {
  const [balanceLimit, setBalanceLimit] = useState(balance / 2);
  const [limitDays, setLimitDays] = useState("30 Days");
  const [limitDaysDropdown, setLimitDaysDropdown] = useState(false);
  const [limitSettingsDropdown, setLimitSettingsDropdown] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState("system");
  const [heading, setHeading] = useState("Automatically Changed Accuracy");

  const selectedCountries = useSelector(getSelectedCountries);
  const countriesWithLanguages = useSelector((state) => state.data.countries);
  const languagesId = useSelector((state) => state.data.languages);

  const [languageLimits, setLanguageLimits] = useState(() => {
    const initialLimits = {};
    if (countriesWithLanguages) {
      selectedCountries.forEach((countryId) => {
        initialLimits[countryId] = {}; // Language limits for each country
        const languages = countriesWithLanguages[countryId]?.languages || [];
        const initialLanguageLimit = parseInt(
          balanceLimit / selectedCountries.length / (languages.length || 1)
        );
        languages.forEach((language) => {
          initialLimits[countryId][language.id] = initialLanguageLimit;
        });
      });
    }
    return initialLimits;
  });

  const handleLanguageLimitChange = (countryId, languageId, newLimit) => {
    setLanguageLimits((prevLimits) => ({
      ...prevLimits,
      [countryId]: {
        ...prevLimits[countryId],
        [languageId]: newLimit,
      },
    }));
  };

  const initialCountryLimit = parseInt(balanceLimit / selectedCountries.length);
  const [countryLimits, setCountryLimits] = useState(
    selectedCountries.reduce((limits, countryId) => {
      limits[countryId] = initialCountryLimit;
      return limits;
    }, {})
  );
  // Function to handle updating the limit for a specific country
  const updateCountryLimit = (countryId, newLimit) => {
    setCountryLimits((prevLimits) => ({
      ...prevLimits,
      [countryId]: parseInt(newLimit),
    }));
  };

  const handleCheckboxChange = (value) => {
    if (selectedCheckbox === value) {
      setSelectedCheckbox("");
    } else {
      setSelectedCheckbox(value);
    }
  };

  return (
    <div
      className="flex flex-col w-[90%] justify-center"
      style={{ transition: "1s ease-in-out" }}
      onClick={(e) => {
        e.stopPropagation();
        setLimitSettingsDropdown(false);
        setLimitDaysDropdown(false);
      }}
    >
      <div className="flex flex-col limit__balance w-[90%] p-[16px] bg-[#e8e8fd] rounded-[25px]">
        <span className="text-center font-[Lato] text-[64px] leading-[76.8px]">
          $ {balance}
        </span>
        <p className="text-center font-[Lato] text-[18px] text-[#747474] leading-[21.8px]">
          Cash Balance
        </p>
        <div className="flex flex-col items-end mr-20 justify-center float-right mt-4">
          <span className="flex justify-center items-center mr-4">
            $ {balanceLimit}
          </span>
          <div>
            <span
              className="flex justify-center items-center gap-[4px]"
              onClick={(e) => {
                e.stopPropagation();
                setLimitDaysDropdown(!limitDaysDropdown);
              }}
            >
              Limit Usage: {limitDays} {downPointer}
            </span>
            {limitDaysDropdown && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="flex justify-end mt-2"
              >
                <div
                  className="flex flex-col bg-white p-[16px] rounded-[10px] relative left-[10rem] gap-[10px] bottom-[2rem]"
                  style={{ boxShadow: "0px 4px 20px 0px rgba(0,0,0,0.25" }}
                >
                  <div className={`flex items-center gap-[8px] px-[10px]`}>
                    <input
                      type="radio"
                      value="30"
                      id="30 Days"
                      name="limitDays"
                      className="cursor-pointer"
                      onChange={() => {
                        setLimitDays("30 Days");
                        setLimitDaysDropdown(false);
                      }}
                      checked={limitDays === "30 Days"}
                    />
                    <label htmlFor="30 Days" className="mb-0 cursor-pointer">
                      30 Days
                    </label>
                  </div>
                  <div
                    className={`flex items-center gap-[8px] px-[10px] self-stretch`}
                  >
                    <input
                      type="radio"
                      value="90"
                      id="90 Days"
                      name="limitDays"
                      className="cursor-pointer"
                      onChange={() => {
                        setLimitDays("90 Days");
                        setLimitDaysDropdown(false);
                      }}
                      checked={limitDays === "90 Days"}
                    />
                    <label htmlFor="90 Days" className="mb-0 cursor-pointer">
                      90 Days
                    </label>
                  </div>
                  <div
                    className={`flex items-center gap-[8px] px-[10px] self-stretch`}
                  >
                    <input
                      type="radio"
                      id="6 Months"
                      value="180"
                      name="limitDays"
                      className="cursor-pointer"
                      onChange={() => {
                        setLimitDays("6 Months");
                        setLimitDaysDropdown(false);
                      }}
                      checked={limitDays === "6 Months"}
                    />
                    <label htmlFor="6 Months" className="mb-0 cursor-pointer">
                      6 Months
                    </label>
                  </div>
                  <div
                    className={`flex items-center gap-[8px] px-[10px] self-stretch`}
                  >
                    <input
                      type="radio"
                      id="12 Months"
                      value="360"
                      name="limitDays"
                      className="cursor-pointer"
                      onChange={() => {
                        setLimitDays("12 Months");
                        setLimitDaysDropdown(false);
                      }}
                      checked={limitDays === "12 Months"}
                    />
                    <label htmlFor="12 Months" className="mb-0 cursor-pointer">
                      12 Months
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <input
          type="range"
          min={0}
          max={balance}
          value={balanceLimit}
          onChange={(e) => setBalanceLimit(parseInt(e.target.value))}
          className={`w-[70%] mx-auto cursor-pointer ${
            limitDaysDropdown ? "-mt-[8rem]" : "mt-[2.35rem]"
          }`}
          style={{
            accentColor: "#797CFE",
          }}
        />

        <div className="flex justify-end mt-4">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setLimitSettingsDropdown(!limitSettingsDropdown);
            }}
            className="limit__button rounded-[50px] py-[8px] px-[16px] flex items-center justify-center gap-[10px] cursor-pointer font-[Lato] text-[18px] leading-normal font-medium"
          >
            Limit Setting {downPointer}
          </button>
        </div>
        {limitSettingsDropdown && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="flex justify-end mt-2"
          >
            <div className="flex flex-col bg-white p-[16px] rounded-[10px]">
              <div
                className={`flex items-center gap-[8px] px-[10px] self-stretch ${
                  selectedCheckbox === "system" ? "selected-checkbox" : ""
                }`}
              >
                <input
                  type="radio"
                  value="system"
                  id="system"
                  name="limit"
                  className="cursor-pointer"
                  onChange={() => {
                    handleCheckboxChange("system");
                    setHeading("Automatically Changed Accuracy");
                  }}
                  checked={selectedCheckbox === "system"}
                />
                <label htmlFor="system" className="mb-0 cursor-pointer">
                  Let the System Decide
                </label>
              </div>
              <div
                className={`flex items-center gap-[8px] px-[10px] self-stretch ${
                  selectedCheckbox === "region" ? "selected-checkbox" : ""
                }`}
              >
                <input
                  type="radio"
                  value="region"
                  id="region"
                  name="limit"
                  className="cursor-pointer"
                  onChange={() => {
                    handleCheckboxChange("region");
                    setHeading("Change limit for Region");
                  }}
                  checked={selectedCheckbox === "region"}
                />
                <label htmlFor="region" className="mb-0 cursor-pointer">
                  Assign by yourself for the Region
                </label>
              </div>
              <div
                className={`flex items-center gap-[8px] px-[10px] self-stretch ${
                  selectedCheckbox === "language" ? "selected-checkbox" : ""
                }`}
              >
                <input
                  type="radio"
                  id="language"
                  value="language"
                  name="limit"
                  className="cursor-pointer"
                  onChange={() => {
                    handleCheckboxChange("language");
                    setHeading("Change Accuracy of Specific Languages");
                  }}
                  checked={selectedCheckbox === "language"}
                />
                <label htmlFor="language" className="mb-0 cursor-pointer">
                  Assign for each Language in Specific Region
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      <span className="flex justify-center mt-4 font-[Inter] text-[16px] leading-normal font-normal">
        {heading}
      </span>
      <div className="overflow-y-scroll h-[70vh] my-4 w-[90%]">
        {selectedCountries.map((countryId) => (
          <div className="mt-2">
            <div className="relative top-[15px] left-[15px] px-[8px] py-[4px] inline-flex justify-center items-center bg-[#C1C1FF] rounded-[15px] border-[1px] border-solid border-[#8989FF]">
              {countries[countryId]}
            </div>
            <div className="flex justify-between items-center p-4 bg-[#E8E8FD] rounded-[25px]">
              <div className="flex flex-col justify-center items-center">
                {selectedCheckbox === "region" ? (
                  <div className="flex gap-[5px] items-center rounded-[63px] bg-[#fff] px-[10px] py-[3px] font-[Lato] text-[30px] font-medium leading-normal">
                    $
                    <input
                      type="text"
                      value={countryLimits[countryId] || ""}
                      onChange={(e) =>
                        updateCountryLimit(countryId, e.target.value)
                      }
                      className="flex-1 max-w-[80px] rounded-[63px] "
                    />
                  </div>
                ) : (
                  <h3 className="font-[Lato] text-[36px] font-medium leading-normal">
                    ${parseInt(balanceLimit / selectedCountries.length)}
                  </h3>
                )}

                <p className="font-[Lato] text-[18px] leading-normal font-medium text-[#747474]">
                  Limit
                </p>
              </div>
              <div className="w-[1px] h-[92px] bg-[#8989FF] mx-2 shrink-0"></div>
              <div
                className="flex justify-start items-start
              gap-[9.445px] overflow-x-scroll ml-2"
              >
                {countriesWithLanguages[countryId]?.languages &&
                  countriesWithLanguages[countryId]?.languages.map(
                    (language) => (
                      <div
                        key={language.id}
                        className="flex flex-col p-2 gap-[10px] rounded-[15px] border-[1px] border-solid border-[#8989FF] items-center"
                      >
                        <div className="text-[#747474] font-[Lato] text-[18px] font-semibold leading-[16px] flex flex-row">
                          {language.languageText}
                        </div>
                        <div className="font-[Lato] text-[18px] font-semibold leading-[16px]">
                          {selectedCheckbox === "language" ? (
                            <input
                              type="text"
                              value={
                                languageLimits[countryId]?.[language.id] || ""
                              }
                              onChange={(e) =>
                                handleLanguageLimitChange(
                                  countryId,
                                  language.id,
                                  e.target.value
                                )
                              }
                              className="max-w-[40px] rounded-[5px] px-1 text-center"
                              // Add other attributes and styles as needed
                            />
                          ) : (
                            `${parseInt(
                              balanceLimit /
                                selectedCountries.length /
                                (countriesWithLanguages[countryId]?.languages
                                  .length || 1)
                            )}`
                          )}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SetLimit;
