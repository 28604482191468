import React, { Component } from "react";
// import ReactHtmlParser from "react-html-parser";

class DetailPopUp2 extends Component {
  render() {
    console.log("detail blog data", this.props.blogDetail.title);
    const markup = { __html: `${this.props.blogDetail.blogDetails}` };

    return (
      <div
        className="modal fade bd-example-modal-lg"
        id="modelPopUp1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <p className="byName">By: Harshit Saini</p>
                  <p className="DateBlog">Date: 21/07/2021</p>
                </div>
                <div className="col-md-8">
                  <img
                    alt="Blog-img"
                    src={this?.props?.blogDetail?.blogDetailImage}
                  />
                </div>
                <h1>{this.props.blogDetail.title}</h1>
                {/* {ReactHtmlParser(this.props.blogDetail.blogDetails)} */}
                <div dangerouslySetInnerHTML={markup}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailPopUp2;
