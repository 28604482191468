import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyAbvjChC7Vwc1NHxJhluqYeZ3Md6e7coTA",
  authDomain: "sso-signup-f85d5.firebaseapp.com",
  projectId: "sso-signup-f85d5",
  storageBucket: "sso-signup-f85d5.appspot.com",
  messagingSenderId: "96514389986",
  appId: "1:96514389986:web:cc0fb8783cc6d0605f2bbb",
  measurementId: "G-BQGGGFF7FK",
};
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const googleprovider = new firebase.auth.GoogleAuthProvider();
const facebookprovider = new firebase.auth.FacebookAuthProvider();
const microsoftprovider = new firebase.auth.OAuthProvider("microsoft.com");
export { auth, googleprovider, facebookprovider, microsoftprovider };
