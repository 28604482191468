const generateJobPostings = (jobPostings) => {
    const structuredDataArray = jobPostings.map((job) => (
      {
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        "title": job.Role,
        "description": job.descp,
        "identifier": {
          "@type": "PropertyValue",
          "name": "Glocalise",
          "value": "jobs" 
        },
        "hiringOrganization" : {
          "@type": "Organization",
          "name": "Glocalise",
          "sameAs": `https://plea-sure.notion.site/Job-Board-85511c94c4d842568731c65e5b1b5e6a?pvs=4`,
          "logo": "https://www.glocali.se/favicon.ico.png"
        },
        "employmentType": job.type,
        "datePosted": job.posted,
        "validThrough": job.expDate+"T00:00",
        "jobLocation": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "",
            "addressLocality": job.location,
            "addressRegion": "",
            "postalCode": "",
            "addressCountry": "IN"
          }
        },
        "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "USD",
          "value": {
            "@type": "QuantitativeValue",
            "minValue": job.Salary, // Note: This should be populated with the appropriate value from your data source
            "maxValue": job.maxSalary,
            "unitText": "MONTH"
          }
        },
        "responsibilities": job.responsibilities,
        "skills": job.skills,
        "qualifications": job.qualifications,
        "educationRequirements": job.educationRequirements,
        "experienceRequirements": job.experienceRequirements
      }
    ));
    return structuredDataArray;
};

export  {generateJobPostings};