import React, { useState } from "react";
import LowerDiv from "./LowerDivV2";
import UpperDiv from "./UpperDiv";
import "./test.css";

function Content(props) {
  const [zone, setZone] = useState("");
  const [region, setRegion] = useState("");
  const [child2, setChild2] = React.useState(
    "100000,200000,300000,400000,500000,600000"
  );

  return (
    <div
      className="Content scrollArea"
      style={{
        display: "flex",
        flex: 2,
        flexDirection: "column",
        justifyContent: "center",
        // border: "3px solid blue",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 10,
          width: "97%",
          borderRadius: "8px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span style={{ fontSize: "16px", fontWeight: "600" }}>Region</span>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <UpperDiv
            dummyData={props.dummyData}
            setDummyData={props.setDummyData}
            zoneData={zone}
            regionData={region}
            child2data={child2}
            child2controller={setChild2}
            zoneController={setZone}
            regionController={setRegion}
          />
        </div>

        <div style={{ flex: 1, display: "flex" }}>
          <LowerDiv
            dummyData={props.dummyData}
            setDummyData={props.setDummyData}
            zoneData={zone}
            regionData={region}
            child2data={child2}
            child2controller={setChild2}
            zoneController={setZone}
            regionController={setRegion}
            selectedCountry={props.selectedCountry}
            setSelectedCountry={props.setSelectedCountry}
          />
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
}

export default Content;
