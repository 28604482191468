import React, { useMemo, useState } from "react";
import Drop from "./Drop";
import SearchBar from "../pages/components/SearchBar";
import { useSelector } from "react-redux";
import {
  getSelectedCountries,
  getSelectedCountriesFromSaved,
} from "../redux/dataSlices";

function Dns({ automatically, bolleandata, wantPast = false }) {
  const selectedCountries = useSelector(getSelectedCountries);
  const selectedCountriesSaved = useSelector(getSelectedCountriesFromSaved);
  const countriesToShow = useMemo(() => {
    if (!wantPast) return selectedCountries;
    return selectedCountriesSaved;
  }, [wantPast, selectedCountriesSaved, selectedCountries]);
  const searchValue = useSelector((state) => state.data.inputText);
  // React.useEffect(() => {
  // 	selectedCountry.split(",").map((country, key) => {
  // 		if (selectedCountry.length >= 1) {
  // 			if (key !== 0) {
  // 				setDummyData((prev) => prev + "," + country);
  // 			}
  // 		}
  // 		return null;
  // 	});
  // }, [selectedCountry]);

  const [filterr, setFilterr] = useState(false);
  const filterOpen = () => {
    setFilterr(!filterr);
  };

  return (
    <div style={{ width: "100%" }}>
      <h4 style={{ textAlign: "center", paddingBottom: "10px" }}>DNS </h4>
      <div
        style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}
      >
        <SearchBar />
        <div
          className="d-flex flex-row filter justify-content-between point"
          onClick={filterOpen}
          style={{ height: "45px" }}
        >
          <div className="filter-div">Filter</div>
          <i className="bi bi-funnel"></i>
        </div>
      </div>
      {/* INSIDE FILTER  */}
      <span
        className="filter-drop"
        style={{
          display: filterr ? "flex" : "none",
          border: "2px solid red",
          marginTop: "0px",
          position: "absolute",
          right: "65px",
        }}
      >
        <div>Sort By</div>
        <div className="filter-inside">
          <button className="filter-btn">Premium</button>
          <button className="filter-btn">Prefix</button>
          <button className="filter-btn">Suffix</button>
        </div>
        <div className="filter-inside">
          <button className="filter-btn">Root Domain</button>

          <button className="filter-btn">Active</button>
        </div>
        <div className="filter-inside">
          <button className="filter-btn">Not Active</button>
          <button className="filter-btn">Blocked </button>
        </div>
        <div className="filter-inside2">
          <div>Clear</div>
          <div className="apply">Apply</div>
        </div>
      </span>
      {/* INSIDE FILTER ENDS  */}
      <div
        className="mainDropDiv"
        style={{
          display: "flex",
          flexDirection: "row",
          // border: "1px solid black",
          // maxWidth: "100px",
          margin: "10px",
          fontWeight: "500",
        }}
      >
        <div style={{ flex: 1 }}>
          <input type="checkbox" />
        </div>
        <div style={{ flex: 2 }}>URL</div>
        <div style={{ flex: 2 }}>
          <div className="">Status</div>
        </div>
        <div style={{ flex: 1 }}>Country</div>
        <div style={{ flex: 2 }}></div>
      </div>
      <hr
        style={{
          display: "none",
          height: "2px",
          backgroundColor: "#ededed",
        }}
      />
      <div
        className="mainDropDiv"
        style={{
          display: "flex",
          flexDirection: "column",
          // margin: "1px",
          padding: "10px",
          borderBottom: "1px solid #ededed	",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // border: "1px solid red",
            // maxHeight: "10px",
          }}
        >
          <div style={{ flex: 1 }}>
            <input type="checkbox" />
          </div>
          <div style={{ flex: 2 }}>{searchValue}</div>
          <div style={{ flex: 2 }}>
            <button className="butn active-btn">Active </button>
          </div>
          <div style={{ flex: 2 }}>Global</div>
          <div style={{ flex: 1 }}>
            {/* {view ? (
							<i
								class="bi bi-chevron-up point"
								onClick={() => setView((prev) => !prev)}
							></i>
						) : (
							<i
								class="bi bi-chevron-down point"
								onClick={() => setView((prev) => !prev)}
							></i>
						)} */}
          </div>
        </div>
        {/* {view ? (
					<div
						style={{
							borderRadius: "10px",
							boxShadow: "0px 0px 1px rgb(180, 180, 180)",
							backgroundColor: "#F2F2F2",
							margin: "20px 20px 20px 10px",
							padding: "20px",
						}}
					>
						<h5>DNS records for ccTLDs</h5>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div
								className="DnsOptions"
								style={{
									display: "flex",
									border: "1px solid rgba(0, 0, 0, 0.2)",
									borderRadius: "8px",
								}}
								onChange={(prop) => {}}
							>
								<input
									name={"HelloWorld" + keyz}
									value={1}
									type="radio"
									hidden
									defaultChecked={!automatically}
									id={"btnradio1" + keyz}
								/>
								<label className="dnsLabel" htmlFor={"btnradio1" + keyz}>
									{suffix}
								</label>
								<label
									style={{
										borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
										borderRight: "1px solid rgba(0, 0, 0, 0.1) ",
									}}
									className="dnsLabel"
									htmlFor={"btnradio2" + keyz}
								>
									{prefix}
								</label>
								<input
									name={"HelloWorld" + keyz}
									value={3}
									type="radio"
									id={"btnradio3" + keyz}
									hidden
								/>
								<label className="dnsLabel" htmlFor={"btnradio3" + keyz}>
									{premium}
								</label>
							</div>
						</div>
						{domainType !== 2 ? (
							<DomainElement inputText={inputText} domainType={domainType} />
						) : (
							<></>
						)}

						<button onClick={() => {}} className="SaveButton">
							Save
						</button>
					</div>
				) : (
					<></>
				)} */}
      </div>

      {countriesToShow.map((id, key5) => (
        <Drop
          wantPast={wantPast}
          key={id}
          keyz={key5}
          alreadyConfigured={selectedCountriesSaved.includes(id) && !wantPast}
          countryId={id}
          automatically={automatically}
          bolleandata={bolleandata}
        />
      ))}
    </div>
  );
}

export default Dns;
