import SearchBar from "./SearchBar";
import WhiteLoad from "./WhiteLoad";
import PopOuts from "./popouts/PopOuts";
import React, { useMemo, useRef } from "react";
import "./test.css";
import PartB from "./PartB";
import sortIfSelected from "../../utils/sort";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedCountries,
  setCountriesForLanguageSelection,
  setSelectedCountries,
} from "../../redux/dataSlices";
import zones from "../../data/zones";
import countries, { individualSection } from "../../data/countries";
import { useEffect } from "react";
// import { masterData } from "./TestingData";
function LowerDiv(props) {
  const selectedCountries = useSelector(getSelectedCountries);
  const indSelectedCountries = useMemo(
    () =>
      selectedCountries.map((e) => {
        return e.split("-").at(-1);
      }),
    [selectedCountries]
  );
  const selectedCountriesContainer = useRef(null);
  const [continentsSelected, indexesThatAreSelectedinContinents] = useMemo(
    () => sortIfSelected(individualSection["continents"], indSelectedCountries),
    [indSelectedCountries]
  );
  const countrySearch = useSelector((state) => state.data.countrySearch);
  const hotspotZones = useSelector((state) => state.data.hotspotZones);
  const hotspotRegions = useSelector((state) => state.data.hotspotRegions);
  const dispatch = useDispatch();
  const btnStyle = {
    flex: 1,
    fontWeight: "550",
    backgroundColor: "white",
    fontSize: "14px",
    border: "none",
    borderRadius: "8px",
    marginTop: "5px",
    padding: "15px 15px 15px 10px",
    color: "#979B99",
    outlineWidth: "1px",
    outlineStyle: "solid",
    lineHeight: "1px",
    maxHeight: "32px",
    minWidth: "60px",
    overflow: "hidden",
    marginRight: "12px",
    textAlign: "center",
  };
  useEffect(() => {
    if (selectedCountriesContainer.current) {
      selectedCountriesContainer.current.scrollTop =
        selectedCountriesContainer.current.scrollHeight;
    }
  }, [selectedCountries]);
  return (
    <div
      style={{
        // border: "2px solid black",
        width: "100%",
        display: "flex",
        margin: "2%",
        // maxHeight: "30%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <span
          id="theTester"
          style={{
            flex: 1,
            fontSize: "16px",
            fontWeight: "600",
            width: "100%",
          }}
        >
          Selected Countries:
        </span>
        <span
          style={{
            flex: 2,
            marginLeft: "3%",
            fontSize: "16px",
            fontWeight: "600",
            width: "100%",
          }}
        >
          Individual Section
        </span>
      </div>

      <div
        style={{
          flex: 10,
          display: "flex",
          // maxHeight: "40%",
          flexDirection: "row",
        }}
      >
        {/* 

				
					FIRST PART
				
				
				*/}

        <div
          style={{
            flex: 1,
            display: "flex",
            resize: "none",
            overflow: "hidden",
            // border: "3px solid red",
          }}
        >
          <div
            style={{
              flex: 1,
              margin: "3%",
              borderRadius: "8px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              // border: "2px solid blue",
              // maxHeight: "40%",
              height: "500px",
              boxShadow: "0px 0px 5px rgb(180, 180, 180)",
            }}
          >
            <SearchBar />

            <div
              style={{
                flex: 1,
                margin: "10px",
                fontSize: "14px",
                maxHeight: "20px",
                color: "#CCCCCC",
              }}
            >
              {hotspotZones
                .map((data, key) => {
                  return zones[data];
                })
                .join(", ")}
              {hotspotZones.length >= 1 ? "Countries" : ""}
            </div>
            {
              //Show the PopOuts when :
              //when region data is NOT empty
              //or
              //when props.selectedCountryArray is NOT empty
            }
            {hotspotRegions.length !== 0 || selectedCountries.length !== 0 ? (
              <div
                ref={selectedCountriesContainer}
                className="scrollArea"
                style={{
                  direction: "rtl",
                  padding: "0px 20px 20px 20px",
                  marginTop: "20px",
                  alignContent: "center",
                  overflowY: "auto",
                }}
              >
                <p
                  className="d-inline-block shadow-sm p-2 mb-3"
                  style={{ borderRadius: "14px", fontWeight: "bold" }}
                >
                  Number of Countries&nbsp; | {selectedCountries.length}
                </p>
                <div
                  style={{
                    direction: "ltr",
                    maxHeight: "400px",
                    width: "100px",
                  }}
                >
                  {selectedCountries.map((data) => {
                    return <PopOuts key={data} id={data} />;
                  })}
                </div>
              </div>
            ) : (
              <WhiteLoad />
            )}
            <img className="block " src="/images2/scrollEnd.png" alt="" />
          </div>
        </div>

        {/* 

				
					SECOND PART
				
				
						*/}
        <div style={{ flex: 2, display: "flex" }}>
          <div
            className="scrollArea"
            style={{
              flex: 1,
              margin: "2%",
              borderRadius: "8px",
              padding: "10px",
              display: "flex",
              width: "100px",
              flexDirection: "column",
              boxShadow: "0px 0px 5px rgb(180, 180, 180)",
              maxHeight: "500px",
              overflowY: "auto",
              alignItems: "center",
            }}
          >
            <SearchBar />
            <div
              style={{
                fontSize: "11px",
                marginTop: "10px",
                color: "#CCCCCC",
                marginBottom: "10px",
              }}
            >
              HOTSPOT Countries
            </div>
            <div
              id="zone"
              className="scrollArea"
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                // flexWrap: "wrap",
                marginTop: "-1vh",
                overflowX: "auto",
                padding: "2px",
              }}
            >
              {continentsSelected.map((data, key) => {
                const selected = key < indexesThatAreSelectedinContinents;
                const show =
                  countrySearch === ""
                    ? true
                    : countries[data]
                        .toLowerCase()
                        .includes(countrySearch.toLowerCase());
                if (show)
                  return (
                    <span
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        overflowWrap: "wrap",
                      }}
                      key={key}
                    >
                      <button
                        style={{
                          ...btnStyle,
                          backgroundColor: selected ? "#D7F3ED" : "white",
                          outline: selected ? "none" : "1px solid #979B99",
                        }}
                        id={"scrollButton" + key}
                        onClick={(e) => {
                          dispatch(setSelectedCountries(data));
                          dispatch(setCountriesForLanguageSelection());
                        }}
                      >
                        {countries[data]}
                      </button>
                    </span>
                  );
                else return <></>;
              })}
            </div>
            <PartB indSelectedCountries={indSelectedCountries} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LowerDiv;
