import React, { useRef } from "react";
import { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import "./checkbox.css";
// type AnyType = any;
const Checkboxy = () => {
  let [displayValue, setIsDisplay] = useState("none");
  let selectedCheckboxes1 = [];
  const [countries, setCountries] = useState([
    { id: "1", label: "English" },
    { id: "2", label: "Hindi" },
    { id: "3", label: "Spanish" },
    { id: "4", label: "French" },
    { id: "5", label: "Filipino" },
    { id: "6", label: "Arabic" },
    { id: "7", label: "Indonesian" },
    { id: "8", label: "Tamil" },
  ]);

  const [search, setSearch] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(["Harry Kane"]);

  useEffect(() => {
    const result = countries.filter((country) =>
      country.label.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredCountries(result);
  }, [search, countries]);

  const handleCheckboxChange = (event) => {
    const checkboxLabel = event.target.name;
    const isChecked = event.target.checked;

    if (isChecked) {
      if (selectedCheckboxes && selectedCheckboxes.length < 8) {
        setSelectedCheckboxes((prevSelectedCheckboxes) => [
          ...prevSelectedCheckboxes,
          checkboxLabel,
        ]);
      } else {
        event.preventDefault();
        alert(`You can only select up to 8 checkboxes.`);
      }
    } else {
      setSelectedCheckboxes((prevSelectedCheckboxes) =>
        prevSelectedCheckboxes.filter((label) => label !== checkboxLabel)
      );
    }
  };

  const handleSave = () => {
    localStorage.setItem(
      "selectedCheckboxes",
      JSON.stringify(selectedCheckboxes)
    );
    setIsDisplay("none");
  };
  const handleCancel = () => {
    setIsDisplay("none");
  };
  const handleOpen = () => {
    setIsDisplay("block");
  };
  useEffect(() => {
    setSelectedCheckboxes(
      JSON.parse(localStorage.getItem("selectedCheckboxes")) || ["harry kane"]
    );
  }, []);

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const handleSort = () => {
    let _langItems = [...selectedCheckboxes];
    console.log(_langItems);

    const draggedItemContent = _langItems.splice(dragItem.current, 1)[0];

    _langItems.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    localStorage.setItem("selectedCheckboxes", JSON.stringify(_langItems));
    setSelectedCheckboxes(
      JSON.parse(localStorage.getItem("selectedCheckboxes"))
    );
  };
  // selectedCheckboxes1 = JSON.parse(localStorage.getItem("selectedCheckboxes"));

  return (
    <>
      <div className="container justify d-flex">
        <p className="devpara">
          You can switch language in chat whenever you need
        </p>
        <input
          className="btn btn-outline-dark langbtn"
          type="submit"
          value="Add Language"
          onClick={handleOpen}
        />
      </div>

      <div
        className="container bottom_lang"
        style={{ backgroundColor: "white" }}
      >
        <div
          className="checky"
          style={{
            margin: 0,
            padding: 0,
            display: displayValue,
            marginBottom: "1rem",
          }}
        >
          <div
            className="cont"
            style={{
              width: "100%",
              margin: "0",
              padding: "0",
              display: displayValue,
              zindex: "100",
              paddingLeft: "1rem",
            }}
          >
            <div
              className="cont1 d-flex"
              style={{ justifyContent: "space-between" }}
            >
              <h2 className="addlanguages">Add Languages</h2>
              <input
                type="text"
                placeholder="Search Languages"
                style={{
                  borderBottom: "3px solid blue",
                  fontSize: "1.1rem",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div
              className="form"
              style={{
                width: "100%",
                overflowY: "scroll",
                height: "18rem",
                borderBottom: "1px solid grey",
                margin: "0",
                padding: "0",
              }}
            >
              <FormGroup>
                {filteredCountries.map((info) => (
                  <FormControlLabel
                    key={info.id}
                    control={
                      <Checkbox
                        checked={selectedCheckboxes.includes(info.label)}
                        onChange={handleCheckboxChange}
                        name={info.label}
                      />
                    }
                    label={info.label}
                    style={{
                      marginBottom: "0%",
                      marginLeft: "1%",
                      background: "white",
                    }}
                  />
                ))}
              </FormGroup>
            </div>

            <div className="container devbuttons d-flex margin_top">
              {/* <input className="btn btn-outline-dark resetmsgbtn " type="submit" value="Cancel" onClick={handleCancel} /> */}
              <input
                className="btn btn-dark msgbtn"
                type="submit"
                value="Save"
                onClick={handleSave}
              />
            </div>
          </div>
        </div>

        {selectedCheckboxes.map((data, index) => (
          <>
            <div
              className="container justify d-flex mt-3  "
              key={index}
              style={{ cursor: "move" }}
              draggable
              onDragStart={(e) => (dragItem.current = index)}
              onDragEnter={(e) => (dragOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}
            >
              <div
                className="devpara1"
                style={{ fontSize: "1rem", marginLeft: "1rem" }}
              >
                {index + 1}) {data}
              </div>
              <MoreVertSharpIcon
                style={{
                  color: "grey",
                  fontSize: "1.5rem",
                  marginRight: "2.5rem",
                }}
                className="MoreVertSharpIcon"
              />
            </div>
            {index === 0 && (
              <p style={{ color: "rgb(108,110,143)" }} className="ml-3">
                This is the Primary Language
              </p>
            )}
            <hr
              style={{
                background: "none",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            />
          </>
        ))}
      </div>
    </>
  );
};

export default Checkboxy;
