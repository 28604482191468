import React, { useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
import { Link } from "react-router-dom";
import TranslateIcon from "@mui/icons-material/Translate";
function Header({ onToggleNavbar }) {
  const [arrow, setArrow] = useState(false);
  const [toglehover, setToglehover] = useState({
    Home: false,
    ChatBot: false,
    "Analytics & Statistics": false,
    "Language & Region": false,
    "Data Management": false,
    Settings: false,
    Logout: false,
  });
  const { pathname } = useLocation();
  console.log(pathname);
  const { enqueueSnackbar } = useSnackbar();
  const ssodata = JSON.parse(localStorage.getItem("datasso"));
  const navigate = useNavigate();
  const toggle = () => {
    setArrow(!arrow);
    if (onToggleNavbar) {
      onToggleNavbar();
    }
  };

  // const greyedout = {
  //   backgroundColor: "#d9d9d9",
  // };
  const logout = () => {
    try {
      // window.location.replace("http://localhost:3000/signup/?logout=true");
      localStorage.clear();
      enqueueSnackbar("successfully logout", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const navigating = () => {
    navigate("/");
  };
  const handleMouseEnter = (itemName) => {
    setToglehover((to) => ({ ...to, [itemName]: true }));
  };
  const handleMouseLeave = (itemName) => {
    setToglehover((to) => ({ ...to, [itemName]: false }));
  };

  return (
    <nav className={arrow ? "close " : "open "}>
      <div className="top-div">
        <span className="brand-icon">
          <NavLink className="logoText" to="/">
            <div className="d-flex">
              <img
                style={{ width: "28.241px", height: "27.655px" }}
                classsName=" img-fluid"
                src="./header_images/logo.svg"
                alt="logo.png"
                onClick={navigating}
              />
              <div>Glocali.se</div>
            </div>
          </NavLink>
        </span>
      </div>
      <div className="arrow-menu" onClick={toggle}>
        {arrow ? (
          <img
            src="./header_images/chevron-right.svg"
            alt=""
            style={{ cursor: "pointer" }}
          />
        ) : (
          <img
            src="./header_images/chevron-left.svg"
            alt=""
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      <div className="NavLink-section">
        <NavLink
          to="/home"
          className="navlink"
          onClick={() => {
            enqueueSnackbar("Still Under Development", {
              variant: "default",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              TransitionComponent: Zoom,
            });
          }}
          onMouseEnter={() => handleMouseEnter("Home")}
          onMouseLeave={() => handleMouseLeave("Home")}
        >
          <div className="d-flex">
            <img
              className="img-fluid"
              style={{ margin: "0px 16px", width: "24px", height: "24px" }}
              src={
                toglehover.Home
                  ? "./header_images/home-color.svg"
                  : "./header_images/home.svg"
              }
              alt=""
            />
            <h6>Home</h6>
          </div>
        </NavLink>
        <NavLink
          to="/chatbot"
          className="navlink"
          onMouseEnter={() => handleMouseEnter("ChatBot")}
          onMouseLeave={() => handleMouseLeave("ChatBot")}
        >
          <div className="d-flex">
            <img
              className="img-fluid"
              style={{ margin: "0px 16px", width: "24px", height: "24px" }}
              src={
                toglehover.ChatBot || pathname === "/chatbot"
                  ? "./header_images/message-color.png"
                  : "./header_images/message.png"
              }
            />
            <h6>ChatBot</h6>
          </div>
        </NavLink>

        <NavLink
          to="/timespent"
          className="navlink"
          onClick={() => {
            enqueueSnackbar("Still Under Development", {
              variant: "default",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              TransitionComponent: Zoom,
            });
          }}
          onMouseEnter={() => handleMouseEnter("Analytics & Statistics")}
          onMouseLeave={() => handleMouseLeave("Analytics & Statistics")}
        >
          <div className="d-flex">
            <img
              style={{ margin: "0px 16px", width: "24px", height: "24px" }}
              src={
                toglehover["Analytics & Statistics"]
                  ? "./header_images/pie-chart-color.svg"
                  : "./header_images/pie-chart.svg"
              }
              className="img-fluid"
              alt=""
            />
            <h6>Analytics & Statistics</h6>
          </div>
        </NavLink>
        <NavLink
          to="/datamanage"
          className="navlink"
          onMouseEnter={() => handleMouseEnter("Data management")}
          onMouseLeave={() => handleMouseLeave("Data management")}
        >
          <div className="d-flex">
            <img
              style={{ margin: "0px 16px", width: "24px", height: "24px" }}
              src={
                toglehover["Data management"] || pathname === "/datamanage"
                  ? "./header_images/command-color.svg"
                  : "./header_images/command.svg"
              }
              className="img-fluid "
              alt=""
            />
            <h6>Data Mangement</h6>
          </div>
        </NavLink>

        <NavLink
          to="/Language-&-Region"
          className="navlink"
          onMouseEnter={() => handleMouseEnter("Language & Region")}
          onMouseLeave={() => handleMouseLeave("Language & Region")}
        >
          <div className="d-flex">
            <img
              className="img-fluid"
              style={{ margin: "0px 16px", width: "24px", height: "24px" }}
              src={
                toglehover["Language & Region"] ||
                pathname === "/Language-&-Region"
                  ? "./header_images/languagecircle-color.svg"
                  : "./header_images/languagecircle.svg"
              }
              alt=""
            />
            <h6>Language & Region</h6>
          </div>
        </NavLink>

        <NavLink
          to="/settings"
          className="navlink"
          onMouseEnter={() => handleMouseEnter("Settings")}
          onMouseLeave={() => handleMouseLeave("Settings")}
        >
          <div className="d-flex">
            <img
              className="img-fluid"
              style={{ margin: "0px 16px", width: "24px", height: "24px" }}
              src={
                toglehover.Settings
                  ? "./header_images/settings-color.svg"
                  : "./header_images/settings.svg"
              }
            />
            <h6>Settings</h6>
          </div>
        </NavLink>
        <NavLink
          to="#"
          className="navlink"
          onClick={logout}
          onMouseEnter={() => handleMouseEnter("Logout")}
          onMouseLeave={() => handleMouseLeave("Logout")}
        >
          <div className="d-flex">
            <img
              className="img-fluid"
              style={{ margin: "0px 16px", width: "24px", height: "24px" }}
              src={
                toglehover.Logout
                  ? "./header_images/logout color.png"
                  : "./header_images/logout.png"
              }
            />
            <h6>Logout</h6>
          </div>
        </NavLink>
      </div>
    </nav>
  );
}

export default Header;
