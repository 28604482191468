import React, { useState, useRef, useMemo, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Dns from "../components/Dns";
import help from "../components/helping/help.png";
import Helping from "../components/helping/Helping";
// import Header from "../components/Header/Header";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
import "./checkbox/checkbox.css";
import App from "./App";
import {
  addRegions,
  pushToKv,
  pushToKv2,
  registerHostname,
} from "../redux/apiCalls";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import AccuracyPage from "./language/AccuracyPage";
import "./hero.css";
import Checkboxy from "./checkbox/Checkboxy";
import DynamicProfilePhoto from "./DynamicProfilePhoto";
import { useDispatch, useSelector } from "react-redux";
import {
  resetInputText,
  setCountriesForLanguageSelection,
  setInputText,
  setRefreshDoamin,
  setSearchValue2,
  setTopLevelHostNameId,
} from "../redux/dataSlices";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tab";
import Sidebar from "../components/sidebar/Sidebar";
import { Button } from "../ui/button";
import { CheckCNAMEAddedOrNot } from "../components/Drop";
import Makepayment from "./Makepayment";

function Hero({ balance, handleBalanceUpdate }) {
  const datat = localStorage.getItem("datasso");
  const parsedData = JSON.parse(datat);
  // console.log(parsedData.name);
  const user = useSelector((state) => state.user.currentUser);
  const [textToCopy, setTextToCopy] = useState("www");
  const [isCopied, setIsCopied] = useState(false);
  const selectedCountriesData = useSelector((state) => state.data.countries);
  const selectedRegionsData = useSelector((state) => ({
    hotspotZones: state.data.hotspotZones,
    hotspotRegions: state.data.hotspotRegions,
    hotspotSubRegions: state.data.hotspotSubRegions,
    selectedCountries: state.data.selectedCountries,
  }));
  const defaultPrefix = "www.";
  const handleCopyClick = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  const dispatch = useDispatch();
  const searchValue2 = useSelector((state) => state.data.setSearchValue2);
  const [isCopied1, setIsCopied1] = useState(false);
  const [textToCopy1, setTextToCopy1] = useState("saas.glocalise.site");
  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    setToggledomainbutton(true);
    // Check if the inputValue starts with the defaultPrefix
    if (inputValue.startsWith("www.") && inputValue !== "www.") {
      dispatch(setSearchValue2(inputValue));
    } else if (inputValue === "www.") {
      dispatch(setSearchValue2(""));
    } else {
      dispatch(setSearchValue2("www." + inputValue));
    }
  };

  const handleCopyClick1 = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopy1)
      .then(() => {
        setIsCopied1(true);
        setTimeout(() => setIsCopied1(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  const [isCopied2, setIsCopied2] = useState(false);
  const [textToCopy2, setTextToCopy2] = useState("Martix");
  const [isCopiedCvalue, setIsCopiedCvalue] = useState(false);
  const [textToCopyCvalue, setTextToCopyCvalue] = useState(
    "saas.glocalise.site"
  );
  const handleCopyClickCvalue = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopyCvalue)
      .then(() => {
        setIsCopiedCvalue(true);
        setTimeout(() => setIsCopiedCvalue(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  const handleCopyClick2 = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopy2)
      .then(() => {
        setIsCopied2(true);
        setTimeout(() => setIsCopied2(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  const [isCopied3, setIsCopied3] = useState(false);
  const [textToCopy3, setTextToCopy3] = useState(
    "CName Value from hosting provider"
  );
  const inputText = useSelector((state) => state.data.inputText);

  const handleCopyClick3 = () => {
    enqueueSnackbar("Text copied", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
    navigator.clipboard
      .writeText(textToCopy3)
      .then(() => {
        setIsCopied3(true);
        setTimeout(() => setIsCopied3(false), 2500); // Reset the copied state after 1.5 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  const { isOpen } = useContext(AppContext);
  const divStyle = {
    marginLeft: isOpen === false ? "0%" : "0%",
    width: isOpen === false ? "100vw" : "101vw",
  };
  const divStyle1 = {
    marginLeft: isOpen === false ? "0%" : "29%",
    width: isOpen === false ? "66%" : "66%",
  };
  const divStyle2 = {
    marginLeft: isOpen === false ? "0%" : "29%",
    width: isOpen === false ? "66%" : "66%",
    position: "relative",
    top: "450px",
  };
  const divStyle3 = {
    marginLeft: isOpen === false ? "0%" : "29%",
    width: isOpen === false ? "66%" : "66%",
    position: "relative",
    top: "450px",
  };
  const divStyle4 = {
    marginLeft: isOpen === false ? "0%" : "29%",
    width: isOpen === false ? "66%" : "66%",
    position: "relative",
    top: "500px",
  };
  const divStyle5 = {
    marginLeft: isOpen === false ? "-3%" : "25%",
    width: isOpen === false ? "66%" : "66%",
  };
  const divStyle6 = {
    marginLeft: isOpen === false ? "0%" : "29%",
    width: isOpen === false ? "66%" : "66%",
    overflowY: "auto",
    padding: "10px 0px",
  };
  //   ======= useStates Used =========
  const [selectedCountry, setSelectedCountry] = useState("");
  const [dummyData, setDummyData] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState(false);
  const [visible, setVisible] = useState(false);

  const [visible3, setVisible3] = useState(false);
  const [data, setData] = useState([
    { area: "blog", pin: "www", university: "Notion", usa: "www" },
  ]);
  // const [content, setContent] = useState([]);
  const content = [];

  //   ======== toggle functions to show and hide content ========
  const toggle = () => setView(!view);
  const toggle1 = (e) => {
    if (e.target.id === "coding") {
      setVisible(true);
      setVisible3(false);
    } else if (e.target.id === "song") {
      setVisible3(true);
      setVisible(false);
    }
  };

  const [automata, setAutomata] = useState(true);
  const [checkedvalue, setCheckedvalue] = useState("coding");
  const [settingboolean, setSettingboolean] = useState(true);
  //   ========= Function to add new filed on Plus Icon ==========

  // useEffect(() => {
  //   // setDomainroutes(searchValue);
  // }, [searchValue]);
  const handleClick = () => {
    setData([
      ...data,
      { area: "blog", pin: "www", university: "Notion", usa: "www" },
    ]);
  };
  const remove = () => {
    setData(data.slice(1));

    if (data.length === 1) {
      setData([{ area: "blog", pin: "www", university: "Notion", usa: "www" }]);
    }
  };
  const firstroute = (ind, val) => {
    const d = [...data];
    d[ind].pin = val;
    setData([...d]);
  };
  const secondroute = (ind, val) => {
    const d = [...data];
    d[ind].area = val;
    setData([...d]);
  };
  const thirdroute = (ind, val) => {
    const d = [...data];
    d[ind].usa = val;
    setData([...d]);
  };
  const domainroute = (ind, val) => {
    const d = [...data];
    d[ind].university = val;
    setData([...d]);
  };
  const changed = (e) => {
    setCheckedvalue(e.target.value);
    if (e.target.value === "coding") {
      setSettingboolean(true);
    } else {
      setSettingboolean(false);
    }
  };
  // const gettingchilddata = (data) => {
  //   setChilddata(!data);
  // };
  const changesettingbollean = (data) => {
    setSettingboolean(data);
  };

  const [buttonState, setButtonState] = useState(false);
  const data1 = localStorage.getItem("datapic");
  const parsedData1 = JSON.parse(data1);
  const domainOnly = useMemo(() => inputText.slice(4), [inputText]);
  const topLevelHostNameId = useSelector(
    (state) => state.data.topLevelHostNameId
  );
  const [saving, setSaving] = useState(false);
  async function handleTopLevelDomain(val) {
    const valueTo = val ? val.slice(4) : domainOnly;
    if (valueTo.length === 0) return;
    const domain = `www.${valueTo}`;
    try {
      setSaving(true);
      const {
        data: { data: value, message, success },
      } = await registerHostname({ url: valueTo, isDomain: true });
      if (!success) {
        enqueueSnackbar(message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        return;
      } else {
        enqueueSnackbar(message, {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        dispatch(setTopLevelHostNameId(value._id));
        dispatch(setRefreshDoamin(true));
        await pushToKv2({
          key: domain,
          value: value._id,
        });
      }
    } catch (error) {
      dispatch(setSearchValue2(""));
      enqueueSnackbar("Error registering domain", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
      setConnected(false);
      console.log(error);
    } finally {
      setSaving(false);
      setToggledomainbutton((state) => !state);
    }
  }
  async function handleSaveClick() {
    setButtonState((prev) => !prev);
    const domain = `https://www.${domainOnly}`;
    try {
      await addRegions(selectedRegionsData);
      enqueueSnackbar("Regions Saved successfully", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
      const DataToPush = {};
      DataToPush.regions = {
        default: {
          domain,
          en: 1,
          default: "en",
        },
      };
      DataToPush.parent = `https://matrix.${domainOnly}`;
      Object.keys(selectedCountriesData).forEach(async (countryCode, index) => {
        if (selectedCountriesData[countryCode].status === "active") {
          const { languages, domain: settedDomain } =
            selectedCountriesData[countryCode];
          const domain = `https://${countryCode}.${domainOnly}`;
          const region = {
            domain: settedDomain ?? domain,
            langs: {},
          };
          languages?.forEach((lang) => {
            if (lang.isDefault) {
              region.langs["default"] = lang.id;
            }
            region.langs[lang.id] = lang.accuracy / 100;
          });
          DataToPush.regions[countryCode] = region;
          return null;
        }
      });
      // DataToPush.parent = "https://www." + domainOnly;
      await pushToKv({
        key: topLevelHostNameId,
        value: JSON.stringify({ data: DataToPush }),
      }).then(async (data) => {
        setButtonState((prev) => !prev);
        if (data.status === 200) {
          enqueueSnackbar("Data Saved successfully", {
            variant: "default",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            TransitionComponent: Zoom,
          });
          // dispatch(clearSelectedCountries());
        } else {
          enqueueSnackbar("Data Could not be saved.Try again later", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            TransitionComponent: Zoom,
          });
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setButtonState(false);
    }
  }

  //   =========== Function to fetch Api for the content ===========
  // const apiCall = async () => {
  //   const res = await fetch(`https://api.cloudflare.com/client/v4/zones/b13a17c7aa3f73de591352a2b5f2f5e3/custom_hostnames`,{
  //   method:"POST",
  //   // mode: "no-cors",
  //     headers:{
  //       "X-Auth-Key" : "4dbef2fc7e0e136ad6b2db46f86303c1cad4a",
  //       "X-Auth-Email" : "atiuttam.tech@gmail.com"
  //     },
  //     // body:JSON.stringify(formData)
  //   });
  //   const resData = await res.json();
  //   console.log(resData);
  //   setContent(resData.data[0]);
  // };

  // useEffect(() => {
  //   apiCall();
  // }, []);
  // console.log(content);
  const [connected, setConnected] = useState(false);
  const [helpshow, setHelpshow] = useState(false);
  const [toggleimage, setToggleimage] = useState(true);
  const [toggledomainbutton, setToggledomainbutton] = useState(false);
  // console.log(inputText);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const navigates = () => {
    navigate("/settings");
  };

  const openhelp = () => {
    setHelpshow(true);
    setToggleimage(false);
  };
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  useEffect(() => {
    handleBalanceUpdate();
  }, []);
  //  ========== Main html part =============
  return (
    <div className="hero__body">
      {/* Sidebar */}
      <div className="hero__navbar">
        {/* Old sidebar */}
        {/* <Header onToggleNavbar={toggleNavbar} /> */}
        <Sidebar onToggleNavbar={toggleNavbar} />
      </div>
      {/* Blocking popup for paypal */}
      {/* <div
        className="flex w-full h-full fixed z-[1000] justify-center items-center"
        style={{
          background: "rgba(0, 0, 0, 0.08)",
          backdropFilter: "blur(10px)",
          display: balance > 100 ? "none" : "flex",
        }}
      >
        <Makepayment />
      </div> */}
      <div
        className={`hero__home ${
          isNavbarOpen ? "hero__navbar-open" : "hero__navbar-closed"
        }`}
      >
        <section id="hero" className="d-flex flex-column align-items-center">
          {/* ========= Upper part (Searchbox and User Id) ========== */}
          <div
            className="form2 d-flex flex-row justify-content-between top"
            data-aos="fade-in"
            style={divStyle5}
          >
            <div className="form-group has-search dns-form-search2 inside-top ">
              <span
                className="fa fa-search form-control-feedback"
                id="search-icon"
              ></span>
              <input type="text" className="form-control " />
            </div>
            <div
              className="d-flex flex-row a"
              style={{ marginLeft: "-10%", cursor: "pointer" }}
              onClick={navigates}
            >
              {/* <img
							src="https://weddingkj.s3.ap-south-1.amazonaws.com/Frame+3055.png"
							alt=""
							className="user-img"
						/> */}
              {/* <div className="hr-parent" >
							<div className="hr-child">
								<h5 className="hr-child-h5" id="hr-title" >
									{parsedData ? <DynamicProfilePhoto name={parsedData.name} size={100} /> : "HR"}
								</h5>
							</div>
						</div> */}

              {parsedData1 ? (
                <img src={parsedData1.pic} alt="" className="pro-image" />
              ) : (
                <>
                  {
                    <div className="hr-parent">
                      <div className="hr-child">
                        <h5 className="hr-child-h5" id="hr-title">
                          {user ? (
                            <DynamicProfilePhoto name={user.name} size={100} />
                          ) : (
                            "HR"
                          )}
                        </h5>
                      </div>
                    </div>
                  }
                </>
              )}

              <div>
                <h4 style={{ fontSize: "1.3rem", marginLeft: "1rem" }}>
                  {parsedData.name}
                </h4>
              </div>
            </div>
          </div>
          {/* =========== University Div ============ */}
          <div className="uni" style={divStyle1}>
            Language & Region{" "}
          </div>
          {/* ================== Main Form Starts =================*/}
          <div className="form mergedForm scrollArea" style={divStyle6}>
            <App
              dummyData={dummyData}
              setDummyData={setDummyData}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          </div>
          <AccuracyPage isNavbarOpen={isNavbarOpen} />
          <div className="form" style={divStyle2}>
            <h3>Domain Settings</h3>
            <div className="flex justify-between flex-wrap w-100">
              <div className="flex flex-wrap gap-2">
                <input
                  style={{
                    padding: "20px",
                    margin: "1px",
                    outline: "none",
                    borderRadius: "12px",
                    border: "1px solid rgba(0,0,0,0.2)",
                  }}
                  type="text"
                  className="usa-div"
                  value={searchValue2}
                  onChange={handleSearchChange}
                  // onBlur={handleBlur}
                  ref={inputRef}
                  disabled={!toggledomainbutton}
                />
                <Button
                  onClick={() => {
                    if (toggledomainbutton) {
                      dispatch(setInputText(searchValue2));
                      dispatch(setCountriesForLanguageSelection());
                      handleTopLevelDomain(searchValue2);
                    } else {
                      // setDisabledomaininput(!disabledomaininput);
                      inputRef.current.focus();
                      setToggledomainbutton((state) => !state);
                    }
                  }}
                >
                  {saving ? "Saving" : toggledomainbutton ? "Save" : "Edit"}
                </Button>
                {toggledomainbutton && (
                  <Button
                    variant="outline"
                    onClick={() => {
                      dispatch(resetInputText(`www.${user.domain}`));
                      setToggledomainbutton(false);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {!toggledomainbutton && (
                  <CheckCNAMEAddedOrNot
                    showOnlyIcon={true}
                    hostName={searchValue2}
                    connected={connected}
                    setConnected={setConnected}
                  />
                )}
              </div>
              {/* toggle dropdown button */}
              <div className="dropdownbutton">
                {view ? (
                  <i
                    className="bi bi-chevron-up"
                    style={{ cursor: "pointer" }}
                    onClick={toggle}
                  ></i>
                ) : (
                  <i
                    className="bi bi-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={toggle}
                  ></i>
                )}
              </div>
              {/* toggle dropdown button ends */}
            </div>
            {/* ====== dropdown menu starts ====== */}
            <div
              style={{
                display: view ? "block" : "none",
                width: "100%",
                marginTop: "-2.5rem",
              }}
            >
              {/* first div  */}
              <p
                style={{
                  fontFamily: "Lato",
                  fontWeight: "400",
                  color: "#646866",
                  marginTop: "1rem",
                }}
              >
                This is one time step of onboarding main (parent) your domain
                name so you can set up other child domains for this parent
                domain
              </p>

              <div className="data-div">
                <div className="d-flex flex-row justify-content-between title-div">
                  <div className="bold inside">CNAME </div>
                  <div className="uni-val bold">CNAME Value</div>
                </div>
                {/* inputs tags feting data from API  */}
                <div className="input-div">
                  {/* <input type="text" value="www" style={{ cursor: "pointer" }} />
								<input type="text" value="saas.glocalise.net" style={{ cursor: "pointer" }} /> */}
                  <div
                    className="flex"
                    title={isCopied ? "Text Copied" : "Click to copy"}
                    style={{ cursor: "pointer" }}
                    onClick={handleCopyClick}
                  >
                    {textToCopy}
                    <img
                      src="../images/other/copyIcon.png"
                      alt="icon"
                      style={{
                        width: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div
                    title={isCopied1 ? "Text Copied" : "Click to copy"}
                    style={{ cursor: "pointer" }}
                    onClick={handleCopyClick1}
                    className="right-div flex flex-row-reverse"
                  >
                    {textToCopy1}
                    <img
                      src="../images/other/copyIcon.png"
                      alt="icon"
                      style={{
                        width: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
              <p
                style={{
                  fontFamily: "Lato",
                  fontWeight: "400",
                  color: "#646866",
                  marginTop: "2rem",
                }}
              >
                Check our{" "}
                <a href="" style={{ textDecoration: "none", color: "#39C6A4" }}>
                  dns documentation
                </a>{" "}
                (with article!) or contact us +1 455 212 5275 or
                support@glocali.se if you need further help.
              </p>
              <p
                style={{
                  fontFamily: "Lato",
                  fontWeight: "400",
                  color: "#646866",
                  // marginTop: "-1rem",
                }}
              >
                Once you have entered the DNS, please wait a few minutes to
                allow your changes to be taken into account.
              </p>

              {/* first div ends */}

              {/* second div starts */}

              {/* second div ends */}

              {/* third div starts  */}

              {/* third div ends  */}

              {/* fourth div starts  */}
              <div className="data-div">
                <div className="d-flex flex-row justify-content-between title-div">
                  <div className="bold inside">CName</div>
                  {/* <div className="sku-val bold">CNAME </div> */}
                  <div className="uni-val bold">CName Value</div>
                </div>
                {/* inputs tags feting data from API  */}
                <div className="input-div flex">
                  <div
                    className="flex"
                    title={isCopied2 ? "Text Copied" : "Click to copy"}
                    style={{ cursor: "pointer" }}
                    onClick={handleCopyClick2}
                  >
                    {textToCopy2}
                    <img
                      src="../images/other/copyIcon.png"
                      alt="icon"
                      style={{
                        width: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCopyClick2();
                      }}
                    />
                  </div>
                  <div
                    className="flex flex-row-reverse"
                    title={isCopied3 ? "Text Copied" : "Click to copy"}
                    style={{ marginRight: "0rem", cursor: "pointer" }}
                    onClick={handleCopyClick3}
                  >
                    {textToCopy3}
                    <img
                      src="../images/other/copyIcon.png"
                      alt="icon"
                      style={{
                        width: "20px",
                        marginRight: "5px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCopyClick3();
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* fourth div ends  */}

              {/* BLOCK REGIONS STARTS */}

              <div className="frame-3409-Fg7" id="1:13">
                <p
                  className="block-regions-V6b"
                  style={{ fontWeight: "400" }}
                  id="1:14"
                >
                  Blocked Countries
                </p>
                <Checkboxy />
                <fieldset>
                  <div className="text-left bold">
                    Do you have a root Domain?
                  </div>
                  <div className="d-flex flex-row">
                    {/* Yes checkbox  */}
                    <div className="options">
                      <input
                        type="checkbox"
                        id="coding-root"
                        name="interest"
                        value="coding"
                        className="check point"
                        onClick={toggle1}
                        checked={visible}
                      />
                      <label for="coding-root" className="bold point">
                        Yes
                      </label>
                    </div>
                    {/* multiple checkbox  */}

                    {/* no checkbox  */}
                    <div className="options">
                      <input
                        type="checkbox"
                        id="song"
                        name="interest"
                        value="music"
                        className="check point"
                        checked={visible3}
                        onClick={toggle1}
                      />
                      <label for="music" className="bold">
                        No
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* BLOCK REGION ENDS  */}

              {/* FieldSet for multiple checkboxes */}

              {/* fieldset ends  */}
            </div>
            {/* ======== Yes dropdown Starts ===========*/}
            {/* functionality to show or hide dropdown  */}

            {/* first div  */}

            {/* first div ends */}

            {/* second div starts */}
            {/* <div className="data-div">
              <div className="d-flex flex-row justify-content-between title-div">
                <div className="bold inside">TXT Value</div>
                <div className="uni-val bold">TXT Name</div>
              </div> */}
            {/* inputs tags feting data from API  */}
            {/* <div className="input-div">
                <input type="text" value={content.AreaValue} />
                <input type="text" value={content.CodeName} />
              </div>
            </div> */}
            {/* second div ends */}

            {/* third div starts  */}
            {/* <div className="data-div">
              <div className="d-flex flex-row justify-content-between title-div">
                <div className="bold inside">add TXT Value</div>
                <div className="uni-val bold">add TXT Name</div>
              </div> */}
            {/* inputs tags feting data from API  */}
            {/* <div className="input-div">
                <input type="text" value={content.DAPName} />
                <input type="text" value={content.SKUsMatchSno} />
              </div>
            </div> */}
            {/* third div ends  */}
            {/* FieldSet for multiple checkboxes */}

            {/* Fieldset Ends  */}

            {/* ======== Yes dropdown Ends ===========*/}
            {/* functionality to show or hide dropdown  */}
            <div
              style={{
                display: visible ? "block" : "none",
                width: "100%",
              }}
            >
              {/* Using Map to add multiple fields on clicking on plus  */}
              {data.map((val, i) => (
                <div key={val} className="d-flex flex-row mt-3   merged-div">
                  <div className="tags">
                    <input
                      type="text"
                      value={val.pin}
                      onChange={(e) => firstroute(i, e.target.value)}
                      placeholder="www"
                    />
                  </div>
                  <div className="tags">
                    <input
                      type="text"
                      value={val.area}
                      onChange={(e) => secondroute(i, e.target.value)}
                      placeholder="blogs"
                    />
                  </div>
                  <div className="tags-input">
                    <input
                      type="text"
                      value={val.university}
                      onChange={(e) => domainroute(i, e.target.value)}
                      placeholder="domain name"
                    />
                  </div>
                  <span className="forwardslash">/</span>
                  <div className="tags">
                    <input
                      type="text"
                      value={val.usa}
                      onChange={(e) => thirdroute(i, e.target.val)}
                      placeholder="about"
                    />
                  </div>
                  <div className="plus point" id="minus" onClick={remove}>
                    <i
                      className="fa-solid fa-minus"
                      style={{ color: "#ff0000" }}
                    ></i>
                  </div>
                </div>
              ))}
              {/* functionality to add new field on plus icon  */}
              <div className="d-flex justify-content-center mt-3 mb-3">
                <div className="plus point" onClick={handleClick}>
                  <i
                    className="fa-solid fa-plus"
                    style={{ color: "#00ff1e" }}
                  ></i>
                </div>
              </div>
              {/* first div  */}

              {/* first div ends */}

              {/* second div starts */}
              {/* <div className="data-div">
              <div className="d-flex flex-row justify-content-between title-div">
                <div className="bold inside">TXT Value</div>
                <div className="uni-val bold">TXT Name</div>
              </div> */}
              {/* inputs tags feting data from API  */}
              {/* <div className="input-div">
                <input type="text" value={content.AreaValue} />
                <input type="text" value={content.CodeName} />
              </div>
            </div> */}
              {/* second div ends */}

              {/* third div starts  */}
              {/* <div className="data-div">
              <div className="d-flex flex-row justify-content-between title-div">
                <div className="bold inside">add TXT Value</div>
                <div className="uni-val bold">add TXT Name</div>
              </div> */}
              {/* inputs tags feting data from API  */}
              {/* <div className="input-div">
                <input type="text" value={content.DAPName} />
                <input type="text" value={content.SKUsMatchSno} />
              </div>
            </div> */}
              {/* third div ends  */}
              {/* FieldSet for multiple checkboxes */}
              {data.map((val, i) => (
                <div key={val} className="data-div">
                  <div className="d-flex flex-row justify-content-between title-div">
                    <div className="bold inside">CNAME </div>
                    <div className="uni-val bold">CNAME Value</div>
                  </div>
                  {/* inputs tags feting data from API  */}
                  <div className="input-div">
                    <input
                      type="text"
                      value={`${val.area ? `${val.area} ` : ""}${
                        val.pin ? `${val.pin} ` : ""
                      }${val.usa ? val.usa : ""}`}
                    />{" "}
                    <input
                      type="text"
                      value={textToCopyCvalue}
                      title={isCopiedCvalue ? "Text Copied" : "Click to copy"}
                      style={{ cursor: "pointer" }}
                      onClick={handleCopyClickCvalue}
                      className="input"
                    />
                    <img
                      src="../images/other/copyIcon.png"
                      alt="icon"
                      style={{
                        width: "15px",
                        height: "25px",
                        marginLeft: "-600px",
                        marginTop: "0px",
                        cursor: "pointer",
                      }}
                      title={isCopiedCvalue ? "Text Copied" : "Click to copy"}
                      onClick={handleCopyClickCvalue}
                    />
                  </div>
                </div>
              ))}

              {/* Fieldset ends  */}
            </div>
          </div>
          {/*====================== Form ends ================== */}
          <div className="form2" style={divStyle3}>
            <fieldset>
              <div className="text-left bold">
                How do you want to onboard Domains?
              </div>
              {/* yes checkbox  */}
              <div className="d-flex flex-row">
                <div
                  className="options"
                  onChange={(e) => {
                    setAutomata((prev) => !prev);
                  }}
                >
                  <input
                    type="radio"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="check point"
                    checked={checkedvalue === "coding"}
                    onChange={changed}
                  />
                  <span
                    style={{ padding: "10px 10px 10px 0px" }}
                    for="coding"
                    className="bold"
                  >
                    Automatically
                  </span>
                  <input
                    type="radio"
                    id="music"
                    name="interest"
                    value="music"
                    className="check point"
                    checked={checkedvalue === "music"}
                    onChange={changed}
                  />
                  <span for="music" className="bold">
                    Manual
                  </span>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="form" style={divStyle4}>
            <Tabs defaultValue="edit" className="flex flex-col items-center">
              <TabsList className="w-full max-w-[80%] mx-auto">
                <TabsTrigger value="edit">Current DNS</TabsTrigger>
                <TabsTrigger value="past">Edit DNS</TabsTrigger>
              </TabsList>
              <TabsContent value="edit">
                <Dns
                  automatically={automata}
                  bolleandata={settingboolean}
                  // childdata={gettingchilddata}
                />
              </TabsContent>
              <TabsContent value="past">
                <Dns
                  automatically={automata}
                  wantPast={true}
                  bolleandata={settingboolean}
                  // childdata={gettingchilddata}
                />
              </TabsContent>
            </Tabs>
            <button
              id="finalSubmitButton"
              disabled={buttonState}
              onClick={handleSaveClick}
              className="SaveButton"
            >
              {buttonState ? "Saving..." : "Save"}
            </button>
          </div>
        </section>
        {toggleimage ? (
          <img src={help} alt="help" className="helpimage" onClick={openhelp} />
        ) : null}
        {helpshow ? (
          <Helping
            onClick={() => {
              setHelpshow(false);
            }}
            settoggle={setToggleimage}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Hero;

// <div className="frame-3405-jQF" id="1:15">
// 							<div className="form-group has-search block-search mb-4">
// 									<span className="fa fa-search form-control-feedback">
// 										<i className="bi bi-search"></i>
// 									</span>
// 									<input type="text" className="form-control block-inputbox" />
// 								</div>

// 								<div className="d-flex flex-row justify-content-between w-100">
// 									<div className="d-flex flex-column justify-content-between">
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />
// 											<div className="block-div">BLOCK </div>
// 										</div>
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />

// 											<div className="block-div2">BLOCKED </div>
// 										</div>
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />
// 											<div className="block-div">BLOCK </div>
// 										</div>
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />
// 											<div className="block-div">BLOCK </div>
// 										</div>
// 									</div>

// 									<div className="d-flex flex-column justify-content-between">
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />
// 											<div className="block-div">BLOCK </div>
// 										</div>
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />

// 											<div className="block-div2">BLOCKED </div>
// 										</div>
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />
// 											<div className="block-div">BLOCK </div>
// 										</div>
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />
// 											<div className="block-div">BLOCK </div>
// 										</div>
// 									</div>

// 									<div className="d-flex flex-column justify-content-between">
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />
// 											<div className="block-div">BLOCK </div>
// 										</div>
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />

// 											<div className="block-div2">BLOCKED </div>
// 										</div>
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />
// 											<div className="block-div">BLOCK </div>
// 										</div>
// 										<div className="d-flex flex-row justify-content-between">
// 											<input type="text" className="block-input" />
// 											<div className="block-div">BLOCK </div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
