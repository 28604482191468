import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { MoreHorizontal, ListMinus, Trash2 } from "lucide-react";
import {
  addLanguage,
  removeLanguage,
  resetUnSavedLangSelection,
  saveCountryEdits,
  setHighlightCountry,
  setAccuracy,
  updateAccuracy,
  setDefaultLanguage,
} from "../../redux/dataSlices";
import {
  CheckSquare2,
  Square,
  SlidersHorizontal,
  CheckSquare,
  MoreVertical,
} from "lucide-react";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import "./CustomizedCountry.css";
import Zoom from "@mui/material/Zoom";
import { Tooltip } from "@mui/material";
import countries from "../../data/countries";
import { CountryWithFlag } from "./Language";
import { LanguageSelection } from "./LanguageSelection";
import { cn } from "../../lib/utils";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { useMemo } from "react";

function toggleClass(
  ele,
  list = ["border", "border-[#A1A3F7]", "shadow-highlight", "scale-95"]
) {
  list.forEach((tog) => {
    ele.classList.toggle(tog);
  });
}
const Country = ({ country, open, isOpen }) => {
  const dispatch = useDispatch();
  const languagesId = useSelector((state) => state.data.languages);
  const [newAccuracy, setNewAccuracy] = useState(country.accuracy ?? 1);
  const [edit, setEdit] = useState(false);
  const countriesWithLanguages = useSelector((state) => state.data.countries);
  const unsavedLangsSelection = useSelector(
    (state) => state.data.unsavedLangsSelection
  );
  const [newlyAddLang, setNewlyAddLang] = useState([]);
  // console.log(newlyAddLang);

  const [language, setLanguage] = useState();
  const [selectOption, setselectOption] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const [selectedLangs, setSelectedLangs] = useState([]);
  const handleAddLanguage = (lang = undefined) => {
    if (language === "" && !lang) {
      setEdit(false);
    } else {
      const langKey = lang ? lang : language;
      const newLanguage = languagesId[langKey];
      if (newLanguage) {
        dispatch(
          addLanguage({
            countryId: country,
            language: newLanguage,
            id: langKey,
            accuracy: +(localStorage.getItem("accuracy") ?? 0),
          })
        );
      }
    }
    if (!lang) {
      setEdit(false);
      setLanguage("");
    }
  };

  const increaseAccuracy = (languageId) => {
    dispatch(
      updateAccuracy({
        countryId: country,
        id: languageId,
        accuracyIncrement: isNaN(newAccuracy) ? 1 : parseFloat(newAccuracy),
      })
    );
  };

  const decreaseAccuracy = (languageId) => {
    dispatch(
      updateAccuracy({
        countryId: country,
        id: languageId,
        accuracyIncrement: isNaN(newAccuracy) ? -1 : -parseFloat(newAccuracy),
      })
    );
  };
  const handleAccuracyInput = (e, languageId) => {
    const newAccuracy = parseInt(e.target.innerText, 10);
    // Validate the input (optional)
    if (!isNaN(newAccuracy) && newAccuracy >= 0 && newAccuracy <= 100) {
      // Dispatch the action to update accuracy
      dispatch(
        setAccuracy({
          countryId: country,
          id: languageId,
          accuracy: newAccuracy,
        })
      );
    }
  };

  function selectLang(langId) {
    if (selectOption === undefined && !edit) return;
    const idx = selectedLangs.indexOf(langId);
    if (idx === -1) {
      setSelectedLangs((langs) => [...langs, langId]);
    } else {
      selectedLangs.splice(idx, 1);
      setSelectedLangs([...selectedLangs]);
    }
  }
  useEffect(() => {
    if (!selectOption) {
      setSelectedLangs([]);
    }
    if (unsavedLangsSelection[country]) {
      const languagesAddedForCountry = unsavedLangsSelection[country]["add"];
      setNewlyAddLang(languagesAddedForCountry);
    } else {
      setNewlyAddLang([]); // Reset newlyAddLang if no languages added for the country
    }
  }, [selectOption, unsavedLangsSelection, country]);
  const targetedLanguageSearch = useSelector(
    (state) => state.data.targetedLanguageSearch
  );
  const hightlightCountry = useSelector(
    (state) => state.data.hightlightCountry
  );
  const countryRef = useRef(null);
  useEffect(() => {
    if (!countryRef.current) {
      return;
    }
    if (hightlightCountry === country) {
      countryRef.current.parentNode.scrollTo({
        left:
          countryRef.current.offsetLeft - countryRef.current.clientWidth * 2,
        behavior: "smooth",
      });
      dispatch(setHighlightCountry(undefined));
      toggleClass(countryRef.current);
      setTimeout(() => {
        toggleClass(countryRef.current);
      }, 900);
    }
  }, [hightlightCountry, countryRef]);
  const [unsavedLangsSelectionAdd, unsavedLangsSelectionRemove] = useMemo(
    () =>
      unsavedLangsSelection[country]
        ? [
            unsavedLangsSelection[country]["add"],
            unsavedLangsSelection[country]["remove"],
          ]
        : [[], []],
    [unsavedLangsSelection[country]]
  );

  const unsavedLangsSelectionAddIds = useMemo(
    () => unsavedLangsSelectionAdd.map((e) => e.id),
    [unsavedLangsSelectionAdd]
  );

  const unsavedLangsSelectionRemoveIds = useMemo(
    () => unsavedLangsSelectionRemove.map((e) => e.id),
    [unsavedLangsSelectionRemove]
  );
  const show =
    targetedLanguageSearch === "" || !countriesWithLanguages[country].languages
      ? true
      : countriesWithLanguages[country]?.languages
          .map((e) => (e.languageText ? e.languageText.toLowerCase() : e.id))
          .filter((e) => e.includes(targetedLanguageSearch.toLowerCase()))
          .length !== 0;
  if (show) {
    return (
      <div
        ref={countryRef}
        className={`flex relative transition-all gap-2 ${
          isOpen ? "min-w-[22.8%]" : "min-w-[23.5%]"
        } bg-secContainerColor overflow-hidden max-h-[80vh] rounded-3xl p-2 px-3 flex-col`}
        style={{ transition: "1s ease-in-out" }}
      >
        <div className={`grow ${edit ? "max-h-[85%]" : "max-h-[90%]"} pb-24`}>
          <div className="flex flex-col my-2 pb-2 border-b border-black">
            <CountryWithFlag countryId={country} />
          </div>
          <LanguageSelection
            countryId={country}
            langs={[
              ...(countriesWithLanguages[country].languages
                ? countriesWithLanguages[country].languages
                : []),
              ...unsavedLangsSelectionAdd,
            ].filter((e) => !unsavedLangsSelectionRemoveIds.includes(e.id))}
            handleAddLanguage={handleAddLanguage}
          />
          <ul className="flex flex-col my-2 grow h-full gap-3 overflow-y-auto">
            {countriesWithLanguages[country]?.languages &&
              countriesWithLanguages[country]?.languages.map((language) => (
                <li
                  onClick={() => selectLang(language.id)}
                  key={language.id}
                  className="flex justify-between cursor-pointer items-center"
                >
                  {edit &&
                    (selectedLangs.includes(language.id) ? (
                      <CheckSquare2
                        className={cn("w-4 h-4 my-1 text-primaryColor")}
                        onClick={() => {
                          setSelectedLangs((prevSelectedLangs) =>
                            prevSelectedLangs.filter((id) => id !== language.id)
                          );
                        }}
                      />
                    ) : (
                      <Square className={"mr-1 h-4 w-4 text-primaryColor"} />
                    ))}
                  <div className="language_text grow-[2] text-base font-medium">
                    {language.languageText}
                  </div>

                  <div className="[&>*]:border grow [&>*]:border-black [&>*]:rounded-xl flex items-center justify-end gap-1 empty:hidden">
                    {edit && (
                      <AddIcon
                        onClick={() => {
                          increaseAccuracy(language.id);
                        }}
                        className="incre_icon"
                        style={{ width: "0.9em", height: "0.9em" }}
                      >
                        Increase Accuracy
                      </AddIcon>
                    )}
                    <Tooltip
                      className="w-[3em] text-center"
                      TransitionComponent={Zoom}
                      title="To set accuracy of each region click on edit"
                    >
                      {/* <span
                        className="p-1"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid black",
                        }}
                      >
                        {language.accuracy}%
                      </span> */}
                      <span
                        className="p-1 grow-[1]"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid black",
                          cursor: edit ? "text" : "default",
                          maxWidth: "3.5em",
                        }}
                      >
                        <span
                          contentEditable={edit}
                          suppressContentEditableWarning={false}
                          onInput={(e) => handleAccuracyInput(e, language.id)}
                        >
                          {language.accuracy}
                        </span>
                        %
                      </span>
                    </Tooltip>
                    {/* <input className="py-1 w-[3em] text-center" value={language.accuracy} onChange={handleInputchange} /> */}
                    {edit && (
                      <RemoveIcon
                        onClick={() => {
                          if (language.accuracy === 50) {
                            return;
                          }
                          decreaseAccuracy(language.id);
                        }}
                        className="decre_icon"
                        style={{ width: "0.9em", height: "0.9em" }}
                      >
                        Decrease Accuracy
                      </RemoveIcon>
                    )}
                  </div>
                  {edit && (
                    <DefaultLanguageSetter
                      countryId={country}
                      langId={language.id}
                    />
                  )}
                  {/* {edit && language.isDefault === undefined && (
                    <CloseIcon
                      onClick={() =>
                        dispatch(
                          removeLanguage({
                            countryId: country,
                            languageId: language.id,
                          })
                        )
                      }
                      className="remove_icon"
                    />
                  )} */}
                </li>
              ))}
            {newlyAddLang &&
              newlyAddLang.map((item) => {
                return (
                  <li>
                    <div className="flex justify-between text-center">
                      {item.language}
                      <div className="bg-red-500 font-semibold border-2 rounded-md text-xs p-1 text-white">
                        {" "}
                        Unsaved
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        {edit && (
          <div className="flex bg-secContainerColor items-center gap-3">
            <Tooltip title="Delete" placement="top">
              <Trash2
                style={{ width: "22px", height: "22px", cursor: "pointer" }}
                onClick={() => {
                  selectedLangs.forEach((langId) => {
                    dispatch(
                      removeLanguage({
                        countryId: country,
                        id: langId,
                      })
                    );
                  });
                  setEdit(false);
                  setSelectedLangs([]);
                  setselectOption(undefined);
                  enqueueSnackbar("Languages cleared", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                }}
              />
            </Tooltip>
            <Tooltip title="Edit Accuracy" placement="top">
              <SlidersHorizontal
                style={{ width: "22px", height: "22px", cursor: "pointer" }}
                className={cn({
                  "text-primaryColor": selectOption === "Multiple",
                })}
                onClick={() => {
                  if (selectOption === "Multiple") {
                    setselectOption(undefined);
                  } else {
                    setselectOption("Multiple");
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Select All" placement="top">
              <CheckSquare
                style={{ width: "22px", height: "22px", cursor: "pointer" }}
                className={cn({ "text-primaryColor": selectOption === "All" })}
                onClick={() => {
                  if (selectOption === "All") {
                    setselectOption(undefined);
                    setSelectedLangs([]);
                  } else {
                    setselectOption("All");
                    setSelectedLangs(
                      countriesWithLanguages[country].languages.map((e) => e.id)
                    );
                  }
                }}
              />
            </Tooltip>
          </div>
        )}
        <div className="bg-secContainerColor absolute bottom-3 right-0 w-full grow flex border-t px-2 pt-2 border-black items-center justify-between">
          <div className="flex gap-2 items-center grow">
            <Button
              className="bg-primaryColor"
              onClick={() => {
                if (unsavedLangsSelection[country]) {
                  unsavedLangsSelection[country]["add"].forEach((lang) => {
                    dispatch(addLanguage(lang));
                  });
                  unsavedLangsSelection[country]["remove"].forEach((lang) => {
                    dispatch(removeLanguage(lang));
                  });
                }
                dispatch(saveCountryEdits(country));
                setEdit(false);
                enqueueSnackbar(`Settings Saved`, {
                  variant: "default",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  TransitionComponent: Zoom,
                });
              }}
            >
              Save
            </Button>
            <button
              onClick={() => {
                dispatch(resetUnSavedLangSelection(country));
                setSelectedLangs([]);
                enqueueSnackbar("Resetted", {
                  variant: "default",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  TransitionComponent: Zoom,
                });
                setEdit(false);
              }}
            >
              Reset
            </button>
          </div>
          <div className="flex items-center gap-2 justify-between cursor-pointer">
            <Tooltip title="More options" placement="top">
              <MoreHorizontal
                onClick={(e) => {
                  setEdit(!edit);
                  setselectOption(undefined);
                }}
              />
            </Tooltip>
          </div>

          {/* <div className="save_reset">
            {edit && (
              <button onClick={handleAddLanguage} className="save">
                SAVE
              </button>
            )}
          </div> */}
        </div>
      </div>
    );
  } else return <></>;
};

export default Country;

function DefaultLanguageSetter({ countryId, langId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <MoreVertical />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            dispatch(
              setDefaultLanguage({
                countryId,
                langId,
              })
            );
          }}
        >
          Set Default
        </button>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
