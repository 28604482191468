import React from "react";
import "./index.css";
import Menu from "./Menu.svg";
import cropped7 from "./cropped7.png";
import { useEffect, useRef } from "react";
const Index = () => {
  const scrollIframeToBottom = () => {
    const iframe = document.getElementById("ifr");
    const currentScroll =
      iframe.contentWindow.pageYOffset ||
      iframe.contentDocument.documentElement.scrollTop;
    const targetScroll = currentScroll + window.innerHeight;
    iframe.contentWindow.scrollTo(0, targetScroll);
  };

  const scrollIframeToTop = () => {
    const iframe = document.getElementById("ifr");
    const currentScroll =
      iframe.contentWindow.pageYOffset ||
      iframe.contentDocument.documentElement.scrollTop;
    const targetScroll = currentScroll + 200 - window.innerHeight;
    iframe.contentWindow.scrollTo(0, targetScroll);
  };

  return (
    <div className="outerdiv">
      <img
        src={cropped7}
        alt="photo"
        width="10%"
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          zIndex: 1000000000000000000,
        }}
      />
      <div
        id="iframe_container"
        style={{
          background: "black",
          position: "fixed",
          left: "10%",
          top: "20px",
          bottom: "60px",
          right: "10%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src={Menu}
          alt="photo"
          width="80%"
          style={{
            position: "fixed",
            borderBottom: "0px",
            borderLeft: "1.6px solid grey",
            borderRight: "1.6px solid grey",
            borderTop: "1.6px solid grey",
            zIndex: 1000000000000000000,
          }}
        />
        <iframe
          id="ifr"
          name="ifr"
          style={{
            background: "black",
            position: "absolute",
            height: "90%",
            width: "100%",
            borderRight: "2px solid grey",
            borderBottom: "7px solid grey",
            zIndex: 1000000000,
            marginTop: "5.2rem",
          }}
          src="images2/test3.html"
          onload="document.body.style.height = frames.ifr.document.body.offsetHeight + parseInt(document.getElementById('iframe_container').style.top) + parseInt(document.getElementById('iframe_container').style.bottom) + 'px' "
        ></iframe>
      </div>
      {/* <img class="cropped" src="cropped6.png" alt="photo" width="11.5%" style=" position:fixed; top: 12%; left:-1.6% " /> */}
      <div style={{ position: "fixed", top: "52%", right: "90%" }}>
        <span
          className="material-symbols-outlined"
          style={{ color: "white" }}
          onClick={scrollIframeToTop}
        >
          arrow_back_ios
        </span>
      </div>
      <div style={{ position: "fixed", top: "52%", left: "90.5%" }}>
        <span
          className="material-symbols-outlined"
          style={{ color: "white" }}
          onClick={scrollIframeToBottom}
        >
          arrow_forward_ios
        </span>
      </div>
    </div>
  );
};
export default Index;
