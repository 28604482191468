import React from 'react'
import "./sponserlogo.css"
const SponserLogo = () => {
  return (
    <div className="sponser-container">
        <div className="topbar">Works seamlessly with 1000s of plaftorms</div>
        <div className="sponserlogo">
            <div className="sponsorlogo-img">
                <div className="sponsorlogo-img1">
                    <img src="/images/other/webflow.svg" className='img1' alt="logo" />
                </div>
                <div className="sponsorlogo-img2">
                   <img src="/images/other/wordpress.svg" className='img1' alt="logo" />
                </div>
                <div className="sponsorlogo-img3">
                  <img src="/images/other/bigcommerce.svg" className='img1' alt="logo" />
                </div>
                <div className="sponsorlogo-img4">
                  <img src="/images/other/wix.svg" className='img1' alt="logo" />
                </div>
                <div className="sponsorlogo-img5">
                    <img src="/images/other/weebly.svg" className='img1' alt="logo" />
                    </div>
                <div className="sponsorlogo-img6">
                 <img src="/images/other/shopify.svg" className='img1' alt="logo" />
                </div>

            </div>
        </div>
    </div>
  )
}

export default SponserLogo