import React from 'react'
import "./top4form.css"
const Top4Form = ({activeStep,setActiveStep,setUserData }) => {
  const [kindOfWork,setkindOfWork]=React.useState("");
  const handleChange = (e) =>{
    setkindOfWork(e.target.value);
    setUserData((prev)=>[prev[0],prev[1],prev[2],prev[3],e.target.value,prev[5],prev[6],prev[7],prev[8],prev[9]]);
    setActiveStep(5)
  }
  return (
    <>
    <div className="top4form-container">
        <div className="top4form-wrapper">
    <h2 className="top4-head">What kind of work do you do?</h2>
    <select name="work" id="" className='input-top2' value={kindOfWork} onChange={(e) =>{handleChange(e)}} required >
  <option value="" className='input-option' defaultValue={true} disabled hidden >Choose an option</option>
  <option value="design">Design</option>
  <option value="operations">Operations</option>
  <option value="engineering">Engineering/Development</option>
  <option value="uxresearch">UX Research</option>
  <option value="sales">Sales/ Success</option>
  <option value="productmanagement">Product Management</option>
  <option value="marketing">Marketing</option>
  <option value="projectmanagement">Project Management</option>


</select>
        </div>
    </div>
    </>
   
  )
}

export default Top4Form