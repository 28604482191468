import React from 'react'
import "./top1form.css"
const Top1Form = ({setActiveStep,setUserData}) => {
  const [domain,setDomain]=React.useState("");
const handleChange = (e) =>{
  setDomain(e.target.value);
  setUserData((prev)=>[e.target.value,prev[1],prev[2],prev[3],prev[4],prev[5],prev[6],prev[7],prev[8],prev[9]]);
  setActiveStep(2)
}
  return (
    <>
    <div className="top1form-container">
        <div className="top1form-wrapper">
    <h2 className="top1-head">Confirm you domain </h2>
    <input type="text" placeholder='Input domain here' className='input-top1' value={domain}  onChange={handleChange} required />
        </div>
    </div>
    </>
  )
}

export default Top1Form