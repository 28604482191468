import React, { useEffect, useState } from "react";
import AddMoney from "./AddMoney";
import axios from "axios";
import { getAdminBalance } from "../../redux/apiCalls";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
const list = [
  {
    currency: "USD",
  },
  {
    currency: "AUD",
  },
  {
    currency: "GBP",
  },
  {
    currency: "EUR",
  },
];
const Wallet = ({ balance, handleBalanceUpdate }) => {
  const { enqueueSnackbar } = useSnackbar();

  // const [balance, setBalance] = useState(0);
  // const handleBalanceUpdate = () => {
  //   getAdminBalance()
  //     .then((res) => setBalance(res.data))
  //     .catch((err) => console.error("Error fetching balance:", err));
  // };
  useEffect(() => {
    // handleBalanceUpdate();
    if (balance < 100) {
      enqueueSnackbar("Minimum Balance $100 required", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => {
    setIsOpen(!isOpen);
  };
  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  return (
    <div>
      <div className="w-[844px] h-[1166px] p-4 bg-violet-100 rounded-[25px] flex-col justify-start items-center gap-4 inline-flex">
        <div className="self-stretch justify-end items-center gap-[484px] inline-flex">
          <div className="w-[163px] h-7  py-4 bg-neutral-50 rounded-[20px] justify-center items-center gap-2.5 flex">
            <div className="text-blue-700 text-lg font-medium font-['Lato']">
              Cost Estimator
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
            >
              <path
                d="M9.19474 5.97895V7.16842C9.19474 8.01053 8.51053 8.69474 7.66842 8.69474H3.33158C2.48947 8.69474 1.80526 8.01053 1.80526 7.16842V2.83158C1.80526 1.98947 2.48947 1.30526 3.33158 1.30526H4.52105C4.44737 1.10526 4.39474 0.884211 4.39474 0.652632C4.39474 0.421053 4.44737 0.2 4.52105 0H3.33158C1.77368 0 0.5 1.27368 0.5 2.83158V7.16842C0.5 8.72632 1.77368 10 3.33158 10H7.66842C9.22632 10 10.5 8.72632 10.5 7.16842V5.97895C10.3 6.05263 10.0789 6.10526 9.84737 6.10526C9.61579 6.10526 9.39474 6.06316 9.19474 5.97895Z"
                fill="#000AFF"
              />
              <path
                d="M9.84683 0H6.20473C5.84683 0 5.5521 0.294737 5.5521 0.652632C5.5521 1.01053 5.84683 1.30526 6.20473 1.30526H8.26789L3.61525 5.95789C3.36262 6.21053 3.36262 6.63158 3.61525 6.88421C3.74157 7.01053 3.90999 7.07368 4.07841 7.07368C4.24683 7.07368 4.41526 7.01053 4.54157 6.88421L9.1942 2.23158V4.29474C9.1942 4.65263 9.48894 4.94737 9.84683 4.94737C10.2047 4.94737 10.4995 4.65263 10.4995 4.29474V0.652632C10.4995 0.294737 10.2047 0 9.84683 0Z"
                fill="#000AFF"
              />
            </svg>
          </div>
        </div>

        <div className="flex-col justify-start items-center flex">
          <div className="justify-start items-start inline-flex">
            <div className="text-black text-[64px] font-medium font-['Lato']">
              $
            </div>
            <div className="px-4 rounded-[50px] justify-center items-center gap-2.5 flex">
              <div className="text-black text-[64px] font-medium font-['Lato']">
                {balance}
              </div>
            </div>
          </div>
          <div className="text-neutral-500 text-lg font-medium font-['Lato']">
            Translation Credits
          </div>
        </div>

        <AddMoney onBalanceUpdate={handleBalanceUpdate} />

        <div className="px-2 pt-5 pb-4 bg-sky-50 rounded-[20px] flex-col justify-start items-center gap-6 flex">
          <div className="h-7 px-8 py-2 ml-72 bg-cyan-300 rounded-[37px] justify-end items-start gap-2.5 inline-flex relative main-w-[500px]">
            <button
              className="text-black text-xs font-medium font-['Lato']"
              onClick={toggling}
            >
              Add Currency +
            </button>

            {isOpen && (
              <div className="absolute  right-0 z-10 mt-4 w-full origin-top-right rounded-md-border border-gray-100 bg-white shadow-lg">
                {list.map((item, i) => (
                  <div
                    className="rounded-lg px-4 py-2 text-sm text-gray-500 font-semibold no-underline hover:bg-gray-100 cursor-pointer flex justify-between"
                    key={i}
                    onClick={onOptionClicked(item.currency)}
                  >
                    <h3>
                      <img src={`../images/other/${i}.svg`} />
                      {item.currency}
                    </h3>
                    <h3>
                      <img src={"../images/other/s1.svg"} />
                    </h3>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <span className="text-black text-lg font-medium font-['Lato'] ml-3">
              Australian Dollar
            </span>
            <span className="text-neutral-400 text-lg font-medium font-['Lato']">
              .................................................................................
            </span>
            <span className="text-black text-lg font-medium font-['Lato']">
              ₹81,0220
            </span>
          </div>
          <div>
            <span className="text-black text-lg font-medium font-['Lato']">
              British Pound (GBP){" "}
            </span>
            <span className="text-neutral-400 text-lg font-medium font-['Lato']">
              ..........................................................................
            </span>
            <span className="text-black text-lg font-medium font-['Lato']">
              £92200
            </span>
          </div>
          <div>
            <span className="text-black text-lg font-medium font-['Lato']">
              Euro(EUR){" "}
            </span>
            <span className="text-neutral-400 text-lg font-medium font-['Lato']">
              ...........................................................................................
            </span>
            <span className="text-black text-lg font-medium font-['Lato']">
              €32284
            </span>
          </div>
          <div>
            <span className="text-black text-lg font-medium font-['Lato']">
              {selectedOption}{" "}
            </span>
            <span className="text-neutral-400 text-lg font-medium font-['Lato']">
              ...........................................................................................
            </span>
            <span className="text-black text-lg font-medium font-['Lato']">
              £93200
            </span>
          </div>
        </div>

        <div className="p-2 bg-white rounded-[15px] flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch justify-between items-center inline-flex">
            <div className="text-black text-lg font-medium font-['Lato']">
              Recent Transactions
            </div>
            <div className="justify-start items-start gap-4 flex">
              <div className="justify-center items-center gap-1 flex">
                <div className="text-right text-blue-700 text-xs font-medium font-['Lato']">
                  Passbook
                </div>
              </div>
              <div className="justify-center items-center gap-1 flex">
                <div className="text-right text-black text-xs font-medium font-['Lato']">
                  Export .CSV
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="10"
                  viewBox="0 0 11 10"
                  fill="none"
                >
                  <path
                    d="M5.2882 7.94613C5.479 8.15937 5.80448 8.18182 6.02894 7.9798C6.04017 7.96857 6.05139 7.95735 6.06261 7.94613L8.82356 4.92705C9.01435 4.70258 8.99191 4.3771 8.78989 4.19753C8.57664 4.01796 8.25116 4.02918 8.06037 4.2312L6.19729 6.2514V0.516274C6.19729 0.23569 5.9616 0 5.68102 0C5.40044 0 5.16475 0.23569 5.16475 0.516274V6.24018L3.32412 4.20875C3.13332 3.99551 2.80784 3.98429 2.58338 4.18631C2.35891 4.3771 2.35891 4.70258 2.56093 4.92705L5.2882 7.94613Z"
                    fill="black"
                  />
                  <path
                    d="M10.4955 8.96741H0.865883C0.5853 8.96741 0.349609 9.2031 0.349609 9.48368C0.349609 9.76426 0.5853 9.99995 0.865883 9.99995H10.4843C10.7649 9.99995 11.0006 9.76426 11.0006 9.48368C11.0118 9.2031 10.7761 8.96741 10.4955 8.96741Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="w-[690px] p-2 rounded-[15px] border border-indigo-400 justify-between items-center inline-flex">
            <div className="flex-col justify-center items-center gap-4 inline-flex">
              <div className="self-stretch h-[30px] flex-col justify-center items-center gap-2 flex">
                <div className="self-stretch justify-center items-center gap-2.5 inline-flex">
                  <div className="text-zinc-500 text-lg font-medium font-['Lato']">
                    Type
                  </div>
                </div>
                <div className="w-[72px] h-[0px] border border-indigo-400"></div>
              </div>
              <div className="justify-start items-start gap-1.5 inline-flex">
                <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                  Money added
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4ZM12.88 17.76V19H11.13V17.71C10.39 17.53 8.74 16.94 8.11 14.75L9.76 14.08C9.82 14.3 10.34 16.17 12.16 16.17C13.09 16.17 14.14 15.69 14.14 14.56C14.14 13.6 13.44 13.1 11.86 12.53C10.76 12.14 8.51 11.5 8.51 9.22C8.51 9.12 8.52 6.82 11.13 6.26V5H12.88V6.24C14.72 6.56 15.39 8.03 15.54 8.47L13.96 9.14C13.85 8.79 13.37 7.8 12.06 7.8C11.36 7.8 10.25 8.17 10.25 9.19C10.25 10.14 11.11 10.5 12.89 11.09C15.29 11.92 15.9 13.14 15.9 14.54C15.9 17.17 13.4 17.67 12.88 17.76Z"
                    fill="#71C921"
                  />
                  <path
                    d="M12.8896 11.1C11.1096 10.51 10.2496 10.14 10.2496 9.2C10.2496 8.18 11.3596 7.81 12.0596 7.81C13.3696 7.81 13.8496 8.8 13.9596 9.15L15.5396 8.48C15.3896 8.04 14.7196 6.57 12.8796 6.25V5H11.1296V6.26C8.52961 6.82 8.50961 9.11 8.50961 9.22C8.50961 11.49 10.7596 12.13 11.8596 12.53C13.4396 13.09 14.1396 13.6 14.1396 14.56C14.1396 15.69 13.0896 16.17 12.1596 16.17C10.3396 16.17 9.81961 14.3 9.75961 14.08L8.09961 14.75C8.72961 16.94 10.3796 17.53 11.1196 17.71V19H12.8696V17.76C13.3896 17.67 15.8896 17.17 15.8896 14.54C15.8996 13.15 15.2896 11.93 12.8896 11.1Z"
                    fill="#71C921"
                  />
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                    fill="#71C921"
                  />
                </svg>
              </div>
              <div className="justify-start items-start gap-1.5 inline-flex">
                <div className="w-[103px] text-zinc-500 text-lg font-normal font-['Lato']">
                  Paid for a bill{" "}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.0008 10.15H14.1608C13.511 10.1473 12.8889 9.88666 12.4313 9.42532C11.9737 8.96398 11.7181 8.33975 11.7208 7.68997V2.92997C11.7208 2.65383 11.4969 2.42997 11.2208 2.42997H8.55078C6.34164 2.42997 4.55078 4.22083 4.55078 6.42997V17.57C4.55078 19.7791 6.34164 21.57 8.55078 21.57H15.4508C16.5116 21.57 17.5291 21.1485 18.2792 20.3984C19.0294 19.6483 19.4508 18.6308 19.4508 17.57V10.65C19.4475 10.394 19.255 10.1801 19.0008 10.15Z"
                    fill="#000AFF"
                  />
                  <path
                    d="M14.2508 9.04997H18.8808C19.1276 9.07032 19.3585 8.92609 19.4485 8.69534C19.5385 8.46458 19.4662 8.20212 19.2708 8.04997L13.7208 2.59997C13.5677 2.40214 13.3021 2.32977 13.0699 2.42266C12.8376 2.51555 12.6952 2.75117 12.7208 2.99997V7.56997C12.7206 7.97131 12.8833 8.35553 13.1718 8.63457C13.4603 8.91361 13.8497 9.06353 14.2508 9.04997Z"
                    fill="#000AFF"
                  />
                </svg>
              </div>
              <div className="justify-start items-start gap-1.5 inline-flex">
                <div className="w-[103px] text-zinc-500 text-lg font-normal font-['Lato']">
                  Paid for a bill{" "}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.0008 10.15H14.1608C13.511 10.1473 12.8889 9.88666 12.4313 9.42532C11.9737 8.96398 11.7181 8.33975 11.7208 7.68997V2.92997C11.7208 2.65383 11.4969 2.42997 11.2208 2.42997H8.55078C6.34164 2.42997 4.55078 4.22083 4.55078 6.42997V17.57C4.55078 19.7791 6.34164 21.57 8.55078 21.57H15.4508C16.5116 21.57 17.5291 21.1485 18.2792 20.3984C19.0294 19.6483 19.4508 18.6308 19.4508 17.57V10.65C19.4475 10.394 19.255 10.1801 19.0008 10.15Z"
                    fill="#000AFF"
                  />
                  <path
                    d="M14.2508 9.04997H18.8808C19.1276 9.07032 19.3585 8.92609 19.4485 8.69534C19.5385 8.46458 19.4662 8.20212 19.2708 8.04997L13.7208 2.59997C13.5677 2.40214 13.3021 2.32977 13.0699 2.42266C12.8376 2.51555 12.6952 2.75117 12.7208 2.99997V7.56997C12.7206 7.97131 12.8833 8.35553 13.1718 8.63457C13.4603 8.91361 13.8497 9.06353 14.2508 9.04997Z"
                    fill="#000AFF"
                  />
                </svg>
              </div>
              <div className="justify-start items-start gap-1.5 inline-flex">
                <div className=" text-zinc-500 text-lg font-normal font-['Lato']">
                  Adding Failed
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.94093 5.08011C9.62785 3.79737 10.9658 2.99765 12.4209 3.00011C13.9018 2.98901 15.2676 3.79694 15.9709 5.10011L21.6109 15.5401C22.28 16.7797 22.2478 18.2798 21.5261 19.4895C20.8044 20.6992 19.4996 21.4401 18.0909 21.4401H6.75093C5.33808 21.4404 4.02986 20.6954 3.30931 19.4801C2.58875 18.2648 2.56276 16.7596 3.24093 15.5201L8.94093 5.08011ZM14.6209 5.81011C14.1871 4.99937 13.3404 4.49518 12.4209 4.50011C11.515 4.5064 10.6839 5.00427 10.2509 5.80011L4.56093 16.2201C4.13594 16.9928 4.15133 17.9326 4.60141 18.691C5.05148 19.4493 5.86909 19.913 6.75093 19.9101H18.0909C18.9696 19.9048 19.7815 19.4403 20.2315 18.6855C20.6815 17.9308 20.704 16.9957 20.2909 16.2201L14.6209 5.81011Z"
                    fill="#E70000"
                  />
                  <path
                    d="M12.4209 15.0002C12.0067 15.0002 11.6709 15.336 11.6709 15.7502C11.6709 16.1644 12.0067 16.5002 12.4209 16.5002C12.6207 16.5029 12.813 16.4248 12.9543 16.2836C13.0955 16.1423 13.1736 15.95 13.1709 15.7502C13.1709 15.5522 13.0916 15.3625 12.9507 15.2234C12.8097 15.0843 12.6189 15.0075 12.4209 15.0102V15.0002Z"
                    fill="#E70000"
                  />
                  <path
                    d="M12.4209 13.6302C12.009 13.6248 11.6763 13.2922 11.6709 12.8802V9.78022C11.6709 9.36601 12.0067 9.03022 12.4209 9.03022C12.8351 9.03022 13.1709 9.36601 13.1709 9.78022V12.8802C13.1709 13.2944 12.8351 13.6302 12.4209 13.6302Z"
                    fill="#E70000"
                  />
                </svg>
              </div>

              <div className="justify-start items-start gap-1.5 inline-flex">
                <div className="w-[103px] text-zinc-500 text-lg font-normal font-['Lato']">
                  Paid for a bill{" "}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.0008 10.15H14.1608C13.511 10.1473 12.8889 9.88666 12.4313 9.42532C11.9737 8.96398 11.7181 8.33975 11.7208 7.68997V2.92997C11.7208 2.65383 11.4969 2.42997 11.2208 2.42997H8.55078C6.34164 2.42997 4.55078 4.22083 4.55078 6.42997V17.57C4.55078 19.7791 6.34164 21.57 8.55078 21.57H15.4508C16.5116 21.57 17.5291 21.1485 18.2792 20.3984C19.0294 19.6483 19.4508 18.6308 19.4508 17.57V10.65C19.4475 10.394 19.255 10.1801 19.0008 10.15Z"
                    fill="#000AFF"
                  />
                  <path
                    d="M14.2508 9.04997H18.8808C19.1276 9.07032 19.3585 8.92609 19.4485 8.69534C19.5385 8.46458 19.4662 8.20212 19.2708 8.04997L13.7208 2.59997C13.5677 2.40214 13.3021 2.32977 13.0699 2.42266C12.8376 2.51555 12.6952 2.75117 12.7208 2.99997V7.56997C12.7206 7.97131 12.8833 8.35553 13.1718 8.63457C13.4603 8.91361 13.8497 9.06353 14.2508 9.04997Z"
                    fill="#000AFF"
                  />
                </svg>
              </div>
              <div className="justify-start items-start gap-1.5 inline-flex">
                <div className="w-[103px] text-zinc-500 text-lg font-normal font-['Lato']">
                  Paid for a bill{" "}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.0008 10.15H14.1608C13.511 10.1473 12.8889 9.88666 12.4313 9.42532C11.9737 8.96398 11.7181 8.33975 11.7208 7.68997V2.92997C11.7208 2.65383 11.4969 2.42997 11.2208 2.42997H8.55078C6.34164 2.42997 4.55078 4.22083 4.55078 6.42997V17.57C4.55078 19.7791 6.34164 21.57 8.55078 21.57H15.4508C16.5116 21.57 17.5291 21.1485 18.2792 20.3984C19.0294 19.6483 19.4508 18.6308 19.4508 17.57V10.65C19.4475 10.394 19.255 10.1801 19.0008 10.15Z"
                    fill="#000AFF"
                  />
                  <path
                    d="M14.2508 9.04997H18.8808C19.1276 9.07032 19.3585 8.92609 19.4485 8.69534C19.5385 8.46458 19.4662 8.20212 19.2708 8.04997L13.7208 2.59997C13.5677 2.40214 13.3021 2.32977 13.0699 2.42266C12.8376 2.51555 12.6952 2.75117 12.7208 2.99997V7.56997C12.7206 7.97131 12.8833 8.35553 13.1718 8.63457C13.4603 8.91361 13.8497 9.06353 14.2508 9.04997Z"
                    fill="#000AFF"
                  />
                </svg>
              </div>
            </div>

            <div className="flex-col justify-start items-center gap-4 inline-flex">
              <div className="self-stretch h-[30px] flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-center items-center gap-2.5 inline-flex">
                  <div className="text-zinc-500 text-lg font-medium font-['Lato']">
                    Status
                  </div>
                </div>
                <div className="w-[72px] h-[0px] border border-indigo-400"></div>
              </div>
              <div className="text-lime-500 text-lg font-normal font-['Lato']">
                Success
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                Paid
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                Paid
              </div>
              <div className="text-red-400 text-lg font-normal font-['Lato']">
                Failed
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                Paid
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                Paid
              </div>
            </div>

            <div className="flex-col justify-start items-center gap-4 inline-flex">
              <div className="self-stretch h-[30px] flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-center items-center gap-2.5 inline-flex">
                  <div className="text-zinc-500 text-lg font-medium font-['Lato']">
                    Amount
                  </div>
                </div>
                <div className="w-[72px] h-[0px] border border-indigo-400"></div>
              </div>
              <div className="text-lime-500 text-lg font-normal font-['Lato']">
                +200$
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                -20$
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                -20$
              </div>
              <div className="text-red-400 text-lg font-normal font-['Lato']">
                -20$
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                -20$
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                -20$
              </div>
            </div>

            <div className="flex-col justify-center items-center gap-4 inline-flex">
              <div className="self-stretch h-[30px] flex-col justify-center items-center gap-2 flex">
                <div className="self-stretch justify-center items-center gap-2.5 inline-flex">
                  <div className="text-zinc-500 text-lg font-medium font-['Lato']">
                    Date
                  </div>
                </div>
                <div className="w-[72px] h-[0px] border border-indigo-400"></div>
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                12/12/23 13:24
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                12/12/23 13:24
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                12/12/23 13:24
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                12/12/23 13:24
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                12/12/23 13:24
              </div>
              <div className="text-zinc-500 text-lg font-normal font-['Lato']">
                12/12/23 13:24
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
