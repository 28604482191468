const regions = {
  ME: ["ME", "AF"],
  EM: ["ME", "EU", "AS"],
  AP: ["AS", "OC"],
  NA: ["AM", "CA"],
  EE: ["AF", "EU", "ME"],
  SE: ["EU", "ME", "AF"],
  CE: ["EU", "AF", "ME"],
  CEE: ["EU"],
  EU: ["AF", "EU", "ME"],
  EME: ["AF", "EU", "ME"],
  EMEA: ["AF", "CA", "EU", "ME"],
  NAC: ["EU", "NAT"],
  CEM: ["EU", "AS"],
};
export default regions;

export const regionNames = {
  ME: "Middle East",
  AF: "Africa",
  AS: "Asia",
  EU: "Europe",
  OC: "Oceania",
  AM: "Amer",
  CA: "Carebian",
  NAT: "The North Atlantic",
};
