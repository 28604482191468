import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./herocomponent.css";
import { Language } from "../LanguageCode";
import ChatComponent from "../chatComponent/ChatComponent";
const HeroComponent = ({ domain, setDomain }) => {
  const navigate = useNavigate();
  const [domainValue, setDomainValue] = useState("");
  const [shakeInput, setShakeInput] = useState(false);
  const handleChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\s/g, "");
    if (!value.startsWith("http://") && !value.startsWith("https://")) {
      value = "https://" + value;
    }
    setDomain(value);
    setDomainValue(e.target.value);
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (domainValue.trim() === "") {
      setShakeInput(true);
      setTimeout(() => {
        setShakeInput(false);
      }, 500);
    } else {
      navigate("/frame");
    }
  };

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const ssodata = localStorage.getItem("datasso");
  useEffect(() => {
    if (ssodata) {
      navigate("/Language-&-Region");
      // window.location.href = "https://app.glocalise.net/chatbot";
    }
  }, []);
  function refreshClock() {
    const currentDate = new Date();

    // currentDate.setSeconds(0, 0);
    // var stamp = currentDate.toISOString().replace(/T/, " ").replace(/:00.000Z/, "");
    const temp = new Date(currentDate.setMinutes(currentDate.getMinutes() + 7));
    setDate(temp);
    let tempHour = `${
      temp.getHours() > 12 ? temp.getHours() - 12 : temp.getHours()
    }`;
    let timeString = `${tempHour >= 10 ? tempHour : `0` + tempHour} : ${
      temp.getMinutes() >= 10 ? temp.getMinutes() : `0` + temp.getMinutes()
    } ${temp.getHours() > 12 ? "PM" : "AM"}`;
    setTime(timeString);
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const [chatOpen, setChatOpen] = useState(false);
  const [rotateIcon, setRotateIcon] = useState(false);
  const chatOpener = () => {
    // if(chatOpen && defaultMsg && sendMsg){

    //   setDefaultMsg(!defaultMsg);
    // }
    // else if(chatOpen && sendMsg){
    //   setSendMsg(!sendMsg);
    // }
    // else{
    //   setChatOpen(!chatOpen)
    // }
    if (!chatOpen) {
      setIconPopup("Close Chat");
    } else {
      setIconPopup("Open Chat");
    }
    if (clickSend && !sendMsg) {
      if (customer) {
        setcustomer(!customer);
      } else if (account) {
        setaccount(!account);
      } else if (tech) {
        settech(!tech);
      } else {
        setothers(!others);
      }
      setSendMsg(false);
      setChatOpen(false);
    } else if (sendMsg) {
      setSendMsg(!sendMsg);
      setChatOpen(false);
      setcustomer(false);
      setaccount(false);
      settech(false);
      setothers(false);
    } else {
      setChatOpen(!chatOpen);
      setSendMsg(false);
      setcustomer(false);
      setaccount(false);
      settech(false);
      setothers(false);
    }
    setRotateIcon(!rotateIcon);
  };
  const [sendMsg, setSendMsg] = useState(false);
  const sendingMsg = () => {
    setSendMsg(true);
    // setChatOpen(false);
  };
  const [countryToggle, setCountryToggle] = useState(false);
  const countryDrop = () => {
    setCountryToggle(() => !countryToggle);
  };
  const [defaultMsg, setDefaultMsg] = useState(false);
  const defaultMessagingCustomer = () => {
    setSendMsg(false);
    setcustomer(true);
  };
  const defaultMessagingAccount = () => {
    setSendMsg(false);
    setaccount(true);
  };
  const defaultMessagingTech = () => {
    setSendMsg(false);
    settech(true);
  };
  const defaultMessagingOther = () => {
    setSendMsg(false);
    setothers(true);
  };
  useEffect(() => {
    if (countryToggle) {
      if (document.body.getAttribute("listenersAttachedHero") === "true") {
        return;
      }
      setTimeout(() => {
        document.body.setAttribute("listenersAttachedHero", true);
        document.body.addEventListener("click", (e) => {
          countryDrop();
        });
      }, 100);
    }
    return () =>
      window.removeEventListener("click", (e) => {
        document.body.removeAttribute("listenersAttachedHero");
      });
  }, [countryToggle]);
  const [msgValue, setMsgValue] = useState("");
  const [clickSend, setClickSend] = useState(false);
  const [chatMsg, setChatMsg] = useState([]);
  const [account, setaccount] = useState(false);
  const [customer, setcustomer] = useState(false);
  const [tech, settech] = useState(false);
  const [others, setothers] = useState(false);
  const [languageflag, setLanguageflag] = useState("");
  const [languagecode, setLanguagecode] = useState("");
  const [value, setValue] = useState(false);
  const [iconPopup, setIconPopup] = useState("Open Chat");
  const languagevalue = (index) => {
    setLanguageflag(Language[index].image);
    setLanguagecode(Language[index].code);
    setValue(true);
  };
  return (
    <div className="hero-container">
      <div className="fixed bottom-9 left-4">
        <a href="https://wa.me/14452125275" target="_blank">
          <img
            src="/images/other/WhatsApp.png"
            alt="whatsapp icon"
            width={32}
            className="cursor-pointer"
          />
        </a>
      </div>
      <div className="hero-wrapper">
        <div className="hero-left ">
          <div className="hero-left-head1 ">
            <div>Glocalise your Reach by</div>
            <div className="mx-2" style={{ color: "black", fontWeight: "600" }}>
              {time}
            </div>
          </div>
          <div className="hero-left-head2">
            We Translate <br /> your website on<br /> the fly
          </div>
          <div className="hero-left-head3">
            Enter your domain &gt;{" "}
            <span style={{ color: "#EE9644" }}> preview translations </span>{" "}
            &gt; Activate it
          </div>
          <form onSubmit={handleClick} style={{ width: "100%" }}>
            <div className="hero-left-input">
              <input
                type="text"
                placeholder="Enter your domain"
                className={`hero-left-inputbar ${shakeInput ? "shake" : ""}`}
                name="domain"
                onChange={(e) => handleChange(e)}
              />
              <button className="hero-left-input-button">Next &gt;</button>
            </div>
          </form>

          <div className="hero-left-rate inline-flex">
            <div className="hero-left-rate-left">
              <div className="rate-left-img">
                <div className="hero-left-rate1"></div>

                <div className="hero-left-rate2"></div>
                <div className="hero-left-rate3">+</div>
              </div>
            </div>
            <div className="hero-left-rate-text">
              <div className="rate-text-bottom">
                <div className="rate-text">
                  8<p className="rate-little-text">k</p>
                  <div className="rate-text1">+</div>
                </div>
              </div>
              <div className="review">Reviews</div>
            </div>

            <div className="hero-left-rate-right">
              <div className="hero-right-icon">
                <div className="hero-right-icon1"></div>
                <div className="hero-right-icon2"></div>
                <div className="hero-right-icon3"></div>
              </div>
              <div className="hero-left-rate-text">
                <div className="rate-text-bottom">
                  <div className="rate-text">
                    9<p className="rate-little-text">k</p>
                    <div className="rate-text1">+</div>
                  </div>
                </div>
                <div className="review">Clients</div>
              </div>
            </div>

            <div className="ml-3 mt-3"><a href="https://www.producthunt.com/posts/glocali-se-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-glocali&#0045;se&#0045;2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=440863&theme=light" alt="Glocali&#0046;se - Where&#0032;the&#0032;global&#0032;meets&#0032;Local&#0032;&#0038;&#0032;Local&#0032;goes&#0032;global | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a></div>

          </div>
          
        </div>

        <div className="hero-right">
          <div className="hero-right-img">
            <img
              src="/images/other/Group 406.png"
              className="hero-right-img1"
              alt=""
            />
            <img
              src="/images/other/Group 405.png"
              className="hero-right-img2"
              alt=""
            />
            <img
              src="/images/other/Group 403.png"
              className="hero-right-img3"
              alt=""
            />
            <div className="div">
              <img
                src="/images/other/Localization.png"
                alt=""
                className="hero-right-img4 upper-img "
              />
              <img
                src="/images/other/SEO.png"
                alt=""
                className="hero-right-img5 upper-img "
              />
              <img
                src="/images/other/Stats.png"
                alt=""
                className="hero-right-img6 upper-img "
              />
              <img
                src="/images/other/Reports.png"
                alt=""
                className="hero-right-img7 upper-img "
              />
            </div>
          </div>
        </div>
        <div className="chat-icon-div">
          {/* <i className="bi bi-bell-fill bell"></i> */}
          <div className="chat-icon-container">
            <img
              src="/images/other/chat-icon.png"
              alt=""
              className={rotateIcon ? "rotate-chat-icon" : "chat-icon"}
              onClick={chatOpener}
            />
            <div className="chat-icon-popup">
              <p>{iconPopup}</p>
            </div>
          </div>
          {/* <div className="country-lang-div">
            <div
              className="country-lang"
              onClick={(e) => {
                document.body.click();
                e.stopPropagation();
                countryDrop();
              }}
            >
              <div className="d-flex flex-row align-items-center justify-content-center">
                <img
                  src={
                    !value
                      ? "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
                      : languageflag
                  }
                  alt="Flag of France"
                />
                {!value ? "CA" : languagecode}
              </div>
            </div>
            <i
              className="bi bi-lightning-charge-fill"
              style={{ color: "#ffb301", marginRight: "-10px" }}
            ></i>

            <div>by Glocali.se</div>
          </div> */}
        </div>

        <div
          className="lang-popup"
          style={{ display: countryToggle ? "flex" : "none" }}
        >
          {Language.map((e, index) => (
            <div
              className="d-flex flex-row align-items-center mb-2 justify-content-between"
              onClick={(e) => {
                e.stopPropagation();
                languagevalue(index);
                countryDrop();
              }}
            >
              <img
                src={e.image}
                alt="Flag of France"
                style={{ width: "20px", height: "11px", marginRight: "3px" }}
              />
              <div>{e.code}</div>
            </div>
          ))}
        </div>

        <div
          className="chat-popup"
          style={{ display: chatOpen ? "flex" : "none" }}
        >
          <div className="chat-popup-inside">
            <div className="upper-chat">
              <div className="help">
                Hi there
                <br />
                How can we help?
              </div>
            </div>
            <div className="lower-chat">
              <div
                className="send-msg"
                onClick={sendingMsg}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex flex-column">
                  <div className="send-msg-head">Send us a message</div>
                  <div className="send-msg-desc">
                    We usually reply in a few minutes
                  </div>
                </div>
                <div>
                  <img
                    src="/images/other/send-icon.png"
                    alt=""
                    className="send-icon-prev"
                  />
                </div>
              </div>
              <div className="send-msg">
                <div style={{ cursor: "pointer" }} className="help-center">
                  Glocali.se Help Center
                </div>
              </div>
              <div className="send-msg-2">
                <div className="send-msg-head">Book a demo </div>
                <div className="send-msg-desc">
                  We'd be happy to show you how to localize your website by
                  using our service Glocali.se
                </div>
                <div className="d-flex justify-content-center">
                  <button className="trial">
                    Free trial
                    <i className="bi bi-chevron-right"></i>
                  </button>
                </div>
              </div>
              <div className="send-msg">
                <div className="d-flex flex-column">
                  <div className="send-msg-head">Leave a feedback</div>
                  <div className="send-msg-desc">
                    We believe that you can help us to improve with your
                    feedback. Your opinion matters
                  </div>
                </div>
                <div>
                  <img
                    src="/images/other/dropDown.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="chat-popup"
          style={{ display: sendMsg ? "flex" : "none" }}
        >
          <div className="chat-popup-inside">
            <div className="upper-bar">
              {/* <i className="bi bi-chevron-left"></i> */}
              <i
                className="bi bi-chevron-left back-btn"
                onClick={() => {
                  setSendMsg(false);
                  setChatOpen(true);
                }}
                style={{ cursor: "pointer" }}
              ></i>
              <div className="d-flex flex-row justify-content-end mb-2">
                <img src="/favicon.ico.png" alt="" style={{ width: "10%" }} />
                <div>Glocali.se </div>
              </div>
            </div>
            <div className="chat-container">
              <div className="d-flex flex-row">
                <div className="team-text">
                  Hello, I am here to help you. You can select the team you wish
                  to connect with.
                </div>
              </div>

              <div className="d-flex flex-row">
                <div
                  className="customer-help mt-3"
                  onClick={defaultMessagingCustomer}
                >
                  Customer Support
                </div>
              </div>
              <div className="d-flex flex-row">
                <div
                  className="customer-help mt-1"
                  onClick={defaultMessagingAccount}
                >
                  Account & Billing
                </div>
              </div>
              <div className="d-flex flex-row">
                <div
                  className="customer-help mt-1"
                  onClick={defaultMessagingTech}
                >
                  Technical Team
                </div>
              </div>
              <div className="d-flex flex-row">
                <div
                  className="customer-help mt-1"
                  onClick={defaultMessagingOther}
                >
                  Others
                </div>
              </div>
            </div>
            <div className="msg-hero">
              {/* <i className="bi bi-link-45deg msg-i-hero"></i> */}
              <img
                src="/images/other/msg-link.png"
                alt=""
                className="msg-i-hero"
              />
              <input
                type="text"
                placeholder="Type here"
                className="msg-div-hero"
              />
              <i className="bi bi-emoji-smile smiley-hero"></i>

              {/* </div> */}
              <img
                src="/images/other/global.png"
                alt=""
                className="globee-hero"
              />
              <img
                src="/images/other/send.png"
                alt=""
                style={{ width: "30px", height: "30px", marginLeft: "2px" }}
              />
            </div>
          </div>
        </div>
        <div
          className="chat-popup"
          style={{ display: customer ? "flex" : "none" }}
        >
          <ChatComponent
            // setDefaultMsg={setDefaultMsg}
            setDefaultMsg={setcustomer}
            setSendMsg={setSendMsg}
            chatMsg={chatMsg}
            setChatMsg={setChatMsg}
            setClickSend={setClickSend}
            setMsgValue={setMsgValue}
            defaultMsg={customer}
            clickSend={clickSend}
            msgValue={msgValue}
            service={"Customer Support"}
          />
        </div>
        <div
          className="chat-popup"
          style={{ display: account ? "flex" : "none" }}
        >
          <ChatComponent
            // setDefaultMsg={setDefaultMsg}
            setDefaultMsg={setaccount}
            setSendMsg={setSendMsg}
            chatMsg={chatMsg}
            setChatMsg={setChatMsg}
            setClickSend={setClickSend}
            setMsgValue={setMsgValue}
            defaultMsg={account}
            clickSend={clickSend}
            msgValue={msgValue}
            service={"Account & Billing"}
          />
        </div>
        <div className="chat-popup" style={{ display: tech ? "flex" : "none" }}>
          <ChatComponent
            // setDefaultMsg={setDefaultMsg}
            setDefaultMsg={settech}
            setSendMsg={setSendMsg}
            chatMsg={chatMsg}
            setChatMsg={setChatMsg}
            setClickSend={setClickSend}
            setMsgValue={setMsgValue}
            defaultMsg={tech}
            clickSend={clickSend}
            msgValue={msgValue}
            service={"Technical"}
          />
        </div>
        <div
          className="chat-popup"
          style={{ display: others ? "flex" : "none" }}
        >
          <ChatComponent
            // setDefaultMsg={setDefaultMsg}
            setDefaultMsg={setothers}
            setSendMsg={setSendMsg}
            chatMsg={chatMsg}
            setChatMsg={setChatMsg}
            setClickSend={setClickSend}
            setMsgValue={setMsgValue}
            defaultMsg={others}
            clickSend={clickSend}
            msgValue={msgValue}
            service={""}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
