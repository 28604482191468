import React from 'react'
import "./top5form.css"
const Top5Form = ({activeStep, setActiveStep,setUserData}) => {
  const [companySize,setCompanySize]=React.useState("");
  const [busType,setBusType]=React.useState("");
  const handleChangeA= (e) =>{
    // setUserData((prev)=>[prev[0],prev[1],prev[2],prev[3],prev[4],prev[5],prev[6],prev[7],prev[8]]);
    setUserData((prev)=>[prev[0],prev[1],prev[2],prev[3],prev[4],e.target.value,prev[6],prev[7],prev[8],prev[9]]);
    setCompanySize(e.target.value);

  }
  const handleChangeB= (e) =>{
    setBusType(e.target.value);
    setUserData((prev)=>[prev[0],prev[1],prev[2],prev[3],prev[4],prev[5],e.target.value,prev[7],prev[8],prev[9]]);
    setActiveStep(6)
  }
  return (
   <>
      <div className="top5form-container">
        <div className="top5form-wrapper">
    <h2 className="top5-head">Company Size</h2>
    <select value={companySize} onChange={(e)=>handleChangeA(e)} name="" id="" className='input-top5' required >
  <option value="" className='input-option' defaultValue={true} disabled hidden >Choose an option</option>
  <option value="0-2">0 - 2</option>
  <option value="2-10">2 - 10</option>
  <option value="10-50">10 - 50</option>
  <option value="51-250">51 - 250</option>
  <option value="250-1k">250 - 1k</option>
  <option value="1k-5k">1k - 5k</option>
  <option value="5k+">5k +</option>

</select>
        </div>
        <div className="topform-wrapper1">
    <h2 className="top5-head">Business Type</h2>
    <select name="" id="" className='input-top5' value={busType} onChange={(e) => handleChangeB(e)} required >
  <option value="" className='input-option' defaultValue={true} disabled hidden >Choose an option</option>
  <option value="it">IT and Software</option>
  <option value="import&export">Import Export</option>
  <option value="retailer&wholesale">Retailer & Wholesale</option>
  <option value="manufacturing">Manufacturing</option>
  <option value="gaming">Gaming</option>
  <option value="web3">Web 3</option>
  <option value="food">Food</option>
  <option value="clothing">Clothing</option>
  <option value="construction">Construction & Building</option>
  <option value="others">Others</option>


</select>
        </div>
    </div>
    </>
  )
}

export default Top5Form