import React, { useState } from "react";
import "./stepper.css";
import { useNavigate } from "react-router-dom";
const Stepper = () => {
  const Navigate = useNavigate();

  const getstarted = () => {
    Navigate("/signup");
  };

  const handleStep = (e) => {
    // document.getElementById();
    const actives = document.getElementsByClassName("active-step");

    Object.values(actives).forEach((item) =>
      item.classList.remove("active-step")
    );

    const stepId = e.target.id;
    const step1 = document.getElementById("step1");
    const step2 = document.getElementById("step2");
    const step3 = document.getElementById("step3");
    const step4 = document.getElementById("step4");

    const card = document.getElementById(stepId);
    card.classList.add("shake");
    card.classList.add("active-step");
    setTimeout(() => {
      card.classList.remove("shake");
    }, 500);

    if (stepId === "step2") {
      step1.classList.remove("top-10");

      step1.classList.add("top-4");
    } else if (stepId === "step3") {
      step2.classList.remove("top-8");
      step1.classList.remove("top-10");

      step2.classList.add("top-6");
      step1.classList.add("top-4");
    } else if (stepId === "step4") {
      step4.classList.remove("top-4");
      step3.classList.remove("top-6");
      step2.classList.remove("top-8");
      step1.classList.remove("top-10");

      step4.classList.add("top-10");
      step3.classList.add("top-8");
      step2.classList.add("top-6");
      step1.classList.add("top-4");
    } else if (stepId === "step1") {
      step4.classList.remove("top-10");
      step3.classList.remove("top-8");
      step2.classList.remove("top-6");
      step1.classList.remove("top-4");

      step4.classList.add("top-4");
      step3.classList.add("top-6");
      step2.classList.add("top-8");
      step1.classList.add("top-10");
    }
  };

  return (
    <div className="stepper-container ">
      <div className="stepper-left">
        <div className="header1">Where to start</div>
        <div className="header2">
          Simple translation <br /> in simple steps
        </div>
        <button className="btn" onClick={getstarted}>
          Get started &gt;
        </button>
      </div>
      <div className="stepper-right p-10 rounded-xl">
        <div className="stepper-right-div bg-transparent">
          {/* 25/2024 */}
          <div className=" relative h-[354px] w-[776px] " id="container">
            <div
              className={`absolute  w-full flex shadow-2xl h-full items-center top-10 inner-container active-step`}
              id="step1"
            >
              <span className=" box " onClick={(e) => handleStep(e)} id="step1">
                Step 1
              </span>

              <img
                src="/images/other/windowBg.svg"
                alt=""
                className=" right-0 absolute"
              />
              <div className="text-white z-10 flex top-5 items-center relative">
                <div className="content m-4">
                  <h1 className="text-black text-2xl font-semibold">
                    Domain Name
                  </h1>
                  <p className="text-gray-500 text-wrap">
                    Check live preview of your website <br /> translations by
                    entering domain name.
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <img src="/images/other/Group 342.svg" alt="" className="" />
                </div>
              </div>
            </div>

            <div
              className={` absolute w-[776px] top-8 flex shadow-2xl h-full items-center inner-container z-20`}
              id="step2"
            >
              <span
                className=" box left-60 "
                id="step2"
                onClick={(e) => handleStep(e)}
              >
                Step 2
              </span>

              <img
                src="/images/other/windowBg.svg"
                alt=""
                className=" right-0 absolute"
              />
              <div className="text-white z-10 flex top-5 items-center relative">
                <div className="content m-4">
                  <h1 className="text-black text-2xl font-semibold">
                    Region & Language
                  </h1>
                  <p className="text-gray-500 text-wrap">
                    Select the regions and languages that you <br /> want to
                    take your website on.
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <img src="/images/other/Group 344.svg" alt="" className="" />
                </div>
              </div>
            </div>

            <div
              className={`absolute w-[776px] top-6 flex shadow-2xl h-full items-center inner-container z-10 `}
              id="step3"
            >
              <span
                className=" box right-52"
                id="step3"
                onClick={(e) => handleStep(e)}
              >
                Step 3
              </span>

              <img
                src="/images/other/windowBg.svg"
                alt=""
                className=" right-0 absolute"
              />
              <div className="text-white z-10 flex top-5 items-center relative">
                <div className="content m-4">
                  <h1 className="text-black text-2xl font-semibold">
                    Select Services
                  </h1>
                  <p className="text-gray-500 text-wrap">
                    Tick mark and select the options that you <br /> want to
                    utilize and see on your dashboard.
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <img src="/images/other/groupStep3.svg" alt="" className="" />
                </div>
              </div>
            </div>

            <div
              className={` absolute w-[776px] top-4 flex shadow-2xl h-full items-center inner-container `}
              id="step4"
            >
              <span
                className=" box right-0"
                id="step4"
                onClick={(e) => handleStep(e)}
              >
                Step 4
              </span>

              <img
                src="/images/other/windowBg.svg"
                alt=""
                className=" right-0 absolute"
              />
              <div className="text-white z-10 flex top-5 items-center relative">
                <div className="content m-4">
                  <h1 className="text-black text-2xl font-semibold">
                    Connect DNS
                  </h1>
                  <p className="text-gray-500 text-wrap">
                    Connect the DNS of your domains and your <br />
                    website is all live with some amazing translations!
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <img src="/images/other/GroupStep4.svg" alt="" className="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
