import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedRegions,
  setCountriesForLanguageSelection,
  toggleHotSpotRegions,
} from "../../redux/dataSlices";
import { regionNames } from "../../data/regions";
function RegionButton({ regionId }) {
  const dispatch = useDispatch();
  const selected = useSelector(selectedRegions).includes(regionId);
  return (
    <button
      style={{
        fontWeight: "550",
        direction: "ltr",
        backgroundColor: "white",
        fontSize: "16px",
        border: "none",
        borderRadius: "8px",
        padding: "20px",
        color: "#979B99",
        minWidth: "150px",
        // maxWidth: "190px",
        outlineWidth: "1px",
        outlineStyle: "solid",
        lineHeight: "1px",
        overflow: "hidden",
        marginTop: "6px",
        marginLeft: "5px",
        marginBottom: "6px",
        textAlign: "center",
        ...{
          backgroundColor: selected ? "#D7F3ED" : "white",
          outline: selected ? "none" : "1px solid #979B99",
        },
      }}
      onClick={() => {
        dispatch(toggleHotSpotRegions({ regionId }));
        dispatch(setCountriesForLanguageSelection());
      }}
    >
      {regionNames[regionId]}
    </button>
  );
}

export default RegionButton;
