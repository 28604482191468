// import { publicRequest } from "../requestMethods";
import axios from "axios";
import { publicRequest, userRequestCustom } from "../requestMethods";
import { loginFailure, loginStart, loginSuccess } from "./userRedux";

export const authenticateLogin = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/register-otp", user);
    dispatch(loginSuccess(res.data));
    return res;
  } catch (err) {
    dispatch(loginFailure());
    return err;
  }
};

export const sentOtpForRegister = async (user) => {
  try {
    const res = await publicRequest.post("/register-otp", user);
    return res;
  } catch (err) {
    return err;
  }
};

export const sentOtpForLogin = async (user) => {
  try {
    const res = await publicRequest.post("/otp-sent", user);

    console.log(res.data);
    return res;
  } catch (err) {
    return err;
  }
};

export const LoginAdmin = async (dispatch, data, url = "/validate-otp") => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post(url, data);

    return res;
  } catch (err) {
    dispatch(loginFailure());
    return err;
  }
};

export const updateQuestion = async (data, id, token) => {
  try {
    const res = await publicRequest.put(`/updatequestion/${id}`, data, {
      headers: {
        "auth-token": token,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const registerHostname = async (data) => {
  const res = await userRequestCustom.post("/hostnameRegister2/", data, {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};

export const addRegions = async (data) => {
  const res = await userRequestCustom.post(
    "/regions",
    { allRegions: data },
    {
      headers: { "auth-token": localStorage.getItem("jwtToken") },
    }
  );
  return res;
};

export const saveDefaultAccuracy = async (data) => {
  const res = await userRequestCustom.post(
    "/accuracy",
    { languageaccuracy: data },
    {
      headers: { "auth-token": localStorage.getItem("jwtToken") },
    }
  );
  return res;
};
export const getRegions = async () => {
  const res = await userRequestCustom.get("/regions", {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};

export const getSupportedLanguages = async () => {
  const res = await userRequestCustom.get("/supportedLanguages", {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};
export const getAdminBalance = async () => {
  const res = await userRequestCustom.get("/balance", {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};
export const addAdminBalance = async (data) => {
  console.log("addAdminBalanceData", data);
  const res = await userRequestCustom.put(
    "/addbalance",
    { cost: data },
    {
      headers: { "auth-token": localStorage.getItem("jwtToken") },
    }
  );
  return res;
};
export const getRegionsAndLangs = async () => {
  const res = await userRequestCustom.get("/regionsAndLangs", {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};
export const addRegionsAndLangs = async (data) => {
  const res = await userRequestCustom.post("/regionsAndLangs", data, {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};

export const getDomain = async () => {
  const res = await userRequestCustom.get("/domain", {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};
export const pushToKv = async (data) => {
  const res = await publicRequest.post("/pushToKv", data, {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};

export const pushToKv2 = async (data) => {
  const res = await publicRequest.post("/pushToKv2", data, {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};

export const deleteHostname = async (data) => {
  const res = await publicRequest.delete(`/deleteHostname`, {
    data: data,
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};

export const checkStausForHostName = async (data) => {
  const res = await publicRequest.post(`/hostNameStatus`, data, {
    headers: { "auth-token": localStorage.getItem("jwtToken") },
  });
  return res;
};
