import { useTheme, Zoom } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  authenticateLogin,
  LoginAdmin,
  sentOtpForLogin,
  sentOtpForRegister,
} from "../../../redux/apiCalls";
import { ChevronLeft } from "lucide-react";
import { publicRequest } from "../../../requestMethods";
import "./otpverify.css";
import { useLocation } from "react-router-dom";
import { loginSuccess, setGetSSO } from "../../../redux/userRedux";
import { Button } from "../../../ui/button";
const OtpVerify = ({
  setVerify,
  initialEmailValues,
  values,
  url = "/validate-otp",
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState(60);
  useEffect(() => {
    countDown > 0 && setTimeout(() => setCountDown(countDown - 1), 1000);
  }, [countDown]);

  const handleChange = (otp) => {
    // setOtp(e.target.value)
    // console.log(otp)
    setOtp(otp);
    console.log(otp);
  };
  const handleLogin = async () => {
    if (String(otp).length !== 6) {
      return;
    }
    try {
      const {
        data: { data, success, message },
        status,
      } = await LoginAdmin(dispatch, { otp: otp }, url);
      if (success) {
        localStorage.setItem("jwtToken", data.token);
        dispatch(loginSuccess(data));
        const register = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/ssologin/setssodata`,
          values
        );

        if (status === 200 && register.status === 200) {
          enqueueSnackbar("You have Successfully logged in", {
            variant: "default",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            TransitionComponent: Zoom,
          });
          localStorage.setItem(
            "datasso",
            JSON.stringify({ name: "User", email: register.data.data.email })
          );
          localStorage.setItem("jwtToken", register.data.data.token);
          dispatch(setGetSSO(true));
          setCountDown(0);
          if (pathname === "/signup") {
            navigate("/question");
          } else {
            navigate("/Language-&-Region");
          }
          // window.location.reload();
        } else {
          enqueueSnackbar(`${message}`, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            TransitionComponent: Zoom,
          });
          setCountDown(0);
        }
      } else {
        enqueueSnackbar(message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          TransitionComponent: Zoom,
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        TransitionComponent: Zoom,
      });
    }
  };

  const handleResendOtp = async () => {
    if (values.email !== "") {
      const res = await (pathname === "/signup"
        ? sentOtpForRegister(values)
        : sentOtpForLogin(values));
      if (res.data.success) {
        enqueueSnackbar("OTP sent Successfully to Your Email", {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        setCountDown(60);
      } else {
        enqueueSnackbar(res.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
      }
    }
  };

  return (
    <>
      <div className="otp-wrapper">
        <div className="brand">
          <img
            src="/images/logo/Vector 10.png"
            className="verify-brand-img"
            alt=""
          />
        </div>
        <div className="otp-right">
          <p className="signin">Have an Accoount? </p>
          <p className="link-sign">
            <Link style={{ color: "#EE9644", textDecoration: "none" }}>
              Sign in
            </Link>
          </p>
        </div>
      </div>

      <div className="otp-container">
        <div className="relative otp-box">
          <button
            onClick={() => {
              setVerify(false);
            }}
            className="flex gap-2 absolute left-5 top-5 items-center"
          >
            <ChevronLeft />
            <span className="underline">Go back</span>
          </button>
          <div className="otp-head">Verify email address</div>
          <p className="otp-para">
            Enter 6-digit code we’ve sent to <br /> {values.email} to confirm
            your email <br /> address
          </p>
          <OtpInput
            style={{ width: "30px", height: "30px" }}
            value={otp}
            onChange={handleChange}
            inputStyle="inputStyle"
            numInputs={6}
            renderInput={(props) => <input {...props} />}
          />

          <button className="otp-verify-btn" onClick={handleLogin}>
            Verify email address &gt;
          </button>
          {countDown === 0 ? (
            <Button className="mt-2 self-end" onClick={handleResendOtp}>
              Resend OTP
            </Button>
          ) : (
            <button className="resendotp">
              You can resend code after {countDown} seconds
            </button>
          )}
        </div>
      </div>
      <div className="signup-sidebar">
        <img src="/images/other/Group 465.png" alt="contactus" />
        <div className="signup-sidebar-content">Contact us</div>
      </div>
    </>
  );
};

export default OtpVerify;
