import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
import axios from "axios";
import "./newsletter.css";
const NewsLetter = () => {
  const navigate = useNavigate();
  const [call, setCall] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const clandely = async () => {
    const sendclandely = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/clandely/bookappoinment`,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ email: call }),
      }
    );
    if (sendclandely.status === 200) {
      enqueueSnackbar("Your email is register for appoinment", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
      // window.location.href = ("https://glocalise.notion.site/Book-a-Demo-Calendly-1eb14b0731c145519561ca8ca7d266e2");
    } else if (sendclandely.status === 302) {
      enqueueSnackbar("Your email is already register ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    } else {
      {
        enqueueSnackbar("Enter your email ", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center w-100 flex-column align-items-center">
        <div
          className="newsletter-container"
          style={{ backgroundImage: "url(/images/other/newsletter.png)" }}
        >
          <h1 className="newsletter-head ">
            Book a free call with <br /> our team
          </h1>
          {/* <div className='newsletter-form'>
        <input type="text" placeholder='Enter your name' className="newsletter-inputbar" />
        <button className="newsletter-input-button">Next &gt;</button>

        </div> */}

          <div className="hero-left-input news-input-div">
            <input
              type="text"
              placeholder="Enter your Email"
              className="hero-left-inputbar news-input"
              name="domain"
              value={call}
              onChange={(e) => {
                setCall(e.currentTarget.value);
              }}
              required
            />
            <button className="hero-left-input-button" onClick={clandely}>
              Next &gt;
            </button>
          </div>
        </div>
        <div className="lower-content">
          Fast forward your business growth with glocali.se expand your business
          reach by multi folds by expanding to the very and every region you
          ever wanted, overcome the communication barrier and analyze your
          website stats, connect, communicate and curate all from one platform,
          get Glocali.se <br /> <br />
          You may have noticed some brand logos used on this website to indicate
          the platforms that our SaaS can be used with. We don’t endorse these
          brands. Nor do these brands endorse us. The logos of the specific
          brands are owned by them.
        </div>

        <div
          style={{ borderBottom: "1px solid #F2F2F2", marginBottom: "10px" }}
        ></div>
      </div>
    </>
  );
};

export default NewsLetter;
