import React, { useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import CustomizedCountry from "./CustomizedCountry";
import CustomizedSlider from "./CustomizedSlider";

import "./Language.css";
import { MoveLeft, Search } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-bootstrap";
import countries from "../../data/countries";
import { Tooltip } from "@mui/material";
import {
  addLanguage,
  getSelectedCountries,
  haveUnsavedThings,
  removeLanguage,
  saveCountryEdits,
  setHighlightCountry,
  setTargetSearchVal,
} from "../../redux/dataSlices";
import { useNavigate } from "react-router-dom";
import Crumb from "./Crumb";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
import { addRegionsAndLangs } from "../../redux/apiCalls";
function Language() {
  const navigate = useNavigate();
  const [country, setCountry] = React.useState("Countries");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState(false);
  const [sliderInterface, setSliderInterface] = useState(true);
  const [languageInterface, setLanguageInterface] = useState(false);
  const [scroll, setScroll] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [arrowVisible, setarrowVisible] = useState(false);
  const dispatch = useDispatch();
  const selectedCountries = useSelector(getSelectedCountries);
  const countriesDataWithLangs = useSelector((state) => state.data.countries);
  const activateAll = useSelector((state) => state.data.activateAll);

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      if (isOpen) {
        scrollContainerRef.current.scrollLeft -= 1030;
      } else {
        scrollContainerRef.current.scrollLeft -= 1150;
      } // Adjust the scroll distance as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      if (isOpen) {
        scrollContainerRef.current.scrollLeft += 1030;
      } else {
        scrollContainerRef.current.scrollLeft += 1150;
      } // Adjust the scroll distance as needed
    }
  };

  const handleClick = (event) => {
    setCountry(event.target.textContent);
    setShow(false);
  };
  const [showLanguagesDropDown, setShowLanguagesDropDown] = useState(false);
  const hide = () => {
    setOpen(!open);
    setMenu(true);
  };
  const handleSlider = () => {
    setSliderInterface(true);
    setLanguageInterface(false);
    setShowLanguagesDropDown(false);
    setScroll(true);
  };
  const handleLanguage = () => {
    if (languageInterface && isOpen) {
      setShowLanguagesDropDown(!showLanguagesDropDown);
      return;
    }
    setShow(!show);
    setLanguageInterface(true);
    setSliderInterface(false);
    setScroll(false);
  };
  const handleMenu = () => {
    setOpen(false);
    setMenu(false);
  };
  const handleEnter = () => {
    setarrowVisible(true);
  };
  const handleLeave = () => {
    setarrowVisible(false);
  };
  const languageUnsaved = useSelector(
    (state) => state.data.unsaved?.language ?? false
  );
  const breadCrumbs = [
    {
      name: "Langauge Settings",
      onClick: () => {
        if (languageUnsaved) {
          if (window.confirm("You may unsaved changes. Press ok to save")) {
            addAllRegions(true);
          } else {
            navigate(-1);
          }
          return;
        }
        navigate("/Language-&-Region");
      },
    },
    { name: "Accuracy", onClick: handleSlider },
    { name: "Countries", onClick: handleLanguage },
  ];
  // function submitHandler() {
  //   setData(selectedCountries);
  // }

  const [toglehover, setToglehover] = useState({
    Accuracy: false,
    Countries: false,
  });
  async function addAllRegions(wantToNavigate = false) {
    if (wantToNavigate) {
      enqueueSnackbar(`Your changes are being saved`, {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });
    }
    return addRegionsAndLangs({
      regionsAndLangs: countriesDataWithLangs,
      activated: activateAll,
    })
      .then((e) => {
        dispatch(haveUnsavedThings({ what: "language", toSet: false }));
        enqueueSnackbar(`All Changes saved`, {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        if (wantToNavigate) {
          navigate(-1);
        }
      })
      .catch((e) => {
        enqueueSnackbar(`Error saving`, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
      });
  }
  const handleMouseEnter = (itemName) => {
    setToglehover((to) => ({ ...to, [itemName]: true }));
  };

  const targetedLanguageSearch = useSelector(
    (state) => state.data.targetedLanguageSearch
  );
  const { enqueueSnackbar } = useSnackbar();
  const toggle = () => {
    setIsOpen(!isOpen);
    setShowLanguagesDropDown(false);
  };
  const handleMouseLeave = (itemName) => {
    setToglehover((to) => ({ ...to, [itemName]: false }));
  };
  const unsavedLangsSelection = useSelector(
    (state) => state.data.unsavedLangsSelection
  );
  const menuItem = [
    {
      name: "Accuracy",
      icon: (
        <img
          alt=""
          className="img-fluid"
          style={{ margin: "0px 16px", width: "24px", height: "24px" }}
          src={
            toglehover.Accuracy || sliderInterface
              ? "./icons/accuracy_icon-color.svg"
              : "./icons/accuracy_icon.svg"
          }
        />
      ),
      onClick: handleSlider,
      onMouseEnter: () => {
        handleMouseEnter("Accuracy");
      },
      onMouseLeave: () => {
        handleMouseLeave("Accuracy");
      },
    },
    {
      name: "Countries",
      icon: (
        <img
          alt=""
          className="img-fluid"
          style={{ margin: "0px 16px", width: "24px", height: "24px" }}
          src={
            toglehover.Countries || languageInterface
              ? "./icons/countries_icon-color.svg"
              : "./icons/countries_icon.svg"
          }
        />
      ),
      onClick: handleLanguage,
      onMouseEnter: () => {
        handleMouseEnter("Countries");
      },
      onMouseLeave: () => {
        handleMouseLeave("Countries");
      },
    },
  ];
  return (
    <div className="flex w-full">
      {/* <div
        className={!open ? "left-side-setting-open" : "left-side-setting-close"}
      >
        <div className="top-inside-left-side-setting">
          {open ? (
            <div className="forward-and-backward">
              <ArrowForwardIosIcon onClick={hide} style={{ fontSize: "1px" }} />
              <ArrowForwardIosIcon onClick={hide} style={{ fontSize: "1px" }} />
            </div>
          ) : (
            <div className="forward-and-backward">
              <ArrowBackIosIcon
                onClick={hide}
                style={{
                  fontSize: "16px",
                  position: "absolute",
                  top: "6px",
                  left: "-2px",
                }}
              />
              <ArrowBackIosIcon onClick={hide} style={{ fontSize: "16px" }} />
              <div className="title1">Close sidebar</div>
            </div>
          )}
          <p>
            <CloseIcon
              style={{
                fontSize: "16px",
                strokeWidth: "0.786px",
                marginRight: "5px",
                marginTop: "-4px",
              }}
            />
            Language settings
          </p>
        </div>
        <div className="buttons">
          <button className="button-accuracy" onClick={handleSlider}>
            Accuracy
          </button>
          <div className="region" onClick={handleLanguage}>
            {country}
            {show ? (
              <KeyboardArrowUpIcon
                style={{ fontSize: "25px", float: "right" }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ fontSize: "25px", float: "right" }}
              />
            )}
          </div>
          {show ? (
            <div className="drop-down">
              {selectedCountries.map((countryId) => (
                <div key={countryId} onClick={handleClick}>
                  {countries[countryId]}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div> */}
      <div
        className="sidebar__container flex z-10 mr-12"
        style={{
          position: "fixed",
          top: 0, // Adjust this value based on your layout
          left: 0,
          maxWidth: isOpen ? "23rem" : "8rem",
          transition: "1s ease-in-out",
        }}
      >
        <div
          style={{
            width: isOpen ? "21rem" : "3.5rem",
            transition: "1s ease-in-out",
          }}
          className="sidebar justify-center align-middle"
        >
          <div
            style={{
              width: isOpen ? "24rem" : "6rem",
              transition: "width 1s ease-in-out",
            }}
            className="sidebar__top-div mt-9 justify-between align-middle flex"
          >
            <div className="brand-icon align-middle grow">
              <div
                className={`flex justify-center ${isOpen ? "pl-0" : "pl-3"}`}
                style={{
                  transition: "padding 1s ease-in-out",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: isOpen ? "100%" : "0",
                    display: isOpen ? "flex" : "none",
                    transition:
                      "width 1s ease-in-out, padding 1s ease-in-out, display 1s ease-in-out 2s",
                  }}
                  className="items-center delay-ani delay-[2s] justify-center"
                >
                  {breadCrumbs.map((crumb, idx) => (
                    <Crumb
                      key={crumb.name}
                      isLast={breadCrumbs.length - 1 === idx}
                      {...crumb}
                      sliderInterface={sliderInterface}
                      languageInterface={languageInterface}
                    />
                  ))}
                </div>
              </div>
            </div>
            <Tooltip title={!isOpen ? "Open" : "Close"} placement="right">
              <div
                className={"flex align-middle sidebar__bars"}
                style={{
                  cursor: "pointer",
                }}
                onClick={toggle}
              >
                {isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
                      <path d="M9 3v18m7-6l-3-3l3-3" />
                    </g>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
                      <path d="M9 3v18m5-12l3 3l-3 3" />
                    </g>
                  </svg>
                )}
              </div>
            </Tooltip>
          </div>

          <div
            className="sidebar__NavLink-section mt-14"
            style={{
              padding: isOpen ? "1em 2em" : "0",
              transition: " padding 1s ease-in-out",
              overflow: "hidden",
            }}
          >
            {isOpen && (
              <h3
                style={{ fontFamily: "Lato" }}
                className="font-semibold text-2xl text-center mb-8"
              >
                Language Settings
              </h3>
            )}
            {menuItem.map((item, index) => (
              <Tooltip
                key={item.name}
                title={!isOpen ? item.name : ""}
                placement="right"
              >
                <section
                  key={item.name}
                  className={`flex hover:bg-white transition-all rounded-xl hover:text-primaryColor my-2 p-3 cursor-pointer justify-center items-center ${
                    sliderInterface && item.name === "Accuracy"
                      ? "bg-white text-primaryColor"
                      : ""
                  } ${
                    languageInterface && item.name === "Countries"
                      ? "bg-white text-primaryColor"
                      : ""
                  }`}
                  onClick={item.onClick}
                  onMouseEnter={item.onMouseEnter}
                  onMouseLeave={item.onMouseLeave}
                >
                  <div className="icon flex justify-center items-center">
                    {item.icon}
                  </div>{" "}
                  <h6
                    style={{
                      width: isOpen ? "100%" : "0",
                      paddingLeft: isOpen ? "1rem" : "0",
                      display: isOpen ? "flex" : "none",
                      transition:
                        "width 1s ease-in-out, padding 1s ease-in-out, display 1s ease-in-out",
                    }}
                  >
                    {item.name}
                  </h6>
                  {isOpen && item.name === "Countries" && (
                    <ArrowForwardIosIcon
                      className={`${
                        showLanguagesDropDown ? "-rotate-90" : "rotate-90"
                      }`}
                    />
                  )}
                </section>
              </Tooltip>
            ))}
            {showLanguagesDropDown && (
              <section
                className="bg-white flex items-start flex-col rounded-xl space-y-4 overflow-y-auto max-h-[28rem] font-semibold text-lg"
                style={{ fontFamily: "Lato" }}
              >
                {selectedCountries.map((countryId) => {
                  return (
                    <CountryWithFlag key={countryId} countryId={countryId} />
                  );
                })}
              </section>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${isOpen ? "w-11/12" : "w-full"} mr-1 ${
          !isOpen && sliderInterface ? "ml-28" : ""
        } ${!isOpen && !sliderInterface ? "-ml-16" : ""} ${
          isOpen && sliderInterface ? " ml-96" : ""
        }${isOpen && !sliderInterface ? "ml-32" : ""}`}
        style={{ transition: "1s ease-in-out" }}
      >
        <div className="sidebar-popup-space">
          <div className={menu ? "navbar-menu" : "navbar-menu-close"}>
            {!arrowVisible ? (
              menu ? (
                <div className="menuIcon">
                  <MenuIcon onClick={handleMenu} onMouseEnter={handleEnter} />
                  {/* <span className="title2">Lock sidebar open</span> */}
                </div>
              ) : (
                " "
              )
            ) : (
              <div className="forwardIcon">
                <ArrowForwardIosIcon
                  onMouseLeave={handleLeave}
                  onClick={handleMenu}
                  style={{
                    fontSize: "16px",
                    position: "absolute",
                    top: "6px",
                    left: "-2px",
                  }}
                />
                <ArrowForwardIosIcon
                  onMouseLeave={handleLeave}
                  onClick={handleMenu}
                  style={{ fontSize: "16px" }}
                />
                <span className="title2">Lock sidebar open</span>
              </div>
            )}
            <div className="submenu">
              <p>
                <CloseIcon
                  style={{
                    fontSize: "16px",
                    strokeWidth: "0.786px",
                    marginRight: "5px",
                    marginTop: "-4px",
                  }}
                />
                Language settings
              </p>
              <div className="submenu-buttons">
                <button
                  className="submenu-button-accuracy"
                  onClick={handleSlider}
                >
                  Accuracy
                </button>
                <div className="submenu-region" onClick={handleLanguage}>
                  {country}
                  {show ? (
                    <KeyboardArrowUpIcon
                      style={{ fontSize: "25px", float: "right" }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={{ fontSize: "25px", float: "right" }}
                    />
                  )}
                </div>
                {show ? (
                  <div className="drop-down">
                    {selectedCountries.map((countryId) => (
                      <div key={countryId} id={countryId} onClick={handleClick}>
                        {countries[countryId]}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            scroll
              ? "right-side-language"
              : !open
              ? "language-interface-scroll-open"
              : "language-interface-scroll-close"
          }
        >
          {!scroll && (
            <div className="flex grow items-center max-w-xl p-2 px-3 my-3 w-full rounded-full justify-between bg-[#EBEBEB]">
              <input
                type="text"
                className="bg-transparent grow"
                placeholder="Search Targeted Language"
                value={targetedLanguageSearch}
                onChange={(e) => dispatch(setTargetSearchVal(e.target.value))}
              />
              <Search />
            </div>
          )}
          {sliderInterface ? (
            <CustomizedSlider handleLanguage={handleLanguage} />
          ) : (
            selectedCountries.length > 0 && (
              <div className="flex flex-row w-full items-center">
                {/* <button
                  onClick={scrollLeft}
                  className="bg-gray-200 p-2 rounded h-3"
                >
                  {"<"}
                </button> */}
                <ArrowForwardIosIcon
                  onClick={scrollLeft}
                  className="rotate-180 cursor-pointer hover:text-primaryColor -ml-4 mr-2"
                />
                <section
                  className="flex py-2 gap-4 overflow-y-auto w-full min-h-[85vh] scroll-smooth"
                  ref={scrollContainerRef}
                >
                  {selectedCountries.map((countryId) => {
                    return (
                      <CustomizedCountry
                        key={countryId + "list"}
                        country={countryId.split("-").at(-1)}
                        open={open}
                        isOpen={isOpen}
                      />
                    );
                  })}
                </section>
                {/* <button
                  onClick={scrollRight}
                  className="bg-gray-200 p-2 rounded h-3"
                >
                  {">"}
                </button> */}
                <ArrowForwardIosIcon
                  onClick={scrollRight}
                  className={`cursor-pointer hover:text-primaryColor  ${
                    isOpen ? "-ml-3 mr-2" : "ml-3 -mr-3"
                  }`}
                />
              </div>
            )
          )}
          {!scroll && (
            <div className="flex items-center mt-3 w-full justify-between">
              <span
                onClick={() => {
                  if (languageInterface) {
                    handleSlider();
                  } else if (sliderInterface) {
                    navigate(-1);
                  }
                }}
                className="flex justify-between gap-2 items-center"
              >
                <MoveLeft /> <span>Go back</span>
              </span>
              <div className="flex items-center gap-2">
                <p className="grow max-w-sm text-end text-muted">
                  Click save all to update languages and click Save in DNS
                  section to update your website
                </p>
                <button
                  onClick={() => {
                    selectedCountries.forEach((countryId) => {
                      const country = countryId.split("-").at(-1);
                      if (unsavedLangsSelection[country]) {
                        unsavedLangsSelection[country]["add"].forEach(
                          (lang) => {
                            dispatch(addLanguage(lang));
                          }
                        );
                        unsavedLangsSelection[country]["remove"].forEach(
                          (lang) => {
                            dispatch(removeLanguage(lang));
                          }
                        );
                      }
                      dispatch(saveCountryEdits(country));
                    });
                    addAllRegions();
                  }}
                  className="submit text-sm font-normal mb-0 mt-0 mr-4"
                  mr-4
                >
                  Save All Changes
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Language;
export function CountryWithFlag({ countryId }) {
  const country = countries[countryId];
  const dispatch = useDispatch();
  return (
    <button
      onClick={() => {
        dispatch(setHighlightCountry(countryId));
      }}
      key={countryId}
      className="flex text-start justify-start w-full transition-all  items-center gap-1 hover:bg-slate-100"
    >
      {/* <img
        className="aspect-square rounded-full w-6 object-center object-cover"
        src={languagesWithFlags[countryId]}
        alt={`${country} flag`}
      /> */}
      <span
        className="font-normal capitalize line-clamp-1 text-lg py-1.5 px-3"
        style={{ fontFamily: "Lato", lineHeight: "16px" }}
      >
        {" "}
        {country}
      </span>
    </button>
  );
}
