import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sentOtpForLogin, sentOtpForRegister } from "../../redux/apiCalls";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import "./Login.css";
import { Zoom } from "@mui/material";
import OtpVerify from "../signup/otpverify/OtpVerify";
import axios from "axios";

import {
  auth,
  googleprovider,
  facebookprovider,
  microsoftprovider,
} from "../../Firebase";
import localStorage from "redux-persist/es/storage";
import { useDispatch } from "react-redux";
import { setGetSSO } from "../../redux/userRedux";
const schema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
});

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [verify, setVerify] = useState(false);
  const [userName, setUserName] = useState("");
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: schema,
      validateOnChange: true,
      validateOnBlur: true,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values) => {
        console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
        //// to get rid of all the values after submitting the form
      },
    });

  const ssodata = localStorage.getItem("datasso");
  useEffect(() => {
    const jwt = async () => {
      const jwtToken = await localStorage.getItem("jwtToken");
      console.log(jwtToken, "jwt");
      if (jwtToken) {
        setShow(true);
        navigate("/Language-&-Region");
        // window.location.href = "https://app.glocalise.net/chatbot";
      } else {
        setShow(false);
        // window.location.href = "https://www.glocalise.net";
      }
    };
    jwt();
  }, []);
  const login = async () => {
    const jwtToken = await localStorage.getItem("jwtToken");
    try {
      const responseData = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/ssologin/getssodata`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (responseData.status === 200) {
        let name = responseData.data.name;
        if (name) {
          localStorage.setItem(
            "datasso",
            JSON.stringify({
              name: responseData.data.name,
              email: responseData.data.email,
            })
          );
        } else {
          localStorage.setItem(
            "datasso",
            JSON.stringify({
              name: "User",
              email: responseData.data.email,
            })
          );
        }

        enqueueSnackbar("successfully login", {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        navigate("/Language-&-Region");

        // window.location.href = "https://app.glocalise.net/chatbot";
      } else {
        enqueueSnackbar("Create your Account ", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        navigate("/signup");

        // window.location.href = "https://www.glocalise.net/signup";
      }
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();
  const handleRequestOtp = async () => {
    const res = await sentOtpForLogin(values);
    if (res.status === 200) {
      if (res.data.success) {
        enqueueSnackbar("Otp send successfully to your email", {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        setVerify(true);
      } else {
        enqueueSnackbar(res.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
      }
    }
  };

  const handleAuth = () => {
    try {
      if (!userName) {
        auth
          .signInWithPopup(googleprovider)
          .then((result) => {
            axios
              .post(
                `${process.env.REACT_APP_SERVER_URL}/api/ssologin/setssodata`,
                {
                  name: result.user.displayName,
                  email: result.user.email,
                }
              )
              .then((data) => {
                console.log(data, "data.....");

                if (data.status === 200) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: data.data.data.name,
                      email: data.data.data.email,
                    })
                  );
                  localStorage.setItem("jwtToken", data.data.data.token);
                  dispatch(setGetSSO(true));
                  enqueueSnackbar("Welcome back", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });

                  navigate("/Language-&-Region");
                  // window.location.reload();
                }
              })
              .catch((error) => {
                if (error.response.status === 302) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: error.response.data.data.name,
                      email: error.response.data.data.email,
                    })
                  );
                  enqueueSnackbar("successfuflly login", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/Language-&-Region");

                  // window.location.href = "https://app.glocalise.net/chatbot";
                }
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (userName) {
        auth
          .signOut()
          .then(() => {
            // setUser("");
          })
          .catch((err) => alert(err.message));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const facebook = () => {
    try {
      if (!userName) {
        auth
          .signInWithPopup(facebookprovider)
          .then((result) => {
            axios

              .post(
                `${process.env.REACT_APP_SERVER_URL}/api/ssologin/setssodata`,
                {
                  name: result.user._delegate.displayName,
                  email: result.user._delegate.email,
                }
              )

              .then((data) => {
                if (data.status === 200) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: data.data.data.name,
                      email: data.data.data.email,
                    })
                  );
                  localStorage.setItem("jwtToken", data.data.token);
                  enqueueSnackbar("Welcome back", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/Language-&-Region");
                  window.location.reload();
                }
              })
              .catch((error) => {
                if (error.response.status === 302) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: error.response.data.data.name,
                      email: error.response.data.data.email,
                    })
                  );
                  enqueueSnackbar("successfuflly login", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/Language-&-Region");

                  // window.location.href = "https://app.glocalise.net/chatbot";
                }
              });
          })
          .catch((error) => {
            alert(error.message);
          });
      } else if (userName) {
        auth
          .signOut()
          .then(() => {})
          .catch((err) => alert(err.message));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const microsoft = () => {
    try {
      if (!userName) {
        auth
          .signInWithPopup(microsoftprovider)
          .then((result) => {
            axios
              .post(
                `${process.env.REACT_APP_SERVER_URL}/api/ssologin/setssodata`,
                {
                  name: result.user._delegate.displayName,
                  email: result.user._delegate.email,
                }
              )

              .then((data) => {
                if (data.status === 200) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: data.data.data.name,
                      email: data.data.data.email,
                    })
                  );
                  localStorage.setItem("jwtToken", data.data.token);
                  enqueueSnackbar("Welcome back", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/Language-&-Region");
                  window.location.reload();
                }
              })
              .catch((error) => {
                if (error.response.status === 302) {
                  localStorage.setItem(
                    "datasso",
                    JSON.stringify({
                      name: error.response.data.data.name,
                      email: error.response.data.data.email,
                    })
                  );
                  enqueueSnackbar("successfuflly login", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    TransitionComponent: Zoom,
                  });
                  navigate("/Language-&-Region");

                  // window.location.href = "https://app.glocalise.net/chatbot";
                }
              });
          })
          .catch((error) => {
            alert(error.message);
          });
      } else if (userName) {
        auth
          .signOut()
          .then(() => {})
          .catch((err) => alert(err.message));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const linkedin = () => {
    // window.open("process.env.REACT_APP_SERVER_URL/api/auth/linkedin", "_self");
    // window.location.href = "http://localhost:8800/api/auth/linkedin";
  };

  return (
    <>
      {!verify ? (
        <div>
          <div className="signup-wrapper">
            <div className="brand">
              <Link to="/">
                {" "}
                <img
                  src="/images/logo/Vector 10.png"
                  alt=""
                  className="signup-brand-img"
                />
              </Link>
            </div>
            <div className="signup-right">
              <p className="signin">Create an Account? </p>

              <p className="link-sign">
                <Link
                  to={"/signup"}
                  style={{ color: "#EE9644", textDecoration: "none" }}
                >
                  Sign up
                </Link>
              </p>
            </div>
          </div>
          <div className="signup-container">
            <div className="signup-box">
              <h2 className="signup-box-head">Let's Login Backup</h2>
              <p className="signup-box-para">
                Login Back Glocali.se by entering your email
              </p>

              <div className="form-signup">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="label-form" className="label-form">
                    Email
                  </label>
                  <input
                    type="email"
                    className="input-signup"
                    placeholder="test1@gmail.com"
                    required
                    name="email"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {values.email !== "" ? (
                    <button
                      className="continue-signup"
                      type="submit"
                      onClick={() => {
                        handleRequestOtp();
                        login();
                      }}
                    >
                      Login with email &gt;
                    </button>
                  ) : (
                    <button className="continue-signup" type="submit" disabled>
                      Login with email &gt;
                    </button>
                  )}
                </form>
              </div>
              <div className="form-divider">
                <div className="left-line"></div>

                <div className="middle-content">or Login with</div>

                <div className="right-line"></div>
              </div>
              <div className="login-brand">
                <img
                  src="/images/other/Group 2022.png"
                  alt=""
                  className="login-brand-logo cursor-pointer"
                  onClick={handleAuth}
                />
                <img
                  src="/images/other/Group 2021.png"
                  alt=""
                  className="login-brand-logo cursor-pointer"
                />
                <img
                  src="/images/other/Group 2020.png"
                  alt=""
                  className="login-brand-logo cursor-pointer"
                  onClick={microsoft}
                />
                <img
                  src="/images/other/Group 2019.png"
                  alt=""
                  className="login-brand-logo cursor-pointer"
                  onClick={linkedin}
                />
                <img
                  src="/images/other/Group 2018.png"
                  alt=""
                  className="login-brand-logo cursor-pointer"
                  onClick={facebook}
                />
              </div>
            </div>
          </div>
          <div className="signup-sidebar">
            <img src="/images/other/Group 465.png" alt="contactus" />
            <div className="signup-sidebar-content">Contact us</div>
          </div>
        </div>
      ) : (
        <OtpVerify
          url="/validateotp"
          setVerify={setVerify}
          values={values}
          initialValues={initialValues}
        />
      )}
    </>
  );
};

export default Login;
