import React from 'react'

function SecEmail() {
  return (
    <div>
      bie
    </div>
  )
}

export default SecEmail
