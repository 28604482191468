import React from 'react'
import "./newPassword.css";

function NewPassword({setNewPassVisible,setEmailChangeVisible,passPopper}) {
  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center upper-email">
        <div className="heading-email">New Password</div>
        <i class="bi bi-x-lg cur" onClick={() => setNewPassVisible(false)}></i>
      </div>

      <div className="condition-pass">
        <div className="circle-pass">
          <i class="bi bi-exclamation-lg"></i>
        </div>
        <div className="d-flex flex-column w-100">
          <div className="new-pass">The new password must</div>
          <div>- Contain atleast 6 characters</div>
          <div>- Be different from your current password</div>
        </div>
      </div>

      <div className="d-flex flex-column inside-email">
        <div className="text-email">New password</div>
        <input type="text" className="input-email" />
      </div>
      <div className="forgot-pass">Forgotten password?</div>
      <div className="d-flex flex-column inside-email">
        <div className="text-email">Repeat password</div>
        <input type="text" className="input-email" />
      </div>
      {/* <div className="d-flex flex-column inside-email">
        <div className="text-email">Confirm password</div>
        <input type="text" className="input-email" />
      </div> */}
      <div className='d-flex flex-row-reverse end-email'>
        {passPopper ? ( <button className='submit-pass' onClick={() =>{setNewPassVisible(false)}}>
            Submit
        </button>) : ( <button className='submit-pass' onClick={() =>{setEmailChangeVisible(true); setNewPassVisible(false)}}>
            Submit
        </button>)}
     
        <button className='cancel-pass' onClick={() => setNewPassVisible(false)}>
            Cancel
        </button>
        
      </div>
    </div>
  )
}

export default NewPassword
