// import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// import './App.css';
// import Frame from './pages/frame/Frame';
// import Home from './pages/home/Home';
// import Question from './pages/questions/Question';
// import OtpVerify from './pages/signup/otpverify/OtpVerify';
// import Signup from './pages/signup/Signup';
// import Chatbot from './pages/chatbot/Chatbot';
// import DataManagement from './pages/DataManagement';
// import Settings from './pages/settings/Settings';
// import University from './pages/University';
// import TimeSpent from './pages/TimeSpent';
// import Dashboard from './pages/Dashboard';
// import Hero from './pages/Hero'
// import Index from './pages/start/Index'
// import Chatmonitoring from "./pages/chatbot/Chatmonitoring";
// import Chatpage from "./pages/agent/Chatpage";
// function App() {
//   // const user =  useSelector((state)=>state.user ? state.user.currentUser.token : "" )
//   // console.log(user)
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const getUser = () => {
//       fetch("process.env.REACT_APP_SERVER_URL/api/auth/login/success", {
//         method: "GET",
//         credentials: "include",
//         headers: {
//           "Accept": "application/json",
//           "Content-Type": "application/json",
//           "Access-Control-Allow-Credentials": true,
//         },
//       }).then(response => {
//         if (response.status === 200) return response.json();
//         throw new Error("authentication has been failed!");
//       }).then(resObject => {
//         setUser(resObject.user)
//       }).catch(err => {
//         console.log(err);
//       })
//     };
//     getUser();
//   }, []);
//   console.log(user);
//   const [domain, setDomain] = useState("")
//   return (
//     <div>
//       <Router>
//         <Routes>
//           <Route path='/' element={<Home setDomain={setDomain} domain={domain} />} />
//           <Route path='/frame' element={<Frame setDomain={setDomain} domain={domain} />} />
//           <Route path='/signup' element={<Signup />} />
//           {/* <Route path='/otp-verify' element={<OtpVerify />} /> */}
//           <Route path='/question' element={<Question />} />
//           <Route path="/chatbot" element={<Chatbot />} />
//           <Route path="/datamanage" element={<DataManagement />} />
//           <Route path="/settings" element={<Settings />} />
//           <Route path="/university" element={<University />} />
//           <Route path="/timespent" element={<TimeSpent />} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/Language-&-Region" element={<Hero />} />
//           <Route path="/start" element={<Index />} />
//           <Route path="/admin-setting" element={<Chatmonitoring />} />
//           <Route path="/agent" element={<Chatpage />} />
//         </Routes>
//       </Router>
//     </div>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Frame from "./pages/frame/Frame";
import DemoFrame from "./pages/frame/DemoFrame";
import Home from "./pages/home/Home";
import Question from "./pages/questions/Question";
// import OtpVerify from "./pages/signup/otpverify/OtpVerify";
import TimeSpent2 from "./pages/payment/TimeSpent";
import Signup from "./pages/signup/Signup";
import Chatbot from "./pages/chatbot/Chatbot";
import DataManagement from "./pages/datamangement/datamangement";
import Settings from "./pages/settings/Settings";
import University from "./pages/University";
import TimeSpent from "./pages/TimeSpent";
import Dashboard from "./pages/Dashboard";
import Hero from "./pages/Hero";
import Index from "./pages/start/Index";
import Chatmonitoring from "./pages/chatbot/Chatmonitoring";
import Chatpage from "./pages/agent/Chatpage";
import Blogspage from "./components/Blogspage";
import Language from "./pages/language/Language";
import Blog from "./pages/blog/Index";
import Services from "./pages/Services/Index";
import MainPage1 from "./pages/components/MainPage";
import Login from "./pages/login/Login";
import { useNavigate } from "react-router-dom";
import { loginSuccess } from "./redux/userRedux";
import { getDomain, getSupportedLanguages } from "./redux/apiCalls";
import {
  setDefautltAccuracy,
  setDomainValues,
  setInputText,
  setLanguagesList,
  setRefreshDoamin,
  setSearchValue2,
  setSelectedRegionsWithoutCheck,
  setTopLevelHostNameId,
} from "./redux/dataSlices";
import { enqueueSnackbar } from "notistack";
import { Zoom } from "@mui/material";
import { getAdminBalance } from "./redux/apiCalls";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AppRouter() {
  const dispatch = useDispatch();
  const navi = useNavigate();
  const location = useLocation();
  const refreshDomain = useSelector((state) => state.data.refreshDomain);
  const user = useSelector((state) => state.user.currentUser);
  const checkuser = JSON.parse(localStorage.getItem("datasso"));
  const getSSO = useSelector((state) => state.user.getSSO);
  const [balance, setBalance] = useState(0);
  const handleBalanceUpdate = () => {
    getAdminBalance()
      .then((res) => {
        setBalance(res.data);
      })
      .catch((err) => console.error("Error fetching balance:", err));
  };
  useEffect(() => {
    handleBalanceUpdate();
  }, []);
  // useEffect(() => {
  //   const getUser = () => {
  //     fetch(
  //       "http://localhost:8800/api/auth/login/success",

  //       {
  //         method: "GET",
  //         credentials: "include",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Credentials": true,
  //         },
  //       }
  //     )
  //       .then((response) => {
  //         if (response.status === 200) return response.json();
  //         throw new Error("authentication has been failed!");
  //       })
  //       .then((resObject) => {
  //         setUser(resObject.user);
  //         localStorage.setItem(
  //           "datasso",
  //           JSON.stringify({
  //             name: resObject.user.displayName,
  //             email: resObject.user.emails[0].value,
  //           })
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  // vertical: "top",
  //   getUser();
  // }, []);
  useEffect(() => {
    if (user && refreshDomain) {
      getDomain()
        .then((res) => {
          const { success, data } = res.data;
          if (success && data) {
            enqueueSnackbar("Domain status updated", {
              variant: "default",
              anchorOrigin: {
                horizontal: "center",
                vertical: "top",
              },
              TransitionComponent: Zoom,
            });
            dispatch(setDomainValues(data));
            dispatch(setRefreshDoamin(false));
          }
        })
        .catch((e) => {
          enqueueSnackbar("Error in updating domain status", {
            variant: "error",
            anchorOrigin: {
              horizontal: "center",
            },
            TransitionComponent: Zoom,
          });

          dispatch(setInputText(""));
          dispatch(setSearchValue2(""));
          dispatch(setTopLevelHostNameId(undefined));
        });
    }
  }, [user, refreshDomain]);
  useEffect(() => {
    if (!getSSO) return;
    if (user) {
      getSupportedLanguages()
        .then(({ data: res }) => {
          if (res && res.success) {
            dispatch(setLanguagesList(res.data));
            // enqueueSnackbar("Languages List Updated", {
            //   variant: "default",
            //   anchorOrigin: {
            //     horizontal: "center",
            //     vertical: "top",
            //   },
            //   TransitionComponent: Zoom,
            // });
          }
        })
        .catch((e) => {
          // enqueueSnackbar("Languages Updation failed", {
          //   variant: "default",
          //   anchorOrigin: {
          //     horizontal: "center",
          //     vertical: "top",
          //   },
          //   TransitionComponent: Zoom,
          // });
        });
    }
  }, [getSSO]);

  useEffect(() => {
    if (!getSSO) return;
    let token = localStorage.getItem("jwtToken");
    const login = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/ssologin/getssodata`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          const responseData = await response.json();
          let name = responseData.data.name;
          console.log(responseData.data);
          dispatch(loginSuccess(responseData.data));
          dispatch(
            setDefautltAccuracy(responseData.data.languageaccuracy ?? 100)
          );
          dispatch(
            setSelectedRegionsWithoutCheck({
              regions: responseData.data.regions,
              countries: responseData.data.regionsAndLangs,
              activated: responseData.data.activated,
            })
          );
          // dispatch(setCountriesForLanguageSelection());

          if (name) {
            localStorage.setItem(
              "datasso",
              JSON.stringify({
                name: responseData.data.name,
                email: responseData.data.email,
              })
            );
          } else {
            localStorage.setItem(
              "datasso",
              JSON.stringify({
                name: "User",
                email: responseData.data.email,
              })
            );
          }
          if (
            location.pathname === "/login" ||
            location.pathname === "/signin"
          ) {
            navi("/Language-&-Region");
          }
          // window.location.href = "https://app.glocalise.net/chatbot";
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (token) {
      login();
    }
  }, [getSSO]);
  const [domain, setDomain] = useState("");
  return (
    <Routes>
      <Route
        path="/"
        element={<Home setDomain={setDomain} domain={domain} />}
      />
      <Route
        path="/frame"
        element={<Frame setDomain={setDomain} domain={domain} />}
      />
      <Route path="/start" element={<Index />} />

      <Route path="/demoframe" element={<DemoFrame setDomain={setDomain} />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/services" element={<Services />} />
      {checkuser ? (
        <>
          <Route path="/question" element={<Question />} />
          <Route path="/chatbot" element={<Chatbot />} />
          <Route path="/datamanage" element={<DataManagement />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/university" element={<University />} />
          <Route path="/timespent" element={<TimeSpent />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/Language-&-Region"
            element={
              <Hero
                balance={balance}
                handleBalanceUpdate={handleBalanceUpdate}
              />
            }
          />
          {/* <Route path="/start" element={<Index />} /> */}
          <Route path="/admin-setting" element={<Chatmonitoring />} />
          <Route path="/agent" element={<Chatpage />} />
          <Route path="/blogspage" element={<Blogspage />} />
          <Route path="/language" element={<Language />} />
          <Route path="/mainpage" element={<MainPage1 />} />
          <Route
            path="/payment"
            element={
              <TimeSpent2
                balance={balance}
                handleBalanceUpdate={handleBalanceUpdate}
              />
            }
          />
        </>
      ) : (
        <>
          <Route path="/signup" element={<Signup />} />{" "}
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Signup />} />
        </>
      )}
      {/* <Route path='/otp-verify' element={<OtpVerify/>} /> */}
    </Routes>
  );
}
export default AppRouter;
