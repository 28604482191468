import { TabContext, TabList, TabPanel } from "@mui/lab";
import Navbar from "../navbar/Navbar";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import "./blog.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const PreviousBtn = (props) => {
  const { onClick } = props;
  return (
    <div className="carousel1-Slick-prev" onClick={onClick}>
      <ChevronLeftIcon
        className="iconLeft"
        style={{
          color: "white",
          zIndex: "2",
          fontSize: "1.8rem",
          fontWeight: "400",
        }}
      />
    </div>
  );
};

const NextBtn = (props) => {
  const { onClick } = props;
  return (
    <div className="carousel1-Slick-next" onClick={onClick}>
      <ChevronRightIcon
        className="iconRight"
        style={{
          color: "white",
          zIndex: "2",
          fontSize: "1.8rem",
          fontWeight: "400",
        }}
      />
    </div>
  );
};

const Blog = ({ blogref }) => {
  const [value, setValue] = React.useState("1");
  const [selected, setSelected] = useState("unSelected");
  console.log(selected);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = () => {
    setSelected("Selected");
  };

  const settings1 = {
    dots: true,
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    arrows: true,
    infinite: true,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <div className="blog-container" ref={blogref}>
      <TabContext value={value}>
        <div className="blog-tab-container">
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            className="tab"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tab label="Blogs" value="1" onClick={handleClick} />
            <Tab label="Reviews" value="2" onClick={handleClick} />
            <Tab label="FAQ" value="3" onClick={handleClick} />
          </TabList>
          <TabPanel value="1">
            <div className="tabpanel-container">
              <span className="d-flex justify-content-end">
                <Link to="/blogspage" style={{ textDecoration: "none" }}>
                  <div className="read-more">
                    <span>Read More</span>
                    <i
                      className="bi bi-arrow-right"
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </div>
                </Link>
              </span>
              <a
                href="https://glocalise.notion.site/3855c0166fbc40f5854390e04da607ca"
                target="/blank"
                style={{ textDecoration: "none" }}
              >
                <div className="tabpanel-wrapper">
                  <div className="tab-q">How to Glocalise website?</div>
                </div>
              </a>
              <a
                href="https://glocalise.notion.site/a54773f617bd47a9bb547b0f922465f1"
                target="/blank"
                style={{ textDecoration: "none" }}
              >
                <div className="tabpanel-wrapper">
                  <div className="tab-q">Local Business in Global Markets</div>
                </div>
              </a>
              <a
                href="https://glocalise.notion.site/82adf7ee40cb4d9eb1ba523dfbb4646d"
                target="/blank"
                style={{ textDecoration: "none" }}
              >
                <div className="tabpanel-wrapper">
                  <div className="tab-q">Sunset of google Cookies!</div>
                </div>
              </a>

              <a
                href="https://glocalise.notion.site/97e3199a3b0a457f8f9cc75a004c9a20"
                target="/blank"
                style={{ textDecoration: "none" }}
              >
                <div className="tabpanel-wrapper">
                  <div className="tab-q">What Is Link Building?</div>
                </div>
              </a>
              <a
                href="https://glocalise.notion.site/e8d32b24500a4ac7af89272a61c47648"
                target="/blank"
                style={{ textDecoration: "none" }}
              >
                <div className="tabpanel-wrapper">
                  <div className="tab-q">
                    Why making a website Multilingual is important?
                  </div>
                </div>
              </a>
              <a
                href="https://glocalise.notion.site/fca0676d201a44e9a46ad5097c29219c"
                target="/blank"
                style={{ textDecoration: "none" }}
              >
                <div className="tabpanel-wrapper">
                  <div className="tab-q">Why do You Need Multilingual SEO?</div>
                </div>
              </a>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <Slider {...settings1}>
              <div className="review-main-container">
                <div className="review-container">
                  <div className="left">
                    <div className="card1">
                      <div className="imgcontainer">
                        <img
                          className="img-card"
                          alt="hello"
                          src="../header_images/Cheuk Powell.png"
                        />
                      </div>
                      <div className="head">Cheuk Powell</div>
                      <div className="para">Product Manager</div>
                      <img
                        className="branding"
                        alt="branding"
                        src="/images/other/brand.png"
                      />
                    </div>
                    <img
                      className="card2"
                      alt="imge"
                      src="/images/other/Rectangle 334.png"
                    />
                  </div>
                  <div className="right">
                    "This SaaS revolutionized my business. By translating my
                    website into multiple languages, it opened doors to global
                    markets, driving new business and significantly enhancing
                    our SEO. A game-changer for startups!"
                  </div>
                  <div className="right-corner">
                    <img
                      className="right-corner-top"
                      alt="hello"
                      src="/images/other/Group 439.png"
                    />
                    <div className="right-corner-bottom">September 2023</div>
                  </div>
                </div>
                <div></div>
              </div>

              <div className="review-main-container">
                <div className="review-container">
                  <div className="left">
                    <div className="card1">
                      <div className="imgcontainer">
                        <img
                          className="img-card"
                          alt="hello"
                          src="../header_images/Cyron Anderson.png"
                        />
                      </div>
                      <div className="head">Cyron Anderson</div>
                      <div className="para">CEO & Founder</div>
                      <img
                        className="branding"
                        alt="branding"
                        src="/images/other/Vector.png"
                      />
                    </div>
                    <img
                      className="card2"
                      alt="imge"
                      src="/images/other/Rectangle 334.png"
                    />
                  </div>
                  <div className="right">
                    "This SaaS was a game-changer. Translating my website into
                    multiple languages unlocked global markets, attracting new
                    clients, expanding reach, and supercharging SEO. A
                    must-share for fellow entrepreneurs!"
                  </div>
                  <div className="right-corner">
                    <img
                      className="right-corner-top"
                      alt="hello"
                      src="/images/other/Group 439.png"
                    />
                    <div className="right-corner-bottom">December 2022</div>
                  </div>
                </div>
                <div></div>
              </div>

              <div className="review-main-container">
                <div className="review-container">
                  <div className="left">
                    <div className="card1">
                      <div className="imgcontainer">
                        <img
                          className="img-card"
                          alt="hello"
                          src="../header_images/Derron Russell.png"
                        />
                      </div>
                      <div className="head">Derron Russell</div>
                      <div className="para">Manging Director</div>
                      <img
                        className="branding"
                        alt="branding"
                        src="/images/other/Vector (7).png"
                      />
                    </div>
                    <img
                      className="card2"
                      alt="imge"
                      src="/images/other/Rectangle 334.png"
                    />
                  </div>
                  <div className="right">
                    "This SaaS transformed my website. With automated SEO
                    through multilingual translations, my business has reached
                    untapped global markets. In just 2 months, I've witnessed a
                    significant and promising difference."
                  </div>
                  <div className="right-corner">
                    <img
                      className="right-corner-top"
                      alt="hello"
                      src="/images/other/Group 439.png"
                    />
                    <div className="right-corner-bottom">February 2023</div>
                  </div>
                </div>
                <div></div>
              </div>

              <div className="review-main-container">
                <div className="review-container">
                  <div className="left">
                    <div className="card1">
                      <div className="imgcontainer">
                        <img
                          className="img-card"
                          alt="hello"
                          src="../header_images/Nicklas Watson.png"
                        />
                      </div>
                      <div className="head">Nicklas Watson</div>
                      <div className="para">Business Partner</div>
                      <img
                        className="branding"
                        alt="branding"
                        src="/images/other/Vector (8).png"
                      />
                    </div>
                    <img
                      className="card2"
                      alt="imge"
                      src="/images/other/Rectangle 334.png"
                    />
                  </div>
                  <div className="right">
                    "This SaaS has been a game-changer. Initially targeting a
                    few regions, the results drove me to expand. Multilingual
                    website translation significantly broadened my reach,
                    attracting business from previously untouched regions, all
                    while enhancing SEO."
                  </div>
                  <div className="right-corner">
                    <img
                      className="right-corner-top"
                      alt="hello"
                      src="/images/other/Group 439.png"
                    />
                    <div className="right-corner-bottom">June 2023</div>
                  </div>
                </div>
                <div></div>
              </div>
            </Slider>
          </TabPanel>
          <TabPanel value="3" sx={{ marginTop: "90px" }}>
            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-head">
                  Do you support right-to-left languages?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion-para">
                  Glocali.se supports languages written from right to left,
                  including the languages that are Arabic, Hebrew, Aramaic,
                  Azeri, Dhivehi/Maldivian, Persian/Farsi, Urdu, Kurdish
                  (Sorani) and Farsi.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-head">
                  Can I customize my chatbot design?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion-para">
                  Apart from the multiple chatbot templates that we provide as
                  default. You can with just a little bit of CSS knowledge, add
                  your own unique touch to your chatbot by customizing it in the
                  way you want.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-head">
                  Can I customize the manual language selection button on the
                  website?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion-para">
                  We understand that every website is unique and has its own
                  design and way of working. That's why we've prioritized
                  providing a manual language selection option that can be
                  modified and beautified to fit the specific needs of your
                  website. To give your own unique touch
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-head">
                  How does Glocalise function?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion-para">
                  <li>
                    Glocali.se broadens business reach by parsing websites into
                    region-specific subdomains and enabling language
                    translations for each version
                  </li>
                  <li>
                    Glocali.se fixes the SEO to enhance website by optimizing
                    technical SEO for improved search engine visibility and
                    increased website reach automatically using AI
                  </li>
                  <li>
                    Our multilingual chatbot enables you to communicate with
                    customers in their native language, while also allowing you
                    to use your own language
                  </li>
                  <li>
                    Discover everything about your website with our stats and
                    analytics view, that other analytics platforms don’t reveal
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-head">
                  I don't have access to my website codes can I still use your
                  service?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion-para">
                  <li>Enter the domain name</li>
                  <li>Select the regions and language</li>
                  <li>
                    Connect the domain via DNS and done, Glocali.se get’s
                    activated on your website
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-head">
                  Do I need any technical knowledge to use your translation
                  service?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion-para">
                  No, you don’t need to know programming or coding in order to
                  activate glocali.se on your website, Activating glocali.se is
                  very simple, all you need to do is add DNS records for the
                  regions and subdomains you have selected in your domain and if
                  needed one our teammate will help you out to update the DNS
                  records, if you get any hurdle anywhere throughout the process
                </Typography>
              </AccordionDetails>
            </Accordion>
          </TabPanel>
        </div>
      </TabContext>
    </div>
  );
};

export default Blog;
