import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCountriesForLanguageSelection,
  toggleSubRegions,
} from "../../redux/dataSlices";
import { regionNames } from "../../data/regions";
import { subRegionNames } from "../../data/subRegions";

function ChildOne({ regions, region }) {
  const hotspotSubRegions = useSelector(
    (state) => state.data.hotspotSubRegions
  );
  const dispatch = useDispatch();
  const buttonStyle = {
    fontWeight: "550",
    backgroundColor: "#D7F3ED",
    fontSize: "15px",
    border: "none",
    borderRadius: "8px",
    padding: "10px",
    color: "#979B99",
    minWidth: "150px",
    marginBottom: "12px",
    whiteSpace: "break-spaces",
    outline: "0px solid #979B99",
    overflow: "hidden",
    margin: "5px",
    textAlign: "center",
  };
  function isSelected(key) {
    return hotspotSubRegions.includes(key);
  }
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        fontSize: "11px",
        color: "#646866",
        padding: "4px",
        paddingLeft: "14px", // editted
        overflowY: "hidden",
      }}
    >
      <h6>{regionNames[region]}</h6>
      {regions?.map((nestedRegion) => {
        const key = [region, nestedRegion].join("-");
        const selected = isSelected(key);
        return (
          <button
            onClick={() => {
              dispatch(toggleSubRegions(key));
              dispatch(setCountriesForLanguageSelection());
            }}
            key={key}
            style={{
              ...buttonStyle,
              backgroundColor: selected ? "#D7F3ED" : "white",
              outline: selected ? "none" : "1px solid #979B99",
            }}
          >
            {subRegionNames[nestedRegion]}
          </button>
        );
      })}
    </div>
  );
}

export default ChildOne;
