import { createSlice, nanoid } from "@reduxjs/toolkit";
import regions from "../data/regions";
import nestedSubCountires, {
  nestedCountriesSubRegionWise,
} from "../data/nestedSubRegions";
import subRegions, { allSubRegions } from "../data/subRegions";
import zones from "../data/zones";
import countries from "../data/countries";

const initialState = {
  users: [],
  selectedCountries: [],
  languages: {},
  defaultLanguages: {},
  hotspotZones: [],
  activateAll: false,
  unsaved: {},
  saveRegionsToDB: false,
  refreshDomain: true,
  inputText: "",
  setSearchValue2: "",
  countries: {},
  hotspotRegions: [],
  hotspotSubRegions: [],
  defaultAccuracy: 100,
  countrySearch: "",
  targetedLanguageSearch: "",
  hightlightCountry: undefined,
  unsavedLangsSelection: {},
  jobSchema:[],
  topLevelHostNameId: undefined,
  chatPersonalization: {
    css: {
      background: "background:#39c6a4;",
    },
  },
};
function mergeCountries(countries, toMerge) {
  const valsToMerge = new Set(toMerge);
  const already = new Set(countries);
  valsToMerge.forEach((val) => already.add(val));
  return [...already];
}
export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setUserInformation: (state, action) => {
      state.users = [...state.users] || [];
      if (action.payload) {
        state.users.push(action.payload);
      }
    },
    setLanguagesList: (state, action) => {
      state.languages = action.payload.languages;
      state.defaultLanguages = action.payload.defaultLanguages;
    },
    setRefreshDoamin: (state, action) => {
      state.refreshDomain = action.payload;
    },
    togglesaveRegionsToDB: (state) => {
      state.saveRegionsToDB = !state.saveRegionsToDB;
    },
    haveUnsavedThings: (state, action) => {
      const { what, toSet = true } = action.payload;
      state.unsaved[what] = toSet;
    },
    activateAll: (state) => {
      state.activateAll = !state.activateAll;
      // if (state.activateAll) {
      //   Object.keys(state.countries).forEach((countryKey) => {
      //     state.countries[countryKey]?.languages?.forEach(
      //       (lang) => (lang.accuracy = 100)
      //     );
      //   });
      // }
    },
    setTopLevelHostNameId: (state, action) => {
      state.topLevelHostNameId = action.payload;
    },
    setDomainValues: (state, action) => {
      state.setSearchValue2 = action.payload.name;
      state.inputText = action.payload.name;
      state.topLevelHostNameId = action.payload._id;
    },
    resetUnSavedLangSelection: (state, action) => {
      delete state.unsavedLangsSelection[action.payload];
    },
    setUnsavedLangSelection: (state, action) => {
      const removed = action.payload.remove;
      if (state.unsavedLangsSelection[action.payload.countryId]) {
        if (removed) {
          state.unsavedLangsSelection[action.payload.countryId]["add"] =
            state.unsavedLangsSelection[action.payload.countryId]["add"].filter(
              (e) => {
                return e.id !== action.payload.langData.id;
              }
            );
          state.unsavedLangsSelection[action.payload.countryId]["remove"].push(
            action.payload.langData
          );
        } else {
          // if (!alreadySaved) {
          state.unsavedLangsSelection[action.payload.countryId]["remove"] =
            state.unsavedLangsSelection[action.payload.countryId][
              "remove"
            ].filter((e) => e.id !== action.payload.langData.id);
          // }
          state.unsavedLangsSelection[action.payload.countryId]["add"].push(
            action.payload.langData
          );
        }
      } else {
        if (removed) {
          state.unsavedLangsSelection[action.payload.countryId] = {
            remove: [action.payload.langData],
            add: [],
          };
        } else {
          state.unsavedLangsSelection[action.payload.countryId] = {
            add: [action.payload.langData],
            remove: [],
          };
        }
      }
    },
    setHighlightCountry: (state, action) => {
      state.hightlightCountry = action.payload;
    },
    setChatPersonalization: (state, action) => {
      switch (action.payload["type"]) {
        case "CSS":
          state.chatPersonalization["css"] = {
            ...state.chatPersonalization["css"],
            ...action.payload.data,
          };
          break;
        default:
          break;
      }
    },
    setSearchValue2: (state, action) => {
      state.setSearchValue2 = action.payload.toLowerCase();
    },
    setInputText: (state, action) => {
      state.inputText = action.payload;
    },
    setSearchVal: (state, action) => {
      state.countrySearch = action.payload;
    },
    setTargetSearchVal: (state, action) => {
      state.targetedLanguageSearch = action.payload;
    },
    setDomainForCountry: (state, action) => {
      const { countryKey, domain } = action.payload;
      if (!state.countries[countryKey]) {
        state.countries[countryKey] = {};
      }
      Object.assign(state.countries[countryKey], {
        domain,
      });
    },
    resetInputText: (state, action) => {
      state.inputText = action.payload;
      state.setSearchValue2 = action.payload;
    },
    setCountriesForLanguageSelection: (state, action) => {
      state.selectedCountries.forEach((e) => {
        const key = e.split("-").at(-1);
        let langs = [];
        if (state.countries[key] === undefined) {
          state.countries[key] = {};
          langs = [
            {
              isDefault: true,
              languageText:
                state.languages[state.defaultLanguages[key] || "en"] ||
                "English",
              id: state.defaultLanguages[key] || "en",
              accuracy: state.activateAll ? 100 : state.defaultAccuracy,
            },
          ];
          if (state.activateAll) {
            Object.entries(state.languages).forEach(([langKey, langVal]) => {
              if (state.defaultLanguages[key] !== langKey) {
                langs.push({
                  isDefault: false,
                  languageText: langVal,
                  accuracy: 100,
                  id: langKey,
                });
              }
            });
          }
          Object.assign(state.countries[key], {
            languages: langs,
            text: countries[key],
          });
        } else {
          if (state.countries[key].languages === undefined) {
            state.countries[key] = { ...state.countries[key] };
            langs = [
              {
                isDefault: true,
                languageText:
                  state.languages[state.defaultLanguages[key] || "en"] ||
                  "English",
                id: state.defaultLanguages[key] || "en",
                accuracy: state.activateAll ? 100 : state.defaultAccuracy,
              },
            ];
            Object.assign(state.countries[key], {
              languages: langs,
              text: countries[key],
            });
          }
          if (state.activateAll) {
            const alreadyAddedLangKeys =
              state.countries[key]?.languages?.map((e) => e.id) || [];
            langs = state.countries[key].languages || [];
            langs = langs.map((e) => {
              e.accuracy = 100;
              return e;
            });
            Object.entries(state.languages).forEach(([langKey, langVal]) => {
              if (!alreadyAddedLangKeys.includes(langKey)) {
                langs.push({
                  isDefault: false,
                  languageText: langVal,
                  accuracy: 100,
                  id: langKey,
                });
              }
            });
            Object.assign(state.countries[key], {
              languages: langs,
              text: countries[key],
            });
          }
        }
      });
      state.saveRegionsToDB = true;
    },
    toggleSubRegions: (state, action) => {
      let subRegionId = action.payload;
      const idx = state.hotspotSubRegions.indexOf(subRegionId);
      const keys = subRegionId.split("-");
      if (keys.length === 3) {
        subRegionId = [keys.at(1), keys.at(-1)].join("-");
      }
      const data = nestedCountriesSubRegionWise[subRegionId].map((e) =>
        [subRegionId, e].join("-")
      );
      if (idx === -1) {
        state.hotspotSubRegions.push(subRegionId);
        state.selectedCountries = mergeCountries(state.selectedCountries, data);
      } else {
        state.hotspotSubRegions.splice(idx, 1);
        state.selectedCountries = state.selectedCountries.filter(
          (e) => !data.includes(e)
        );
      }
    },
    toggleChildData: (state, action) => {
      const idx = state.childData.indexOf(action.payload);
      if (idx === -1) {
        state.childData.push(action.payload);
      } else {
        state.childData.splice(idx, 1);
      }
    },
    toggleHotSpotRegions: (state, action) => {
      const regionId = action.payload.regionId;
      const hotspotRegions = state.hotspotRegions.map((e) =>
        e.split("-").at(-1)
      );
      const idx = hotspotRegions.indexOf(regionId);
      // If the regionId is not found in hotspotRegions it adds the region and its sub-regions to the STATE 
      // Meaning it's clicked for the first Time
      if (idx === -1) {
        const key = regionId;
        state.hotspotRegions.push(key);
        state.hotspotSubRegions.push(
          ...allSubRegions[key].map((e) => `${key}-${e}`)
        );
        allSubRegions[regionId].forEach((reg) => {
          const key = `${regionId}-${reg}`;
          if (!nestedCountriesSubRegionWise[key]) return;
          const countryKeys = nestedCountriesSubRegionWise[key].map((e) =>
            [key, e].join("-")
          );
          if (!countryKeys) return;
          state.selectedCountries = mergeCountries(
            state.selectedCountries,
            countryKeys
          );
        });
      }
      // It removes the regions and it's sub-regions from the STATE
      // Meaning it's clicked for the Second time 
      else {
        state.hotspotRegions = state.hotspotRegions.filter((e) => {
          return e.split("-").at(-1) !== regionId;
        });
        const dd = allSubRegions[regionId].map((e) => `${regionId}-${e}`);
        const ss = state.selectedCountries.filter((e) => {
          const d = e.split("-");
          return d.length === 4 && d[1] === regionId;
        });
        state.selectedCountries = state.selectedCountries.filter(
          (e) => !ss.includes(e)
        );
        state.hotspotSubRegions = state.hotspotSubRegions.filter(
          (e) => !dd.includes(e)
        );
        allSubRegions[regionId].forEach((reg) => {
          const key = `${regionId}-${reg}`;
          if (!nestedCountriesSubRegionWise[key]) return;
          const countryKeys = nestedCountriesSubRegionWise[key].map((e) =>
            [key, e].join("-")
          );
          state.selectedCountries = state.selectedCountries.filter(
            (e) => !countryKeys.includes(e)
          );
        });
      }
    },
    
    toggleHotSpotZones: (state, action) => {
      try {
        const zoneId = action.payload;
        const idx = state.hotspotZones.indexOf(zoneId);
        if (idx === -1) {
          state.hotspotZones.push(zoneId);
          const regs = regions[zoneId].map((e) => `${zoneId}-${e}`);
          state.hotspotRegions.push(...regs);
          const data = [];
          regions[zoneId].forEach((region) => {
            const subRegionId = [zoneId, region].join("-");
            state.hotspotSubRegions.push(
              ...subRegions[subRegionId].map((e) => {
                const key = `${subRegionId}-${e}`;
                data.push(
                  ...nestedSubCountires[key].map((e) => [key, e].join("-"))
                );
                return [region, e].join("-");
              })
            );
          });
          state.selectedCountries = mergeCountries(
            state.selectedCountries,
            data
          );
          return;
        } else {
          state.hotspotZones.splice(idx, 1);
          let keysToFilter = regions[zoneId].map((e) => `${zoneId}-${e}`);
          state.hotspotRegions = state.hotspotRegions.filter(
            (e) => !keysToFilter.includes(e)
          );
          const keysToRemoveFromCountries = [];
          regions[zoneId].forEach((region) => {
            const subRegionId = [zoneId, region].join("-");

            keysToFilter.push(
              ...subRegions[subRegionId].map((e) => {
                const key = `${subRegionId}-${e}`;
                keysToRemoveFromCountries.push(
                  ...nestedSubCountires[key].map((e) => [key, e].join("-"))
                );
                return [region, e].join("-");
              })
            );
          });
          state.selectedCountries = state.selectedCountries.filter(
            (e) => !keysToRemoveFromCountries.includes(e)
          );
          state.hotspotSubRegions = state.hotspotSubRegions.filter(
            (e) => !keysToFilter.includes(e)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    // toggleData: (state, action) => {
    //   const { dataType, type, id } = action.payload;
    //   const data = new Set();
    //   const regionsData = new Set();
    //   const subRegionsData = new Set();
    //   switch (dataType) {
    //     case "ZONE":
    //       if (type === "remove") {
    //         state.hotspotZones = state.hotspotZones.filter((e) => e !== id);
    //       } else {
    //         state.hotspotZones.push(id);
    //       }
    //       const zonesOnlyId = state.hotspotZones;
    //       for (let i = 0; i < zonesOnlyId.length; i++) {
    //         const zoneId = zonesOnlyId[i];
    //         const regionsWithZone = regions[zoneId];
    //         regionsWithZone.forEach((regionKey) => {
    //           const subRegionKey = [zoneId, regionKey].join("-");
    //           regionsData.add(regionKey);
    //           subRegions[subRegionKey].forEach((subRegion) => {
    //             const countriesKey = [zoneId, regionKey, subRegion].join("-");
    //             subRegionsData.add(subRegion);
    //             nestedSubCountires[countriesKey].forEach((cKey) => {
    //               data.add(cKey);
    //             });
    //           });
    //         });
    //       }
    //       break;
    //     case "REGION":
    //       if (type === "remove") {
    //         state.hotspotRegions = state.hotspotRegions.filter((e) => e !== id);
    //       } else {
    //         state.hotspotRegions.push(id);
    //       }
    //       const regionsOnlyId = state.hotspotRegions;
    //       for (let i = 0; i < regionsOnlyId.length; i++) {
    //         const regionId = regionsOnlyId[i];
    //         const subRegionsWithId = allSubRegions[regionId];
    //         subRegionsWithId.forEach((subRegionKey) => {
    //           const subRegionId = [regionId, subRegionKey].join("-");
    //           subRegionsData.add(subRegionKey);
    //           subRegions[subRegionId].forEach((subRegion) => {
    //             const countriesKey = [regionId, subRegion].join("-");
    //             subRegionsData.add(subRegion);
    //             nestedCountriesSubRegionWise[countriesKey].forEach((cKey) => {
    //               data.add(cKey);
    //             });
    //           });
    //         });
    //       }
    //       break;
    //     case "SUBREGION":
    //       if (type === "remove") {
    //         state.hotspotSubRegions = state.hotspotRegions.filter(
    //           (e) => e !== id
    //         );
    //       } else {
    //         state.hotspotSubRegions.push(id);
    //       }
    //       break;
    //     default:
    //       break;
    //   }

    //   state.selectedCountries = [...data];
    //   state.hotspotRegions = [...regionsData];
    //   state.hotspotSubRegions = [...subRegionsData];
    // },
    clearAll: (state) => {
      state.userInformation = [];
      state.selectedCountries = [];
      state.hotspotZones = [];
      state.hotspotRegions = [];
      state.hotspotSubRegions = [];
      state.inputText = "";
      state.setSearchValue2 = "";
      state.targetedLanguageSearch = "";
      state.countrySearch = "";
      state.countries = {};
      state.hightlightCountry = undefined;
      state.unsavedLangsSelection = {};
      state.topLevelHostNameId = undefined;
      state.activateAll = false;
      state.refreshDomain = true;
      state = {
        ...state,
        ...initialState,
      };
      localStorage.removeItem("persist:root");
    },
    setSelectedRegionsWithoutCheck: (state, action) => {
      const { regions, countries, activated } = action.payload;
      const {
        hotspotZones,
        hotspotRegions,
        hotspotSubRegions,
        selectedCountries,
      } = regions;
      if (countries) {
        state.countries = { ...countries };
        state.selectedCountries = Object.keys(countries);
      } else {
        state.countries = { ...state.countries };
      }
      state.selectedCountries.forEach((e) => {
        const id = e.split("-").at(-1);
        const d = { ...state.countries[id], dontShow: true };
        state.countries[id] = d;
      });
      state.activateAll = activated || false;
      state.hotspotRegions = hotspotRegions || state.hotspotRegions;
      state.hotspotZones = hotspotZones || state.hotspotZones;
      state.hotspotSubRegions = hotspotSubRegions || state.hotspotSubRegions;
    },
    setDontShow: (state, action) => {
      const { countryKey, dontShow } = action.payload;
      state.countries[countryKey] = {
        ...state.countries[countryKey],
        dontShow,
      };
    },
    clearSelectedCountries: (state) => {
      state.selectedCountries = [];
      state.hotspotRegions = {};
      state.hotspotZones = {};
      state.hotspotSubRegions = {};
    },
    setSelectedCountries: (state, action) => {
      const val = action.payload;
      const idx = state.selectedCountries
        .map((e) => e.split("-").at(-1))
        .indexOf(val);
      if (idx === -1) {
        state.selectedCountries.push(val);
      } else {
        state.selectedCountries.splice(idx, 1);
      }
      if (state.countries[val]) {
        state.countries[val].dontShow = false;
      }
    },
    setStatusForHostNames: (state, action) => {
      const { countryKey, status } = action.payload;
      state.countries[countryKey] = {
        ...state.countries[countryKey],
        status,
      };
    },
    addLanguage: (state, action) => {
      const { countryId, language, accuracy, id } = action.payload;
      const country = state.countries[countryId];
      if (country) {
        state.unsaved["language"] = true;
        if (!country.languages) {
          country.languages = [];
        }

        if (country.languages.find((e) => e.id === id) === undefined) {
          country.languages.push({
            id,
            languageText: language,
            accuracy: accuracy,
          });
        }
      }
    },
    setDefaultLanguage: (state, action) => {
      const { countryId, langId } = action.payload;
      if (state.countries[countryId]) {
        const langs = state.countries[countryId].languages;
        const toDefaultIdx = langs.findIndex((lang) => lang.id === langId);
        const defaultNow = langs[0];
        defaultNow.isDefault = false;
        langs[toDefaultIdx].isDefault = true;
        langs[0] = langs[toDefaultIdx];
        langs[toDefaultIdx] = defaultNow;
        state.countries[countryId].languages = langs;
        state.unsaved["language"] = true;
      }
    },
    removeLanguage: (state, action) => {
      const { countryId, id } = action.payload;
      const country = state.countries[countryId];
      state.unsaved["language"] = true;
      if (country) {
        country.languages = country.languages.filter((language) => {
          return language.isDefault || language.id !== id;
        });
      }
    },
    setAccuracy: (state, action) => {
      const { countryId, id, accuracy } = action.payload;
      const country = state.countries[countryId];
      state.unsaved["language"] = true;
      if (country) {
        const language = country.languages.find((l) => l.id === id);
        if (language) {
          // Validate accuracy (optional)
          if (!isNaN(accuracy) && accuracy >= 0 && accuracy <= 100) {
            language.accuracy = accuracy;
          }
        }
      }
    },

    updateAccuracy: (state, action) => {
      const { countryId, id, accuracyIncrement } = action.payload;
      const country = state.countries[countryId];
      state.unsaved["language"] = true;
      if (country) {
        const language = country.languages.find((l) => l.id === id);

        if (language) {
          // Ensure the accuracy remains within a valid range (0% to 100%)
          const newAccuracy = Math.min(
            100,
            Math.max(0, language.accuracy + accuracyIncrement)
          );
          language.accuracy = newAccuracy;
        }
      }
    },
    setDefautltAccuracy: (state, action) => {
      state.defaultAccuracy = action.payload;
    },
    defaultAccuracy: (state, action) => {
      const { newAccuracy } = action.payload;
      // Loop through all countries and languages and set the default accuracy
      Object.keys(state.countries).forEach((countryId) => {
        const country = state.countries[countryId];
        country.languages?.forEach((language) => {
          if (language === undefined) {
            language = {};
          }
          language.accuracy = newAccuracy;
        });
      });
    },
    addCountry: (state, action) => {
      const { country, defaultLang, accuracy } = action.payload;
      const existingCountry = state.countries[country];
      state.unsaved["language"] = true;
      if (!existingCountry) {
        state.countries[country] = {
          text: country,
          languages: [],
        };
      }

      if (defaultLang) {
        const countryToUpdate = state.countries.find((c) => c.text === country);
        if (countryToUpdate) {
          countryToUpdate.languages.push({
            id: nanoid(),
            languageText: defaultLang,
            accuracy: accuracy,
          });
        }
      }
    },
    saveCountryEdits: (state, action) => {
      const countryKey = action.payload;
      if (countryKey && state.unsavedLangsSelection[countryKey]) {
        state.unsavedLangsSelection[countryKey] = { add: [], remove: [] };
      }
      state.unsaved["language"] = true;
    },
    updateJobSchema: (state,action)=>{
      console.log(action.payload);

      state.jobSchema = action.payload.map((job) => (
        {
          "@context": "https://schema.org/",
          "@type": "JobPosting",
          "title": job.Role,
          "description": job.descp,
          "identifier": {
            "@type": "PropertyValue",
            "name": "Glocalise",
            "value": "jobs"
          },
          "hiringOrganization" : {
            "@type": "Organization",
            "name": "Glocalise",
            "sameAs": `${process.env.REACT_APP_SERVER_URL}/Glocali-se-Careers-4fbcd0316c194a0c8d6d718173d140d0`,
            "logo": "https://www.glocali.se/favicon.ico.png"
          },
          "employmentType": "FULL_TIME",
          "datePosted": job.posted,
          "validThrough": job.expDate,
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "4598 W. 18th Ln",
              "addressLocality": "Yuma",
              "addressRegion": "AZ",
              "postalCode": "85364",
              "addressCountry": "US"
            }
          },
          "baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "USD",
            "value": {
              "@type": "QuantitativeValue",
              "minValue": job.Salary, // Note: This should be populated with the appropriate value from your data source
              "maxValue": job.maxSalary,
              "unitText": "MONTH"
            }
          },
          "responsibilities": job.responsibilities,
          "skills": job.skills,
          "qualifications": job.qualifications,
          "educationRequirements": job.educationRequirements,
          "experienceRequirements": job.experienceRequirements
        }
      ));
    }
  },
});
export const selectUsers = (state) => state.data.users;

export const selectedRegions = (state) => {
  const vals = new Set();
  const dd = {};
  state.data.hotspotSubRegions.forEach((e) => {
    const ids = e.split("-");
    const region = ids.at(-2);
    const subReg = ids.at(-1);
    if (dd[region] === undefined) {
      dd[region] = [subReg];
    } else {
      dd[region].push(subReg);
    }
  });
  Object.keys(dd).forEach((e) => {
    if (dd[e].length > 0) {
      vals.add(e);
    }
  });
  return [...vals];
};

export const getSelectedCountriesFromBoth = (state) => {
  const vals = new Set();
  state.data.selectedCountries.forEach((region) => {
    vals.add(region.split("-").at(-1));
  });
  state.user.currentUser?.regions?.selectedCountries.forEach((region) => {
    vals.add(region.split("-").at(-1));
  });
  return [...vals];
};
export const getSelectedCountriesFromSaved = (state) => {
  const vals = new Set();
  state.user.currentUser?.regions?.selectedCountries.forEach((region) => {
    vals.add(region.split("-").at(-1));
  });
  return [...vals];
};
export const getSelectedCountries = (state) => {
  const vals = new Set();
  state.data.selectedCountries.forEach((region) => {
    vals.add(region.split("-").at(-1));
  });
  return [...vals];
};

export const {
  setUserInformation,
  addLanguage,
  removeCountry,
  removeLanguage,
  setHighlightCountry,
  setAccuracy,
  updateAccuracy,
  saveCountryEdits,
  setSearchVal,
  setRefreshDoamin,
  addCountry,
  defaultAccuracy,
  resetInputText,
  setDefaultLanguage,
  toggleHotSpotRegions,
  setLanguagesList,
  toggleHotSpotZones,
  setDomainValues,
  setSelectedCountries,
  resetUnSavedLangSelection,
  setTopLevelHostNameId,
  toggleChildData,
  clearAll,
  setTargetSearchVal,
  setDontShow,
  setSelectedRegionsWithoutCheck,
  setStatusForHostNames,
  setSearchValue2,
  togglesaveRegionsToDB,
  toggleSubRegions,
  setInputText,
  setUnsavedLangSelection,
  setCountriesForLanguageSelection,
  setChatPersonalization,
  setDefautltAccuracy,
  setDomainForCountry,
  clearSelectedCountries,
  haveUnsavedThings,
  updateJobSchema,
  activateAll,
} = dataSlice.actions;

export default dataSlice.reducer;

// countries: [
//   {
//     id: 1,
//     text: '',
//     languages: [
//       { id: 1, text: 'Hindi', accuracy: 0 },
//       { id: 2, text: 'English', accuracy: 0 },
//       { id: 3, text: 'Albania', accuracy: 0 },
//       { id: 4, text: 'French', accuracy: 0 },
//       { id: 5, text: 'Mandarin', accuracy: 0 },
//       { id: 6, text: 'Spanish', accuracy: 0 },
//       { id: 7, text: 'Russian', accuracy: 0 },
//       { id: 8, text: 'Arabic', accuracy: 0 },

//       // Add other languages for the United Kingdom here
//     ],
//   },
//   {
//     id: 2,
//     text: 'Luxembourg',
//     languages: [
//       { id: 9, text: 'Hindi', accuracy: 0 },
//       { id: 10, text: 'English', accuracy: 0 },
//       { id: 11, text: 'Albania', accuracy: 0 },
//       { id: 12, text: 'French', accuracy: 0 },
//       { id: 13, text: 'Mandarin', accuracy: 0 },
//       { id: 14, text: 'Spanish', accuracy: 0 },
//       { id: 15, text: 'Russian', accuracy: 0 },
//       { id: 16, text: 'Arabic', accuracy: 0 },
//       // Add languages for Luxembourg here
//     ],
//   },
//   {
//     id: 3,
//     text: 'Germany',
//     languages: [
//       // Add languages for Germany here
//       { id: 17, text: 'Hindi', accuracy: 0 },
//       { id: 18, text: 'English', accuracy: 0 },
//       { id: 19, text: 'Albania', accuracy: 0 },
//       { id: 20, text: 'French', accuracy: 0 },
//       { id: 21, text: 'Mandarin', accuracy: 0 },
//       { id: 22, text: 'Spanish', accuracy: 0 },
//       { id: 23, text: 'Russian', accuracy: 0 },
//       { id: 24, text: 'Arabic', accuracy: 0 },
//     ],
//   },
//   {
//     id: 4,
//     text: 'Franch',
//     languages: [
//       { id: 25, text: 'Hindi', accuracy: 0 },
//       { id: 26, text: 'English', accuracy: 0 },
//       { id: 27, text: 'Albania', accuracy: 0 },
//       { id: 28, text: 'French', accuracy: 0 },
//       { id: 29, text: 'Mandarin', accuracy: 0 },
//       { id: 30, text: 'Spanish', accuracy: 0 },
//       { id: 31, text: 'Russian', accuracy: 0 },
//       { id: 32, text: 'Arabic', accuracy: 0 },
//     ],
//   },
//   {
//     id: 5,
//     text: 'Germany',
//     languages: [
//       { id: 31, text: 'Hindi', accuracy: 0 },
//       { id: 32, text: 'English', accuracy: 0 },
//       { id: 33, text: 'Albania', accuracy: 0 },
//       { id: 34, text: 'French', accuracy: 0 },
//       { id: 35, text: 'Mandarin', accuracy: 0 },
//       { id: 36, text: 'Spanish', accuracy: 0 },
//       { id: 37, text: 'Russian', accuracy: 0 },
//       { id: 38, text: 'Arabic', accuracy: 0 },
//     ],
//   },
//   {
//     id: 6,
//     text: 'Italy',
//     languages: [
//       { id: 39, text: 'Hindi'   ,accuracy:0  },
//       { id: 40, text: 'English' ,accuracy:0  },
//       { id: 41, text: 'Albania' ,accuracy:0  },
//       { id: 42, text: 'French'  ,accuracy:0  },
//       { id: 43, text: 'Mandarin',accuracy:0  },
//       { id: 44, text: 'Spanish' ,accuracy:0  },
//       { id: 44, text: 'Russian' ,accuracy:0  },
//       { id: 45, text: 'Arabic'  ,accuracy:0 },
//     ],
//   },
//   {
//     id: 7,
//     text: 'Netherlands',
//     languages: [
//       { id: 46, text: 'Hindi'   ,accuracy:0  },
//       { id: 47, text: 'English' ,accuracy:0  },
//       { id: 48, text: 'Albania' ,accuracy:0  },
//       { id: 49, text: 'French'  ,accuracy:0  },
//       { id: 50, text: 'Mandarin',accuracy:0  },
//       { id: 51, text: 'Spanish' ,accuracy:0  },
//       { id: 52, text: 'Russian' ,accuracy:0  },
//       { id: 53, text: 'Arabic'  ,accuracy:0  },
//     ],
//   },
//   {
//     id: 8,
//     text: 'Switzerlands',
//     languages: [
//       { id: 54, text: 'Hindi'   ,accuracy:0  },
//       { id: 55, text: 'English' ,accuracy:0  },
//       { id: 56, text: 'Albania' ,accuracy:0  },
//       { id: 57, text: 'French'  ,accuracy:0  },
//       { id: 58, text: 'Mandarin',accuracy:0  },
//       { id: 59, text: 'Spanish' ,accuracy:0  },
//       { id: 60, text: 'Russian' ,accuracy:0  },
//       { id: 61, text: 'Arabic'  ,accuracy:0 },
//     ],
//   },

//   // Add other countries with their respective languages here
// ],
