import React from "react";
import "./top6form.css";
const Top6Form = ({ activeStep, setActiveStep, setUserData }) => {
  const [role, setRole] = React.useState("");
  const handleChange = (e) => {
    setRole(e.target.value);
    setUserData((prev) => [
      prev[0],
      e.target.value,
      prev[2],
      prev[3],
      prev[4],
      prev[5],
      prev[6],
      prev[7],
      prev[8],
      prev[9],
    ]);
    setActiveStep(3);
  };
  return (
    <div className="top6form-container">
      <div className="top6form-wrapper">
        <h2 className="top6-head">What’s your role?</h2>
        <select
          name="role"
          id=""
          className="input-top6"
          value={role}
          onChange={(e) => handleChange(e)}
          required
        >
          <option
            value=""
            className="input-option"
            defaultValue={true}
            disabled
            hidden
          >
            Choose an option
          </option>
          <option value="executive">Executive (C-Level, VP)</option>
          <option value="director">Director</option>
          <option value="team">Team Manager</option>
          <option value="individualcontributor">Individual Contributor</option>
        </select>
      </div>
    </div>
  );
};

export default Top6Form;
