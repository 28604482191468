// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { PersistGate } from 'redux-persist/integration/react';
// import store, { persistor } from './redux/store';
// import { Provider } from 'react-redux';
// import { SnackbarProvider } from 'notistack';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <SnackbarProvider>
//   <Provider store={store}>
//    <PersistGate loading={null} persistor={persistor}>
//     <App />
//    </PersistGate>
//     </Provider>
//   </SnackbarProvider>

// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";
import { Provider } from "react-redux";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { AppProvider } from "./AppContext";
import { MaterialDesignContent } from "notistack";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

// const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
//   "&.notistack-MuiContent-default": {
//     backgroundColor: "#ffffff",
//     color: "#757575",
//   },
// }));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SnackbarProvider
    // autoHideDuration={2500}
    persist
    // Components={{
    //   default: StyledMaterialDesignContent,
    // }}
    action={(snackbarId) => (
      <button onClick={() => closeSnackbar(snackbarId)} className="mr-2">
        <CloseIcon />
      </button>
    )}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppProvider>
          <App />
        </AppProvider>
      </PersistGate>
    </Provider>
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
