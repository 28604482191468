// import React, { Component } from 'react'

// class GetInTouch extends Component {
//     render() {
//         return (
//             <React.Fragment>
//                 <div className="Get-In-Touch">
//                     <div className="container">
//                         <p className="get-in-touch-p">Let's Get</p>
//                         <p className="get-in-touch-sec-p">In touch</p>
//                         <form>
//                             <div className="row">

//                                 <div className="col-lg-6 col-md-6">
//                                     <p className="inputName">Name</p>
//                                     <input className="inpFirst" type="text"></input>
//                                 </div>
//                                 <div className="col-lg-6 col-md-6">
//                                     <p className="inputEmail">Email-Id</p>
//                                     <input className="inpSec" type="text"></input>
//                                 </div>
//                                 <div className="col-lg-12 col-md-12">
//                                     <p className="inputthird">I want to discuss</p>
//                                     <textarea className="inpthird"></textarea>
//                                 </div>
//                                 <button className="sndbtn" type="submit">Send</button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>

//             </React.Fragment>
//         )
//     }
// }

// export default GetInTouch



import React, { Component } from 'react'
import axios from "axios"
import { useState } from 'react';


const GetInTouch = () => {
    // e.preventDefault()
    const [data, setData] = useState({
        email: "", area: "", name: ""
    })
    let name, value
    const handleInput = (e) => {

        setData(prev => ({ ...prev, [e.target.id]: e.target.value }))
    }
    const send = async (e) => {
        e.preventDefault()
        const res = await axios.post("https://bms-total-backend.azurewebsites.net/get", data);

    }
    return (
        <>
            <div className="Get-In-Touch center">
                <div className="container">
                    <p className="get-in-touch-p">Let's Get</p>
                    <p className="get-in-touch-sec-p">In touch</p>

                    <div className="row">

                        <div className="col-lg-6 col-md-6">
                            <p className="inputName">Name</p>
                            <input className="inpFirst" type="text" onChange={handleInput} id='name'></input>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <p className="inputEmail">Email-Id</p>
                            <input className="inpSec" type="email" onChange={handleInput} id='email'></input>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <p className="inputthird">I want to discuss</p>
                            <textarea className="inpthird" onChange={handleInput} id='area'></textarea>
                        </div>
                        <button className="sndbtn" type="submit" onClick={send}>Send</button>
                    </div>

                </div>
            </div>


        </>
    )
}


export default GetInTouch
