// import React, { useState } from "react";
// import "./settings.css";
// import EmailChange from "../../components/emailChange/EmailChange";
// import Modal from "react-awesome-modal";
// import PasswordChange from "../../components/passwordChange/PasswordChange";
// import ConfirmEmail from "../../components/confirmEmail/ConfirmEmail";
// import NewPassword from "../../components/newPassword/NewPassword";
// import SecondaryEmail from "../../components/secondaryEmail/SecondaryEmail";
// import { useNavigate } from "react-router-dom";

// function Settings() {
//   const [personal, setPersonal] = useState(true);
//   const [language, setLanguage] = useState(false);
//   const [activity, setActivity] = useState(false);
//   const [emailChangeVisible, setEmailChangeVisible] = useState(false);
//   const [emailConfirmVisible, setEmailConfirmVisible] = useState(false);
//   const [passChangeVisible, setPassChangeVisible] = useState(false);
//   const [newPassVisible, setNewPassVisible] = useState(false);
//   const [secEmail, setSecEmail] = useState(false);
//   const [forgotten, setForgotten] = useState(false);
//   const [passPopper, setPassPopper] = useState(false);
//   const [formData, setFormData] = useState({
//     email: "",
//     password: ""
//   })
//   const navigate = useNavigate();

//   return (
//     <div>
//       <div className="upper d-flex flex-row align-itmes-center">
//         <i className="bi bi-x-lg cur" onClick={() => { navigate("/Language-&-Region") }}></i>
//         <div>Settings</div>
//       </div>
//       <div className="line"></div>
//       <div className="d-flex flex-row">
//         <div className="d-flex flex-column sidenav">
//           <div className="mt-5 side-margin">ACCOUNT</div>
//           <div className="d-flex flex-row w-100 justify-content-between">

//             <div
//               className="side-margin mt-4 cur d-flex flex-row"
//               onClick={() => {
//                 setPersonal(true);
//                 setLanguage(false);
//                 setActivity(false);
//               }}
//               style={{ color: personal ? "#39C6A4" : "black" }}
//             >
//               Personal information
//             </div>
//             <div className="active-div" style={{ display: personal ? "block" : "none" }}>

//             </div>
//           </div>
//           <div className="d-flex flex-row w-100 justify-content-between">

//             <div
//               className="side-margin mt-4 cur d-flex flex-row w-100"
//               onClick={() => {
//                 setPersonal(false);
//                 setLanguage(true);
//                 setActivity(false);
//               }}
//               style={{ color: language ? "#39C6A4" : "black" }}
//             >
//               Language and time zone
//             </div>
//             <div className="active-div" style={{ display: language ? "block" : "none" }}>
//             </div>
//           </div>
//           <div className="d-flex flex-row w-100 justify-content-between">

//             <div
//               className="side-margin mt-4 cur d-flex flex-row justify-content-between"
//               onClick={() => {
//                 setPersonal(false);
//                 setLanguage(false);
//                 setActivity(true);
//               }}
//               style={{ color: activity ? "#39C6A4" : "black" }}
//             >
//               Activity log
//             </div>
//             <div className="active-div" style={{ display: activity ? "block" : "none" }}>
//             </div>
//           </div>
//         </div>
//         {personal ? (
//           <div className="d-flex flex-column main-content">
//             <div className="personal">Personal Information </div>
//             <div className="profile mt-4">Profile picture </div>
//             <div className="d-flex flex-row mt-4">
//               <img
//                 src="https://weddingkj.s3.ap-south-1.amazonaws.com/Frame+3055.png"
//                 alt=""
//                 className="pro-image"
//               />
//               <div className="d-flex flex-column">
//                 {/* <button className="up-btn d-flex align-items-center">
//                   Upload
//                 </button> */}

//                 <input type="file" className="up-btn d-flex align-items-center" />
//                 <label htmlFor="">Upload</label>
//                 <div className="size-div mt-2">
//                   JPG, GIF or PNG. Minimum 300x300 pixels. Maximum file size 2
//                   MB.
//                 </div>
//               </div>
//             </div>
//             <div className="profile mt-4">Full Name</div>
//             <div className="d-flex flex-row w-100 justify-content-between mt-2">
//               <div className="d-flex flex-column name-col">
//                 <div className="name">First Name</div>
//                 <input type="text" className="in-box mt-1" />
//               </div>
//               <div className="d-flex flex-column">
//                 <div className="name">Last Name</div>
//                 <input type="text" className="in-box mt-1" required />
//               </div>
//             </div>
//             <div className="profile mt-4">Email Address</div>
//             <div className="d-flex flex-row w-100 justify-content-between mt-2">
//               <div className="d-flex flex-column name-col">
//                 <div className="name">Primary Email </div>
//                 <input type="text" className="in-box mt-1" value={formData.email} onChange={(e) => { setFormData({ ...formData, email: e.currentTarget.value }) }} required />
//               </div>
//               <div className="d-flex flex-column">
//                 <div className="name">Secondary Email</div>
//                 <input type="text" className="in-box mt-1" />
//               </div>
//             </div>
//             <div className="d-flex flex-row mt-1">
//               <div className="d-flex flex-row">
//                 <div
//                   className="change"
//                   onClick={() => setEmailChangeVisible(true)}
//                 >
//                   Change email
//                 </div>
//                 <div
//                   className="change"
//                   onClick={() => {
//                     setPassChangeVisible(true);
//                     setPassPopper(true);
//                   }}
//                 >
//                   Change password
//                 </div>
//               </div>
//               <div className="sec change" onClick={() => setSecEmail(true)}>
//                 Confirm secondary email
//               </div>
//             </div>
//             <div className="profile mt-4">Mobile number</div>
//             <div className="d-flex flex-row mt-1">
//               <div className="loc">+7</div>
//               <input type="text" className="nu" required />
//               <button
//                 className="con"
//                 onClick={() => setEmailConfirmVisible(true)}
//               >
//                 Confirm
//               </button>
//             </div>
//             <button className="save mt-5">Save</button>
//           </div>
//         ) : (
//           <>
//             {language ? (
//               <div className="d-flex flex-column main-content">
//                 <div className="main-heading-lang">Language and time zone </div>
//                 <div className="d-flex flex-row upper-lang">
//                   <div className="side-lang">
//                     <div className="heading-lang">Language</div>
//                     <label htmlFor="">
//                       <select name="" id="" className="select-lang">
//                         <option value="">English</option>
//                         <option value="">Hindi</option>
//                       </select>
//                     </label>
//                   </div>
//                   <div className="side-lang">
//                     <div className="heading-lang">Time zone</div>
//                     <select name="" id="" className="select-lang">
//                       <option value="">Pacific/Midway</option>
//                       {/* <option value="">Hindi</option> */}
//                     </select>
//                   </div>
//                 </div>
//                 <button className="save mt-5 d-flex flex-row-reverse">
//                   Save
//                 </button>
//               </div>
//             ) : (
//               <div className="d-flex flex-column main-content">
//                 <div className="main-heading-lang">Language and time zone </div>
//                 <select name="" id="" className="month-select">
//                   <option value="">One month</option>
//                 </select>

//                 <table className="table table-hover mt-5">
//                   <thead className="table-active">
//                     <tr>
//                       <th scope="col">Date</th>
//                       <th scope="col">Time</th>
//                       <th scope="col">Ip Address</th>
//                       <th scope="col">Location</th>
//                       <th scope="col">Device type</th>
//                       <th scope="col">Email</th>

//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <th scope="row">March 10, 2023</th>
//                       <td>09:14</td>
//                       <td>37.453.335.53, 10.11.24.354</td>
//                       <td>India</td>
//                       <td>Imac</td>
//                       <td>harshitacha@email.com</td>
//                     </tr>
//                     <tr>
//                       <th scope="row">March 10, 2023</th>
//                       <td>09:14</td>
//                       <td>37.453.335.53, 10.11.24.354</td>
//                       <td>India</td>
//                       <td>Imac</td>
//                       <td>harshitacha@email.com</td>
//                     </tr>
//                     <tr>
//                       <th scope="row">March 10, 2023</th>
//                       <td>09:14</td>
//                       <td>37.453.335.53, 10.11.24.354</td>
//                       <td>India</td>
//                       <td>Imac</td>
//                       <td>harshitacha@email.com</td>
//                     </tr>
//                     <tr>
//                       <th scope="row">March 10, 2023</th>
//                       <td>09:14</td>
//                       <td>37.453.335.53, 10.11.24.354</td>
//                       <td>India</td>
//                       <td>Imac</td>
//                       <td>harshitacha@email.com</td>
//                     </tr>
//                     <tr>
//                       <th scope="row">March 10, 2023</th>
//                       <td>09:14</td>
//                       <td>37.453.335.53, 10.11.24.354</td>
//                       <td>India</td>
//                       <td>Imac</td>
//                       <td>harshitacha@email.com</td>
//                     </tr>
//                     <tr>
//                       <th scope="row">March 10, 2023</th>
//                       <td>09:14</td>
//                       <td>37.453.335.53, 10.11.24.354</td>
//                       <td>India</td>
//                       <td>Imac</td>
//                       <td>harshitacha@email.com</td>
//                     </tr>
//                     <tr>
//                       <th scope="row">March 10, 2023</th>
//                       <td>09:14</td>
//                       <td>37.453.335.53, 10.11.24.354</td>
//                       <td>India</td>
//                       <td>Imac</td>
//                       <td>harshitacha@email.com</td>
//                     </tr>
//                     <tr>
//                       <th scope="row">March 10, 2023</th>
//                       <td>09:14</td>
//                       <td>37.453.335.53, 10.11.24.354</td>
//                       <td>India</td>
//                       <td>Imac</td>
//                       <td>harshitacha@email.com</td>
//                     </tr>
//                     <tr>
//                       <th scope="row">March 10, 2023</th>
//                       <td>09:14</td>
//                       <td>37.453.335.53, 10.11.24.354</td>
//                       <td>India</td>
//                       <td>Imac</td>
//                       <td>harshitacha@email.com</td>
//                     </tr>

//                   </tbody>
//                 </table>
//               </div>
//             )}
//           </>
//         )}
//       </div>
//       <Modal
//         visible={emailChangeVisible}
//         width="770"
//         height="411"
//         effect="fadeInUp"
//         onClickAway={() => setEmailChangeVisible(false)}
//       >
//         <EmailChange
//           emailChangeVisible={emailChangeVisible}
//           setEmailChangeVisible={setEmailChangeVisible}
//           setEmailConfirmVisible={setEmailConfirmVisible}
//           setForgotten={setForgotten}
//         />
//       </Modal>
//       <Modal
//         visible={emailConfirmVisible}
//         width="456"
//         height="411"
//         effect="fadeInUp"
//         onClickAway={() => setEmailConfirmVisible(false)}
//       >
//         <ConfirmEmail
//           setEmailConfirmVisible={setEmailConfirmVisible}
//           setNewPassVisible={setNewPassVisible}
//           forgotten={forgotten}
//           setForgotten={setForgotten}
//         />
//       </Modal>
//       <Modal
//         visible={newPassVisible}
//         width="770"
//         height="556"
//         effect="fadeInUp"
//         onClickAway={() => setNewPassVisible(false)}
//       >
//         <NewPassword
//           setNewPassVisible={setNewPassVisible}
//           setEmailChangeVisible={setEmailChangeVisible}
//           passPopper={passPopper}
//         />
//       </Modal>
//       <Modal
//         visible={passChangeVisible}
//         width="770"
//         height="636"
//         effect="fadeInUp"
//         onClickAway={() => setPassChangeVisible(false)}
//       >
//         <PasswordChange
//           setPassChangeVisible={setPassChangeVisible}
//           setEmailConfirmVisible={setEmailConfirmVisible}
//           setForgotten={setForgotten}
//         />
//       </Modal>
//       <Modal
//         visible={secEmail}
//         width="770"
//         height="411"
//         effect="fadeInUp"
//         onClickAway={() => setSecEmail(false)}
//       >
//         <SecondaryEmail
//           secEmail={secEmail}
//           setSecEmail={setSecEmail}
//           setEmailConfirmVisible={setEmailConfirmVisible}
//           setForgotten={setForgotten}
//         />
//       </Modal>
//     </div>
//   );
// }

// export default Settings;

import React, { useState, useRef } from "react";
import "./settings.css";
import EmailChange from "../../components/emailChange/EmailChange";
import Modal from "react-awesome-modal";
import PasswordChange from "../../components/passwordChange/PasswordChange";
import help from "../../components/helping/help.png";
import Helping from "../../components/helping/Helping";
import ConfirmEmail from "../../components/confirmEmail/ConfirmEmail";
import NewPassword from "../../components/newPassword/NewPassword";
import SecondaryEmail from "../../components/secondaryEmail/SecondaryEmail";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Zoom } from "@mui/material";

import { Chart } from "chart.js";
import "./chatmonitoring.css";
import DataTable1 from "./DataTable1";
import axios from "axios";
import DynamicProfilePhoto from "./DynamicProfilePhoto";
import PhoneInput from "react-phone-number-input";
import { useSelector } from "react-redux";
import { enqueueSnackbar, useSnackbar } from "notistack";
function Settings() {
  const [photo, setphoto] = useState("");
  const [photo1, setphoto1] = useState("");
  const [personal, setPersonal] = useState(true);
  const [activity, setActivity] = useState(false);
  const [emailChangeVisible, setEmailChangeVisible] = useState(false);
  const [emailConfirmVisible, setEmailConfirmVisible] = useState(false);
  const [passChangeVisible, setPassChangeVisible] = useState(false);
  const [newPassVisible, setNewPassVisible] = useState(false);
  const [secEmail, setSecEmail] = useState(false);
  const [forgotten, setForgotten] = useState(false);
  const [passPopper, setPassPopper] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [toggleimage, setToggleimage] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [verify, setVerify] = useState(false);
  const [helpshow, setHelpshow] = useState(false);
  // const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  // const separateCountryCode = () => {
  //   if (phoneNumber.startsWith('+')) {
  //     const extractedCode = phoneNumber.match(/^\+(\d+)/);
  //     if (extractedCode) {
  //       setCountryCode(extractedCode[0]);
  //       setPhoneNumber(phoneNumber.substring(extractedCode[0].length));
  //     }
  //   }
  // }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setphoto1(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setphoto(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const user = useSelector((state) => state.user.currentUser);
  const handlesubmit = async (e) => {
    e.preventDefault();

    const d = new FormData();
    d.append("file", photo1);
    d.append("upload_preset", "harshit");
    d.append("cloud_name", "dd8w9zrqr");
    const uploadRes = await axios.post(
      "https://api.cloudinary.com/v1_1/dd8w9zrqr/image/upload",
      d
    );
    console.log(uploadRes.data);
    const { url } = uploadRes.data;
    localStorage.setItem(
      "datapic",
      JSON.stringify({
        pic: url,
      })
    );
  };

  const openhelp = () => {
    setHelpshow(true);
    setToggleimage(false);
  };

  const data1 = localStorage.getItem("datapic");
  const parsedData1 = JSON.parse(data1);

  return (
    <>
      <div className="firstcont">
        <CloseIcon
          className="cross"
          style={{ fontSize: "1.4rem", cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="settings">Settings</div>
      </div>
      <div className="secondcont">
        <div className="row justify-center">
          <div
            // className="col-3"
            style={{
              border: "0.5px solid rgba(242, 242, 242, 1)",
              fontWeight: "10",
              padding: "0",
              margin: "0",
              position: "relative",
              height: "90vh",
              width: "12vw",
            }}
          >
            <div className="adminsettings">ACCOUNT</div>
            <div
              className="chatmonitoring"
              style={{
                borderRight: personal
                  ? "2px solid rgba(57, 198, 164, 1)"
                  : "none",
                position: "relative",
              }}
            >
              <a
                style={{
                  color: personal
                    ? "rgba(57, 198, 164, 1)"
                    : "rgba(151, 155, 153, 1)",
                  textDecoration: "none",
                  fontWeight: "400",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPersonal(true);
                  setVerify(false);
                  setActivity(false);
                }}
              >
                Personal information
              </a>
            </div>
            <div
              className="userdatabase"
              style={{
                borderRight: verify
                  ? "2px solid rgba(57, 198, 164, 1)"
                  : "none",
                fontWeight: "400",
                position: "relative",
                marginTop: "10%",
              }}
            >
              <a
                style={{
                  color: verify
                    ? "rgba(57, 198, 164, 1)"
                    : "rgba(151, 155, 153, 1)",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPersonal(false);
                  setVerify(true);
                  setActivity(false);
                }}
              >
                Language and time zone
              </a>
            </div>
            <div
              className="userdatabase1"
              style={{
                borderRight: activity
                  ? "2px solid rgba(57, 198, 164, 1)"
                  : "none",
                fontWeight: "400",
                position: "relative",
              }}
            >
              <a
                style={{
                  color: activity
                    ? "rgba(57, 198, 164, 1)"
                    : "rgba(151, 155, 153, 1)",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPersonal(false);
                  setVerify(false);
                  setActivity(true);
                }}
              >
                Activity log
              </a>
            </div>
          </div>

          <div
            class="col-9"
            style={{
              borderLeft: "0.5px solid rgba(242, 242, 242, 1)",
              borderTop: "0.5px solid rgba(242, 242, 242, 1)",
              borderRight: "0.5px solid rgba(242, 242, 242, 1)",
              paddingLeft: "3%",
            }}
          >
            {personal ? (
              <>
                <div className="d-flex flex-column main-content">
                  <div className="personal">Personal Information </div>
                  <div className="profile mt-4">Profile picture </div>
                  <div className="d-flex flex-row mt-4">
                    {/* {parsedData1 ? <img
                    src={parsedData1.pic}
                    alt=""
                    className="pro-image"
                  /> : <>{
                    photo ? <img
                      src={photo}
                      alt=""
                      className="pro-image"
                    /> : <div className="hr-parent" style={{ marginRight: "1rem" }}>
                      <div className="hr-child">
                        <h5 className="hr-child-h5" id="hr-title" >
                          {parsedData ? <DynamicProfilePhoto name={parsedData.name} size={100} /> : "HR"}
                        </h5>
                      </div>
                    </div>}</>} */}

                    {photo ? (
                      <img src={photo} alt="" className="pro-image" />
                    ) : (
                      <>
                        {parsedData1 ? (
                          <img
                            src={parsedData1.pic}
                            alt=""
                            className="pro-image"
                          />
                        ) : (
                          <>
                            {photo ? (
                              <img src={photo} alt="" className="pro-image" />
                            ) : (
                              <div
                                className="hr-parent"
                                style={{ marginRight: "1rem" }}
                              >
                                <div className="hr-child">
                                  <h5 className="hr-child-h5" id="hr-title">
                                    {user ? (
                                      <DynamicProfilePhoto
                                        name={user.name}
                                        size={100}
                                      />
                                    ) : (
                                      "HR"
                                    )}
                                  </h5>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <div className="d-flex flex-column">
                      {/* <button className="up-btn d-flex align-items-center">
                  Upload
                  </button> */}

                      <input
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        className="up-btn d-flex align-items-center"
                      />
                      <label htmlFor="">Upload</label>
                      <div className="size-div mt-2">
                        JPG, GIF or PNG. Minimum 300x300 pixels. Maximum file
                        size 2 MB.
                      </div>
                    </div>
                  </div>
                  <div className="profile mt-4">Full Name</div>
                  <div className="d-flex flex-row w-100 justify-content-between mt-2">
                    <div className="d-flex flex-column name-col">
                      <div className="name">First Name</div>
                      <input
                        type="text"
                        className="in-box mt-1"
                        value={user.name}
                      />
                    </div>
                    <div
                      className="d-flex flex-column"
                      style={{ marginRight: "80%" }}
                    >
                      <div className="name">Last Name</div>
                      <input type="text" className="in-box mt-1" />
                    </div>
                  </div>
                  <div className="profile mt-4">Email Address</div>
                  <div className="d-flex flex-row w-100 justify-content-between mt-2">
                    <div className="d-flex flex-column name-col">
                      <div className="name">Primary Email </div>
                      {emailChangeVisible ? (
                        <input
                          type="text"
                          className="in-box mt-1"
                          value={formData.email}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              email: e.currentTarget.value,
                            });
                          }}
                        />
                      ) : (
                        <p className="in-box mt-1">{user.email}</p>
                      )}
                    </div>
                    <div
                      className="d-flex flex-column"
                      style={{ marginRight: "80%" }}
                    >
                      <div className="name">Secondary Email</div>
                      <input
                        type="text"
                        className="in-box mt-1"
                        value={user.secEmail}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row mt-1">
                    <div className="d-flex flex-row">
                      <div
                        className="change"
                        onClick={() => {
                          if (user.secEmail) {
                            setEmailChangeVisible(true);
                          } else {
                            enqueueSnackbar(
                              "Set up secondary email",

                              {
                                variant: "default",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                                TransitionComponent: Zoom,
                              }
                            );
                          }
                        }}
                      >
                        Change email
                      </div>
                      <div
                        className="change"
                        onClick={() => {
                          setPassChangeVisible(true);
                          setPassPopper(true);
                        }}
                      >
                        Change password
                      </div>
                    </div>
                    <div
                      className="sec change"
                      onClick={() => setSecEmail(true)}
                    >
                      Confirm secondary email
                    </div>
                  </div>
                  <div
                    className="profile mt-4"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    Mobile number
                  </div>
                  <div className="d-flex flex-row mt-1">
                    {/* <div className="loc">{countryCode ? countryCode : "+"}</div>
                    <input
                      type="text"
                      className="nu"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          separateCountryCode();
                        }
                      }}
                    /> */}
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                    />
                    <button
                      className="con"
                      onClick={() => setEmailConfirmVisible(true)}
                    >
                      Confirm
                    </button>
                  </div>
                  <button className="save mt-5" onClick={handlesubmit}>
                    Save
                  </button>
                </div>
              </>
            ) : verify ? (
              <>
                {/* <div className="chart" style={{ width: "70%", border: "1px solid rgba(238, 236, 236, 1)", borderRadius: "0.5rem", justifyContent: "center", textAlign: "center", alignItems: "center", paddingTop: "3%", paddingRight: "3%" }}> */}

                {/* </div> */}

                <div
                  className="table_with_data"
                  style={{
                    position: "relative",
                    marginBottom: "10%",
                    marginTop: "3%",
                  }}
                >
                  <div className="d-flex flex-column main-content">
                    <div className="main-heading-lang">
                      Language and time zone{" "}
                    </div>

                    <div className="d-flex flex-row upper-lang">
                      <div className="side-lang">
                        <div className="heading-lang">Language</div>
                        <label htmlFor="">
                          <select name="" id="" className="select-lang">
                            <option value="">English</option>
                            <option value="">Hindi</option>
                          </select>
                        </label>
                      </div>
                      <div className="side-lang">
                        <div className="heading-lang">Time zone</div>
                        <select name="" id="" className="select-lang">
                          <option value="">Pacific/Midway</option>
                        </select>
                      </div>
                    </div>
                    <button className="save mt-5 d-flex flex-row-reverse">
                      Save
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="d-flex flex-column main-content"
                  style={{ marginLeft: "7rem" }}
                >
                  {/* <select name="" id="" className="month-select">
                      <option value="">One month</option>
                    </select> */}
                  <div className="main-heading-lang">Activity Log </div>

                  {/* <div style={{ maxHeight: "300px", overflowY: "auto", padding: "0px", margin: "0px", marginTop: "1rem" }}>
                      <table className="table table-hover mt-5" style={{}}>

                        <thead className="table-active">
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Time</th>
                            <th scope="col">Ip Address</th>
                            <th scope="col">Location</th>
                            <th scope="col">Device type</th>
                            <th scope="col">Email</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">March 10, 2023</th>
                            <td>09:14</td>
                            <td>37.453.335.53, 10.11.24.354</td>
                            <td>India</td>
                            <td>Imac</td>
                            <td>harshitacha@email.com</td>
                          </tr>
                          <tr>
                            <th scope="row">March 10, 2023</th>
                            <td>09:14</td>
                            <td>37.453.335.53, 10.11.24.354</td>
                            <td>India</td>
                            <td>Imac</td>
                            <td>harshitacha@email.com</td>
                          </tr>
                          <tr>
                            <th scope="row">March 10, 2023</th>
                            <td>09:14</td>
                            <td>37.453.335.53, 10.11.24.354</td>
                            <td>India</td>
                            <td>Imac</td>
                            <td>harshitacha@email.com</td>
                          </tr>
                          <tr>
                            <th scope="row">March 10, 2023</th>
                            <td>09:14</td>
                            <td>37.453.335.53, 10.11.24.354</td>
                            <td>India</td>
                            <td>Imac</td>
                            <td>harshitacha@email.com</td>
                          </tr>
                          <tr>
                            <th scope="row">March 10, 2023</th>
                            <td>09:14</td>
                            <td>37.453.335.53, 10.11.24.354</td>
                            <td>India</td>
                            <td>Imac</td>
                            <td>harshitacha@email.com</td>
                          </tr>
                          <tr>
                            <th scope="row">March 10, 2023</th>
                            <td>09:14</td>
                            <td>37.453.335.53, 10.11.24.354</td>
                            <td>India</td>
                            <td>Imac</td>
                            <td>harshitacha@email.com</td>
                          </tr>
                          <tr>
                            <th scope="row">March 10, 2023</th>
                            <td>09:14</td>
                            <td>37.453.335.53, 10.11.24.354</td>
                            <td>India</td>
                            <td>Imac</td>
                            <td>harshitacha@email.com</td>
                          </tr>
                          <tr>
                            <th scope="row">March 10, 2023</th>
                            <td>09:14</td>
                            <td>37.453.335.53, 10.11.24.354</td>
                            <td>India</td>
                            <td>Imac</td>
                            <td>harshitacha@email.com</td>
                          </tr>
                          <tr>
                            <th scope="row">March 10, 2023</th>
                            <td>09:14</td>
                            <td>37.453.335.53, 10.11.24.354</td>
                            <td>India</td>
                            <td>Imac</td>
                            <td>harshitacha@email.com</td>
                          </tr>

                        </tbody>

                      </table>
                    </div> */}
                  <DataTable1 />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={emailChangeVisible}
        width="770"
        height="411"
        effect="fadeInUp"
        onClickAway={() => setEmailChangeVisible(false)}
      >
        <EmailChange
          emailChangeVisible={emailChangeVisible}
          setEmailChangeVisible={setEmailChangeVisible}
          setEmailConfirmVisible={setEmailConfirmVisible}
          setForgotten={setForgotten}
        />
      </Modal>
      <Modal
        visible={emailConfirmVisible}
        width="456"
        height="411"
        effect="fadeInUp"
        onClickAway={() => setEmailConfirmVisible(false)}
      >
        <ConfirmEmail
          setEmailConfirmVisible={setEmailConfirmVisible}
          setNewPassVisible={setNewPassVisible}
          forgotten={forgotten}
          setForgotten={setForgotten}
        />
      </Modal>
      <Modal
        visible={newPassVisible}
        width="770"
        height="556"
        effect="fadeInUp"
        onClickAway={() => setNewPassVisible(false)}
      >
        <NewPassword
          setNewPassVisible={setNewPassVisible}
          setEmailChangeVisible={setEmailChangeVisible}
          passPopper={passPopper}
        />
      </Modal>
      <Modal
        visible={passChangeVisible}
        width="770"
        height="636"
        effect="fadeInUp"
        onClickAway={() => setPassChangeVisible(false)}
      >
        <PasswordChange
          setPassChangeVisible={setPassChangeVisible}
          setEmailConfirmVisible={setEmailConfirmVisible}
          setForgotten={setForgotten}
        />
      </Modal>
      <Modal
        visible={secEmail}
        width="770"
        height="411"
        effect="fadeInUp"
        onClickAway={() => setSecEmail(false)}
      >
        <SecondaryEmail
          secEmail={secEmail}
          setSecEmail={setSecEmail}
          setEmailConfirmVisible={setEmailConfirmVisible}
          setForgotten={setForgotten}
        />
      </Modal>
      {toggleimage ? (
        <img src={help} alt="help" className="helpimage" onClick={openhelp} />
      ) : null}
      {helpshow ? (
        <Helping
          onClick={() => {
            setHelpshow(false);
          }}
          settoggle={setToggleimage}
        />
      ) : null}
    </>
  );
}

export default Settings;
