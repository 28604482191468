import React, { useState } from "react";
import "./notification.css";

function Notification() {
  const [hide, setHide] = useState(false);
  const hiding = () => {
    setHide(true);
  };
  return (
    <div>
      <div
        className="notif"
        style={{
          height: "50px",
          background: "#a1a3f7",
          display: hide ? "none" : "flex",
        }}
      >
        <div className="d-flex flex-row align-itmes-center">
          {/* <img src="https://images.emojiterra.com/google/android-11/512px/1f389.png" alt="" style={{height:"24px",width:"26px"}} className='mx-3' /> */}
          <div>Backed by</div>
          <div className="d-flex flex-row align-itmes-center mx-1">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/512px-Microsoft_logo.svg.png"
              alt=""
              style={{
                height: "15px",
                width: "15px",
                marginRight: "6px",
                marginTop: "5px",
                marginLeft: "5px",
              }}
            />
            Microsoft &
          </div>
          <div className="d-flex flex-row align-itmes-center mx-5">
            <img
              src="/images/other/nv-logo.png"
              alt=""
              style={{
                height: "32px",
                width: "44px",
                marginLeft: "-16px",
                marginRight: "-11px",
              }}
              className="mx-n5"
            />
            <div style={{ marginLeft: "40px" }}>Nvidia</div>
            {/* <img src="https://images.emojiterra.com/google/android-11/512px/1f389.png" alt="" style={{height:"24px",width:"26px"}} className='mx-3' /> */}
          </div>
        </div>
        <i
          className="bi bi-x-lg"
          onClick={hiding}
          style={{ cursor: "pointer" }}
        ></i>
      </div>
    </div>
  );
}

export default Notification;
