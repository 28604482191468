import React,{useState} from "react";
import "./emailChange.css";
import SecEmail from '../secEmail/SecEmail';
import Modal from 'react-awesome-modal';
import ConfirmEmail from "../confirmEmail/ConfirmEmail";


function EmailChange({setEmailChangeVisible,setEmailConfirmVisible,setForgotten}) {
    // const [visible3, setVisible3] = useState(false)
  return (
    <div>
      <div className='d-flex flex-row justify-content-between align-items-center upper-email'>
        <div className='heading-email'>
        Change Email

        </div>
        <i class="bi bi-x-lg cur" onClick={() => setEmailChangeVisible(false)}></i>
      </div>
      <div className='d-flex flex-column inside-email'>
        <div className='text-email'>
            New email
        </div>
        <input type="text" className='input-email' required/>

      </div>
      <div className='d-flex flex-column inside-email'>
        <div className='text-email'>
            Current password
        </div>
        <input type="text" className='input-email' required/>

      </div>
      <div className='forgot' onClick={()=>{setEmailConfirmVisible(true);setEmailChangeVisible(false);setForgotten(true)}}>
        Forgotten password?
      </div>
      <div className='d-flex flex-row end-email'>
        <button className='cancel-email' onClick={() => setEmailChangeVisible(false)}>
            Cancel
        </button>
        <button className='submit-email' onClick={()=>{
          
          setEmailConfirmVisible(true);
          setEmailChangeVisible(false)}
          }>
            Submit
        </button>
      </div>
      
    </div>
  )
}

export default EmailChange
