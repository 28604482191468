import React, { useState } from "react";
import { useSelector } from "react-redux";
import { dollar, circle, rightArrow } from "./svgs";
import { getSelectedCountriesFromSaved } from "../../redux/dataSlices";

const Subscriptions = () => {
  const countryId = useSelector(getSelectedCountriesFromSaved);
  const inputText = useSelector((state) => state.data.inputText);
  // const tld = countryId.split("-").at(-1);
  const domainnames = inputText.slice(4);

  const [bill, setBill] = useState("monthly");
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let lastDayOfYear = new Date(today.getFullYear() + 1, 0, 0);

  // handlePay to be implemented
  const handlePay = () => {};

  return (
    <div className="bg-[#E8E8FD] w-[90%] flex flex-col p-[16px] gap-[16px] items-center rounded-[25px]">
      <p className="font-[Lato] text-[24px] font-medium leading-normal">
        Check the translation estimation for your website
      </p>
      <div className="flex flex-col py-[8px] content-center items-center gap-[20px] shrink-0 self-stretch bg-[#fff] rounded-[15px]">
        <div className="flex justify-end w-full mr-4 mt-2">
          <div className="flex bg-[#BABAFF] rounded-[50px] py-[16px] px-[20px] h-[28px] items-center justify-center cursor-pointer">
            <p className="font-[Lato] text-[18px] font-medium leading-normal">
              Money Calculator
            </p>
          </div>
        </div>

        <div className="flex bg-[#BABAFF] rounded-[50px] py-[16px] px-[20px] h-[28px] items-center justify-center cursor-pointer">
          <p className="font-[Lato] text-[18px] font-medium leading-normal">
            Change Currency
          </p>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-col items-start justify-start h-full">
            {dollar}
          </div>
          <h1 className="font-[Lato] text-[75px] font-semibold">
            600 / <span className="text-[64px]">m</span>
          </h1>
        </div>
        <div className="flex justify-center items-center gap-[4px]">
          <p className="font-[Lato] text-[16px] font-bold leading-normal">
            Next Bill Date
          </p>
          <div className="flex px-[16px] py-[8px] justify-center items-center bg-[#CDEAFF] rounded-[15px] font-[Lato] text-[20px] font-medium leading-normal">
            {bill === "monthly" &&
              `${lastDayOfMonth.getDate()}/${lastDayOfMonth.getMonth() + 1}/
            ${lastDayOfMonth.getFullYear()}`}
            {bill === "yearly" &&
              `${lastDayOfYear.getDate()}/${lastDayOfYear.getMonth() + 1}/
            ${lastDayOfYear.getFullYear()}`}
          </div>
        </div>
        <div className="relative -mt-[14rem] right-[20rem]">
          <div className=" flex flex-col justify-center items-center bg-[#BBF] rounded-[47px] gap-[8px] px-[30px] py-[20px] ">
            <div className="flex py-[2.75px] px-[10.5px] bg-[#fff] rounded-[27px] justify-center items-center">
              <p className="font-[Lato] text-[18px] font-medium leading-normal">
                Monthly
              </p>
            </div>
            <div
              className="flex flex-col gap-[10px] shrink-0 p-[4px] rounded-[100px] border-solid border-[0.5px] border-[#000] h-[77px] bg-[#fff] cursor-pointer"
              style={{
                justifyContent: bill === "monthly" ? "flex-start" : "flex-end",
              }}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   if (bill === "monthly") {
              //     setBill("yearly");
              //   } else {
              //     setBill("monthly");
              //   }
              // }}
            >
              <div>{circle}</div>
            </div>
            <div className="flex py-[2.75px] px-[10.5px] bg-[#ddd] rounded-[27px] justify-center items-center">
              <p className="font-[Lato] text-[18px] font-medium leading-normal">
                Yearly
              </p>
            </div>
          </div>
          <p className="mt-2 text-[#9E9E9E] font-[Lato] text-[18px] leading-normal">
            2 Months free trial
          </p>
        </div>
        <div className="flex w-full justify-end mr-4 mt-2 mb-2">
          <div className="flex justify-center items-center gap-[10px] text-center font-[Lato] text-[18px] font-medium">
            <p className="text-[#747474] ">Domain Subscription</p>
            <div
              className="flex text-[#0075FF]  underline cursor-pointer justify-center items-center gap-[4px]"
              onClick={(e) => {
                e.stopPropagation();
                handlePay();
              }}
            >
              Pay Now {rightArrow}
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-[20rem] mt-2 mb-2"
        style={{ borderBottom: "1px solid #000" }}
      ></div>
      <div
        className="bg-[#fff] flex flex-col p-[16px] rounded-[15px] shrink-0 items-center w-full "
        style={{ border: "1px solid rgba(128, 0, 255, 0.97)" }}
      >
        <div className="flex flex-row justify-start w-full">
          <h1 className="text-[#212121] font-[Lato] text-[24px] font-bold leading-normal">
            Price Breakdown
          </h1>
        </div>
        <div className="flex px-[56px] justify-between font-[Lato] text-[18px] w-full my-[25px] ">
          <p>Domains</p>
          <p>Word Counts</p>
        </div>
        <div className="flex flex-col max-h-[350px] overflow-y-scroll w-full">
          {countryId.map((id, index) => (
            <>
              <div className="flex justify-between pr-[100px] items-start self-stretch mt-2 font-[Lato] text-[18px] font-medium leading-normal">
                <p>
                  {id}.{domainnames}
                </p>
                <p>{index + 1}</p>
              </div>
              <div
                className="flex w-full mt-2"
                style={{ borderBottom: "0.5px solid #8989Ff" }}
              ></div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
