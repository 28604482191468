import * as React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import MuiInput from '@mui/material/Input';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import "./CustomizedSlider.css";
import { useDispatch, useSelector } from "react-redux";
import { defaultAccuracy, setDefautltAccuracy } from "../../redux/dataSlices";
import { NavLink } from "react-router-dom";
import { saveDefaultAccuracy } from "../../redux/apiCalls";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
const PrettoSlider = styled(Slider)({
  color: "#EDEDED",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#39C6A4",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export default function CustomizedSlider({ handleLanguage }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const defaultAccuracyVal = useSelector((state) => state.data.defaultAccuracy);
  const handelAccuracy = async () => {
    dispatch(defaultAccuracy({ newAccuracy: defaultAccuracyVal }));
    saveDefaultAccuracy(defaultAccuracyVal)
      .then((e) => {
        enqueueSnackbar("Accuracy Saved", {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
      })
      .catch((e) => {
        enqueueSnackbar("Error saving accuracy", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
      })
      .finally(() => {
        handleLanguage();
      });
  };

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 40,
      label: "40",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 60,
      label: "60",
    },
    {
      value: 70,
      label: "70",
    },
    {
      value: 80,
      label: "80",
    },
    {
      value: 90,
      label: "90",
    },
    {
      value: 100,
      label: "100",
    },
  ];

  const handleincrease = () => {
    if (defaultAccuracyVal > 50)
      dispatch(setDefautltAccuracy(defaultAccuracyVal - 1));
    // else setnum(50);
  };
  const handledecrease = () => {
    if (defaultAccuracyVal < 100)
      dispatch(setDefautltAccuracy(defaultAccuracyVal + 1));
    else dispatch(setDefautltAccuracy(100));
  };

  const handleInputChange = (event) => {
    if (event.target.value > 50) {
      dispatch(setDefautltAccuracy(Number(event.target.value)));
    }
  };
  return (
    <Box style={{ width: "800px", margin: "auto" }}>
      <div className="container bottom_lang justify ">
        <h6 className="devheader_lang">Choose the accuracy</h6>
        <div className="container-1">
          <div className="d-flex accuracy justify box-shadow">
            <RemoveIcon className="icons1" onClick={handleincrease} />
            <div className="one">{defaultAccuracyVal}</div>
            <AddIcon className="icons2" onClick={handledecrease} />
          </div>

          <PrettoSlider
            // valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={defaultAccuracyVal}
            value={defaultAccuracyVal}
            // min={50}
            max={100}
            onChange={handleInputChange}
            marks={marks}
          />

          <div className="text-on-slider">
            *Accuracy of translation will be applied to all selected languages
          </div>
        </div>
      </div>
      {/* <NavLink to={"/language"}> */}
      <button className="slider-btn" onClick={handelAccuracy}>
        Done
      </button>
      {/* </NavLink> */}
    </Box>
  );
}
