import { BrowserRouter } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import AppRouter from "./AppRouter";
import "./App.css";

function App() {
  const initialOptions = {
    clientId:
      "AaH7s5pUOfGi8PTr44ws3vvPNSTOniMx8qyU7DfjGnTzaYT8IIjhVvAjEW9d9MLb_ibwETyhWBDxW-fd",
    currency: "USD",
    intent: "capture",
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </PayPalScriptProvider>
  );
}
export default App;
