import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { MailOpen } from "lucide-react";
import { X } from "lucide-react";

import "./frame.css";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { ZoomIn } from "@mui/icons-material";
import { Zoom } from "@mui/material";
import { EMAIL_REGEX, getDomainName } from "../../lib/utils";
import { publicRequest } from "../../requestMethods";
export async function updatePreview(data) {
  try {
    return publicRequest.post(`utils/preview`, data, {
      baseURL: `${process.env.REACT_APP_SERVER_URL}/api/`,
    });
  } catch (error) {}
}
const DemoFrame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const emailSvg = <MailOpen />;
  const whatsappSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M0 23.936L1.76586 17.79C0.676606 15.9912 0.103662 13.9507 0.104463 11.8602C0.107264 5.32053 5.69101 0 12.5523 0C15.8819 0.00133515 19.0072 1.23653 21.3574 3.47842C23.7074 5.72031 25.0012 8.70034 25 11.8698C24.997 18.4093 19.4124 23.7306 12.5523 23.7306C12.5519 23.7306 12.5525 23.7306 12.5523 23.7306H12.5469C10.4637 23.7298 8.41685 23.2318 6.59876 22.2867L0 23.936ZM6.90434 20.1396L7.28237 20.3531C8.87052 21.2514 10.6914 21.7265 12.5483 21.7275H12.5523C18.2546 21.7275 22.8957 17.3047 22.8981 11.869C22.8991 9.23478 21.8241 6.75772 19.8707 4.89443C17.9171 3.03115 15.3198 2.00443 12.5561 2.00328C6.84931 2.00328 2.20832 6.42546 2.20612 11.861C2.20532 13.7237 2.75205 15.5376 3.78787 17.1073L4.03402 17.4804L2.98859 21.1181L6.90434 20.1396Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.44117 6.90188C9.20823 6.40825 8.96288 6.39833 8.74115 6.38975C8.55984 6.38231 8.35232 6.38269 8.14519 6.38269C7.93787 6.38269 7.60087 6.45708 7.3159 6.75367C7.03073 7.05027 6.22705 7.76743 6.22705 9.22599C6.22705 10.6847 7.34172 12.0941 7.49721 12.2921C7.6527 12.4899 9.64889 15.5786 12.8102 16.7669C15.4375 17.7545 15.9721 17.5581 16.5424 17.5087C17.1127 17.4593 18.3825 16.7915 18.6417 16.0993C18.901 15.4072 18.901 14.814 18.8232 14.6898C18.7453 14.5664 18.538 14.4922 18.227 14.344C17.916 14.1958 16.3869 13.4784 16.1017 13.3796C15.8166 13.2806 15.6092 13.2314 15.4019 13.5282C15.1944 13.8246 14.5988 14.4922 14.4173 14.6898C14.2358 14.888 14.0543 14.9128 13.7433 14.7644C13.4323 14.6158 12.4305 14.303 11.2422 13.293C10.3177 12.5074 9.69332 11.5369 9.51181 11.2402C9.3305 10.9438 9.50881 10.7973 9.64849 10.6353C9.90004 10.3437 10.3221 9.81937 10.4258 9.62177C10.5294 9.42378 10.4776 9.25079 10.3999 9.10239C10.3221 8.95419 9.71753 7.4882 9.44117 6.90188Z"
        fill="black"
      />
    </svg>
  );
  const [domain, setDomain] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [validNumber, setValidNumber] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const [numberInput, setNumberInput] = useState("");
  const handleClick = () => {
    // setShow(false);
    // setShown(true);
    // setTimeout(() => {
    //   setShown(false);
    // }, 2000);
  };

  const [iframeUrl, setIframeUrl] = useState();
  useEffect(() => {
    if (domain && (validEmail || validNumber)) {
      const url = getDomainName(domain);

      if (url) {
        setIframeUrl(`https://${url.replaceAll(".", "-")}.glocalise.site`);
      } else {
        enqueueSnackbar("Enter valid domain", {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
        navigate(-1);
      }
    }
  }, [domain, validEmail, validNumber]);

  // review this

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlDomain = searchParams.get("domain");
    if (urlDomain) {
      setDomain(urlDomain);
    }
  }, [location.search]);
  useEffect(() => {
    window.addEventListener("message", function (event) {
      event.stopPropagation();
      if (event.data.type === "iframeURLUpdate") {
        setIframeUrl(event.data.url);
      }
    });
    return () => window.removeEventListener("message", {});
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const iframeRef = useRef(null);
  const handleCopyClickCvalue = () => {
    if (iframeRef) {
      navigator.permissions
        .query({ name: "clipboard-write" })
        .then((result) => {
          if (result.state === "granted" || result.state === "prompt") {
            navigator.clipboard
              .writeText(iframeUrl)
              .then(() => {
                enqueueSnackbar("Url copied", {
                  variant: "default",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  TransitionComponent: Zoom,
                });
              })
              .catch((err) => console.error("Failed to copy text: ", err));
          } else {
            enqueueSnackbar("Clipboard access blocked", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              TransitionComponent: Zoom,
            });
          }
        });
    }
  };

  return (
    <>
      {!(validEmail || validNumber) && (
        <div className="w-[60%] demoFrame" style={{ margin: "10px auto" }}>
          <div
            className="relative"
            style={{ top: 50, left: "110%", zIndex: 250, cursor: "pointer" }}
            onClick={() => {
              enqueueSnackbar("Please enter your email or whatsapp", {
                variant: "default",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                TransitionComponent: Zoom,
                autoHideDuration: 3000,
              });
            }}
          >
            <X />
          </div>
          <Tabs
            defaultActiveKey="step1"
            style={{
              zIndex: "100",
              background: "#fbfefd",
              width: "60%",
              margin: "0 auto",
              marginTop: "1.5rem",
            }}
            id="justify-tab-example"
            justify
            // fill
          >
            <Tab
              eventKey="step1"
              title={emailSvg}
              style={{
                boxShadow: "0 0 12px 0 rgba(67,70,71,0.07)",
                background: "white",
                color: "black",
              }}
            >
              <div className="flex justify-center items-center w-full pt-20">
                <div className="mt-10">
                  <p
                    className="mt-10 text-center font-[Lato] text-[18px] font-normal"
                    style={{ lineHeight: "20px" }}
                  >
                    Enter your email to continue seeing translation
                  </p>
                  <div
                    style={{
                      borderRadius: "7px",
                      boxShadow: "0 0 12px 0 rgba(67,70,71,0.07)",
                      width: "70% ",
                      height: "450px",
                      background: "#fff",
                      zIndex: "200",
                      margin: "0 auto",
                      marginTop: "2rem",
                      padding: "1rem",
                    }}
                  >
                    <div
                      className={`flex`}
                      style={{
                        transition: "padding 1s ease-in-out",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{ width: "88.241px", height: "33.655px" }}
                        className="img-fluid"
                        src="\images\logo\Vector 10.png"
                        alt="logo.png"
                      />
                      <div className="flex items-center">
                        {/* <p>Glocali.se</p> */}
                      </div>
                    </div>
                    <img
                      src="/images/other/Group 406.png"
                      className="relative w-[90%] left-[5%]"
                      alt=""
                    />
                    <img
                      src="/images/other/Group 405.png"
                      className="relative top-[-18%]"
                      alt=""
                    />
                    <img
                      src="/images/other/Group 403.png"
                      className="relative top-[-43%]"
                      alt=""
                    />
                    <div
                      className="relative w-[95%] h-[65%] top-[-125%]"
                      style={{
                        background: "#333333",
                        margin: "0 auto",
                        borderRadius: "6.664px",
                        transform: "rotate(2.5deg)",
                        zIndex: 202,
                      }}
                    ></div>
                    <div
                      className="flex flex-col relative w-[93%] h-[65%] top-[-190%] left-[-2%] p-4"
                      style={{
                        background: "#fff",
                        margin: "0 auto",
                        borderRadius: "6.664px",
                        zIndex: 203,
                      }}
                    >
                      <div className="flex font-[Lato] text-[20px] font-normal mt-2">
                        Enter your email &nbsp; {emailSvg}
                      </div>
                      <div className="flex mt-6 text-[17px]">
                        <div
                          style={{
                            borderRadius: "8px",
                            boxShadow: "0px 2px 13px 1px rgba(7, 24, 20, 0.08)",
                            padding: "8px",
                          }}
                        >
                          email
                        </div>
                        <input
                          type="email"
                          value={emailInput}
                          onChange={(e) => setEmailInput(e.target.value)}
                          className="text-[16px] px-2"
                          style={{
                            marginLeft: "5px",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 13px 1px rgba(7, 24, 20, 0.08)",
                            width: "80%",
                          }}
                        />
                      </div>

                      {/* add onClick */}
                      <button
                        className="hero-left-input-button mb-4 mr-4"
                        style={{ width: "90px" }}
                        onClick={() => {
                          if (EMAIL_REGEX.test(emailInput)) {
                            updatePreview({
                              url: domain,
                              email: emailInput,
                            }).then((e) => {
                              setValidEmail(true);
                            });
                          } else {
                            enqueueSnackbar("Enter valid email", {
                              variant: "error",
                              anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                              TransitionComponent: Zoom,
                              autoHideDuration: 3000,
                            });
                          }
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="contact" title="Email or Whatsapp" disabled></Tab>
            <Tab
              eventKey="step4"
              title={whatsappSvg}
              style={{
                boxShadow: "0 0 12px 0 rgba(67,70,71,0.07)",
                background: "white",
                color: "black",
              }}
            >
              <div className="flex justify-center items-center w-full pt-20">
                <div className="mt-10">
                  <p
                    className="mt-10 text-center font-[Lato] text-[18px] font-normal"
                    style={{ lineHeight: "20px" }}
                  >
                    Enter your Whatsapp number to continue seeing translation
                  </p>
                  <div
                    style={{
                      borderRadius: "7px",
                      boxShadow: "0 0 12px 0 rgba(67,70,71,0.07)",
                      width: "70% ",
                      height: "450px",
                      background: "#fff",
                      zIndex: "200",
                      margin: "0 auto",
                      marginTop: "2rem",
                      padding: "1rem",
                    }}
                  >
                    <div
                      className={`flex`}
                      style={{
                        transition: "padding 1s ease-in-out",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{ width: "88.241px", height: "33.655px" }}
                        className="img-fluid"
                        src="\images\logo\Vector 10.png"
                        alt="logo.png"
                      />
                      <div className="flex items-center">
                        {/* <p>Glocali.se</p> */}
                      </div>
                    </div>
                    <img
                      src="/images/other/Group 406.png"
                      className="relative w-[90%] left-[5%]"
                      alt=""
                    />
                    <img
                      src="/images/other/Group 405.png"
                      className="relative top-[-18%]"
                      alt=""
                    />
                    <img
                      src="/images/other/Group 403.png"
                      className="relative top-[-43%]"
                      alt=""
                    />
                    <div
                      className="relative w-[95%] h-[65%] top-[-125%]"
                      style={{
                        background: "#333333",
                        margin: "0 auto",
                        borderRadius: "6.664px",
                        transform: "rotate(2.5deg)",
                        zIndex: 202,
                      }}
                    ></div>
                    <div
                      className="flex flex-col relative w-[93%] h-[65%] top-[-190%] left-[-2%] p-4"
                      style={{
                        background: "#fff",
                        margin: "0 auto",
                        borderRadius: "6.664px",
                        zIndex: 203,
                      }}
                    >
                      <div className="flex font-[Lato] text-[20px] font-normal mt-2">
                        Your whatsapp number &nbsp; {whatsappSvg}
                      </div>
                      <div className="flex mt-6 text-[17px]">
                        <input
                          value={countryCode}
                          onChange={(e) => setCountryCode(e.target.value)}
                          className="text-[16px] px-2"
                          style={{
                            marginLeft: "5px",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 13px 1px rgba(7, 24, 20, 0.08)",
                            width: "20%",
                            padding: "8px",
                          }}
                        />
                        <input
                          type=""
                          value={numberInput}
                          onChange={(e) => setNumberInput(e.target.value)}
                          className="text-[16px] px-2"
                          style={{
                            marginLeft: "5px",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 13px 1px rgba(7, 24, 20, 0.08)",
                            width: "80%",
                            padding: "8px",
                          }}
                        />
                      </div>

                      {/* add onClick */}
                      <button
                        className="hero-left-input-button mb-4 mr-4"
                        style={{ width: "90px" }}
                        onClick={() => {
                          updatePreview({
                            url: domain,
                            number: numberInput,
                            countryCode,
                          }).then((e) => {
                            setValidNumber(true);
                          });
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      )}

      {/* <div className="country-lang-divpk z-50">
        <div className="country-langpk" onClick={countryDrop}>
          <div className="d-flex flex-row align-items-center justify-content-center">
            <img
              src={
                !value
                  ? `https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg`
                  : languageflag
              }
              alt="Flag of France"
            />
            {!value ? `CA` : languagecode}
          </div>
        </div>
        <i
          className="bi bi-lightning-charge-fill"
          style={{ color: "#ffb301", marginRight: "-10px" }}
        ></i>

        <div>by Glocali.se</div>
      </div>
      {countryToggle ? (
        <div
          className="lang-popuppk"
          style={{ display: countryToggle ? "flex" : "none" }}
        >
          {Language.map((e, index) => (
            <div
              key={index}
              className="d-flex flex-row align-items-center mb-2 justify-content-between"
              onClick={() => languagevalue(index)}
            >
              <img
                src={e.image}
                alt="Flag of France"
                style={{ width: "20px", height: "11px", marginRight: "3px" }}
              />
              <div>{e.code}</div>
            </div>
          ))}
        </div>
      ) : null} */}

      {(validEmail || validNumber) && (
        <>
          <div
            className="frame-container"
            style={{ backgroundColor: "#f5f5f5" }}
            id="frame-container"
            onClick={handleClick}
          >
            <h1 className="frame-head">
              See how translation will work <br />
              on your website
            </h1>
            <div className="frame-wrapper" id="wrapper">
              <img
                src="/images/other/Menu.png"
                alt=""
                className="frame-header"
              />
              <iframe
                ref={iframeRef}
                src={iframeUrl}
                frameborder="0"
                title="welcome to glocali.se preview section"
                className="frame-area"
              ></iframe>
              {/* <div className="frame-img">
          {shown && (
            <div className="feedback-message">
              Activated Feedback for better user stats
            </div>
          )}
          <img
            src="/images/other/Feedback.png"
            alt="feedback"
            className="feedback-img"
          />
          {show && (
            <div className="chatbot-message">
              Activated Multilingual <br /> Chatbot on your <br /> website
            </div>
          )}
          <img src="/images/other/ChatBot.png" alt="chatbot" />
        </div> */}
            </div>
            <div className="button-container">
              <button
                className="btn123"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyClickCvalue();
                }}
              >
                Copy
              </button>

              <button
                onClick={() => {
                  if (iframeRef) {
                    window.open(iframeUrl);
                  }
                }}
                className="btn2"
              >
                Open in Tab &gt;{" "}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DemoFrame;
