import React from "react";
import "./top2form.css";
const Top2Form = ({ activeStep, setActiveStep, setUserData }) => {
  const [tech, setTech] = React.useState("");
  const handleChange = (e) => {
    setTech(e.target.value);
    setUserData((prev) => [
      prev[0],
      prev[1],
      prev[2],
      prev[3],
      prev[4],
      prev[5],
      prev[6],
      e.target.value,
      prev[8],
      prev[9],
    ]);
    setActiveStep(7);
  };
  return (
    <>
      <div className="top2form-container">
        <div className="top2form-wrapper">
          <h2 className="top2-head">Technology used in your website</h2>
          <select
            name="technology"
            value={tech}
            id=""
            className="input-top2"
            onChange={(e) => handleChange(e)}
            required
          >
            <option
              value=""
              className="input-option"
              defaultValue={true}
              disabled
              hidden
            >
              Choose an option
            </option>
            <option value="wordpress">Wordpress</option>
            <option value="wix">Wix</option>
            <option value="shopify">Shopify</option>
            <option value="tilda">Tilda</option>
            <option value="presta Shop">Presta Shop</option>
            <option value="joomla">Joomla</option>
            <option value="magento">Magento</option>
            <option value="other">Others</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Top2Form;
