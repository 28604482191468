import React, { useRef, useEffect } from "react";

const DynamicProfilePhoto = ({ name, size }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    let initials = name
      .split(" ")
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();

    const colors = ["#F44336"];

    const randomColor = colors[Math.floor(Math.random() * colors.length)];

    // Set canvas size
    canvas.width = size;
    canvas.height = size;

    // Draw circular avatar
    context.beginPath();
    // context.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
    // context.arc(50, 100, 20, 0, 2 * Math.PI)
    context.fillStyle = randomColor;
    context.fill();

    // Draw text (initials)
    context.font = `${size / 2}px Arial`;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillStyle = "#ffffff";
    context.fillText(initials, size / 2, size / 2);
  }, [name, size]);

  return <canvas ref={canvasRef} style={{ width: "2rem", height: "2rem" }} />;
};

export default DynamicProfilePhoto;
