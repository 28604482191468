import React, { forwardRef } from "react";

import "./services.css";
const Services = ({ serviceref }) => {
  return (
    <div className="services-container" ref={serviceref}>
      <div className="topheader-container">
        <div className="topheader">We offer</div>
        <div className="topheader1">Our services</div>
      </div>
      <div className="services-wrapper">
        <div className="services-left">
          <div className="header21">Chatbot</div>
          <div className="header3">
            Break the ice! Communicate with your customers even if <br /> you
            don't know their language. We translate your chats <br /> on fly.
          </div>
        </div>
        <div className="services-right">
          <div className="services-right-div">
            {/* <div className="services-right-top">
              <h2 className="top-head">Glocali.se</h2>
              <div className="bottom">
                <img src="/images/other/A.png" alt="online" className='img-online' />
                <p className="para-online">online</p>
              </div>
            </div>
            <div className="services-right-bottom">
              <img src="/images/other/Frame 2818.png" alt="frame" className="right-bottom-img" style={{width:"435px",height:"242px"}}/>
            </div> */}
            <img
              src="/images/other/Frame 2819.png"
              alt=""
              style={{ width: "100%" }}
            />

            <div className="slider-wrapper">
              <div className="card-s1">
                <img src="/images/other/B.png" alt="" className="img-slider" />
              </div>
              <div className="card-s2">
                <img src="/images/other/S.png" alt="" className="img-slider" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
