/**
 * * Structure
 * * [zone-id]-[region-id]-[sub-region-id] => list of nestedSubCountires
 */
const nestedSubCountires = {
  "ME-ME-LE": ["il", "ps", "lb", "jo", "sy"],
  "ME-ME-AP": ["ae", "ye", "bh", "om", "qa", "kw", "iq", "sa"],
  "ME-ME-ME": ["ir"],
  "ME-AF-NA": ["dz", "ly", "tn", "ma", "sd", "eg"],
  "EM-ME-LE": ["il", "lb", "sy", "jo", "tr", "ps"],
  "EM-ME-AP": ["sa", "ae", "ye", "bh", "om", "qa", "kw", "iq"],
  "EM-ME-ME": ["ir"],
  "EM-ME-MET": [
    "dz",
    "km",
    "dj",
    "eg",
    "lb",
    "ly",
    "ma",
    "mr",
    "so",
    "sd",
    "tn",
  ],
  "EM-EU-EE": ["ru", "ua", "pl", "ro", "cz", "hu", "by", "bg", "md", "sk"],
  "EM-EU-WE": ["de", "nl", "be", "fr", "at", "ch", "lu", "mc", "li"],
  "EM-EU-NE": ["gb", "se", "is", "dk", "fi", "ie", "no", "lt", "lv", "ee"],
  "EM-EU-SE": [
    "it",
    "es",
    "gr",
    "pt",
    "rs",
    "hr",
    "ba",
    "al",
    "mk",
    "si",
    "me",
    "mt",
    "ad",
    "sm",
    "va",
  ],
  "EM-AS-WAS": [
    "ge",
    "am",
    "az",
    "tr",
    "cy",
    "sy",
    "lb",
    "il",
    "ps",
    "jo",
    "iq",
    "om",
    "ye",
    "kw",
    "bh",
    "qa",
    "sa",
  ],
  "EM-AS-EAS": ["cn", "mn", "kp", "kr", "jp", "mo"],
  "EM-AS-CNA": ["ru", "tj", "uz", "kz", "kg", "tm"],
  "EM-AS-SE": ["ru", "tj", "uz", "kz", "kg", "tm"],
  "EM-AS-SSA": [
    "lk",
    "bd",
    "in",
    "af",
    "pk",
    "bt",
    "vn",
    "tl",
    "th",
    "sg",
    "ph",
    "mm",
    "my",
    "la",
    "id",
    "kh",
    "bn",
    "ir",
    "mv",
    "np",
  ],
  "AP-AS-EAS": [
    "cn",
    "mn",
    "kp",
    "kr",
    "jp",
    "hk",
    "tw",
    "mo",
    "sg",
    "th",
    "tl",
    "vn",
  ],
  "AP-AS-SAS": [
    "lk",
    "bd",
    "in",
    "af",
    "pk",
    "bt",
    "np",
    "mv",
    "ir",
    "bn",
    "kh",
    "id",
    "la",
  ],
  "AP-AS-SES": ["my", "ph", "mm", "kg", "tj"],
  "AP-OC-ANZ": ["nz", "au"],
  "AP-OC-MS": ["fj", "sb", "vu", "pg"],
  "AP-OC-MI": ["fm", "ki", "mh", "pw"],
  "AP-OC-PO": ["to", "ws", "tv"],
  "NA-AM-AM": [
    "ca",
    "cr",
    "gt",
    "sv",
    "hn",
    "jm",
    "mx",
    "ni",
    "pa",
    "kn",
    "lc",
    "us",
  ],
  "NA-CA-CA": [
    "jm",
    "bb",
    "tt",
    "bs",
    "ag",
    "dm",
    "gd",
    "lc",
    "do",
    "vc",
    "kn",
    "ht",
    "cu",
    "ms",
    "vi",
    "tc",
    "ai",
    "bz",
    "pr",
    "sr",
    "gy",
    "vg",
    "ky",
    "aw",
    "bq",
    "cw",
    "sx",
    "gp",
    "mq",
    "bm",
    "bl",
    "mf",
    "gf",
  ],
  "EE-AF-WA": [
    "ng",
    "gh",
    "ci",
    "ne",
    "bf",
    "ml",
    "sn",
    "gn",
    "bj",
    "tg",
    "sl",
    "lr",
    "gw",
    "mr",
    "cv",
    "gm",
  ],
  "EE-AF-EA": [
    "et",
    "tz",
    "ke",
    "mz",
    "mg",
    "ug",
    "dj",
    "km",
    "mw",
    "zm",
    "td",
    "so",
    "zw",
    "rw",
    "bi",
    "ss",
    "mu",
    "er",
    "sc",
  ],
  "EE-AF-NA": ["eg", "dz", "sd", "ma", "tn", "ly"],
  "EE-AF-MA": ["cd", "ao", "cm", "cg", "cf", "st", "ga", "gq"],
  "EE-AF-SA": ["za", "na", "bw", "ls", "sz"],
  "EE-EU-EE": ["ru", "ua", "pl", "ro", "cz", "hu", "by", "bg", "md", "sk"],
  "EE-ME-LE": ["il", "lb", "sy", "jo", "ps"],
  "EE-ME-AP": ["sa", "ae", "ye", "bh", "om", "qa", "kw", "iq"],
  "EE-ME-ME": ["ir"],
  "EE-ME-MET": [
    "dz",
    "km",
    "dj",
    "eg",
    "lb",
    "ly",
    "ma",
    "mr",
    "so",
    "sd",
    "tn",
  ],
  "SE-EU-SE": [
    "it",
    "es",
    "gr",
    "pt",
    "rs",
    "hr",
    "ba",
    "al",
    "mk",
    "si",
    "me",
    "mt",
    "ad",
    "sm",
    "va",
  ],
  "SE-ME-LE": ["il", "lb", "sy", "jo", "tr", "ps"],
  "SE-ME-AP": ["sa", "ae", "ye", "bh", "om", "qa", "kw", "iq"],
  "SE-ME-ME": ["tr", "ir"],
  "SE-ME-MET": [
    "dz",
    "km",
    "dj",
    "eg",
    "lb",
    "ly",
    "ma",
    "mr",
    "so",
    "sd",
    "tn",
  ],
  "SE-AF-WA": [
    "ng",
    "gh",
    "ci",
    "ne",
    "bf",
    "ml",
    "sn",
    "gn",
    "bj",
    "tg",
    "sl",
    "lr",
    "gw",
    "mr",
    "cv",
    "gm",
  ],
  "SE-AF-EA": [
    "et",
    "tz",
    "ke",
    "mz",
    "mg",
    "ug",
    "dj",
    "km",
    "mw",
    "zm",
    "td",
    "so",
    "zw",
    "rw",
    "bi",
    "ss",
    "mu",
    "er",
    "sc",
  ],
  "SE-AF-NA": ["eg", "dz", "sd", "ma", "tn", "ly"],
  "SE-AF-MA": ["cd", "ao", "cm", "cg", "cf", "st", "ga", "gq"],
  "SE-AF-SA": ["za", "na", "bw", "ls", "sz"],
  "CE-EU-CE": [
    "cz",
    "pl",
    "hu",
    "de",
    "cz",
    "sk",
    "at",
    "li",
    "ch",
    "si",
    "hr",
    "rs",
    "ro",
    "bg",
  ],
  "CE-AF-EA": [
    "et",
    "tz",
    "ke",
    "mz",
    "mg",
    "ug",
    "dj",
    "km",
    "mw",
    "zm",
    "td",
    "so",
    "zw",
    "rw",
    "bi",
    "ss",
    "mu",
    "er",
    "sc",
  ],
  "CE-AF-SA": ["za", "na", "bw", "ls", "sz"],
  "CE-AF-WA": [
    "ng",
    "gh",
    "ci",
    "ne",
    "bf",
    "ml",
    "sn",
    "gn",
    "bj",
    "tg",
    "sl",
    "lr",
    "gw",
    "mr",
    "cv",
    "gm",
  ],
  "CE-AF-NA": ["eg", "dz", "sd", "ma", "tn", "ly"],
  "CE-AF-MA": ["cd", "ao", "cm", "cg", "cf", "st", "ga", "gq"],
  "CE-ME-LE": ["il", "ps", "lb", "jo", "sy", "tr"],
  "CE-ME-AP": ["kw", "iq", "ae", "ye", "qa", "om", "sa", "bh"],
  "CE-ME-ME": ["ir"],
  "CE-ME-MET": [
    "dz",
    "km",
    "dj",
    "eg",
    "lb",
    "ly",
    "ma",
    "mr",
    "so",
    "sd",
    "tn",
  ],
  "CEE-EU-CE": ["de", "cz", "sk", "at", "li", "ch", "si", "hr", "rs"],
  "CEE-EU-EE": ["ru", "ua", "pl", "ro", "cz", "hu", "by", "bg", "md", "sk"],
  "EU-AF-NA": ["eg", "dz", "sd", "ma", "tn", "ly"],
  "EU-EU-EE": ["ru", "ua", "pl", "ro", "cz", "hu", "by", "bg", "md", "sk"],
  "EU-EU-WE": ["de", "nl", "be", "fr", "at", "ch", "lu", "mc", "li"],
  "EU-EU-NE": ["gb", "se", "is", "dk", "fi", "ie", "no", "lt", "lv", "ee"],
  "EU-EU-SE": [
    "it",
    "es",
    "gr",
    "pt",
    "rs",
    "hr",
    "ba",
    "al",
    "mk",
    "si",
    "me",
    "mt",
    "ad",
    "sm",
    "va",
  ],
  "EU-ME-LE": ["il", "lb", "sy", "jo", "tr", "ps"],
  "EU-ME-AP": ["sa", "ae", "ye", "bh", "om", "qa", "kw", "iq"],
  "EU-ME-ME": ["ir"],
  "EU-ME-MET": [
    "dz",
    "km",
    "dj",
    "eg",
    "lb",
    "ly",
    "ma",
    "mr",
    "so",
    "sd",
    "tn",
  ],
  "EME-AF-WA": [
    "ng",
    "gh",
    "ci",
    "ne",
    "bf",
    "ml",
    "sn",
    "gn",
    "bj",
    "tg",
    "sl",
    "lr",
    "gw",
    "mr",
    "cv",
    "gm",
  ],
  "EME-AF-EA": [
    "et",
    "tz",
    "ke",
    "mz",
    "mg",
    "ug",
    "dj",
    "km",
    "mw",
    "zm",
    "td",
    "so",
    "zw",
    "rw",
    "bi",
    "ss",
    "mu",
    "er",
    "sc",
  ],
  "EME-AF-NA": ["eg", "dz", "sd", "ma", "tn", "ly"],
  "EME-AF-MA": ["cd", "ao", "cm", "cg", "cf", "st", "ga", "gq"],
  "EME-AF-SA": ["za", "na", "bw", "ls", "sz"],
  "EME-EU-EE": ["ru", "ua", "pl", "ro", "cz", "hu", "by", "bg", "md", "sk"],
  "EME-EU-WE": ["de", "nl", "be", "fr", "at", "ch", "lu", "mc", "li"],
  "EME-EU-NE": ["gb", "se", "is", "dk", "fi", "ie", "no", "lt", "lv", "ee"],
  "EME-EU-SE": [
    "it",
    "es",
    "gr",
    "pt",
    "rs",
    "hr",
    "ba",
    "al",
    "mk",
    "si",
    "me",
    "mt",
    "ad",
    "sm",
    "va",
  ],
  "EME-": ["in"],
  "EME-ME-LE": ["il", "lb", "sy", "jo", "tr", "ps"],
  "EME-ME-AP": ["sa", "ae", "ye", "bh", "om", "qa", "kw", "iq"],
  "EME-ME-ME": ["ir"],
  "EME-ME-MET": [
    "dz",
    "km",
    "dj",
    "eg",
    "lb",
    "ly",
    "ma",
    "mr",
    "so",
    "sd",
    "tn",
  ],
  "EMEA-AF-WA": [
    "ng",
    "gh",
    "ci",
    "ne",
    "bf",
    "ml",
    "sn",
    "gn",
    "bj",
    "tg",
    "sl",
    "lr",
    "gw",
    "mr",
    "cv",
    "gm",
  ],
  "EMEA-AF-EA": [
    "et",
    "tz",
    "ke",
    "mz",
    "mg",
    "ug",
    "dj",
    "km",
    "mw",
    "zm",
    "td",
    "so",
    "zw",
    "rw",
    "bi",
    "ss",
    "mu",
    "er",
    "sc",
  ],
  "EMEA-AF-NA": ["eg", "dz", "sd", "ma", "tn", "ly"],
  "EMEA-AF-MA": ["cd", "ao", "cm", "cg", "cf", "st", "ga", "gq"],
  "EMEA-AF-SA": ["za", "na", "bw", "ls", "sz"],
  "EMEA-EU-EE": ["ru", "ua", "pl", "ro", "cz", "hu", "by", "bg", "md", "sk"],
  "EMEA-EU-WE": ["de", "nl", "be", "fr", "at", "ch", "lu", "mc", "li"],
  "EMEA-EU-NE": ["gb", "se", "is", "dk", "fi", "ie", "no", "lt", "lv", "ee"],
  "EMEA-EU-SE": [
    "it",
    "es",
    "gr",
    "pt",
    "rs",
    "hr",
    "ba",
    "al",
    "mk",
    "si",
    "me",
    "mt",
    "ad",
    "sm",
    "va",
  ],
  "EMEA-ME-LE": ["il", "lb", "sy", "jo", "tr", "ps"],
  "EMEA-ME-AP": ["sa", "ae", "ye", "bh", "om", "qa", "kw", "iq"],
  "EMEA-ME-ME": ["ir"],
  "EMEA-ME-MET": [
    "dz",
    "km",
    "dj",
    "eg",
    "lb",
    "ly",
    "ma",
    "mr",
    "so",
    "sd",
    "tn",
  ],
  "EMEA-CA-CA": [
    "jm",
    "bb",
    "tt",
    "bs",
    "ag",
    "dm",
    "gd",
    "lc",
    "do",
    "vc",
    "kn",
    "ht",
    "cu",
    "ms",
    "vi",
    "tc",
    "ai",
    "bz",
    "pr",
    "sr",
    "gy",
    "vg",
    "ky",
    "aw",
    "bq",
    "cw",
    "sx",
    "gp",
    "mq",
    "bm",
    "bl",
    "mf",
    "gf",
  ],
  "NAC-EU-CE": [
    "cz",
    "pl",
    "hu",
    "de",
    "al",
    "sk",
    "at",
    "mk",
    "ch",
    "si",
    "hr",
    "rs",
    "me",
    "ba",
  ],
  "NAC-NAT-NAT": [
    "be",
    "ca",
    "dk",
    "fr",
    "cz",
    "de",
    "gr",
    "hu",
    "is",
    "it",
    "lu",
    "nl",
    "no",
    "pl",
    "pt",
    "es",
    "tr",
    "gb",
    "us",
  ],
  "CEM-EU-EE": ["by", "md"],
  "CEM-AS-CAS": ["kg", "kz", "tj", "uz"],
  "CEM-AS-CAU": ["az", "am", "ru"],
};
export const nestedCountriesSubRegionWise = {
  "ME-LE": ["il", "lb", "sy", "jo", "tr", "ps"],
  "ME-AP": ["sa", "ae", "ye", "bh", "om", "qa", "kw", "iq"],
  "ME-ME": ["tr", "ir"],
  "ME-MET": ["dz", "km", "dj", "eg", "lb", "ly", "ma", "mr", "so", "sd", "tn"],
  "AS-WAS": [
    "ge",
    "am",
    "az",
    "tr",
    "cy",
    "sy",
    "lb",
    "il",
    "ps",
    "jo",
    "iq",
    "om",
    "ye",
    "kw",
    "bh",
    "qa",
    "sa",
    "my",
  ],
  "AS-SAS": [
    "lk",
    "bd",
    "in",
    "af",
    "pk",
    "bt",
    "np",
    "mv",
    "ir",
    "bn",
    "kh",
    "id",
    "la",
    "ph",
  ],
  "AS-EAS": [
    "cn",
    "mn",
    "kp",
    "kr",
    "jp",
    "hk",
    "tw",
    "mo",
    "sg",
    "th",
    "tl",
    "vn",
  ],
  "AS-CNA": ["ru", "tj", "uz", "kz", "tm", "kg"],
  "AF-NA": ["eg", "dz", "sd", "ma", "tn", "ly"],
  "AF-WA": [
    "ng",
    "gh",
    "ci",
    "ne",
    "bf",
    "ml",
    "sn",
    "gn",
    "bj",
    "tg",
    "sl",
    "lr",
    "gw",
    "mr",
    "cv",
    "gm",
  ],
  "AF-EA": [
    "et",
    "tz",
    "ke",
    "mz",
    "mg",
    "ug",
    "dj",
    "km",
    "mw",
    "zm",
    "td",
    "so",
    "zw",
    "rw",
    "bi",
    "ss",
    "mu",
    "er",
    "sc",
  ],
  "AF-SA": [
    "cd",
    "ao",
    "cm",
    "cg",
    "cf",
    "st",
    "ga",
    "gq",
    "sz",
    "za",
    "na",
    "bw",
    "ls",
  ],
  "EU-CE": [
    "cz",
    "pl",
    "hu",
    "de",
    "sk",
    "at",
    "li",
    "ch",
    "si",
    "hr",
    "rs",
    "ro",
    "bg",
    "al",
    "mk",
    "me",
    "ba",
  ],
  "EU-NE": ["gb", "se", "is", "dk", "fi", "ie", "no", "lt", "lv", "ee"],
  "EU-EE": ["ru", "ua", "pl", "ro", "cz", "hu", "by", "bg", "md", "sk"],
  "EU-SE": [
    "it",
    "es",
    "gr",
    "pt",
    "rs",
    "hr",
    "ba",
    "al",
    "mk",
    "si",
    "me",
    "mt",
    "ad",
    "sm",
    "va",
  ],
  "EU-WE": ["de", "nl", "be", "fr", "at", "ch", "lu", "mc", "li"],
  "OC-ANZ": ["au", "tk", "nz", "nu", "ck"],
  "OC-MS": ["pg", "vu", "fj", "sb"],
  "OC-MI": ["fm", "nr", "ki", "pw", "mh"],
  "OC-PO": ["tv", "ws", "to"],
  "AM-AM": [
    "ca",
    "cr",
    "gt",
    "sv",
    "hn",
    "jm",
    "mx",
    "ni",
    "pa",
    "kn",
    "lc",
    "us",
  ],
  "NAT-NAT": [
    "be",
    "ca",
    "dk",
    "fr",
    "cz",
    "de",
    "gr",
    "hu",
    "is",
    "it",
    "lu",
    "nl",
    "no",
    "pl",
    "pt",
    "es",
    "tr",
    "gb",
    "us",
  ],
  "CA-CA": [
    "jm",
    "bb",
    "tt",
    "bs",
    "ag",
    "dm",
    "gd",
    "lc",
    "do",
    "vc",
    "kn",
    "ht",
    "cu",
    "ms",
    "vi",
    "tc",
    "ai",
    "bz",
    "pr",
    "sr",
    "gy",
    "vg",
    "ky",
    "aw",
    "bq",
    "cw",
    "sx",
    "gp",
    "mq",
    "bm",
    "bl",
    "mf",
    "gf",
  ],
};

export default nestedSubCountires;
