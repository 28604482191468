import React, { useState } from "react";
import "./TranslationEstimator.css";

const TranslationEstimator = () => {
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [estimationResult, setEstimationResult] = useState({
    wordCount: "",
    price: "",
    wordsByPage: [],
  });

  const estimateTranslation = async () => {
    try {
      const response = await fetch(
        "https://jsonplaceholder.typicode.com/posts/1/"
      );
      const data = await response.json();

      setEstimationResult({
        wordCount: data.body.length,
        price: 10,
        wordsByPage: data.body.split(" "),
      });
    } catch (error) {
      console.error("Error fetching translation estimation:", error);
    }
  };

  return (
    <div className="w-[75%]">
      <div className="estimator flex flex-col items-center rounded-[25px] gap-[16px] p-[16px] w-[100%]">
        <div className="flex justify-start w-[100%]">
          <h1 className="font-[Lato] text-[24px]  font-medium">
            Check the translation estimation for your website
          </h1>
        </div>
        <div className="flex w-[100%] gap-[16px]">
          <input
            className="p-[16px] gap-[10px] rounded-[15px] w-[90%]"
            style={{ backgroundColor: "#fff" }}
            type="text"
            id="websiteUrl"
            placeholder="Enter URL"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
          />

          <button
            onClick={estimateTranslation}
            className="px-[24px] py-[16px] rounded-[15px] font-[Lato] text-[18px] text-medium"
          >
            Estimate
          </button>
        </div>
        <div
          id="result"
          style={{
            display: estimationResult.wordCount ? "block" : "none",
          }}
          className="w-[100%]"
        >
          <div className="result1 flex gap-[20px] w-[100%] py-[8px]">
            <p id="wordCount">
              {" "}
              <h1 className="font-[Lato] text-[64px] font-medium leading-normal">
                {estimationResult.wordCount}
              </h1>
              <span className="font-[Lato] text-[18px] font-medium leading-normal text-[#747474] -mt-2">
                Estimated Word Count
              </span>
            </p>
            <p id="price" className="">
              <h1 className="font-[Lato] text-[64px] font-medium leading-normal">
                ${estimationResult.price}
              </h1>
              <span className="font-[Lato] text-[18px] font-medium leading-normal text-[#747474] -mt-2">
                Estimated Price
              </span>
            </p>
          </div>
          <div className="page flex flex-col w-[100%] p-[16px] max-h-[300px] mt-4">
            <h3>Words by Page:</h3>
            <table className="mt-4">
              <tbody>
                {estimationResult.wordsByPage.map((word, index) => (
                  <>
                    <tr
                      key={index}
                      className="font-[Lato] text-[18px] font-medium leading-normal"
                    >
                      <td style={{ paddingRight: "0" }}>{word}</td>
                      <td style={{ paddingRight: "0", textAlign: "end" }}>
                        {index + 1}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div
                          className="w-[100%] mt-1"
                          style={{ borderBottom: "0.5px solid #8989FF" }}
                        ></div>
                      </td>
                      <td>
                        <div
                          className="w-[100%] mt-1"
                          style={{ borderBottom: "0.5px solid #8989FF" }}
                        ></div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranslationEstimator;
