import React, { Component } from "react";
import blogBanner from "./assets/images/blogBanner.png";

class HeroSection extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="bnr-sec center">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className="service-bnr-left-blog">
                  <div className="cptn-text" id="cptn-text">
                    <a
                    href="/"
                    target="_blank"
                    rel="noreferrer">
                      <h5>Glocalise</h5>
                    </a>
                    <h1 style={{ color: "#428cfa", fontSize: "80px" }}>
                      Blogs
                    </h1>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-6" style={{ overflow: "hidden" }}>
                <div className="service-hero-img">
                  <img
                    className="service-women-img1"
                    alt="img"
                    src={blogBanner}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeroSection;
