export const Language = [
  {
    code: "ES",
    image: "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg",
  },
  {
    code: "EU",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Flag_of_Europe.svg/255px-Flag_of_Europe.svg.png",
  },
  {
    code: "FI",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/800px-Flag_of_Finland.svg.png",
  },
  {
    code: "EN",
    image: "https://cdn.britannica.com/86/3286-050-F16637E1/Flag-Fiji.jpg",
  },
  {
    code: "FR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_Falkland_Islands.svg/2560px-Flag_of_the_Falkland_Islands.svg.png",
  },
  {
    code: "FR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg",
  },
  {
    code: "FO",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg",
  },
  {
    code: "FR",
    image: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
  },
  {
    code: "FR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg",
  },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
  },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg",
  },
  {
    code: "KA",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg",
  },
  {
    code: "FR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Flag_of_French_Guiana.svg/2560px-Flag_of_French_Guiana.svg.png",
  },
  {
    code: "FR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_Guernsey.svg/800px-Flag_of_Guernsey.svg.png",
  },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
  },
  //   {
  //     code: "EN",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Gibraltar.svg",
  //   },
  {
    code: "DA",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg",
  },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg",
  },
  {
    code: "FR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg",
  },
  //   {
  //     code: "FR",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Guadeloupe.svg",
  //   },
  {
    code: "ES",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg",
  },
  {
    code: "EL",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg",
  },
  //   {
  //     code: "ES",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/5/5a/Flag_of_Guatemala.svg",
  //   },
  //   {
  //     code: "EN",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guam.svg",
  //   },
  //   {
  //     code: "CH",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guam.svg",
  //   },
  {
    code: "PT",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg",
  },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg",
  },
  {
    code: "ZH",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg",
  },
  //   {
  //     code: "EN",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_Heard_Island_and_McDonald_Islands.svg",
  //   },
  {
    code: "ES",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg",
  },
  {
    code: "HR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
  },
  {
    code: "HT",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg",
  },
  {
    code: "HU",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
  },
  //   {
  //     code: "ES",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_the_Canary_Islands.svg",
  //   },
  {
    code: "ID",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg",
  },
  {
    code: "GA",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg",
  },
  {
    code: "HE",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
  },

  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg",
  },

  {
    code: "HI",
    image: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },

  //   {
  //     code: "EN",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_the_British_Indian_Ocean_Territory.svg",
  //   },

  {
    code: "AR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg",
  },
  {
    code: "FA",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
  },

  {
    code: "IS",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
  },

  {
    code: "IT",
    image: "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
  },

  //   {
  //     code: "EN",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Jersey.svg",
  //   },

  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg",
  },

  {
    code: "AR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg",
  },

  {
    code: "JA",
    image: "https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg",
  },

  {
    code: "SW",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
  },

  {
    code: "KY",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
  },

  {
    code: "KM",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
  },

  //   {
  //     code: "EN",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Kiribati.svg",
  //   },

  //   {
  //     code: "FR",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_the_Comoros.svg",
  //   },

  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg",
  },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg",
  },
  {
    code: "KP",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_North_Korea.svg",
  },
  {
    code: "KR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg",
  },
  //   {
  //     code: "XK",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Kosovo.svg",
  //   },
  {
    code: "KW",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
  },
  {
    code: "KW",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
  },
  {
    code: "KG",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
  },
  {
    code: "LA",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
  },
  {
    code: "LV",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
  },
  {
    code: "LB",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg",
  },
  {
    code: "LV",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
  },
  {
    code: "AR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg",
  },
  {
    code: "AR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg",
  },
  //   {
  //     code: "FR",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Monaco.svg",
  //   },
  {
    code: "RO",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg",
  },
  {
    code: "SR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg",
  },
  //   {
  //     code: "FR",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/5/5f/Flag_of_Saint_Martin.svg",
  //   },
  {
    code: "MG",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg",
  },
  //   {
  //     code: "EN",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Marshall_Islands.svg",
  //   },
  //   {
  //     code: "MK",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/f/f8/Flag_of_North_Macedonia.svg",
  //   },
  {
    code: "FR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg",
  },
  {
    code: "MY",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg",
  },

  {
    code: "MN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg",
  },

  //   {
  //     code: "ZH",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_the_People%27s_Republic_of_China.svg",
  //   },

  //   {
  //     code: "CH",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_the_Northern_Mariana_Islands.svg",
  //   },
  //   {
  //     code: "FR",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_France.svg",
  //   },

  {
    code: "AR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg",
  },

  //   {
  //     code: "EN",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Montserrat.svg",
  //   },

  {
    code: "MT",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg",
  },
  {
    code: "DV",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg",
  },
  {
    code: "NY",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg",
  },
  {
    code: "ES",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg",
  },
  {
    code: "MS",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg",
  },

  {
    code: "FR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_Ascension_Island.svg/1200px-Flag_of_Ascension_Island.svg.png",
    name: "Ascension Island",
  },
  {
    code: "CA",
    image: "https://cdn.britannica.com/83/5583-050-2F48FD32/Flag-Andorra.jpg",
    name: "Andorra",
  },
  {
    code: "AR",
    image:
      "https://cdn.pixabay.com/photo/2021/11/15/09/57/united-arab-emirates-6797567__340.png",
    name: "United Arab Emirates",
  },
  {
    code: "FA",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Flag_of_Afghanistan_%282013%E2%80%932021%29.svg/255px-Flag_of_Afghanistan_%282013%E2%80%932021%29.svg.png",
    name: "Afghanistan",
  },
  {
    code: "EN",
    image:
      "https://cdn.britannica.com/04/5104-050-9E557169/Flag-Antigua-and-Barbuda.jpg",
    name: "Antigua & Barbuda",
  },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg",
    name: "Anguilla",
  },
  {
    code: "SQ",
    image: "https://cdn.britannica.com/00/6200-004-42B7690E/Flag-Albania.jpg",
    name: "Albania",
  },
  {
    code: "HY",
    image: "https://cdn.britannica.com/11/4711-004-1504C169/Flag-Armenia.jpg",
    name: "Armenia",
  },
  {
    code: "PT",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Flag_of_Angola.svg/255px-Flag_of_Angola.svg.png",
    name: "Angola",
  },

  {
    code: "ES",
    image: "https://cdn.britannica.com/69/5869-004-7D75CD05/Flag-Argentina.jpg",
    name: "Argentina",
  },
  {
    code: "SM",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Flag_of_American_Samoa.svg/1200px-Flag_of_American_Samoa.svg.png",
    name: "American Samoa",
  },
  {
    code: "DE",
    image: "https://cdn.britannica.com/73/6073-004-B0B9EBEE/Flag-Austria.jpg",
    name: "Austria",
  },
  {
    code: "EN",
    image: "https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg",
    name: "Australia",
  },
  {
    code: "NL",
    image: "https://cdn.britannica.com/54/69554-050-D246E82A/Flag-Aruba.jpg",
    name: "Aruba",
  },
  {
    code: "DA",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_%C3%85land.svg",
    name: "Åland Islands",
  },
  {
    code: "AZ",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
    name: "Azerbaijan",
  },
  {
    code: "PT",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg",
  },

  {
    code: "NA",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg",
  },

  //   {
  //     code: "NC",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_New_Caledonia.svg",
  //   },

  {
    code: "NE",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg",
  },

  //   {
  //     code: "NF",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Norfolk_Island.svg",
  //   },
  {
    code: "NG",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
  },

  {
    code: "NI",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg",
  },

  {
    code: "NL",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
  },

  {
    code: "NO",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
  },

  {
    code: "NP",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
  },
  {
    code: "NE",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
  },
  //   {
  //     code: "FR",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nauru.svg",
  //   },
  //   {
  //     code: "EN",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/d/de/Flag_of_Niue.svg",
  //   },
  {
    code: "MA",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg",
  },
  {
    code: "AR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg",
  },
  {
    code: "ES",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg",
  },
  {
    code: "ES",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg",
  },
  {
    code: "FR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg",
  },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg",
  },
  {
    code: "FIL",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg",
  },
  {
    code: "UR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
  },
  {
    code: "PL",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg",
  },
  //   {
  //     code: "FR",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_France_%28local%29.svg",
  //   },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg",
  },
  {
    code: "ES",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg",
  },
  {
    code: "AR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Palestine.svg",
  },
  {
    code: "PT",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg",
  },
  {
    code: "EN",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg",
  },
  {
    code: "ES",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg",
  },
  {
    code: "AR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
  },
  //   {
  //     code: "RW",
  //     image:
  //       "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_R%C3%A9union_%28local%29.svg",
  //   },
  {
    code: "RO",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg",
  },
  {
    code: "SR",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg",
  },
  {
    code: "RU",
    image: "https://upload.wikimedia.org/wikipedia/en/f/f3/Flag_of_Russia.svg",
  },
];

const languageWithFlags = {};
Language.forEach((lan) => {
  languageWithFlags[lan.code] = lan.image;
});
export default languageWithFlags;
