import React, { Component } from "react";
import poster from "./assets/images/Brand identity.png";

class Branding extends Component {
  render() {
    return (
      <React.Fragment>
          <div className="center">
            <div className="what-bsns-row">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-6 center">
                    <div className="bsns-right-sec">
                      <div className="srvc-item-sec">
                        <div className="center">
                            <img alt="img" src={poster} />
                        </div>
                        <h3 style={{ fontSize: "1.75rem" }}>SEO Enhancement</h3>
                        <p className="serviceP">
                          SEO Enhancement that brings the traffic that you
                          always needed, by going beyond the borders of search
                          engine algorithms.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-6">
                    <div className="bsns-right-sec">
                      <p className="small-heading">SEO</p>
                      <br></br>
                      <p className="large-heading">Enhancement</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

export default Branding;
