import React, { Component } from "react";
import Portfolio from "./assets/images/Portfolio-.png";

class Website extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="center">
          <div className="what-bsns-row center">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-7 col-md-6"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="bsns-left-sec">
                    <div className="srvc-item-sec">
                      <div className="center">
                        <img src={Portfolio}></img>
                      </div>
                      <h3 style={{ fontSize: "1.75rem" }}>
                        ML Based Analytics
                      </h3>
                      <p className="serviceP">
                        Everything you ever wanted to know about your
                        website.... ...but your analytics never told you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="bsns-right-sec">
                    <p className="small-heading">ML Based</p>
                    <br></br>
                    <p className="large-heading">Analytics</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Website;
