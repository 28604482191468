import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import "./Helping.css";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import "prismjs/themes/prism-tomorrow.css";
import axios from "axios";
function Helping({ onClick, settoggle }) {
  const [close, setClose] = useState(true);
  const handleClose = () => {
    setClose(!close);
    settoggle(true);
    onClick();
  };

  const navigate = useNavigate();

  return (
    <div className={close ? "helping-main-body" : "closesidebar"}>
      <div className="Reactangle d-flex justify-content-between mt-50px">
        <h3 className="chatbot-title">
          <ArrowBackIosIcon
            style={{
              marginLeft: "-10px",
              marginRight: "10px",
              marginTop: "0px",
              fontSize: "30px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
          Help Center
          <CloseIcon
            style={{
              fontSize: "40px",
              marginTop: "30px",
              position: "absolute",
              right: "10px",
              top: "0px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </h3>
      </div>
      <div
        className="col-6"
        style={{
          height: "50px",
          margin: "20px 0px",
        }}
      >
        <form
          className="container-fluid"
          style={{ alignItems: "center", Padding: "10px", width: "100%" }}
        >
          <div
            className="input-group"
            style={{
              marginTop: "4px",
              width: "500px",
              marginLeft: "-26px",
            }}
          >
            <span
              className="input-group-text"
              style={{ background: "#EDEDED" }}
              id="basic-addon1"
            >
              <SearchIcon></SearchIcon>
            </span>
            <input
              type="text"
              className="form-control"
              style={{ outline: "none", backgroundColor: "skyblue" }}
              placeholder=""
              aria-label="Username"
            />
          </div>
        </form>
      </div>

      <div className="iframe-body">
        <iframe
          width="500px"
          height="540px"
          src="https://e.notionhero.io/e1/p/c4f5936-f706200e8fdac01e9ce434dd42dcce6"
        ></iframe>
      </div>
    </div>
  );
}

export default Helping;
