import axios from "axios";
// change the baseUrl according to given server port e.g., 8000...,

const BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api/admin/`;

// const customapi="https://naman-atiuttam-studious-system-4xjjrr544g525gxw-8800.preview.app.github.dev/";
export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

let TOKEN = "";
if (
  localStorage.getItem("persist:root") &&
  JSON.parse(localStorage.getItem("persist:root")).user &&
  JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user) &&
  JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser
) {
  TOKEN = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
    .currentUser.token;
}
export const userRequestCustom = axios.create({
  baseURL: BASE_URL,
  headers: {
    "auth-token": TOKEN,
  },
});
