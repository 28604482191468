/**
 * * Mapping structure
 * * [region-id] => list of regions
 */
const subRegions = {
  "ME-ME": ["LE", "AP", "ME"],
  "ME-AF": ["NA"],
  "EM-ME": ["LE", "AP", "ME", "MET"],
  "EM-EU": ["EE", "WE", "NE", "SE"],
  "EM-AS": ["WAS", "EAS", "CNA", "SSA"],
  "AP-AS": ["EAS", "SAS", "SES"],
  "AP-OC": ["ANZ", "MS", "MI", "PO"],
  "EE-AF": ["WA", "EA", "NA", "MA", "SA"],
  "EE-EU": ["EE"],
  "EE-ME": ["MET", "ME", "AP", "LE"],
  "SE-EU": ["SE"],
  "SE-ME": ["LE", "AP", "ME", "MET"],
  "SE-AF": ["WA", "EA", "NA", "MA", "SA"],
  "CE-EU": ["CE"],
  "CE-AF": ["EA", "SA", "WA", "NA", "MA"],
  "CE-ME": ["LE", "AP", "ME", "MET"],
  "CEE-EU": ["CE", "EE"],
  "EU-AF": ["NA"],
  "EU-EU": ["EE", "WE", "NE", "SE"],
  "EU-ME": ["LE", "AP", "ME", "MET"],
  "EME-AF": ["WA", "EA", "NA", "MA", "SA"],
  "EME-EU": ["EE", "WE", "NE", "SE"],
  "EME-ME": ["LE", "AP", "ME", "MET"],
  "EMEA-AF": ["WA", "EA", "NA", "MA", "SA"],
  "EMEA-CA": ["CA"],
  "EMEA-EU": ["EE", "WE", "NE", "SE"],
  "EMEA-ME": ["LE", "AP", "ME", "MET"],
  "NAC-EU": ["CE"],
  "NAC-NAT": ["NAT"],
  "CEM-EU": ["EE"],
  "NA-AM": ["AM"],
  "NA-CA": ["CA"],
  "CEM-AS": ["CAS", "CAU"],
};
export const allSubRegions = {
  ME: ["LE", "AP", "ME", "MET"],
  AF: ["NA", "WA", "EA", "SA"],
  EU: ["NE", "EE", "SE", "WE", "CE"],
  AS: ["WAS", "SAS", "EAS", "CNA"],
  OC: ["ANZ", "MS", "MI", "PO"],
  AM: ["AM"],
  CA: ["CA"],
  NAT: ["NAT"],
};

export default subRegions;

export const subRegionNames = {
  LE: "Levant",
  AP: "Arabian Peninsula",
  WAS: "Western Asia",
  ME: "Mesopotamian",
  MET: "Me Tier",
  NA: "North Africa",
  EAS: "East Asia",
  SAS: "South Asia",
  EE: "Eastern Europe",
  MA: "Middle Africa",
  SES: "Southeast Asia",
  IN: "India",
  CNA: "Cental & North Asia",
  SSA: "South & Southeast Asia",
  WA: "Western Africa",
  EA: "Eastern Africa",
  SA: "Southern Africa",
  NE: "North Europe",
  SE: "Southern Europe",
  WE: "Western Europe",
  CE: "Central Europe",
  ANZ: "Australia and New Zealand",
  MS: "Melanesia",
  MI: "Micronesia",
  PO: "Polynesia",
  CA: "Carebian",
  AM: "Amer",
  NAT: "The North Atlantic",
  CAU: "Caucasus",
  CAS: "Central Asia",
};
