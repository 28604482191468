import React from 'react'
import "./top8form.css"
const Top8Form = ({setUserData}) => {
  const [role,setRole]=React.useState("");
  const handleChange=(e)=>{
    setUserData((prev)=>[prev[0],prev[1],prev[2],prev[3],prev[4],prev[5],prev[6],prev[7],prev[8],e.target.value]);
    setRole(e.target.value);
  }
  return (
    <div className="top8form-container">
    <div className="top8form-wrapper">
<h2 className="top8-head">How did you hear about us?</h2>
<select name="" value={role} onChange={(e)=>handleChange(e)} id="" className='input-top8' required >
<option value="" className='input-option' defaultValue={true} disabled hidden >Choose an option</option>
<option value="twitter">Twitter</option>
<option value="linkedin">Linkedin</option>
<option value="tv">TV</option>
<option value="ads">Ads</option>
<option value="others">Others</option>

</select>
    </div>
</div>
  )
}

export default Top8Form