import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const URL_EXTRACT_REGEX = /(?:www\.)?([^:\/\n?]+)/g;
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function getDomainName(url) {
  const match = url.replaceAll("https://", "").match(URL_EXTRACT_REGEX);
  return match ? match[0] : null;
}
