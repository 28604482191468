// import React from 'react'
// import "./chatmonitoring.css"
// import CloseIcon from '@mui/icons-material/Close';
// import { Link, useNavigate } from "react-router-dom";
// import DataTable from './DataTable1';
// import Chart from './chart';
// import { useState } from 'react';
// import { UserData } from './Data';
// const Chatmonitoring = () => {
//     const navigate = useNavigate();
//     const [userdata, setuserdata] = useState({
//         labels: UserData.map((data) => data.year),
//         datasets: [{
//             label: "Users Gained",
//             data: UserData.map((data) => data.userGain),
//             borderWidth: 1,
//             backgroundColor: [
//                 'rgba(255, 99, 132, 0.2)',
//                 'rgba(255, 159, 64, 0.2)',
//                 'rgba(255, 205, 86, 0.2)',
//                 'rgba(75, 192, 192, 0.2)',
//                 'rgba(54, 162, 235, 0.2)',
//                 'rgba(153, 102, 255, 0.2)',
//                 'rgba(201, 203, 207, 0.2)'
//             ],

//             // barPercentage: 0.5,
//             borderRadius: 5

//         }],

//     })

//     const [personal, setPersonal] = useState(true);

//     return (
//         <>
//             <div className="firstcont">
//                 <CloseIcon className='cross' style={{ fontSize: '1.4rem', cursor: "pointer" }} onClick={() => { navigate("/Language-&-Region") }} />
//                 <div className="settings">Settings</div>

//             </div>
//             <div class="secondcont">

//                 <div class="row">
//                     <div class="col-2" style={{ border: '0.5px solid rgba(242, 242, 242, 1)', fontWeight: '10', padding: '0', margin: '0', position: 'relative' }}>
//                         <div className='adminsettings'>ADMIN SETTINGS</div>
//                         <div className='chatmonitoring' style={{ borderRight: personal ? "2px solid rgba(57, 198, 164, 1)" : "none" }}>
//                             <a href="#about1" style={{ color: personal ? "rgba(57, 198, 164, 1)" : "rgba(151, 155, 153, 1)", textDecoration: "none", fontWeight: "500" }} onClick={() => {
//                                 setPersonal(true);
//                             }}>Chat monitoring</a>
//                         </div>
//                         <div className='userdatabase' style={{ borderRight: !personal ? "2px solid rgba(57, 198, 164, 1)" : "none", fontWeight: "500" }}><a href="#about" style={{ color: !personal ? "rgba(57, 198, 164, 1)" : "rgba(151, 155, 153, 1)", textDecoration: "none" }} onClick={() => {
//                             setPersonal(false);
//                         }}>User database</a></div>
//                     </div>

//                     <div class="col-10" style={{ borderLeft: '0.5px solid rgba(242, 242, 242, 1)', borderTop: '0.5px solid rgba(242, 242, 242, 1)', borderRight: '0.5px solid rgba(242, 242, 242, 1)', paddingLeft: '3%' }}>
//                         <h6 className='chatmonitoring6' id="about1">Chat monitoring</h6>
//                         <div className="table_with_data" style={{ position: 'relative', marginBottom: "10%", marginTop: "3%" }}>
//                             <DataTable />
//                             <input className="btn btn-dark user_btn" type="submit" value="Download users" style={{ position: 'absolute' }} />
//                         </div>

//                         <h6 id="about" style={{ fontFamily: "'Lato', sans-serif", fontWeight: "600", fontSize: "1.2rem" }}>Most used languages</h6>
//                         <div className="chart" style={{ width: "70%", border: "1px solid rgba(238, 236, 236, 1)", borderRadius: "0.5rem", justifyContent: "center", textAlign: "center", alignItems: "center", paddingTop: "3%", paddingRight: "3%" }}>
//                             <Chart chartdata={userdata} />
//                         </div>

//                     </div>

//                 </div>
//             </div>
//         </>
//     )
// }

// export default Chatmonitoring

import React from "react";
import "../settings/chatmonitoring.css";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "./DataTable1";
import Chart from "./chart";
import { useState } from "react";
import { UserData } from "./Data";
import { Visibility } from "@mui/icons-material";
const Chatmonitoring = () => {
  const navigate = useNavigate();
  const [userdata, setuserdata] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        borderWidth: 1,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],

        // barPercentage: 0.5,
        borderRadius: 5,
      },
    ],
  });

  const [personal, setPersonal] = useState(true);
  const [verify, setVerify] = useState(true);
  return (
    <>
      <div className="firstcont">
        <CloseIcon
          className="cross"
          style={{ fontSize: "1.4rem", cursor: "pointer" }}
          onClick={() => {
            navigate("/Language-&-Region");
          }}
        />
        <div className="settings">Settings</div>
      </div>
      <div class="secondcont">
        <div class="row">
          <div
            class="col-2"
            style={{
              border: "0.5px solid rgba(242, 242, 242, 1)",
              fontWeight: "10",
              padding: "0",
              margin: "0",
              position: "relative",
              height: "90vh",
            }}
          >
            <div className="adminsettings">ADMIN SETTINGS</div>
            <div
              className="chatmonitoring"
              style={{
                borderRight: personal
                  ? "2px solid rgba(57, 198, 164, 1)"
                  : "none",
              }}
            >
              <a
                style={{
                  color: personal
                    ? "rgba(57, 198, 164, 1)"
                    : "rgba(151, 155, 153, 1)",
                  textDecoration: "none",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPersonal(true);
                  setVerify(true);
                }}
              >
                Chat monitoring
              </a>
            </div>
            <div
              className="userdatabase"
              style={{
                borderRight: !personal
                  ? "2px solid rgba(57, 198, 164, 1)"
                  : "none",
                fontWeight: "500",
                marginTop: "50%",
              }}
            >
              <a
                style={{
                  color: !personal
                    ? "rgba(57, 198, 164, 1)"
                    : "rgba(151, 155, 153, 1)",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPersonal(false);
                  setVerify(false);
                }}
              >
                User database
              </a>
            </div>
          </div>

          <div
            class="col-10"
            style={{
              borderLeft: "0.5px solid rgba(242, 242, 242, 1)",
              borderTop: "0.5px solid rgba(242, 242, 242, 1)",
              borderRight: "0.5px solid rgba(242, 242, 242, 1)",
              paddingLeft: "3%",
            }}
          >
            {verify ? (
              <>
                {" "}
                <h6 className="chatmonitoring6" id="about1">
                  Chat monitoring
                </h6>
                <div
                  className="table_with_data"
                  style={{
                    position: "relative",
                    marginBottom: "10%",
                    marginTop: "3%",
                  }}
                >
                  <DataTable />
                  <input
                    className="btn btn-dark user_btn"
                    type="submit"
                    value="Download users"
                    style={{ position: "absolute" }}
                  />
                </div>
              </>
            ) : (
              <>
                <h6
                  style={{
                    fontFamily: "'Lato', sans-serif",
                    fontWeight: "600",
                    fontSize: "1.5rem",
                    marginTop: "5.5%",
                    marginBottom: "3%",
                  }}
                >
                  Most used languages
                </h6>
                <div
                  className="chart"
                  style={{
                    width: "70%",
                    border: "1px solid rgba(238, 236, 236, 1)",
                    borderRadius: "0.5rem",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "3%",
                    paddingRight: "3%",
                  }}
                >
                  <Chart chartdata={userdata} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chatmonitoring;
