import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { Zoom } from "@mui/material";
function TimeSpent() {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar("Still Under Development", {
      variant: "default",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
    });
  }, []);
  return (
    // <div className='d-flex align-items-center justify-content-center text-light'>
    //   TIME SPENT PAGE
    // </div>
    <></>
  );
}

export default TimeSpent;
