import React, { Component } from "react";
import pixeltrue from "./assets/images/pixeltrue-plan-1.png";

class Banner extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="bnr-sec center">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className="service-bnr-left">
                  <div className="cptn-text" id="cptn-text">
                    <h5>
                      Think
                      <a href="/" target="_blank" rel="noreferrer">
                        <h5>Glocali.se</h5>
                      </a>
                    </h5>
                    <h1>Don't</h1>
                    <h1>Compromise</h1>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-6" style={{ overflow: "hidden" }}>
                <div className="service-hero-img">
                  <img
                    className="service-women-img"
                    alt="img"
                    src={pixeltrue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Banner;
