import React from "react";
import "./Sidebar.css";
import { Zoom, Tooltip } from "@mui/material";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { clearAll } from "../../redux/dataSlices";
import { setGetSSO } from "../../redux/userRedux";
const Sidebar = ({ onToggleNavbar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    if (onToggleNavbar) {
      onToggleNavbar();
    }
  };
  const [toglehover, setToglehover] = useState({
    Home: false,
    ChatBot: false,
    "Analytics & Statistics": false,
    "Language & Region": false,
    "Data Management": false,
    Billing: false,
    Settings: false,
    Logout: false,
  });
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    try {
      localStorage.clear();
      dispatch(clearAll());
      dispatch(setGetSSO(false));
      enqueueSnackbar("successfully logout", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Zoom,
      });

      // window.location.replace("http://localhost:3000/signup/?logout=true");
      navigate("/signup?logout=true");
    } catch (error) {
      console.log(error);
    }
  };
  const navigating = () => {
    navigate("/");
  };
  const handleMouseEnter = (itemName) => {
    setToglehover((to) => ({ ...to, [itemName]: true }));
  };
  const handleMouseLeave = (itemName) => {
    setToglehover((to) => ({ ...to, [itemName]: false }));
  };
  const menuItem = [
    {
      path: "/home",
      name: "Home",
      icon: (
        <img
          className="img-fluid"
          style={{ margin: "0px 16px", width: "24px", height: "24px" }}
          src={
            toglehover.Home
              ? "./header_images/home-color.svg"
              : "./header_images/home.svg"
          }
          alt=""
        />
      ),
      onClick: () => {
        enqueueSnackbar("Still Under Development", {
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
        });
      },
      onMouseEnter: () => handleMouseEnter("Home"),
      onMouseLeave: () => handleMouseLeave("Home"),
    },

    // {
    //   path: "/chatbot",
    //   name: "Chatbot",
    //   icon: (
    //     <img
    //       className="img-fluid"
    //       alt=""
    //       style={{ margin: "0px 16px", width: "24px", height: "24px" }}
    //       src={
    //         toglehover["ChatBot"] || pathname === "/chatbot"
    //           ? "./header_images/message-color.png"
    //           : "./header_images/message.png"
    //       }
    //     />
    //   ),
    //   onClick: () => {
    //     enqueueSnackbar("Still Under Development", {
    //       variant: "default",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //       TransitionComponent: Zoom,
    //     });
    //   },
    //   onMouseEnter: () => handleMouseEnter("ChatBot"),
    //   onMouseLeave: () => handleMouseLeave("ChatBot"),
    // },
    // {
    //   path: "/timespent",
    //   name: "Analytics & Statistics",
    //   icon: (
    //     <img
    //       style={{ margin: "0px 16px", width: "24px", height: "24px" }}
    //       src={
    //         toglehover["Analytics & Statistics"]
    //           ? "./header_images/pie-chart-color.svg"
    //           : "./header_images/pie-chart.svg"
    //       }
    //       className="img-fluid "
    //       alt=""
    //     />
    //   ),
    //   onClick: () => {
    //     enqueueSnackbar("Still Under Development", {
    //       variant: "default",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //       TransitionComponent: Zoom,
    //     });
    //   },
    //   onMouseEnter: () => {
    //     handleMouseEnter("Analytics & Statistics");
    //   },
    //   onMouseLeave: () => {
    //     handleMouseLeave("Analytics & Statistics");
    //   },
    // },
    // {
    //   path: "/datamanage",
    //   name: "Data Management",
    //   icon: (
    //     <img
    //       style={{ margin: "0px 16px", width: "24px", height: "24px" }}
    //       src={
    //         toglehover["Data management"] || pathname === "/datamanage"
    //           ? "./header_images/command-color.svg"
    //           : "./header_images/command.svg"
    //       }
    //       className="img-fluid "
    //       alt=""
    //     />
    //   ),
    //   onClick: () => {},
    //   onMouseEnter: () => {
    //     handleMouseEnter("Data management");
    //   },
    //   onMouseLeave: () => {
    //     handleMouseLeave("Data management");
    //   },
    // },
    {
      path: "/Language-&-Region",
      name: "Language & Region",
      icon: (
        <img
          alt=""
          className="img-fluid"
          style={{ margin: "0px 16px", width: "24px", height: "24px" }}
          src={
            toglehover["Language & Region"] || pathname === "/Language-&-Region"
              ? "./header_images/languagecircle-color.svg"
              : "./header_images/languagecircle.svg"
          }
        />
      ),
      onClick: () => {},
      onMouseEnter: () => {
        handleMouseEnter("Language & Region");
      },
      onMouseLeave: () => {
        handleMouseLeave("Language & Region");
      },
    },
    {
      path: "/payment",
      name: "Billing",
      icon: (
        <img
          className="img-fluid"
          alt=""
          style={{ margin: "0px 16px", width: "24px", height: "24px" }}
          src={
            toglehover["Billing"] || pathname === "/payment"
              ? "./header_images/BillingIcon-color.png"
              : "./header_images/BillingIcon.png"
          }
        />
      ),
      onClick: () => {},
      onMouseEnter: () => handleMouseEnter("Billing"),
      onMouseLeave: () => handleMouseLeave("Billing"),
    },
    {
      path: "/settings",
      name: "Settings",
      icon: (
        <img
          alt=""
          className="img-fluid"
          style={{ margin: "0px 16px", width: "24px", height: "24px" }}
          src={
            toglehover.Settings
              ? "./header_images/settings-color.svg"
              : "./header_images/settings.svg"
          }
        />
      ),
      onClick: () => {},
      onMouseEnter: () => {
        handleMouseEnter("Settings");
      },
      onMouseLeave: () => {
        handleMouseLeave("Settings");
      },
    },
    {
      path: "/",
      name: "Logout",
      icon: (
        <img
          className="img-fluid"
          alt=""
          style={{ margin: "0px 16px", width: "24px", height: "24px" }}
          src={
            toglehover.Logout
              ? "./header_images/logout color.png"
              : "./header_images/logout.png"
          }
        />
      ),
      onClick: () => {
        logout();
      },
      onMouseEnter: () => {
        handleMouseEnter("Logout");
      },
      onMouseLeave: () => {
        handleMouseLeave("Logout");
      },
    },
  ];
  return (
    <div
      className="sidebar__container flex z-10 mr-12"
      style={{
        position: "fixed",
        top: 0, // Adjust this value based on your layout
        left: 0,
        maxWidth: isOpen ? "23rem" : "8rem",
        transition: "1s ease-in-out",
      }}
    >
      <div
        style={{
          width: isOpen ? "21rem" : "3.5rem",
          transition: "1s ease-in-out",
        }}
        className="sidebar justify-center align-middle"
      >
        <div
          style={{
            width: isOpen ? "20rem" : "6rem",
            transition: "width 1s ease-in-out",
          }}
          className="sidebar__top-div mt-9 justify-between align-middle flex"
        >
          <div className="brand-icon align-middle">
            <NavLink className="sidebar__logoText" to="/">
              <div
                className={`flex  ${isOpen ? "pl-14" : "pl-3"}`}
                style={{
                  transition: "padding 1s ease-in-out",
                  overflow: "hidden",
                }}
              >
                {isOpen ? (
                  <img
                    style={{ width: "12rem", height: "auto" }}
                    className="img-fluid"
                    src="\images\logo\Vector 10.png"
                    alt="logo.png"
                    onClick={navigating}
                  />
                ) : (
                  <img
                    style={{ width: "2.5rem", height: "auto" }}
                    className="img-fluid"
                    src="/favicon.ico.png"
                    alt="logo.png"
                    onClick={navigating}
                  />
                )}

                <div
                  style={{
                    width: isOpen ? "100%" : "0",
                    display: isOpen ? "flex" : "none",
                    transition:
                      "width 1s ease-in-out, padding 1s ease-in-out, display 1s ease-in-out",
                  }}
                  className="flex"
                >
                  {/* <p>Glocali</p> */}
                </div>
              </div>
            </NavLink>
          </div>
          <Tooltip title={!isOpen ? "Open" : "Close"} placement="right">
            <div
              className={"flex align-middle sidebar__bars"}
              style={{
                cursor: "pointer",
              }}
              onClick={toggle}
            >
              {isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 24 24"
                >
                  <g
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  >
                    <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
                    <path d="M9 3v18m7-6l-3-3l3-3" />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 24 24"
                >
                  <g
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  >
                    <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
                    <path d="M9 3v18m5-12l3 3l-3 3" />
                  </g>
                </svg>
              )}
            </div>
          </Tooltip>
        </div>

        <div
          className="sidebar__NavLink-section mt-14"
          style={{
            paddingLeft: isOpen ? "5vw" : "0",
            transition: " padding 1s ease-in-out",
            overflow: "hidden",
          }}
        >
          {menuItem.map((item, index) => (
            <Tooltip
              key={item.path}
              title={!isOpen ? item.name : ""}
              placement="right"
            >
              <NavLink
                to={item.path}
                key={index}
                className="sidebar__navlink"
                onClick={item.onClick}
                onMouseEnter={item.onMouseEnter}
                onMouseLeave={item.onMouseLeave}
              >
                <div className="icon">{item.icon}</div>

                <h6
                  style={{
                    width: isOpen ? "100%" : "0",
                    paddingLeft: isOpen ? "1rem" : "0",
                    display: isOpen ? "flex" : "none",
                    transition:
                      "width 1s ease-in-out, padding 1s ease-in-out, display 1s ease-in-out",
                  }}
                >
                  {item.name}
                </h6>
              </NavLink>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
