import React from "react";

function ChatComponent({
  setDefaultMsg,
  setSendMsg,
  chatMsg,
  setChatMsg,
  setClickSend,
  setMsgValue,
  defaultMsg,
  clickSend,
  msgValue,
  service,
}) {
  return (
    <div>
      <div
        className="chat-popup"
        style={{ display: defaultMsg ? "flex" : "none" }}
      >
        <div className="chat-popup-inside">
          <div
            className="upper-bar"
            style={{ justifyContent: "space-between" }}
          >
            <i
              className="bi bi-chevron-left back-btn"
              onClick={() => {
                setDefaultMsg(false);
                setSendMsg(true);
              }}
              style={{ cursor: "pointer" }}
            ></i>
            <div className="call-div">
              <div className="d-flex flex-row align-items-center">
                <img src="/images/other/Group 2026.png" alt="" />
                <div
                  className="d-flex flex-column align-items-center text-dark mx-1"
                  style={{ fontSize: "9px" }}
                >
                  <div style={{ marginBottom: "-2px", fontWeight: "600" }}>
                    Jonny
                  </div>
                  <div>online</div>
                </div>
              </div>
              {/* <div className="d-flex align-items-center justify-content-center call-btn">

                  <img src="/images/other/headphones.png" alt="" />

                  </div> */}
            </div>
          </div>
          <div className="chat-container-2">
            <div className="d-flex flex-row">
              <div className="team-text">
                Hello, our {service} team is online. How can we help?
              </div>
            </div>
            {chatMsg.map((e) => (
              <div key={e} className="d-flex justify-content-end mt-2">
                <div
                  className="user-text"
                  style={{ display: clickSend ? "flex" : "none" }}
                >
                  {e}
                </div>
              </div>
            ))}
          </div>
          <div className="msg-hero">
            {/* <i class="bi bi-link-45deg msg-i-hero"></i> */}
            <img
              src="/images/other/msg-link.png"
              alt=""
              className="msg-i-hero"
            />
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                setClickSend(true);
                setChatMsg([...chatMsg, msgValue]);
                setMsgValue("");
              }}
            >
              <input
                type="text"
                placeholder="Type here"
                className="msg-div-hero"
                onChange={(e) => setMsgValue(e.target.value)}
                value={msgValue}
              />
            </form>
            <i className="bi bi-emoji-smile smiley-hero"></i>

            {/* </div> */}
            <img
              src="/images/other/global.png"
              alt=""
              className="globee-hero"
            />
            <img
              src="/images/other/send.png"
              alt=""
              className="send-icon-after"
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "2px",
                cursor: "pointer",
                zIndex: "1000",
              }}
              onClick={() => {
                setClickSend(true);
                setChatMsg([...chatMsg, msgValue]);
                setMsgValue("");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatComponent;
