import React, { useEffect, useRef, useState } from "react";
import Blog from "../../components/blogs/Blog";
import Footer from "../../components/footer/Footer";
import HeroComponent from "../../components/herocomponent/HeroComponent";
import Localize from "../../components/localize/Localize";
import Navbar from "../../components/navbar/Navbar";
import NewsLetter from "../../components/newsletter/NewsLetter";
import Seo from "../../components/seo/Seo";
import Services from "../../components/services/Services";
import SponserLogo from "../../components/sponsorlogo/SponserLogo";
import Stats from "../../components/stats/Stats";
import Stepper from "../../components/stepper/Stepper";
import Notification from "../../components/notification/Notification";
import { generateJobPostings } from "../../data/jobSchema";

const Home = ({ domain, setDomain }) => {
  const servicesRef = useRef(null);
  const [jobPostings, setJobPostings] = useState([]);
  const blogsRef = useRef(null);

  const scrollToServices = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBlogs = () => {
    blogsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchJobMeta = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/visitor/jobs`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        // console.log("data",data)
        setJobPostings(data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchJobMeta();
  }, []);
  // console.log(jobPostings);
  useEffect(() => {
    if (jobPostings.length > 0) {
      const metaTags = generateMetaTags(jobPostings);

      const scriptTags = generateJobPostings(jobPostings);
      insertMetaAndScriptTags(metaTags,scriptTags);
    }
  }, [jobPostings]);

  function generateMetaTags(jobPostings) {
    const metaTags = [];
    jobPostings.forEach((job) => {
      const title = job.Role;
      const description = job.descp;
      metaTags.push({
        title,
        description,
      });
    });
    return metaTags;
  }

  // Insert meta tags into DOM
  const insertMetaAndScriptTags = (metaTags, scriptTags) => {
    console.log('inserted')
    metaTags.forEach((tag) => {
      const metaTag = document.createElement("meta");
      const titleTag = document.createElement("title");
      metaTag.setAttribute("name", "description");
      metaTag.setAttribute("content", tag.description);
      titleTag.innerText = tag.title;
      document.head.appendChild(metaTag);
      document.head.appendChild(titleTag);

    });
    scriptTags.forEach((content) => {
      const scriptTag = document.createElement("script");
      scriptTag.type = "application/ld+json";
      // console.log((JSON.parse(content)));
      const parsedContent = typeof content === 'string' ? JSON.parse(content) : content;
      scriptTag.textContent = JSON.stringify(parsedContent);
      document.head.appendChild(scriptTag);
    });
    
  };

 
  return (
    <>
      <Notification />
      <Navbar
        scrollToServices={scrollToServices}
        scrollToBlogs={scrollToBlogs}
      />
      <HeroComponent setDomain={setDomain} domain={domain} />
      <SponserLogo />
      <Stepper />
      <Services serviceref={servicesRef} />
      <Localize />
      <Seo />
      <Stats />
      <Blog blogref={blogsRef} />
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Home;
